import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import "./CompleteControls.scoped.scss";

interface ICompleteControlsProps {
  disabled: boolean,
  isStepCompleted: boolean,
  hasIncompleteInputs: boolean,
  onClick: () => void,
  autoComplete: boolean,
}

const CompleteControls: React.FC<ICompleteControlsProps> = (props) =>
{
  const { t } = useTranslation('step');
  const completeButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (props.autoComplete 
      && !props.hasIncompleteInputs 
      && !props.isStepCompleted) {
      completeButtonRef.current?.click();
    }
  }, [props.hasIncompleteInputs, props.isStepCompleted, props.autoComplete]);

  const buttonClassName = "primary-button " + ((props.autoComplete && !props.isStepCompleted) ? "hidden" : "");

  return(
    <button
      onClick={props.disabled
        ? undefined
        : props.onClick}
      disabled={props.disabled
        || (!props.isStepCompleted
          && props.hasIncompleteInputs)}
      className={buttonClassName}
      ref={completeButtonRef}
    >
      {props.isStepCompleted
        ? t("Reopen Step")
        : t("Complete Step")
      }
    </button>);
}

export default CompleteControls;