import React from "react";
import { SWStatus } from "interfaces/execution/executionInterfaces";
import "./SWStatusIndicator.scoped.scss";
import { useTranslation } from "react-i18next";

interface ISWStatusIndicatorProps {
  status: SWStatus,
  completedOnPaper: boolean,
}

const SWStatusIndicator: React.FC<ISWStatusIndicatorProps> = ({
  status,
  completedOnPaper,
}) => 
{ 
  const { t } = useTranslation('executionSW');
  return (
    <span
      className="sw-status"
    >
      <div
        className={`orb ${status === SWStatus.NA
          ? "NA"
          : status.toString()
          }`
        }>
      </div>
      <span
        className="text"
      >
        {completedOnPaper &&
          t("Completed (on paper)")
        }

        {!completedOnPaper &&
          t(status.toString())
        }
      </span>
    </span>);
}
export default SWStatusIndicator;