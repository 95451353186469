import { ISW, SWTypes, INotice, NoticeTypes, IReferenceDoc, IStep, IStepComponent, StepComponentTypes, IImageComponentData, ISelectComponentData, INumberInputData, INoticeComponentData, IPPE, IMicrosoftStreamVideoData, ILinkData, ITableComponentData, IPPEComponent, IVideoComponentData, IRichTextParagraphData, IYesNoComponentData, IPassFailComponentData } from "interfaces/sw/SWInterfaces";

export function formatSWPDocument(swpDoc: any): ISW {
  if (!swpDoc.DocType) {
    throw new Error("SWP Document is missing DocType.");
  }

  const sw = swpDoc.StandardWork;

  return {
    docType: swpDoc.DocType,
    docVersion: swpDoc.DocVersion,
    id: sw.Guid,
    version: sw.Version.toString(),
    type: formatSWType(sw.Type),
    title: sw.Title,
    description: sw.Description,
    ppe: sw.PPE.map((ppe: any): IPPE => ({ ppe, })),
    notices: sw.Notices.length
      ? sw.Notices.map((n: any): INotice => formatNotice(n))
      : [],
    refDocs: sw.ReferenceDocuments.length
      ? sw.ReferenceDocuments.map((d: any): IReferenceDoc => formatReferenceDoc(d))
      : [],
    steps: sw.Steps.length
      ? sw.Steps
        .sort((a: any, b: any) => a.SortOrder < b.SortOrder ? -1 : 1)
        .map((s: any): IStep => formatStep(s, "", undefined))
      : [],
    //hasCriticalSteps: some(sw.Steps.find((x: any) => x.IsCritStep))
    hasCriticalSteps: checkAnyCriticalSteps(sw)
  };
}

// Check if any Critical Steps in SW.
function checkAnyCriticalSteps(swDoc: any): boolean {
  let hasCriticalSteps: boolean = false;
  for (let i = 0; i < swDoc.Steps.length; i++) {
    if (swDoc.Steps[i].IsCritStep) {
      hasCriticalSteps = true;
      break;
    }
    for (let j = 0; j < swDoc.Steps[i].Children.length; j++) {
      if (swDoc.Steps[i].Children[j].IsCritStep) {
        hasCriticalSteps = true;
        break;
      }
      for (let k = 0; k < swDoc.Steps[i].Children[j].Children.length; k++) {
        if (swDoc.Steps[i].Children[j].Children[k].IsCritStep) {
          hasCriticalSteps = true;
          break;
        }
      }
    }
  }
  return hasCriticalSteps;
}

// Format SW's Steps if they are Critical and their numbers
export function formatCritcalSWPDocument(swDoc: ISW): ISW {
  if (swDoc.hasCriticalSteps) {
    for (let i = 0; i < swDoc.steps.length; i++) {
      if (!swDoc.steps[i].isCritical) {
        swDoc.steps[i].showStep = false;
      }

      for (let j = 0; j < swDoc.steps[i].children.length; j++) {

        if (swDoc.steps[i].children[j].isCritical) {
          swDoc.steps[i].children[j].showStep = true;
          swDoc.steps[i].showStep = true;
        } else {
          swDoc.steps[i].children[j].showStep = false;
        }

        for (let k = 0; k < swDoc.steps[i].children[j].children.length; k++) {
          if (swDoc.steps[i].children[j].children[k].isCritical) {
            swDoc.steps[i].children[j].children[k].showStep = true;
            swDoc.steps[i].children[j].showStep = true;
            swDoc.steps[i].showStep = true;
          } else {
            swDoc.steps[i].children[j].children[k].showStep = false;
          }
        }
      }
    }

    // Add proper numbers to the Critical Steps.
    const criticalSteps: IStep[] = swDoc.steps.filter(a => a.showStep);
    for (let i = 0; i < criticalSteps.length; i++) {
      criticalSteps[i].number = (i + 1).toString();

      if (criticalSteps[i].children) {
        const criticalChildSteps: IStep[] = criticalSteps[i].children.filter(a => a.showStep);
        for (let j = 0; j < criticalChildSteps.length; j++) {
          criticalChildSteps[j].number = (i + 1).toString() + "." + (j + 1).toString();

          if (criticalChildSteps[j].children) {
            const criticalGrandChildSteps: IStep[] = criticalChildSteps[j].children.filter(a => a.showStep);
            for (let k = 0; k < criticalGrandChildSteps.length; k++) {
              criticalGrandChildSteps[k].number = (i + 1).toString() + "." + (j + 1).toString() + "." + (k + 1).toString();

            }
          }
        }
      }
    }

  }
  return swDoc;

}

function formatSWType(type: any): SWTypes {
  if (type === "SWI") {
    return SWTypes.SWI;
  } else if (type === "ECL") {
    return SWTypes.ECL;
  } else if (type === "CL") {
    return SWTypes.CL;
  } else if (type === "TLMSWI" ||
    type === "TECHSWI") {
    return SWTypes.TLMSWI;
  } else if (type === "LCL") {
    return SWTypes.LCL;
  } else if (type === "REPOSWI") {
    return SWTypes.TLMSWI;
  } else if (type === "MFGSWI") {
    return SWTypes.MFGSWI;
  } else if (type === "MFGCL") {
    return SWTypes.MFGCL;
  } else if (type === "MFGRC") {
    return SWTypes.MFGRC;
  }

  throw new Error(`Invalid SW Type: ${type}`);
}

function formatNotice(notice: any): INotice {
  return {
    id: notice.Guid,
    text: notice.Text,
    type: formatNoticeType(notice.Type),
    sortOrder: parseInt(notice.SortOrder, 10),
    filename: notice.Filename,
  };
}

function formatNoticeType(type: any): NoticeTypes {
  if (type === "Info") {
    return NoticeTypes.Info;
  } else if (type === "Warning") {
    return NoticeTypes.Warning;
  } else if (type === "Caution") {
    return NoticeTypes.Caution;
  } else {
    throw new Error(`Invalid NoticeType: ${type}`);
  }
}

function formatReferenceDoc(refDoc: any): IReferenceDoc {
  let refDocData: IReferenceDoc;
  refDocData = {
    id: refDoc.Guid,
    label: refDoc.Label,
    refDocData: refDoc.Filename,
    sortOrder: refDoc.SortOrder,
    type: refDoc.Type,
  };

  if (refDocData.type === 'Table') {
    refDocData.tableData = JSON.parse(refDocData.refDocData);
  }

  return refDocData;
}

function formatStep(s: any, hierarchy: string, parentId: undefined | string): IStep {
  const number = hierarchy
    ? `${hierarchy}.${parseInt(s.SortOrder, 10)}`
    : s.SortOrder.toString();

  return {
    id: s.Guid,
    parentId,
    title: s.Title,
    number,
    isConditional: !!s.IsConditional,
    conditionalText: s.ConditionalText,
    isMemoryStep: s.IsMemoryStep,
    isCritical: s.IsCritStep,
    isTask: s.IsTask,
    showStep: true,
    sortOrder: parseInt(s.SortOrder, 10),
    components: s.Components.length
      ? s.Components
        .sort((a: any, b: any) => a.SortOrder < b.SortOrder ? -1 : 1)
        .map((comp: any): IStepComponent => formatStepComponent(comp))
      : [],
    children: s.Children.length
      ? s.Children
        .sort((a: any, b: any) => a.SortOrder < b.SortOrder ? -1 : 1)
        .map((child: any): IStep => formatStep(child, number, s.Guid))
      : [],
  };
}

function formatStepComponent(comp: any): IStepComponent {
  let type: StepComponentTypes;

  // Check component type

  const validTypeStrings = Object.values(StepComponentTypes);

  if (validTypeStrings.includes(comp.Type)) {
    type = comp.Type;
  } else {
    throw new Error(`Invalid step component type: ${comp.Type}`);
  }

  return {
    id: comp.Guid,
    type,
    label: comp.Label,
    data: comp.Data
      ? formatStepComponentData(comp.Data)
      : null,
    sortOrder: parseInt(comp.SortOrder, 10),
  };
}

function formatStepComponentData(data: any): INoticeComponentData
  | IImageComponentData
  | ISelectComponentData
  | INumberInputData
  | IMicrosoftStreamVideoData
  | ILinkData
  | ITableComponentData
  | IPPEComponent
  | IRichTextParagraphData
  | IVideoComponentData
  | IYesNoComponentData
  | IPassFailComponentData
  | null {
  if (data.uom !== undefined) {
    return {
      uom: data.uom,
      lowerLimit: data.lowerLimit,
      expectedValue: data.expectedValue,
      upperLimit: data.upperLimit,
      nonConform: data.nonConform,
    };
  } else if (data.options) {
    return {
      options: data.options,
      expectedValue: data.expectedValue,
      nonConform: data.nonConform,
    };
  } else if (data.expectedValue || data.nonConform) {
    return {
      expectedValue: data.expectedValue,
      nonConform: data.nonConform,
    }
  }
  else if (data.type) {
    return {
      type: formatNoticeType(data.type),
      filename: data.filename,
    };
  } else if (data.filename) {
    return {
      filename: data.filename,
    };
  } else if (data.videoGuid) {
    return {
      videoGuid: data.videoGuid,
    };
  } else if (data.url) {
    return {
      url: data.url,
    };
  } else if (data.cells) {
    return {
      rowCount: data.rowCount,
      colCount: data.colCount,
      cells: data.cells,
    };
  } else if (data.ppe) {
    return {
      ppe: data.ppe,
    };
  } else if (data.html) {
    return {
      html: data.html,
    };
  }

  return null;
}