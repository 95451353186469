import React, { useState, useRef, useEffect } from "react";
import "./EllipsesDropdown.scoped.scss";

export interface IEllipsesDropdownItem {
  key: string,
  label: string | JSX.Element,
  icon?: string,
  onClick?(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void,
}

interface IEllipsesDropdownProps {
  label?: string,
  icon?: string,
  itemTextAlign?: "left" | "center" | "right",
  items: (IEllipsesDropdownItem | undefined)[],
}

const EllipsesDropdown: React.FC<IEllipsesDropdownProps> = ({
  label,
  icon,
  items,
  itemTextAlign,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleDocumentClick(event: MouseEvent) {

      if (!containerRef.current
        || !containerRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    }

    document.addEventListener('mousedown', handleDocumentClick);

    return () => {
      document.removeEventListener("mousedown", handleDocumentClick);
    };
  }, [containerRef, setIsOpen]);

  const toggleMenu = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    e.preventDefault();
    setIsOpen(!isOpen);
  }

  const itemClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, item: IEllipsesDropdownItem) => {
    if (item.onClick) {
      item.onClick(event);
    }

    setIsOpen(false);
  }

  return (
    <div
      className="container"
    >
      <button
        className={`secondary-button toggle-button ${isOpen ? "open" : ""}`}
        onClick={(e) => toggleMenu(e)}
      >
        {icon &&
          <img
            src={icon}
            alt=""
            className="icon-small"
          />
        }
        {label || "⋮"}
      </button>
      <div
        className={`dropdown-menu ${isOpen ? "open" : ""} align-${itemTextAlign || "center"}`}
        ref={containerRef}
      >
        {items.map(i => i === undefined
          ? undefined
          : (
            <button
              className="secondary-button item"
              key={i.key}
              onClick={(e) => {
                e.preventDefault();
                itemClick(e, i);
              }}
            >
              {i.icon &&
                <img
                  src={i.icon}
                  alt=""
                  className="icon-small"
                />
              }
              {i.label}
            </button>
          ))}
      </div>
    </div>
  );
};

export default EllipsesDropdown;