import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./TextOrNumberBox.scoped.scss";

export enum TextInputTypes {
  Text = 0,
  Number = 1,
}

interface ITextOrNumberBoxProps {
  disabled: boolean,
  value?: string,
  onResponseChange(value: string): void,
  inputType: TextInputTypes,
}

const TextInput: React.FC<ITextOrNumberBoxProps> = ({ disabled, onResponseChange, value, inputType }) => {
  const [txtBoxValue, setTxtBoxValue] = useState(value);
  const { t } = useTranslation('executionSW');

  useEffect(() => {
    setTxtBoxValue(value);
  }, [value, setTxtBoxValue]);

  const isValid = (val: string | undefined) => {
    return inputType !== TextInputTypes.Number
      || val === undefined
      || val === ""
      || !isNaN(Number(val));
  }

  const [errorMsg, setErrorMsg] = useState(isValid(value)
    ? ""
    : t("Input must be a valid number."));

  const onChange = (newValue: string) => {
    setTxtBoxValue(newValue);
    if (isValid(newValue)) {
      setErrorMsg("");
    } else {
      setErrorMsg(t("Input must be a valid number."));
    }
  }

  const onBlur = () => {
    if (isValid(txtBoxValue)) {
      onResponseChange(txtBoxValue || "");
    }
  }

  let className = inputType === TextInputTypes.Number ? "number-entry" : "";

  if (errorMsg) {
    className += " error";
  }

  return (
    <>
      <div
        className="container"
      >
        {inputType === TextInputTypes.Number &&
          <label
            className="symbol"
          >
            #
        </label>
        }
        <input
          type="text"
          className={className}
          disabled={disabled}
          value={txtBoxValue}
          onChange={(e) => onChange(e.target.value)}
          onBlur={onBlur}
        />
      </div>
      {errorMsg &&
        <span className="error">
          {errorMsg}
        </span>
      }
    </>
  );
}

export default TextInput;