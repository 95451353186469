import React from "react";
import "./StepList.scoped.scss";
import Step from "./Step";
import { IExecutionJobSW } from "store/execution/executionTypes";

interface IStepListProps {
  sw: IExecutionJobSW,
  focusSWHeader(): void,
}

const StepList: React.FC<IStepListProps> = ({ sw, focusSWHeader }) =>
  <ul>
    {sw.steps.map(st =>
      st.showStep &&
      <Step
        key={st.id}
        jobSWId={sw.jobSWId}
        showCritical={sw.showCriticalSteps}
        step={st}
        focusSWHeader={focusSWHeader}
      />
    )}
  </ul>;

export default StepList;