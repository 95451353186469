import React from "react";
import "./Banner.scoped.scss";

interface IBannerProps {
  type: BannerType,
  children: any,
}

export enum BannerType {
  Info = "info",
  Warn = "warn",
  Error = "error",
  Success = "success"
}

const Banner: React.FC<IBannerProps> = (props) =>
  <div
    className={"banner " + props.type}
  >
    {props.children}
  </div>;

export default Banner;