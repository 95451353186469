import Banner, { BannerType } from "components/common/Banner";
import ItemPicker from "components/common/itemPicker/ItemPicker";
import Modal from "components/common/Modal";
import { ISWPJobTeamMember } from "interfaces/jobs/JobInterfaces";
import { IMasterDataItem } from "interfaces/masterData/masterDataInterfaces";
import { IAADUser } from "interfaces/user/UserInterfaces";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setJobTemplateModal } from "store/jobTemplates/jobTemplatesActions";
import { loadCountries, loadGeoUnits, loadSubBusinessLines } from "store/masterData/masterDataActions";
import { cacheTemplateOffline } from "store/offline/offlineActions";
import useSelector from "store/useSelector";
import TeamForm from "../../management/team/TeamForm";
import "./CacheTemplateModal.scoped.scss";

const CacheTemplateModal: React.FC = () => {
  const {
    masterData: {
      countries,
      geoUnits,
      subBusinessLines
    },
    jobTemplates: {
      templateCacheModal,
    },
    auth: {
      currentUser,
    },
  } = useSelector(state => state)
  const dispatch = useDispatch();
  const { t } = useTranslation('cacheTemplate');
  const [team, setTeam] = useState<ISWPJobTeamMember[]>([]);
  const [country, setCountry] = useState<IMasterDataItem | undefined>();
  const [geoUnit, setGeoUnit] = useState<IMasterDataItem | undefined>();
  const [subBusinessLine, setSubBusinessLine] = useState<IMasterDataItem | undefined>();

  const onCacheClick = () => {
    const template = templateCacheModal.template;
    dispatch(cacheTemplateOffline({
      jobId: templateCacheModal.template?.id || 0,
      team: [
        ...team, {
          email: currentUser.email,
          name: currentUser.name,
          offlinePIN: "",
        },
      ],//Uses the country, geounit & subBusiness passed to this modal if any, otherwise the ones selected, but if none provided the defaults from the current user   
      country: (template?.countryGuid ? {guid:template?.countryGuid} as IMasterDataItem : undefined) || country || currentUser.myCountry,
      geoUnit: (template?.geoUnitCode ? {code:template?.geoUnitCode} as IMasterDataItem : undefined) || geoUnit || currentUser.myGeoUnit,
      subBusinessLine: (template?.subBusinessLineCode ? {code:template?.subBusinessLineCode} as IMasterDataItem : undefined) || subBusinessLine || currentUser.mySubBusinessLine
    }));

    onClose();
  }

  const onClose = () => {
    dispatch(setJobTemplateModal({
      modal: "cacheModal",
      data: {
        isOpen: false,
        template: undefined,
      },
    }));
  }

  const onAddTeamMember = (user: IAADUser) => {
    if (team.find(x => x.email.toLowerCase() === user.email.toLowerCase())) {
      return;
    }

    setTeam([
      ...team, {
        email: user.email,
        name: user.name,
        offlinePIN: null,
      }]);
  }

  const onRemoveTeamMember = (email: string) => {
    if (team
      .find(x => x.email.toLowerCase() === email.toLowerCase())) {
      setTeam(team
        .filter(x => x.email.toLowerCase() !== email.toLowerCase()));
    }
  }
  
  const template = templateCacheModal.template;

  if (!template) {
    return null;
  }

  return (
    <Modal
      isOpen={true}
      header={`${t('Save Template')} - ${(template.jobNumber
        ? `${template.jobNumber}: `
        : "") + template.title}`
      }
      controls={
        <>
          <button
            className="secondary-button"
            onClick={onClose}
          >
            {t('Cancel')}
          </button>
          <button
            className="primary-button"
            onClick={onCacheClick}
            disabled={(!template.countryGuid
              && !country
              && !currentUser.myCountry)
            || (!template.geoUnitCode
              && !geoUnit
              && !currentUser.myGeoUnit)
              || (!template.subBusinessLineCode
                && !subBusinessLine
                && !currentUser.mySubBusinessLine)}
          >
            {t('Save Template')}
          </button>
        </>
      }
    >
      <Banner
        type={BannerType.Warn}
      >
        {t('Warning This template will only be downloaded to ')}<b>{t('this browser')}</b>{t(' on ')}<b>{t('this device')}</b>.<br />
        <br />
        {t("Do not clear your browser's cache. Clearing your cache will delete your offline templates.")}
      </Banner>

      <p>
        {t('After this template is saved to your device, you will be able to create jobs from it while offline.')}
      </p>

      {!template.countryGuid 
        && !currentUser.myCountry &&
        <div
          className="row"
        >
          <ItemPicker
            label={t("Country")}
            isMandatory
            selectedItems={country
              ? [country]
              : []
            }
            onAddItem={(item: IMasterDataItem) => setCountry(item)}
            onRemoveItem={() => setCountry(undefined)}
            loadItems={() => dispatch(loadCountries({
              includeAll: true,
            }))}
            masterDataSection={countries}
            loadOnStart={true}
            itemFormatter={(item: IMasterDataItem) => item.value}
          />
        </div>
      }

      {!template.geoUnitCode 
        && !currentUser.myGeoUnit &&
        <div
          className="row"
        >
          <ItemPicker
            label={t("GeoUnit")}
            isMandatory
            selectedItems={geoUnit
              ? [geoUnit]
              : []
            }
            onAddItem={(item: IMasterDataItem) => setGeoUnit(item)}
            onRemoveItem={() => setGeoUnit(undefined)}
            loadItems={() => dispatch(loadGeoUnits({
              includeAll: true,
            }))}
            masterDataSection={geoUnits}
            loadOnStart={true}
            itemFormatter={(item: IMasterDataItem) => item.value}
          />
        </div>
      }

      {!template.subBusinessLineCode 
        && !currentUser.mySubBusinessLine &&
        <div
          className="row"
        >
          <ItemPicker
            label={t("SubBusinessLine")}
            isMandatory
            selectedItems={subBusinessLine
              ? [subBusinessLine]
              : []
            }
            onAddItem={(item: IMasterDataItem) => setSubBusinessLine(item)}
            onRemoveItem={() => setSubBusinessLine(undefined)}
            loadItems={() => dispatch(loadSubBusinessLines({
              includeAll: true,
            }))}
            masterDataSection={subBusinessLines}
            loadOnStart={true}
            itemFormatter={(item: IMasterDataItem) => item.value}
          />
        </div>
      }

      <p>
        Please choose which team members will be included in the jobs created from this template while offline.
      </p>

      <div
        className="teamform-holder"
      >
        <TeamForm
          onAddTeamMember={onAddTeamMember}
          onRemoveTeamMember={onRemoveTeamMember}
          teamTitle={t("Team")}
          team={team}
        />
      </div>
    </Modal>
  );
};

export default CacheTemplateModal;