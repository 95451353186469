import React from "react";
import { ISWPJobHeader } from "interfaces/jobs/JobInterfaces";
import "./JobHeader.scoped.scss";
import { RouteComponentProps } from "react-router-dom";
import { Routes } from "components/routing/Routing";
import editIcon from "media/icons/dls/edit-1.svg";
import removeIcon from "media/icons/dls/remove.svg"
import { useDispatch } from "react-redux";
import { setJobModal } from "store/myJobs/myJobsActions";

interface IRecurringJobHeaderProps {
  jobHeader: ISWPJobHeader,
}

const RecurringJobHeader: React.FC<RouteComponentProps & IRecurringJobHeaderProps> = ({
  jobHeader,
  history,
}) => {
  const dispatch = useDispatch();

  const jobDisplay = (jobHeader.jobNumber
    ? `${jobHeader.jobNumber}: `
    : "") + jobHeader.title;

  const editClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    history.push(Routes.EditJob.replace(":id", jobHeader.id.toString()));
  }

  const cancelClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    dispatch(setJobModal({
      modal: "cancelJobModal",
      data: {
        isOpen: true,
        jobId: jobHeader.id,
        jobDisplay,
      },
    }));
  }

  const editButton = (
    <button
      className="secondary-button action-button"
      onClick={editClick}
    >
      <img
        src={editIcon}
        alt=""
      />
      <span>
        edit job
      </span>
    </button>
  );

  const cancelButton = (
    <button
      className="secondary-button action-button"
      onClick={cancelClick}
    >
      <img
        src={removeIcon}
        alt=""
      />
      <span>
        cancel job
      </span>
    </button>
  );

  return (
    <div
      className="job-row"
    >
      <span
        className="info"
      >
        <span
          className="job-title"
        >
          <label
            className="extra-details"
          >
            {`${jobHeader.id} - `}
          </label>
          {jobDisplay}
        </span>
      </span>
      {cancelButton}
      {editButton}
    </div>
  );
}

export default RecurringJobHeader;