import React from "react";
import useSelector from "store/useSelector";
import "./SaveDataButton.scoped.scss";
import { useDispatch } from "react-redux";
import { saveExecutionData } from "store/execution/executionActions";
import saveIcon from "media/icons/dls/save.svg";
import { useTranslation } from "react-i18next";
import { ExecutionMode } from "store/execution/executionTypes";

const SaveDataButton: React.FC = () => {
  const {
    execution: {
      stepResponses,
      stepComments,
      isUploadingResponses,
      uploadResponsesError,
      paperExecutions,
      mode,
      resetJobSWIds,
      job,
    },
  } = useSelector(store => store);
  const dispatch = useDispatch();

  const { t } = useTranslation('execution');


  const anyDirty = stepComments.some(x => x.isDirty)
    || stepResponses.some(x => x.isDirty)
    || paperExecutions.some(x => x.isDirty)
    || resetJobSWIds.length > 0
    || job?.sws.some(x => x.jobSWId < 0 && x.isDirty);

  if (uploadResponsesError !== undefined
    || !anyDirty
    || mode === ExecutionMode.Offline) {
    return null;
  }

  const onUploadClick = () => {
    dispatch(saveExecutionData());
  }

  return (
    <button
      className="primary-button save-button"
      onClick={onUploadClick}
      disabled={isUploadingResponses
        || !anyDirty}
    >
      <img
        src={saveIcon}
        className="icon-small black-to-white-filter"
        alt=""
      />

      <span className="text">
        {isUploadingResponses
          ? t("Saving...")
          : t("Save Data")
        }
      </span>
    </button>
  );
}

export default SaveDataButton;