import Banner, { BannerType } from "components/common/Banner";
import Modal from "components/common/Modal";
import { IJobSchedule, JobScheduleType } from "interfaces/jobs/JobInterfaces";
import { cloneDeep } from "lodash";
import React, { useEffect, useState } from "react";
import "./ScheduleEditorModal.scoped.scss";
import DatePicker from "react-datepicker";
import { dayNames, getScheduleDayIndexes, IScheduleValidationResults, validateMonthlySchedule, validateWeeklySchedule } from "./scheduleUtils";

interface IScheduleEditorModalProps {
  schedule: IJobSchedule | undefined,
  onClose(): void,
  onDelete(): void,
  onSave(schedule: IJobSchedule): void,
}

const ScheduleEditorModal: React.FC<IScheduleEditorModalProps> = ({
  schedule: originalSchedule,
  onClose,
  onSave,
}) => {
  const getEmptySchedule = (): IJobSchedule => {
    const now = new Date();

    return {
      type: JobScheduleType.Daily,
      startDate: new Date(now.getFullYear(), now.getMonth(), now.getDate()),
      endDate: new Date(now.getFullYear(), now.getMonth(), now.getDate()),
      everyX: 1,
      dayOfMonth: 1,
      weeklySun: false,
      weeklyMon: false,
      weeklyTue: false,
      weeklyWed: false,
      weeklyThu: false,
      weeklyFri: false,
      totalOccurrences: 1,
    };
  };

  const [schedule, setSchedule] = useState<IJobSchedule>(cloneDeep(getEmptySchedule()));
  const [endType, setEndType] = useState<"date" | "total">("date");
  const [validationResult, setValidationResult] = useState<IScheduleValidationResults>({
    isValid: true,
  });

  useEffect(() => {
    const emptySchedule = getEmptySchedule();

    if (originalSchedule) {
      const newSchedule = {
        ...originalSchedule,
      };

      // For each of the properties, copy the value from the empty
      // schedule into the one that was passed in if the passed
      // in one doesn't have a value for that property.
      Object.keys(emptySchedule)
        .forEach(key => {
          if ((newSchedule as any)[key] === undefined) {
            (newSchedule as any)[key] = (emptySchedule as any)[key];
          }
        });

      setSchedule(newSchedule);

      setEndType(originalSchedule.endDate !== undefined
        ? "date"
        : "total");
    } else {
      setSchedule(cloneDeep(emptySchedule));
    }
  }, [originalSchedule, setSchedule, setEndType]);

  const setScheduleType = (type: JobScheduleType) => {
    let newTotalOcc = schedule.totalOccurrences;

    if (newTotalOcc !== undefined) {
      if (type === JobScheduleType.Daily
        && newTotalOcc > 365) {
        newTotalOcc = 365;
      } else if (type === JobScheduleType.Weekly
        && newTotalOcc > 52) {
        newTotalOcc = 52;
      } else if (type === JobScheduleType.Monthly
        && newTotalOcc > 12) {
        newTotalOcc = 12;
      }
    }

    setSchedule({
      ...schedule,
      type,
      totalOccurrences: newTotalOcc,
    });
  }

  const setDayOfMonth = (dayOfMonth: number) => {
    if (isNaN(dayOfMonth)
      || Math.abs(dayOfMonth) === Infinity
      || dayOfMonth < 1
      || dayOfMonth > 31) {
      setSchedule({
        ...schedule,
        dayOfMonth: undefined,
      });
      return;
    }

    setSchedule({
      ...schedule,
      dayOfMonth,
    });
  }

  const setTotalOccurrences = (totalOccurrences: number) => {
    if (isNaN(totalOccurrences)
      || Math.abs(totalOccurrences) === Infinity
      || totalOccurrences <= 0
      || totalOccurrences > 365) {
      setSchedule({
        ...schedule,
        totalOccurrences: undefined,
      });
      return;
    }

    setSchedule({
      ...schedule,
      totalOccurrences,
    });
  }

  const toggleWeeklyDay = (day: string, isChecked: boolean) => {
    if (!dayNames.some(x => x === day)) {
      return;
    }

    const newSchedule: IJobSchedule = {
      ...schedule,
    };

    (newSchedule as any)[`weekly${day.substr(0, 3)}`] = isChecked;

    setSchedule(newSchedule);
  }

  const onStartDateChanged = (date: Date) => {
    setSchedule({
      ...schedule,
      startDate: date,
      endDate: schedule.endDate
        && schedule.endDate < date
        ? new Date(date)
        : schedule.endDate,
    });
  }

  const onEndDateChanged = (date: Date | null) => {
    setSchedule({
      ...schedule,
      endDate: date || undefined,
    });
  }

  const onSaveClicked = (ignoreWarnings: boolean) => {
    const validationResult = validateSchedule(schedule);

    if (!validationResult.isValid
      && (!ignoreWarnings
        || validationResult.bannerType !== BannerType.Warn)) {
      setValidationResult(validationResult);
      return;
    }

    const validatedSchedule = {
      ...schedule,
    };

    if (endType === "date") {
      validatedSchedule.totalOccurrences = undefined;
    } else {
      validatedSchedule.endDate = undefined;
    }

    if (validatedSchedule.type !== JobScheduleType.Weekly) {
      validatedSchedule.everyX = undefined;
      validatedSchedule.weeklySun = undefined;
      validatedSchedule.weeklyMon = undefined;
      validatedSchedule.weeklyTue = undefined;
      validatedSchedule.weeklyWed = undefined;
      validatedSchedule.weeklyThu = undefined;
      validatedSchedule.weeklyFri = undefined;
      validatedSchedule.weeklySat = undefined;
    }

    if (validatedSchedule.type !== JobScheduleType.Monthly) {
      validatedSchedule.dayOfMonth = undefined;
    }

    onSave(validatedSchedule);
    onClose();
  }

  const validateSchedule = (schedule: IJobSchedule): IScheduleValidationResults => {
    let validationResult: IScheduleValidationResults = {
      isValid: true,
    };

    let nowMidnight = new Date();
    nowMidnight.setHours(0, 0, 0, 0);

    if (schedule.startDate < nowMidnight) {
      return {
        isValid: false,
        bannerType: BannerType.Error,
        errorMessage: "Start date must be no earlier than today.",
      };
    }

    if (endType === "date"
      && schedule.endDate === undefined) {
      return {
        isValid: false,
        bannerType: BannerType.Error,
        errorMessage: "End date is required.",
      };
    } else if (endType === "total") {
      if (!schedule.totalOccurrences
        || schedule.totalOccurrences <= 0) {
        return {
          isValid: false,
          bannerType: BannerType.Error,
          errorMessage: "Total occurrences is required.",
        };
      } else if (schedule.type === JobScheduleType.Weekly
        && schedule.totalOccurrences > 52) {
        return {
          isValid: false,
          bannerType: BannerType.Error,
          errorMessage: "Total occurrences cannot exceed 52 for weekly schedules.",
        };
      } else if (schedule.type === JobScheduleType.Monthly
        && schedule.totalOccurrences > 12) {
        return {
          isValid: false,
          bannerType: BannerType.Error,
          errorMessage: "Total occurrences cannot exceed 12 for weekly schedules.",
        };
      }
    }

    if (endType === "date"
      && schedule.endDate) {
      if (schedule.type === JobScheduleType.Weekly) {
        let chosenWeeklyDays = getScheduleDayIndexes(schedule);

        validationResult = validateWeeklySchedule(schedule.startDate,
          schedule.endDate,
          chosenWeeklyDays);
      } else if (schedule.type === JobScheduleType.Monthly) {
        validationResult = validateMonthlySchedule(schedule.startDate,
          schedule.endDate,
          schedule.dayOfMonth);
      } else if (schedule.endDate <= schedule.startDate) {
        validationResult = {
          isValid: false,
          bannerType: BannerType.Error,
          errorMessage: "End date must occur on or after start date.",
        };
      }

      setValidationResult(validationResult);
    }

    if (schedule.type === JobScheduleType.Monthly
      && (schedule.dayOfMonth === undefined
        || schedule.dayOfMonth < 1
        || schedule.dayOfMonth > 31)) {
      return {
        isValid: false,
        errorMessage: "The day of the month must be a valid day.",
        bannerType: BannerType.Error,
      };
    }

    return validationResult;
  }

  return (
    <Modal
      header="Recurring Job Schedule"
      isOpen={true}
      controls={(
        <>
          <button
            className="secondary-button"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="primary-button"
            onClick={() => onSaveClicked(false)}
          >
            Save
          </button>
        </>
      )}
    >
      <fieldset>
        <legend>Recurrence Pattern</legend>
        <div
          className="type-options"
        >
          {Object.keys(JobScheduleType).map(type => (
            <label
              key={type}
            >
              <input
                type="radio"
                value={type}
                name="type"
                checked={schedule.type === type}
                onChange={() => setScheduleType(type as JobScheduleType)}
              />
              {type}
            </label>
          ))}
        </div>
        <div
          className="recurrence-pattern"
        >
          {schedule.type === JobScheduleType.Weekly &&
            <>
              Recur every {schedule.type.toLowerCase().replace("ly", "")} on:

              <div
                className="days"
              >
                {dayNames.map(day => (
                  <label
                    className="day"
                    key={day}
                  >
                    <input
                      type="checkbox"
                      checked={(schedule as any)[`weekly${day.substr(0, 3)}`] as boolean}
                      onChange={(e) => toggleWeeklyDay(day, e.target.checked)}
                    />
                    {day}
                  </label>
                ))}
              </div>
            </>
          }

          {schedule.type === JobScheduleType.Monthly &&
            <>
              Recur on day {getNumberEntry(schedule.dayOfMonth, setDayOfMonth, 1, 31)} every month.

              {schedule.dayOfMonth !== undefined
                && schedule.dayOfMonth > 28 && (
                  <Banner
                    type={BannerType.Info}
                  >
                    Note: For any month that does not have a {schedule.dayOfMonth}{schedule.dayOfMonth === 31 ? "st" : "th"} day,
                    the job occurrence will fall on the last day of the month instead.
                  </Banner>
                )}
            </>
          }
        </div>
      </fieldset>
      <fieldset
        className="range"
      >
        <legend>Range of recurrence</legend>
        <div
          className="start"
        >
          <label
            className="range-header"
          >
            Start:
          </label>
          <DatePicker
            id={"start-date"}
            selected={schedule.startDate}
            onChange={onStartDateChanged}
            // popperModifiers={{
            //   preventOverflow: {
            //     enabled: true,
            //     escapeWithReference: false,
            //     boundariesElement: "scrollParent"
            //   },
            //   flip: {
            //     behavior: "flip",
            //   },
            // }}
            popperModifiers={[
              {
                name: "flip",
              },
              {
                name: "preventOverflow",
                options: {
                  altBoundary: true,
                },
              },
            ]}
            showTimeSelect={false}
            minDate={new Date()}
          />
        </div>
        <div
          className="range-end"
        >
          <div>
            <label
              className="range-header"
            >
              <input
                type="radio"
                name="endType"
                checked={endType === "date"}
                onChange={() => setEndType("date")}
              />
              End on:
            </label>
            <DatePicker
              id={"end-date"}
              startDate={schedule.startDate}
              endDate={schedule.endDate}
              selected={schedule.endDate}
              onChange={onEndDateChanged}
              maxDate={new Date(schedule.startDate.getFullYear(),
                schedule.startDate.getMonth(),
                schedule.startDate.getDate() + 365)
              }
              // popperModifiers={{
              //   preventOverflow: {
              //     enabled: true,
              //     escapeWithReference: false,
              //     boundariesElement: "scrollParent"
              //   },
              //   flip: {
              //     behavior: "flip",
              //   },
              // }}
              popperModifiers={[
                {
                  name: "flip",
                },
                {
                  name: "preventOverflow",
                  options: {
                    altBoundary: true,
                  },
                },
              ]}
              showTimeSelect={false}
              disabled={endType !== "date"}
              minDate={schedule.startDate}
            />
          </div>
          <div
            className="end-after-row"
          >
            <label>
              <input
                type="radio"
                name="endType"
                checked={endType === "total"}
                onChange={() => setEndType("total")}
              />
              End after:
            </label>
            <input
              type="number"
              value={schedule.totalOccurrences === undefined
                ? ""
                : schedule.totalOccurrences
              }
              onChange={(e) => setTotalOccurrences(parseInt(e.target.value, 10))}
              disabled={endType !== "total"}
            /> occurrences
          </div>
        </div>
      </fieldset>
      {!validationResult.isValid
        && validationResult.bannerType &&
        <Banner
          type={validationResult.bannerType}
        >
          {validationResult.errorMessage}

          {validationResult.bannerType === BannerType.Warn && (
            <button
              className="primary-button save-anyway"
              onClick={() => onSaveClicked(true)}
            >
              Save anyway
            </button>
          )}
        </Banner>
      }
    </Modal>
  );
};

export default ScheduleEditorModal;

function getNumberEntry(value: number | undefined,
  onChange: (num: number) => void,
  min?: number,
  max?: number) {
  return (
    <input
      type="number"
      value={value === undefined
        ? ""
        : value
      }
      onChange={(e) => onChange(parseInt(e.target.value, 10))}
      min={min}
      max={max}
    />
  );
}