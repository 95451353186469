import { IJobTemplateHeader } from "interfaces/jobs/JobInterfaces";
import { createAction } from "@reduxjs/toolkit";
import { IMasterDataItem } from "interfaces/masterData/masterDataInterfaces";
import { IOperation, IPayloadOperation } from "interfaces/operations/OperationInterfaces";
import { IAttributePopup, ITemplateModal } from "./jobTemplatesTypes";

export const setLoadOperation = createAction<IOperation | undefined>("jobTemplates/setLoadOperation");
export const setTemplates = createAction<IJobTemplateHeader[]>("jobTemplates/setTemplates");
export const setHasOfflineError = createAction<boolean>("jobTemplates/setHasOfflineError");
export const hydrateJobTemplateFilters = createAction("jobTemplates/hydrateJobTemplateFilters");
export const addOrgFilter = createAction<IMasterDataItem>("jobTemplates/addJobTemplateOrgFilter");
export const addCountryFilter = createAction<IMasterDataItem>("jobTemplates/addJobTemplateCountryFilter");
export const removeOrgFilter = createAction<IMasterDataItem>("jobTemplates/removeJobTemplateOrgFilter");
export const removeCountryFilter = createAction<IMasterDataItem>("jobTemplates/removeJobTemplateCountryFilter");
export const setSearchTerm = createAction<string>("jobTemplates/setJobTemplateSearchTerm");
export const clearFilters = createAction("jobTemplates/clearFilters");
export const searchJobTemplates = createAction("jobTemplates/searchJobTemplates");
export const cloneTemplate = createAction<{
  template: IJobTemplateHeader,
  countryGuid: string | undefined,
  geoUnitCode: string | undefined,
  subBusinessLineCode: string | undefined,
}>("jobTemplates/cloneTemplate");
export const setCloneOperation = createAction<IPayloadOperation<number> | undefined>("jobTemplates/setCloneOperation");
export const setAttributePopup = createAction<IAttributePopup>("jobTemplates/setAttributePopup");
export const setJobTemplateModal = createAction<{
  data: ITemplateModal,
  modal: "cacheModal" | "unCacheModal" | "retireModal",
}>("myJobs/setJobTemplateModal");
export const retireTemplate = createAction<{
  template: IJobTemplateHeader,
  timestamp: Date,
  userEmail: string,
}>("myJobs/retireTemplate");
export const setRetireTemplateOperation = createAction<IOperation>("myJobs/setRetireTemplateOperation");
