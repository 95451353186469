import { createReducer } from "@reduxjs/toolkit";
import { IUserManagementState } from "./userManagementTypes";
import { toggleAddUserModal, setAddUserModalData, setAddUserSearchText, setManageUsersData, setSearchManageUsersText, setEditUserData, toggleRole, resetManageUsersData, resetAddUserModalData, resetEditUserData, setUsersJobsTab, setUsersJobsOperation, setUsersJobs, setJobSearchTerm } from "./userManagementActions";
import { cloneDeep } from "lodash";
import { UserJobsTabs } from "interfaces/user/UserInterfaces";

const initialState: IUserManagementState = {
  manageUsersData: {
    userList: [],
    searchText: "",
    getUsersOperation: undefined,
  },
  editUserData: {
    isDirty: false,
    user: undefined,
    saveOperation: undefined,
    loadOperation: undefined,
    jobData: {
      currentTab: UserJobsTabs.Active,
      loadJobsOperation: undefined,
      usersJobs: undefined,
      jobSearchTerm: "",
    },
  },
  addUserModalData: {
    isOpen: false,
    searchText: "",
    searchResults: [],
    createOperation: undefined,
    loadOperation: undefined,
  },
}

const userManagementReducer = createReducer(initialState, builder => {
  // Reset Manage Users Data
  builder.addCase(resetManageUsersData, state => {
    state.manageUsersData = cloneDeep(initialState.manageUsersData);
  });

  // Reset Add User Modal Data
  builder.addCase(resetAddUserModalData, state => {
    state.addUserModalData = cloneDeep(initialState.addUserModalData);
  });

  // Reset Edit User Data
  builder.addCase(resetEditUserData, state => {
    state.editUserData = cloneDeep(initialState.editUserData);
  });

  // Set Users Jobs Load Operation
  builder.addCase(setUsersJobsOperation, (state, action) => {
    state.editUserData.jobData.loadJobsOperation = action.payload;
  });

  // Toggle Add User Modal Visibility
  builder.addCase(toggleAddUserModal, (state, action) => {
    state.addUserModalData.isOpen = action.payload;
  });

  // Set Add User Search Text
  builder.addCase(setAddUserSearchText, (state, action) => {
    state.addUserModalData.searchText = action.payload;
  });

  // Set Add User Modal Data
  builder.addCase(setAddUserModalData, (state, action) => {
    state.addUserModalData = action.payload;
  });

  // Set Manage Users Data
  builder.addCase(setManageUsersData, (state, action) => {
    state.manageUsersData = action.payload;
  });

  // Set Manage Users Search Text
  builder.addCase(setSearchManageUsersText, (state, action) => {
    state.manageUsersData.searchText = action.payload;
  });

  // Set Edit User Data
  builder.addCase(setEditUserData, (state, { payload }) => {
    state.editUserData = payload;
  });

  // Set EditUser Job Data Status Filter
  builder.addCase(setUsersJobsTab, (state, { payload }) => {
    state.editUserData.jobData.currentTab = payload;
  });

  // Set Users Jobs
  builder.addCase(setUsersJobs, (state, { payload }) => {
    state.editUserData.jobData.usersJobs = payload;
  });

  // Set Users Job SearchTerm
  builder.addCase(setJobSearchTerm, (state, { payload }) => {
    state.editUserData.jobData.jobSearchTerm = payload;
  });

  // Toggle Role
  builder.addCase(toggleRole, (state, action) => {
    if (!state.editUserData.user) {
      return;
    }

    state.editUserData.isDirty = true;

    if (state.editUserData.user.roles.indexOf(action.payload) > -1) {
      state.editUserData.user.roles =
        state.editUserData.user.roles.filter(x => x !== action.payload);
    } else {
      state.editUserData.user.roles.push(action.payload);
    }
  });
});

export default userManagementReducer;