import { IMyJobsState } from "./myJobsTypes";
import { MyJobsTabs, SWPJobStatus } from "interfaces/jobs/JobInterfaces";
import { createReducer } from "@reduxjs/toolkit";
import { setIsFetching, setMyJobs, setError, setHasOfflineError, setMyJobsTab, setJobModal, hydrateFilters, clearFilters, addOrgFilter, addCountryFilter, removeCountryFilter, removeOrgFilter, setSearchTerm, setCancelJobOperation, setReopenJobOperation, setReopenJobModal, setAdminShowAll, setIsUpdating, removeJobsFromJobList, setJobPDFData } from "./myJobsActions";

export const ACTIVE_JOBS_FILTER_KEY = 'MyJobsActiveFilters';
export const COMPLETED_JOBS_FILTER_KEY = 'MyJobsCompletedFilters';
export const RECURRING_JOBS_FILTER_KEY = 'MyRecurringJobsFilters';

const initialState: IMyJobsState = {
  isFetching: false,
  jobHeaders: null,
  hasOfflineError: false,
  currentTab: MyJobsTabs.Active,
  cancelJobOperation: undefined,
  reopenJobOperation: undefined,
  isUpdating: false,
  jobPDFData: undefined,
  cacheModal: {
    isOpen: false,
    jobId: 0,
    jobDisplay: "",
  },
  unCacheModal: {
    isOpen: false,
    jobId: 0,
    jobDisplay: "",
  },
  cancelJobModal: {
    isOpen: false,
    jobId: 0,
    jobDisplay: "",
  },
  reopenJobModal: {
    isOpen: false,
    jobId: 0,
    jobDisplay: "",
    jobStatus: SWPJobStatus.New,
  },
  activeJobFilters: {
    searchTerm: "",
    orgs: [],
    countries: [],
  },
  completedJobFilters: {
    searchTerm: "",
    orgs: [],
    countries: [],
  },
  recurringJobFilters: {
    searchTerm: "",
    orgs: [],
    countries: [],
  },
  adminShowAll: false,
}

const myJobsReducer = createReducer(initialState, builder => {
  // Set Is Fetching
  builder.addCase(setIsFetching, (state, { payload }) => {
    state.isFetching = payload;
  });

  // Set Admin ShowAll
  builder.addCase(setAdminShowAll, (state, { payload }) => {
    state.adminShowAll = payload;
  });

  // Set Cancel Job Operation
  builder.addCase(setCancelJobOperation, (state, { payload }) => {
    state.cancelJobOperation = payload;
  });
  
  // Set Reopen Job Operation
  builder.addCase(setReopenJobOperation, (state, { payload }) => {
    state.reopenJobOperation = payload;
  });

  // Set My Jobs
  builder.addCase(setMyJobs, (state, { payload }) => {
    state.jobHeaders = payload;
  });

  // Set Error
  builder.addCase(setError, (state, { payload }) => {
    state.errorMessage = payload;
  });

  // Set Has Offline error
  builder.addCase(setHasOfflineError, (state, { payload }) => {
    state.hasOfflineError = payload;
  });

  // Set Status Filter
  builder.addCase(setMyJobsTab, (state, { payload }) => {
    state.currentTab = payload;
  });

  // Set Job PDF Data
  builder.addCase(setJobPDFData, (state, { payload }) => {
    state.jobPDFData = payload;
  });

  // Set Reopen Job Modal
  builder.addCase(setReopenJobModal, (state, { payload }) => {
    state.reopenJobModal = payload.data;
  });

  builder.addCase(setIsUpdating, (state, { payload }) => {
    state.isUpdating = payload;
  });

  // Set Job Modal
  builder.addCase(setJobModal, (state, { payload }) => {
    if (payload.modal === "cacheModal") {
      state.cacheModal = payload.data;
    } else if (payload.modal === "unCacheModal") {
      state.unCacheModal = payload.data;
    } else if (payload.modal === "cancelJobModal") {
      state.cancelJobModal = payload.data;
    }
  });

  // Hydrate Job Template Filters
  builder.addCase(hydrateFilters, (state, { payload }) => {
    let key = "";
    if (payload === MyJobsTabs.Active) {
      key = ACTIVE_JOBS_FILTER_KEY;
    } else if (payload === MyJobsTabs.Completed) {
      key = COMPLETED_JOBS_FILTER_KEY;
    } else if (payload === MyJobsTabs.Recurring) {
      key = RECURRING_JOBS_FILTER_KEY;
    }

    if (!key) {
      return;
    }

    let json: any;
    try {
      const data = localStorage.getItem(key);
      if (!data) {
        return;
      }
      json = JSON.parse(data);
    } catch {
      return;
    }

    if (json.searchTerm !== undefined
      && json.searchTerm !== null
      && Array.isArray(json.countries)
      && Array.isArray(json.orgs)
      && !json
        .countries
        .find((x: any) => !x.guid
          || !x.code
          || !x.value)
      && !json
        .orgs
        .find((x: any) => !x.guid
          || !x.code
          || !x.value)) {

      if (payload === MyJobsTabs.Active) {
        state.activeJobFilters = json;
      } else if (payload === MyJobsTabs.Completed) {
        state.completedJobFilters = json;
      } else if (payload === MyJobsTabs.Recurring) {
        state.recurringJobFilters = json;
      }
    }
  });

  // Clear Filters
  builder.addCase(clearFilters, (state, { payload }) => {
    if (payload === MyJobsTabs.Active) {
      state.activeJobFilters = initialState.activeJobFilters;
    } else if (payload === MyJobsTabs.Completed) {
      state.completedJobFilters = initialState.completedJobFilters;
    } else if (payload === MyJobsTabs.Recurring) {
      state.recurringJobFilters = initialState.recurringJobFilters;
    }
  });

  // Add Org Filter
  builder.addCase(addOrgFilter, (state, { payload }) => {
    let filters = getFilterSet(state, payload.filterSet);

    if (!filters) {
      return;
    }

    if (filters.orgs
      .find(x => x.guid === payload.item.guid)) {
      return;
    }

    filters.orgs.push({ ...payload.item });
  });

  // Add Country Filter
  builder.addCase(addCountryFilter, (state, { payload }) => {
    let filters = getFilterSet(state, payload.filterSet);

    if (!filters) {
      return;
    }

    if (filters.countries
      .find(x => x.guid === payload.item.guid)) {
      return;
    }

    filters.countries.push({ ...payload.item });
  });

  // Remove Org Filter
  builder.addCase(removeOrgFilter, (state, { payload }) => {
    let filters = getFilterSet(state, payload.filterSet);

    if (!filters) {
      return;
    }

    filters.orgs =
      filters.orgs
        .filter(x => x.guid !== payload.item.guid);
  });

  // Remove Country Filter
  builder.addCase(removeCountryFilter, (state, { payload }) => {
    let filters = getFilterSet(state, payload.filterSet);

    if (!filters) {
      return;
    }

    filters.countries =
      filters.countries
        .filter(x => x.guid !== payload.item.guid);
  });

  // Set Search Term
  builder.addCase(setSearchTerm, (state, { payload }) => {
    let filters = getFilterSet(state, payload.filterSet);

    if (!filters) {
      return;
    }

    filters.searchTerm = payload.searchTerm;
  });

  // Remove Jobs from Job List
  builder.addCase(removeJobsFromJobList, (state, { payload }) => {
    if (!state.jobHeaders) {
      return;
    }

    state.jobHeaders = state
      .jobHeaders
      .filter(x => payload.jobIds.indexOf(x.id) === -1);
  });
});

export default myJobsReducer;

function getFilterSet(state: IMyJobsState, tab: MyJobsTabs) {
  if (tab === MyJobsTabs.Active) {
    return state.activeJobFilters;
  } else if (tab === MyJobsTabs.Completed) {
    return state.completedJobFilters;
  } else if (tab === MyJobsTabs.Recurring) {
    return state.recurringJobFilters;
  } else {
    return;
  }
}