import Banner, { BannerType } from "components/common/Banner";
import Modal from "components/common/Modal";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { cancelJob, setJobModal } from "store/myJobs/myJobsActions";
import useSelector from "store/useSelector";

const CancelJobModal: React.FC = () => {
  const {
    myJobs: {
      cancelJobModal
    },
    auth: {
      currentUser,
    },
  } = useSelector(store => store);
  const dispatch = useDispatch();
  const { t } = useTranslation('myJobs');

  const onCancelJobClick = () => {
    dispatch(cancelJob({
      jobId: cancelJobModal.jobId,
      timestamp: new Date(),
      userEmail: currentUser.email,
    }));

    onClose();
  }

  const onClose = () => {
    dispatch(setJobModal({
      modal: "cancelJobModal",
      data: {
        isOpen: false,
        jobId: 0,
        jobDisplay: "",
      },
    }));
  }

  return (
    <Modal
      isOpen={true}
      header={t(`Cancel Job - {jobDisplay}`, {jobDisplay: cancelJobModal.jobDisplay})}
      controls={
        <>
          <button
            className="secondary-button"
            onClick={onClose}
          >
            {t('Dismiss')}
          </button>
          <button
            className="primary-button"
            onClick={onCancelJobClick}
          >
            {t('Cancel Job')}
          </button>
        </>
      }
    >
      <Banner
        type={BannerType.Warn}
      >
        {t('Warning This job will be ')}<b>{t('CANCELLED')}</b>{t(' and will no longer be accessible in WorkRight.')}<br />
        <br />
        {t('Are you sure?  If you do not want to ')}<b>{t('CANCEL')}</b>{t(' this job, click ')}<b>{t('DISMISS')}</b>.
      </Banner>
    </Modal>
  );
};

export default CancelJobModal;