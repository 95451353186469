import React from "react";
import { IMicrosoftStreamVideoData, IStepComponent, StepComponentTypes } from "interfaces/sw/SWInterfaces";
import "./MicrosoftStreamVideo.scoped.scss";
import config from "config";
import useSelector from "store/useSelector";
import { Connectivity } from "interfaces/execution/executionInterfaces";
import { useTranslation } from "react-i18next";

interface IMicrosoftStreamVideoProps {
  component: IStepComponent,
}

const MicrosoftStreamVideo: React.FC<IMicrosoftStreamVideoProps> = ({ component }) => {
  const {
    offline: {
      isOnline,
    },
  } = useSelector(x => x);

  const { t } = useTranslation('components');

  if (component.type !== StepComponentTypes.MicrosoftStreamVideo) {
    return null;
  }

  const videoGuid = (component.data as IMicrosoftStreamVideoData).videoGuid;
  let displayComponent: JSX.Element | null = null;

  if (isOnline === Connectivity.Online) {
    if (videoGuid) {
      displayComponent = (
        <iframe
          className="embed"
          title={videoGuid}
          src={videoGuid}
          allowFullScreen
        ></iframe>
      );
    }
    else {
      displayComponent = (
        <div
          className="embed empty"
        >
          <span>
            {t('No video specified')}
          </span>
        </div>
      );
    }
  }
  else {
    displayComponent = (
      <div
        className="embed empty"
      >
        <span>
          {t('Video is not available since device is offline')}
        </span>
      </div>
    );
  }

  return (
    <div>
      {displayComponent}
    </div>
  );
}

export default MicrosoftStreamVideo;