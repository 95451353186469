import React, { useCallback, useEffect, useRef, useState } from "react";
import "./ExecutionSW.scoped.scss";
import { useDispatch } from "react-redux";
import { duplicateSW, clearSWPaperExecution, clearSWStepComments, clearSWStepResponses, logJobAction, markSWAsNA, openPaperExecuteSW, resetSWResponses, saveExecutionData, setShowAllSteps, switchToExecutionSW } from "store/execution/executionActions";
import useSelector from "store/useSelector";
import SWStatusIndicator from "./SWStatusIndicator";
import { SWStatus } from "interfaces/execution/executionInterfaces";
import { getSWInstanceNumber, getSWInstanceTitle, getSWStatus } from "utilities/swUtilities";
import StepList from "./steps/StepList";
import PPEList from "./PPEList";
import NoticeList from "./NoticeList";
import SWTypeIcon from "components/common/SWTypeIcon";
import Modal from "components/common/Modal";
import ImageModal from "components/common/ImageModal";
import IdbApi from "apis/idb/IdbApi";
import { showErrorToast, showSuccessToast } from "store/toast/toastActions";
import BlockSpinner from "components/common/BlockSpinner";
import { SWPJobStatus } from "interfaces/jobs/JobInterfaces";
import downArrowIcon from "media/icons/dls/arrow-down-2.svg";
import EllipsesDropdown, { IEllipsesDropdownItem } from "components/common/EllipsesDropdown";
import { useTranslation } from "react-i18next";
import SWUserFeedback from "components/jobs/mine/SWUserFeedback";
import { SWTypes } from "interfaces/sw/SWInterfaces";
import { ExecutionMode } from "store/execution/executionTypes";

const caseInsensitiveCompare = (a : string, b : string) =>
  a.toLowerCase() === b.toLowerCase();

interface IExecutionSWProps {
  jobSWId: number,
}

const ExecutionSW: React.FC<IExecutionSWProps> = ({ jobSWId }) => {
  const {
    execution: {
      stepResponses,
      job,
      currentLocation,
      executor,
      paperExecutions,
      realtimeSWPaperExecsLoading,
      mode,
    },
    auth: {
      currentUser: {
        name,
        email,
      },
    },
  } = useSelector(store => store);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setShowAllSteps({
      jobSWId,
    }));
  }, [dispatch, jobSWId]);

  const swHeaderRef = useRef<HTMLDivElement>(null);
  const [isMarkNAModalVisible, setIsMarkNAModalVisible] = useState(false);
  const [isResetSWRespModalVisible, setIsResetSWRespModalVisible] = useState(false);
  const [visiblePaperExecutionImage, setVisiblePaperExecutionImage] = useState("");
  const [paperExecuteFileName, setPaperExecuteFileName] = useState("");
  const { t } = useTranslation('executionSW');

  const memoizedFocusOnHeader = useCallback(
    () => {
      swHeaderRef.current?.scrollIntoView();
    },
    []
  );

  if (!job) {
    return null;
  }

  const sw = job.sws.find(sw => sw.jobSWId === jobSWId);

  if (!sw) {
    return null;
  }

  const swUserFeedback = job.swUserFeedback.find(feedback => feedback.swId === sw.id &&
    feedback.version === sw.version &&
    feedback.jobId === job.id &&
    caseInsensitiveCompare(feedback.createdBy, executor?.email.length ? executor?.email : email));

  const isTeamMember = !!job?.team.find(x =>
    x.email.toLowerCase() === email.toLowerCase());

  const paperExecution = paperExecutions.find(x => x.jobSWId === jobSWId);

  const switchToSW = () => {
    if (sw.type) {
      dispatch(switchToExecutionSW({
        jobSWId,
      }));
    }
  }

  const onMarkSWAsNAClicked = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    setIsMarkNAModalVisible(true);
  }

  const onPaperExecuteClicked = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    dispatch(openPaperExecuteSW({
      jobSWId,
    }))
  }

  const onShowCriticalClicked = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();

    dispatch(setShowAllSteps({
      jobSWId,
    }));
  }

  const onClearResponsesClicked = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    setIsResetSWRespModalVisible(true);
  }

  const onDuplicateSW = () => {
    let lastElement = 0;
    let newJobswid = 0;
    let jobCopy = { ...job };

    //To get last sortorder of sw and get newJobswid
    if (Object.keys(jobCopy.sws).length > 0) {
      lastElement = jobCopy.sws[jobCopy.sws.length - 1].sortOrder;
      newJobswid = -Math.abs(jobCopy.sws[jobCopy.sws.length - 1].jobSWId) - 1;
    }

    dispatch(duplicateSW({
      jobSWId: newJobswid,
      sortOrder: lastElement + 1,
      showCriticalSteps: sw.hasCriticalSteps,
      oldjobSWid: jobSWId,
    }));

    dispatch(logJobAction({
      action: `Duplicate SW ${sw.title} with sortorder ${lastElement + 1} in ${getSWInstanceTitle(jobSWId, job.sws)}`,
      timestamp: new Date(),
      userName: executor?.name || name,
      userEmail: executor?.email || email,
    }));

    if (mode === ExecutionMode.Offline) {
      dispatch(saveExecutionData());
    }
  };

  const markNA = () => {
    dispatch(markSWAsNA({
      jobSWId,
      userEmail: executor?.email || email,
    }));

    const swInstanceNum = getSWInstanceNumber(jobSWId,
      job.sws);

    dispatch(logJobAction({
      action: `Marked ${sw?.title}${!!swInstanceNum
        ? ` (Instance ${swInstanceNum})`
        : ""
        } N/A`,
      timestamp: new Date(),
      userName: executor?.name || name,
      userEmail: executor?.email || email,
    }));
    setIsMarkNAModalVisible(false);

    if (mode === ExecutionMode.Offline) {
      dispatch(saveExecutionData());
    }
  }

  const viewPaperExecutionImage = async () => {
    if (!paperExecution?.imageFilename) {
      return;
    }

    try {
      const imgData = await IdbApi.getUserImageData(paperExecution.imageFilename);

      if (!imgData?.data) {
        dispatch(showErrorToast(t("Paper execution image not found!")));
        return;
      }

      setVisiblePaperExecutionImage(imgData.data);
      setPaperExecuteFileName(imgData.filename);
    } catch (err: any) {
      dispatch(showErrorToast(t(`Failed to load paper execution image {message}`, { message: err.message || err })));
      return;
    }
  }

  const resetSW = () => {
    dispatch(clearSWStepResponses(
      {
        jobSWId: jobSWId,
      }));

    dispatch(clearSWStepComments(
      {
        jobSWId: jobSWId,
      }));

    dispatch(clearSWPaperExecution(
      {
        jobSWId: jobSWId,
      }));

    dispatch(logJobAction({
      action: `Reset due to need to complete again – ${sw?.title}`,
      timestamp: new Date(),
      userName: executor?.name || name,
      userEmail: executor?.email || email,
    }));

    dispatch(resetSWResponses(
      {
        jobId: job.id,
        jobSWId: jobSWId,
      }));

    setIsResetSWRespModalVisible(false);
    dispatch(showSuccessToast(t("SW responses were reset succesfully")));
  }

  let swStatus: SWStatus = getSWStatus(sw, stepResponses);

  const isRealtimeLoadingPaperExec = realtimeSWPaperExecsLoading.indexOf(sw.jobSWId) > -1;

  let itemList: (IEllipsesDropdownItem | undefined)[] = [{
    key: "Mark SW N/A",
    label: t("Mark SW N/A"),
    onClick: onMarkSWAsNAClicked,
  },
  {
    key: "Paper Execute",
    label: t("Paper Execute"),
    onClick: onPaperExecuteClicked,
  },
  sw.hasCriticalSteps ?
    sw.showCriticalSteps ? ({
      key: "Show All Steps",
      label: t("Show All Steps"),
      onClick: onShowCriticalClicked,
    }) : ({
      key: "Show Only Critical",
      label: t("Show Only Critical"),
      onClick: onShowCriticalClicked,
    }) : undefined,
  {
    key: "Reset SW",
    label: t("Reset SW"),
    onClick: onClearResponsesClicked,
  },
  {
    key: "Duplicate SW",
    label: t("Duplicate SW"),
    onClick: onDuplicateSW,
  }
  ];

  let papExecItemList: (IEllipsesDropdownItem | undefined)[] = [{
    key: "View Paper Execution",
    label: t("View Paper Execution"),
    onClick: viewPaperExecutionImage,
  },
  {
    key: "Reset SW",
    label: t("Reset SW"),
    onClick: onClearResponsesClicked,
  },
  {
    key: "Duplicate SW",
    label: t("Duplicate SW"),
    onClick: onDuplicateSW,
  }
  ];

  return (
    <>
      <div
        className="header-container"
      >
        <div
          className="sw-type"
        >
          {sw.type &&
            <SWTypeIcon
              type={sw.type}
            />
          }
          {!sw.type &&
            <BlockSpinner
              width={50}
            />
          }
        </div>
        <div
          className="sw-header"
        >
          <div
            onClick={switchToSW}
            className="sw-details no-text-select"
            ref={swHeaderRef}
          >
            <div
              className="status"
            >
              <SWStatusIndicator
                status={swStatus}
                completedOnPaper={!!paperExecution}
              />
            </div>
            <div
              className="header-row"
            >
              <div
                className="sw-info"
              >
                <span
                  className="sw-title"
                >
                  {getSWInstanceTitle(sw.jobSWId, job.sws)}
                </span>
                <span
                  className="sw-desc"
                >
                  {sw.description}
                </span>
              </div>

              {swStatus === SWStatus.Completed &&
                <SWUserFeedback
                  feedback={swUserFeedback}
                  swId={sw.id}
                  version={sw.version}
                  jobId={job.id}
                  openFeedback={false} />
              }

              {!paperExecution
                && isTeamMember
                && job.status !== SWPJobStatus.Completed
                && job.status !== SWPJobStatus.Cancelled
                && sw.type
                &&
                <EllipsesDropdown
                  label={t("Actions")}
                  icon={downArrowIcon}
                  itemTextAlign="left"
                  items={itemList}
                />
              }

              {paperExecution
                && sw.type
                &&
                <EllipsesDropdown
                  label={t("Actions")}
                  icon={downArrowIcon}
                  itemTextAlign="left"
                  items={papExecItemList}
                />
              }
            </div>
          </div>
        </div>
      </div>
      {currentLocation?.jobSWId === jobSWId &&
        <>
          <div
            className="sw"
          >
            <>
              {sw.ppe.length > 0 &&
                <PPEList
                  ppe={sw.ppe}
                  swId={sw.id}
                  swVersion={sw.version}
                />
              }
              {sw.notices.length > 0 &&
                <NoticeList
                  notices={sw.notices}
                  swId={sw.id}
                  swVersion={sw.version}
                />
              }

              <h4
                className="steps-header"
              >
                {sw.type === SWTypes.TLMSWI ? t('Tasks') : t('Steps')}
              </h4>

              <StepList
                sw={sw}
                focusSWHeader={memoizedFocusOnHeader}
              />
            </>
          </div>
          {!!visiblePaperExecutionImage &&
            <ImageModal
              title={t("Paper Execution Image")}
              imgSrc={visiblePaperExecutionImage}
              fileName={paperExecuteFileName}
              onClose={() => setVisiblePaperExecutionImage("")}
            />
          }
          {isRealtimeLoadingPaperExec &&
            <div
              className="realtime-container"
            >
              <BlockSpinner />
            </div>
          }
        </>
      }
      {isMarkNAModalVisible &&
        <Modal
          header={t("Mark Standard Work N/A")}
          isOpen={true}
          controls={(
            <>
              <button
                className="secondary-button"
                onClick={() => setIsMarkNAModalVisible(false)}
              >
                {t('Cancel')}
              </button>
              <button
                className="primary-button"
                onClick={markNA}
              >
                {t('Mark N/A')}
              </button>
            </>
          )}
        >
          {t('This will individually mark each step in this Standard Work as N/A. Are you sure you want to continue?')}
        </Modal>
      }
      {isResetSWRespModalVisible &&
        <Modal
          header={t("Reset SW")}
          isOpen={true}
          controls={(
            <>
              <button
                className="secondary-button"
                onClick={() => setIsResetSWRespModalVisible(false)}
              >
                {t('Cancel')}
              </button>
              <button
                className="primary-button"
                onClick={resetSW}
              >
                {t('Reset SW')}
              </button>
            </>
          )}
        >
          {t('Are you sure you want to clear all the responses?')}
        </Modal>
      }
      {/* {paperExecuteModalData.isVisible &&
        <PaperExecuteModal
          {...paperExecuteModalData}
        />
      } */}
    </>
  );
}

export default ExecutionSW;