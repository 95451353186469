import { IMasterDataItem } from "interfaces/masterData/masterDataInterfaces";
import { formatArrayResponse, codeNameFormatter, FORMATTING_PROPS, codeNameIdFormatter } from "./masterDataUtils";

export function formatActivityWorkflows(response: any): IMasterDataItem[] {
  if (!response.ActivityWorkFlows?.length
    || !Array.isArray(response.ActivityWorkFlows)) {
    return [];
  }

  return response.ActivityWorkFlows.map((item: any): IMasterDataItem => ({
    value: item.Value,
    guid: item.ActivityWorkflowId.toString(),
    code: item.Dimensions,
  }));
}

export function formatCompetencyElements(response: any): IMasterDataItem[] {
  if (!response.CompetencyElements?.length
    || !Array.isArray(response.CompetencyElements)) {
    return [];
  }

  return response.CompetencyElements.map((item: any): IMasterDataItem => ({
    value: item.Value,
    guid: item.CompetencyElementId.toString(),
  }));
}

export function formatCountries(response: any): IMasterDataItem[] {
  if (!response.Countries?.length
    || !Array.isArray(response.Countries)) {
    return [];
  }

  return response.Countries.map(formatCountry);
}

export function formatCountry(item: any): IMasterDataItem {
  return {
    value: item.Value,
    code: item.Code,
    guid: item.Guid,
  };
}

export function formatGeoUnits(response: any): IMasterDataItem[] {
  if (!response.GeoUnits?.length
    || !Array.isArray(response.GeoUnits)) {
    return [];
  }

  return response.GeoUnits.map(formatGeoUnit);
}

export function formatGeoUnit(item: any): IMasterDataItem {
  return {
    value: item.Name,
    code: item.Code,
    guid: item.Code,
  };
}

export function formatSubBusinessLine(item: any): IMasterDataItem {
  return {
    value: item.Value,
    code: item.Code,
    guid: item.Code,
  };
}

export function formatEquipment(response: any): IMasterDataItem[] {
  if (!response.Equipment?.length
    || !Array.isArray(response.Equipment)) {
    return [];
  }

  return response.Equipment.map((item: any): IMasterDataItem => ({
    value: item.Value,
    guid: item.Guid,
  }));
}

export function formatEpicEquipment(response: any): IMasterDataItem[] {
  if (!response.EpicEquipment?.length
    || !Array.isArray(response.EpicEquipment)) {
    return [];
  }

  return response.EpicEquipment.map((item: any): IMasterDataItem => ({
    value: item.Value,
    guid: item.Id,
  }));
}

export function formatCustomers(response: any): IMasterDataItem[] {
  if (!response.Customers?.length
    || !Array.isArray(response.Customers)) {
    return [];
  }

  return response.Customers.map(formatCustomer);
}

export function formatCustomer(item: any): IMasterDataItem {
  return {
    value: item.Value,
    guid: item.Id,
  };
}

export function formatBusinessRoles(response: any): IMasterDataItem[] {
  if (!response.BusinessRoles?.length
    || !Array.isArray(response.BusinessRoles)) {
    return [];
  }

  return response.BusinessRoles.map((item: any): IMasterDataItem => ({
    value: item.Value,
    guid: item.Id,
  }));
}

export function formatProductCenters(response: any): IMasterDataItem[] {
  if (!response.ProductCenters?.length
    || !Array.isArray(response.ProductCenters)) {
    return [];
  }

  return response.ProductCenters.map((item: any): IMasterDataItem => ({
    value: item.Value,
    guid: item.Code,
  }));
}

export function formatEquivalentStandardWorks(response: any): IMasterDataItem[] {
  if (!response.StandardWorks?.length
    || !Array.isArray(response.StandardWorks)) {
    return [];
  }

  return response.StandardWorks.map(formatEquivalentStandardWork);
}

export function formatEquivalentStandardWork(item: any): IMasterDataItem {
  return {
    value: item.Title,
    guid: item.Guid,
    code: item.Description,
  };
}

export function formatLanguages(response: any): IMasterDataItem[] {
  if (!response.Languages?.length
    || !Array.isArray(response.Languages)) {
    return [];
  }

  return response.Languages.map(formatLanguage);
}

export function formatLanguage(item: any): IMasterDataItem {
  return {
    value: item.Value,
    guid: item.Guid,
  };
}

export function formatOperatingEnvironments(response: any): IMasterDataItem[] {
  if (!response.OperatingEnvironments?.length
    || !Array.isArray(response.OperatingEnvironments)) {
    return [];
  }

  return response.OperatingEnvironments.map((item: any): IMasterDataItem => ({
    value: item.Value,
    guid: item.Guid,
  }));
}

export function formatOrganizations(response: any): IMasterDataItem[] {
  if (!response.Organizations?.length
    || !Array.isArray(response.Organizations)) {
    return [];
  }

  return response.Organizations.map(formatOrganization);
}

export function formatOrganization(item: any): IMasterDataItem {
  return {
    value: item.Value,
    code: item.Code,
    guid: item.Guid,
  };
}

export function formatSubBusinessLines(response: any): IMasterDataItem[] {
  if (!response.SubBusinessLines.length
    || !Array.isArray(response.SubBusinessLines)) {
    return [];
  }

  return response.SubBusinessLines.map((item: any): IMasterDataItem => ({
    value: item.Value,
    guid: item.Code,
    code: item.Code,
  }));
}


export function formatSafetyCategories(response: any): IMasterDataItem[] {
  if (!response.SafetyCategories?.length
    || !Array.isArray(response.SafetyCategories)) {
    return [];
  }

  return response.SafetyCategories.map((item: any): IMasterDataItem => ({
    value: item.Value,
    guid: item.Guid,
  }));
}

export function formatServiceTypes(response: any): IMasterDataItem[] {
  if (!response.ServiceTypes?.length
    || !Array.isArray(response.ServiceTypes)) {
    return [];
  }

  return response.ServiceTypes.map((item: any): IMasterDataItem => ({
    value: item.Value,
    guid: item.Guid,
  }));
}

export function formatTasks(response: any): IMasterDataItem[] {
  if (!response.Tasks?.length
    || !Array.isArray(response.Tasks)) {
    return [];
  }

  return response.Tasks.map((item: any): IMasterDataItem => ({
    value: item.Value,
    guid: item.TaskId,
  }));
}

export function formatOwningPlants(response: any): IMasterDataItem[] {
  return formatArrayResponse(response, 
    codeNameFormatter,
    FORMATTING_PROPS.plants
  );
}

export function formatPerformingPlants(response: any): IMasterDataItem[] {
  return formatArrayResponse(response, 
    codeNameFormatter,
    FORMATTING_PROPS.plants
  );
}

export function formatWorkCenters(response: any): IMasterDataItem[] {
  return formatArrayResponse(response, 
    codeNameIdFormatter,
    FORMATTING_PROPS.workCenters
  );
}

export function formatMaterials(response: any): IMasterDataItem[] {
  return formatArrayResponse(response, 
    codeNameIdFormatter,
    FORMATTING_PROPS.materials
  );
}
