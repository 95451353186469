import React, { useEffect, useState } from "react";
import IdbApi from "apis/idb/IdbApi";
import { IStepComponent, IVideoComponentData } from "interfaces/sw/SWInterfaces";
import "./Video.scoped.scss";

interface IVideoProps {
    swId: string,
    swVersion: string,
    video: IStepComponent,
}

const Video: React.FC<IVideoProps> = ({ swId, swVersion, video }) => {
    const [videoData, setVideoData] = useState<string | undefined>();
    const [errMsg, setErrMsg] = useState<string | undefined>();

    const {
        data,
    } = video;

    let filename = "";

    if (isVideoData(data)) {
        filename = data.filename;
    }

    useEffect(() => {
        let aborted = false;

        const fetchVideo = async () => {
            try {
                if (filename) {
                    const video = await IdbApi.getSWImageData(swId, swVersion, filename);
                    if (!aborted) {
                        setVideoData(video?.data);
                    }
                }
            } catch (err: any) {
                if (!aborted) {
                    setErrMsg(err?.message || err.toString())
                }
            }
        }

        fetchVideo();

        return () => {
            aborted = true;
        };
    }, [swId, swVersion, filename, setVideoData, setErrMsg]);

    if (videoData) {
        return (
            <video controls className="embed">
                <source type="video/mp4" src={videoData} />
            </video>
        );
    } else if (errMsg) {
        return (
            <div
                className="embed empty"
            >
                <span>
                    {errMsg}
                </span>
            </div>
        );
    } else {
        return null;
    }
}

export default Video;

function isVideoData(data: any): data is IVideoComponentData {
    return data !== null
        && data !== undefined
        && 'filename' in data;
}