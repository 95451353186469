import React, { useState, useEffect } from "react";
import Modal from "components/common/Modal";
import UserSearch from "./UserSearch";
import cloneDeep from "lodash/cloneDeep";
import UserApi from "apis/user/UserApi";
import UserList from "./UserList";
import BlockSpinner from "components/common/BlockSpinner";
import emailIcon from "media/icons/dls/email.svg";
import JobsApi from "apis/jobs/JobsApi";
import { getResponseErrorMessage } from "utilities/validationErrorHelpers";
import { useDispatch } from "react-redux";
import { showErrorToast, showSuccessToast } from "store/toast/toastActions";
import { IAADUser } from "interfaces/user/UserInterfaces";
import { useTranslation } from "react-i18next";

interface IAddTeamMemberModalProps {
  isOpen: boolean,
  headerText: string,
  onDoneClicked(): void,
  jobId: number,
}

interface IAddTeamMemberModalState {
  isSearching: boolean,
  searchResults: IAADUser[] | null,
}

const AddTeamMemberModal: React.FC<IAddTeamMemberModalProps> = ({
  isOpen,
  headerText,
  onDoneClicked,
  jobId,
}) => {
  const [searchEntry, setSearchEntry] = useState("");
  const [searchState, setSearchState] = useState<IAddTeamMemberModalState>({
    isSearching: false,
    searchResults: null,
  });
  const [isSharing, setIsSharing] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation('createJob');

  const search = async (searchTerm: string) => {
    if (!searchTerm) {
      setSearchState({
        isSearching: false,
        searchResults: null,
      });
      return;
    }

    setSearchState({
      ...cloneDeep(searchState),
      isSearching: true,
    });

    const searchResults = await UserApi.searchAADUsers(searchTerm);

    setSearchState({
      ...cloneDeep(searchState),
      searchResults,
      isSearching: false,
    });
  }

  useEffect(() => {
    search(searchEntry);
  }, []);

  const onClickTeamMember = async (user: IAADUser) => {
    setIsSharing(true);

    try {
      await JobsApi.shareCompletedJob(jobId, {
        email: user.email,
        name: user.name,
        offlinePIN: null,
      });
      dispatch(showSuccessToast(t(`Shared with {user} successfully.`, { user: user.email })));
    } catch (err: any) {
      dispatch(showErrorToast(t("Failed to share job")
        + getResponseErrorMessage(err)));
    } finally {
      setIsSharing(false);
    }
  }

  let displayComponent: JSX.Element | undefined;

  if (searchState.isSearching
    || isSharing) {
    displayComponent = (
      <BlockSpinner />
    );
  } else if (searchState.searchResults?.length) {
    displayComponent = (
      <UserList
        label={t("Search Results")}
        showSelf={false}
        users={searchState.searchResults}
        onAddClicked={onClickTeamMember}
        addIcon={emailIcon}
        addTitle="Share"
      />
    );
  } else if (searchState.searchResults) {
    displayComponent = (
      <span>{t('No users found.')}</span>
    );
  }

  return (
    <Modal
      isOpen={isOpen}
      header={headerText}
      controls={(
        <button
          className="primary-button"
          onClick={onDoneClicked}
        >
          {t('Done')}
        </button>
      )}
    >
      <UserSearch
        value={searchEntry}
        onChange={value => setSearchEntry(value)}
        isDisabled={searchState.isSearching}
        autoFocus={true}
        onClick={search}
      />
      {displayComponent}
    </Modal>
  );
};

export default AddTeamMemberModal;