import { IMasterDataItem } from "interfaces/masterData/masterDataInterfaces";

export interface IJobTemplateHeader {
  id: number,
  title: string,
  jobNumber: string,
  canEdit: boolean,
  countryGuid: string,
  geoUnitCode: string,
  subBusinessLineCode: string,
}

export interface ISWPJobHeader {
  id: number,
  title: string,
  jobNumber: string,
  isDemo: boolean,
  status: SWPJobStatus,
  lastActionDate: Date,
  completedBy: string | null,
  isOnTeam: boolean,
  deviationCount: number,
  totalSteps: number,
  totalCompletedSteps: number,
  completionPercentage: number,
}

export interface ISWPJob {
  id: number,
  title: string,
  jobNumber: string,
  isDemo: boolean,
  type: SWPJobType,
  sws: ISWPJobSW[],
  team: ISWPJobTeamMember[],
  swUserFeedback: ISWUserFeedback[],
  status: SWPJobStatus,
  lastActionDate: Date,
  completedBy: string | null,
  org: IMasterDataItem | undefined,
  country: IMasterDataItem | undefined,
  geoUnit: IMasterDataItem | undefined,
  subBusinessLine: IMasterDataItem | undefined,
  customer: IMasterDataItem | undefined,
  startTime: Date | undefined,
  endTime: Date | undefined,
  schedule: IJobSchedule | undefined,
  isOnTeam: boolean,
  showAllECLs: boolean | undefined,
  foundStartJobSWId: number,
  jobDocs: ISWPJobDoc[],
  isDirty?: boolean,
  deviationCount: number,
  totalSteps: number,
  totalCompletedSteps: number,
  completionPercentage: number,
}

export enum SWPJobStatus {
  New = "New",
  InProgress = "InProgress",
  Completed = "Completed",
  Cancelled = "Cancelled",
}

export enum SWPJobType {
  Standard = "Standard",
  Template = "Template",
  Recurring = "Recurring",
}

export interface ISWPJobSW {
  jobSWId: number,
  swId: string,
  title: string | null,
  description?: string | null,
  version: string,
  sortOrder: number,
}
export interface IOfflineJobDuplicateJobSW {
  jobId: number,
  jobSWId: number,
  swId: string,
  title: string | null,
  description?: string | null,
  version: string,
  sortOrder: number,
  isOnServer: boolean,
}

export interface ISWPJobDoc {
  fileName: string,
  fileContent: string,
  fileSize: number,
}

export interface ISWPJobTeamMember {
  name: string,
  email: string,
  offlinePIN: string | null,
}

export interface ISWUserFeedback {
  feedbackId: number,
  swId: string,
  version: string,
  jobId: number,
  feedback: string,
  vote: boolean,
  createdBy: string,
}

export interface ISWPJobCreationResponse {
  success: boolean,
  message?: string | null,
  jobId: number,
  jobSWs: ISWPCreatedJobSWResponse[],
}

export interface ISWPCreatedJobSWResponse {
  jobSWId: number,
  swId: string,
  version: string,
  sortOrder: number,
}

export interface ISWPUpdateJobResponse {
  success: boolean,
  message?: string | null,
}

export interface ISWPJobLogItem {
  jobId: number,
  action: string,
  userName?: string,
  userEmail: string,
  timestamp: Date,
  isOnServer: boolean,
  isDirty: boolean,
}

export interface IOfflineSWPJobLog {
  jobId: number,
  log: ISWPJobLogItem[],
}

export interface IOfflineJobShowAllECLs {
  jobId: number,
  showAllECLs: boolean,
  isOnServer: boolean,
}

export enum MyJobsTabs {
  Active = "Active",
  Completed = "Completed",
  Templates = "Templates",
  Recurring = "Recurring",
}

export interface IJobSchedule {
  type: JobScheduleType,
  startDate: Date,
  endDate?: Date,
  totalOccurrences?: number,
  everyX?: number,
  dayOfMonth?: number,
  weeklySun?: boolean,
  weeklyMon?: boolean,
  weeklyTue?: boolean,
  weeklyWed?: boolean,
  weeklyThu?: boolean,
  weeklyFri?: boolean,
  weeklySat?: boolean,
}

export enum JobScheduleType {
  Daily = "Daily",
  Weekly = "Weekly",
  Monthly = "Monthly",
}