import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import { Routes } from "components/routing/Routing";
import { IRealtimePaperExecutionInfo, IRealtimeStepCommentInfo, IRealtimeStepResponseInfo } from "interfaces/execution/executionInterfaces";
import { acquireAccessToken } from "msalConfig";
import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { Switch, Route } from "react-router-dom";
import { handleRealtimeCommentsUpdate, handleRealtimeJobCompletion, handleRealtimeJobHistoryLoad, handleRealtimePaperExecution, handleRealtimeResetSW, handleRealtimeStepUpdate, setjobExecutor } from "store/execution/executionActions";
import { ExecutionMode } from "store/execution/executionTypes";
import useSelector from "store/useSelector";
import Execution from "./Execution";
import PrintView from "./printView/PrintView";

interface IRealtimeExecutionProps {
  currentUser: string,
}

const RealtimeExecution: React.FC<IRealtimeExecutionProps> = (props) => {
  const dispatch = useDispatch();
  const {
    job,
    mode,
  } = useSelector(store => store.execution);
  const jobId = useRef<number>(0);
  const connectionRef = useRef<HubConnection | null>(null);
  const modeRef = useRef<ExecutionMode>(mode);

  jobId.current = job?.id || 0;

  useEffect(() => {
    // Effect for loading and unloading the component
    // to open and close the connection.
    if (modeRef.current === ExecutionMode.Offline) {
      // Don't connect to signalR hubs if offline.
      return;
    }

    // Async function for getting connection and starting it.
    const getConnection = async () => {
      const token = await acquireAccessToken();

      const newConnection = new HubConnectionBuilder()
        .withUrl('/hubs/execution', {
          accessTokenFactory: () => token || "",
        })
        .withAutomaticReconnect()
        .build();

      // Start.
      newConnection
        .start()
        .then(_ => {
          newConnection?.on('ReceiveStepUpdates', (stepInfo: IRealtimeStepResponseInfo[]) => {
            dispatch(handleRealtimeStepUpdate(stepInfo));
          });

          newConnection?.on('ReceiveCommentUpdates', (commentsInfo: IRealtimeStepCommentInfo) => {
            dispatch(handleRealtimeCommentsUpdate(commentsInfo));
          });

          newConnection?.on("ReceiveJobHistoryUpdates", (jobId: number) => {
            dispatch(handleRealtimeJobHistoryLoad({ jobId }));
          });


          newConnection?.on("ReceiveJobCompletion", (jobId: number, email: string) => {
            if (email !== props.currentUser) {
              dispatch(setjobExecutor(email));
              dispatch(handleRealtimeJobCompletion({ jobId }));
            }
          });

          newConnection?.on("RecieveResetSW", (jobId: number, jobSWId: number) => {
            dispatch(handleRealtimeResetSW({ jobSWID: jobSWId }));
          });

          newConnection?.on("ReceivePaperExecution", (jobId: number, paperExec: IRealtimePaperExecutionInfo) => {
            console.log(paperExec);
            if (typeof (paperExec.timestamp) === "string") {
              paperExec.timestamp = new Date(paperExec.timestamp);
            }
            dispatch(handleRealtimePaperExecution({ jobId, paperExec }));
          });

          newConnection?.send("JoinExecution", jobId.current);
        }).catch(e => console.log('Hub connection failed: ', e));

      connectionRef.current = newConnection;
    };

    // Call our async method.
    getConnection();

    return () => {
      if (connectionRef.current) {
        if (jobId.current) {
          connectionRef
            .current
            .send("LeaveExecution", jobId.current)
            .then(_ => connectionRef.current?.stop());
        } else {
          connectionRef.current?.stop();
        }
      }
    };
  }, [dispatch, props.currentUser]);

  useEffect(() => {
    // Effect to handle when the window is forcibly closed.
    const handler = () => {
      if (connectionRef.current) {
        if (jobId.current) {
          connectionRef
            .current
            .send("LeaveExecution", jobId.current)
            .then(_ => connectionRef.current?.stop());
        } else {
          connectionRef.current?.stop();
        }
      }
    };

    window.addEventListener("beforeunload", handler);

    return () => {
      window.removeEventListener("beforeunload", handler);
    };
  }, []);

  return (
    <Switch>
      <Route exact path={Routes.PrintViewJob} component={PrintView} />
      <Route exact path={Routes.PrintViewOfflineJob} component={PrintView} />
      <Route component={Execution} />
    </Switch>
  );
};

export default RealtimeExecution;