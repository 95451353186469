export function getSuffixedNumber(number: number): string {
  let str = Math.abs(number).toString();

  if (str.endsWith("11")
    || str.endsWith("12")
    || str.endsWith("13")) {
    return `${number}th`;
  }

  str = str.substr(str.length - 1);

  const lastDigit = parseInt(str, 10);

  switch(lastDigit) {
    case 1: return `${number}st`;
    case 2: return `${number}nd`;
    case 3: return `${number}rd`;
    default: return `${number}th`;
  }
}