import Banner, { BannerType } from "components/common/Banner";
import "./UserJobs.scoped.scss";
import BlockSpinner from "components/common/BlockSpinner";
import { UserJobsTabs } from "interfaces/user/UserInterfaces";
import React from "react"
import { useDispatch } from "react-redux";
import { getUsersJobs, setJobSearchTerm, setUsersJobsTab } from "store/userManagement/userManagementActions";
import useSelector from "store/useSelector";
import UserJobsStatusFilter from "./UserJobsToolbar";
import { RouteComponentProps } from "react-router-dom";
import JobHeader from "components/jobs/mine/jobHeaders/JobHeader";
import { useTranslation } from "react-i18next";

const UserJobs: React.FC<RouteComponentProps> = (props) => {
  const {
    userManagement: {
      editUserData: {
        user,
        jobData: {
          currentTab,
          usersJobs,
          loadJobsOperation,
          jobSearchTerm,
        },
      },
    },
  } = useSelector(store => store);

  const dispatch = useDispatch();
  const { t } = useTranslation('userSearch');

  const changeTab = (newFilter: UserJobsTabs) => {
    if (newFilter !== currentTab) {
      dispatch(setUsersJobsTab(newFilter));
    }
  }

  const onSearchTermChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setJobSearchTerm(e.target.value));
    dispatch(getUsersJobs());
  };

  let usersJobsDisplay: JSX.Element | null = null;
  if (loadJobsOperation?.isWorking) {
    usersJobsDisplay = <BlockSpinner />;
  } else if (usersJobs!?.length === 0) {
    usersJobsDisplay = (
      <Banner type={BannerType.Info}>
        {t('There are no {currentTab} jobs assigned to {email}.', {currentTab: t(currentTab.toLowerCase()), email: user?.email})}
      </Banner>
    );
  } else if (usersJobs!?.length > 0) {
    usersJobsDisplay = (
      <>
        {usersJobs!?.map(job =>
          <JobHeader
            key={job.id}
            {...props}
            showCacheButton={false}
            isOfflineMode={false}
            showExtraButtons={false}
            jobHeader={job}
          />
        )}
      </>
    );
  }

  return (
    <>
      <UserJobsStatusFilter
        onTabChange={changeTab}
        currentTab={currentTab}
      />
      <input
        className="search-box"
        placeholder={t("Search by job number or name...")}
        type="text"
        value={jobSearchTerm}
        onChange={onSearchTermChanged}
      />
      {usersJobsDisplay} 
    </>
  );
}

export default UserJobs;