import React, { useEffect, useState } from "react";
import IdbApi from "apis/idb/IdbApi";
import "./ImagePreview.scoped.css";

interface IImagePreviewProps {
  filename: string,
  onRemove(): void,
  disabled: boolean,
}

const ImagePreview: React.FC<IImagePreviewProps> = ({ filename, onRemove, disabled }) => {
  const [imgData, setImgData] = useState<string | undefined>();
  const [errMsg, setErrMsg] = useState<string | undefined>();

  useEffect(() => {
    let aborted = false;

    const imageFetch = async () => {
      try {
        const img = await IdbApi.getUserImageData(filename);
        if (!aborted) {
          setImgData(img?.data);
        }
      } catch (err: any) {
        if (!aborted) {
          setErrMsg(err?.message || err.toString());
        }
      }
    }

    imageFetch();

    return () => {
      aborted = true;
    };
  }, [filename, setImgData, errMsg, setErrMsg]);

  return (
    <div className="img-holder">
      {imgData &&
        <img
          src={imgData}
          alt={filename}
          className="selected-image" />
      }
      {errMsg &&
        <span>{errMsg}</span>
      }
      {!disabled &&
        <span
          onClick={onRemove}
          className="remove-img"
        >
          x
        </span>
      }
    </div>
  );
}

export default ImagePreview;