import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { ISWPJobHeader, MyJobsTabs, SWPJobStatus, ISWPJobLogItem, SWPJobType, ISWPJob, ISWPJobCreationResponse } from 'interfaces/jobs/JobInterfaces';
import JobsApi from 'apis/jobs/JobsApi';
import { getResponseErrorMessage } from 'utilities/validationErrorHelpers';
import { OfflineFetchError } from 'utilities/errors/OfflineFetchError';
import { RootState } from 'store/rootStore';
import { getMyJobs, setMyJobsTab, setError, setHasOfflineError, setIsFetching, setMyJobs, setSearchTerm, addOrgFilter, removeOrgFilter, addCountryFilter, removeCountryFilter, cancelJob, setCancelJobOperation, unCacheCancelJob, reopenJob, setReopenJobOperation, setAdminShowAll, removeSelfFromJob, setIsUpdating, printJob, setJobPDFData, duplicateJob } from './myJobsActions';
import { Action } from '@reduxjs/toolkit';
import { IMyJobsState } from './myJobsTypes';
import { IJobFilters } from 'store/jobTemplates/jobTemplatesTypes';
import { ACTIVE_JOBS_FILTER_KEY, COMPLETED_JOBS_FILTER_KEY, RECURRING_JOBS_FILTER_KEY } from './myJobsReducer';
import { showSuccessToast, showErrorToast } from 'store/toast/toastActions';
import IdbApi from 'apis/idb/IdbApi';
import { refreshAwaitingSync } from 'store/offline/offlineActions';
import { searchTemplatesAsync } from 'store/jobTemplates/jobTemplatesSagas';
import { Connectivity } from 'interfaces/execution/executionInterfaces';
import { IAuthState } from 'store/auth/authTypes';
import { ILogJobActionPayload } from 'store/execution/executionTypes';
import { logJobAction } from 'store/execution/executionActions';
import i18n from 'i18n';
import { getJob } from 'store/manageJob/manageJobActions';
import { Routes } from 'components/routing/Routing';
import { history } from 'App';
import { cloneTemplate } from 'store/jobTemplates/jobTemplatesActions';
import AzureBlobsApi from 'apis/azureBlobs/AzureBlobsApi';

const t = i18n.getFixedT(null, 'sagas');

export default function* watchMyJobsSagas() {
  yield all([
    watchGetMyJobs(),
    watchChangeMyJobsTab(),
    watchFilters(),
    watchCancelJob(),
    watchUnCacheCancelJob(),
    watchReopenJob(),
    watchRemoveSelfFromJob(),
    watchPrintJob(),
    watchDuplicateJob(),
  ]);
}

function* watchChangeMyJobsTab() {
  yield takeLatest(setMyJobsTab, setMyJobsTabAsync);
}

function* setMyJobsTabAsync(action: Action) {
  if (!setMyJobsTab.match(action)) {
    return;
  }

  if (action.payload === MyJobsTabs.Templates) {
    yield call(searchTemplatesAsync);
    return;
  }

  yield call(getMyJobsAsync);
}

function* watchGetMyJobs() {
  yield takeLatest([getMyJobs, setAdminShowAll], getMyJobsAsync);
}

function* watchPrintJob() {
  yield takeLatest(printJob, printJobAsync);
}

function* watchCancelJob() {
  yield takeLatest(cancelJob, cancelJobAsync);
}

function* watchReopenJob() {
  yield takeLatest(reopenJob, reopenJobAsync);
}

function* watchRemoveSelfFromJob() {
  yield takeLatest(removeSelfFromJob, removeSelfFromJobAsync);
}

function* watchUnCacheCancelJob() {
  yield takeLatest(unCacheCancelJob, unCacheCancelJobAsync);
}

function* watchDuplicateJob() {
  yield takeLatest(duplicateJob, duplicateJobAsync);
}

function* printJobAsync(action: Action) {
  if (!printJob.match(action)) {
    return;
  }

  yield put(setJobPDFData({
    pdfUrl: "",
    pdfData: "",
    printJobOperation: {
      isWorking: true,
    },
  }));

  try {
    let pdfUrl: string = yield call(JobsApi.printJob, action.payload);

    let pdfDataUri: string = yield call(AzureBlobsApi.getDataUri, pdfUrl);
    
    yield put(setJobPDFData({
      pdfUrl: pdfUrl,
      pdfData: pdfDataUri,
      printJobOperation: {
        wasSuccessful: true,
        isWorking: false,
      },
    }));
  } catch (err: any) {
    yield put(setJobPDFData({
      pdfUrl: "",
      pdfData: "",
      printJobOperation: {
        isWorking: false,
      },
    }));
    yield put(showErrorToast(getResponseErrorMessage(err)));
  }
}

function* removeSelfFromJobAsync(action: Action) {
  if (!removeSelfFromJob.match(action)) {
    return;
  }

  yield put(setIsUpdating(true));
  try {
    yield call(JobsApi.removeSelfFromJob, action.payload);
    yield put(showSuccessToast(t('You have been removed from the job successfully.')));
    yield put(setIsUpdating(false));

    yield call(getMyJobsAsync);
  } catch (err: any) {
    yield put(showErrorToast(getResponseErrorMessage(err)));
    yield put(setIsUpdating(false));
  }
}

function* unCacheCancelJobAsync(action: Action) {
  if (!unCacheCancelJob.match(action)) {
    return;
  }

  yield put(setCancelJobOperation({
    isWorking: true,
  }));

  try {
    // Cache the job cancellation to the Idb.
    yield call([IdbApi, IdbApi.deleteCachedJobCancellation],
      action.payload.jobId);
    yield put(setCancelJobOperation({
      isWorking: false,
      wasSuccessful: true,
    }));
  } catch (err: any) {
    yield put(setCancelJobOperation({
      isWorking: false,
      wasSuccessful: false,
      errorMessage: err,
    }));
  }

  // Update job locally to show cancelled.
  yield put(refreshAwaitingSync());
}

function* reopenJobAsync(action: Action) {
  if (!reopenJob.match(action)) {
    return;
  }
  const connectivityStatus: Connectivity = yield select((store: RootState) => store.offline.isOnline);

  yield put(setReopenJobOperation({
    isWorking: true,
  }));

  try {
    yield call([IdbApi, IdbApi.reopenJob], action.payload.jobId);
    yield put(refreshAwaitingSync());

    if (connectivityStatus === Connectivity.Online
      && action.payload.status === SWPJobStatus.Completed) {
      yield call(JobsApi.reopenJob, action.payload.jobId);
      yield call(getMyJobsAsync);
    } else {
      // Add a log item to the store for completing the job.
      const authState: IAuthState = yield select((store: RootState) => store.auth);

      const logItem: ILogJobActionPayload = {
        action: "Reopened Job",
        userName: authState.currentUser.name,
        userEmail: authState.currentUser.email,
        timestamp: new Date(),
        isDirty: false,
      };

      yield put(logJobAction(logItem));

      // Also add that log item to the idb.
      let jobLogItem: ISWPJobLogItem = {
        ...logItem,
        jobId: action.payload.jobId,
        isOnServer: false,
        isDirty: false,
      }
      yield call([IdbApi, IdbApi.cacheJobLogItem], jobLogItem);
    }
    yield put(showSuccessToast(t('Job Reopened successfully.')));
  } catch (err: any) {
    yield put(setReopenJobOperation({
      isWorking: false,
      wasSuccessful: false,
      errorMessage: err,
    }));
  }
}

function* cancelJobAsync(action: Action) {
  if (!cancelJob.match(action)) {
    return;
  }

  const isOnline: boolean = yield select((store: RootState) => store.offline.isOnline);

  yield put(setCancelJobOperation({
    isWorking: true,
  }));

  try {
    if (isOnline
      && action.payload.jobId > 0) {
      yield call(JobsApi.cancelJob, action.payload);
      yield call([IdbApi, IdbApi.deleteCachedJob],
        action.payload.jobId);
      yield put(showSuccessToast(t('Job cancelled successfully.')));
    } else {
      yield call([IdbApi, IdbApi.cacheJobCancellation], {
        jobId: action.payload.jobId,
        timestamp: action.payload.timestamp,
        isOnServer: false,
        userEmail: action.payload.userEmail,
      });
      yield put(refreshAwaitingSync());
      yield put(showSuccessToast(t('Job cancellation has been cached successfully.')));
    }

    yield put(setCancelJobOperation({
      isWorking: false,
      wasSuccessful: true,
    }));
    yield call(getMyJobsAsync);
  } catch (err: any) {
    yield put(setCancelJobOperation({
      isWorking: false,
      wasSuccessful: false,
      errorMessage: err,
    }));
  }
}

function* getMyJobsAsync() {
  const myJobs: IMyJobsState = yield select((store: RootState) => store.myJobs);

  if (myJobs.currentTab === MyJobsTabs.Templates) {
    return;
  }

  yield put(setError(undefined));
  yield put(setHasOfflineError(false));
  yield put(setIsFetching(true));

  let filters: IJobFilters | undefined = undefined;
  let statusFilter = myJobs.currentTab;

  switch (myJobs.currentTab) {
    case MyJobsTabs.Active:
      filters = myJobs.activeJobFilters;
      break;
    case MyJobsTabs.Completed:
      filters = myJobs.completedJobFilters;
      break;
    case MyJobsTabs.Recurring:
      filters = myJobs.recurringJobFilters;
      statusFilter = MyJobsTabs.Active;
      break;
    default:
      break;
  }

  if (!filters) {
    yield put(showErrorToast(`Invalid call to getMyJobs.`));
    return;
  }

  try {
    let searchResults: ISWPJobHeader[] = yield call(JobsApi.getMyJobs,
      statusFilter,
      filters,
      myJobs.adminShowAll,
      myJobs.currentTab === MyJobsTabs.Recurring
        ? SWPJobType.Recurring
        : undefined);

    // Add in the jobs that are cache-only.
    // These jobs haven't been synced to the server yet
    // and exist only in the cache.
    const cacheOnlyJobs: ISWPJob[] = yield call([IdbApi, IdbApi.getCachedJobs], true);

    if (cacheOnlyJobs.length) {
      searchResults = [
        ...searchResults,
        ...cacheOnlyJobs.map((x: ISWPJob): ISWPJobHeader => ({
          id: x.id,
          title: x.title,
          jobNumber: x.jobNumber,
          isDemo: x.isDemo,
          status: x.status,
          lastActionDate: x.lastActionDate,
          completedBy: x.completedBy,
          isOnTeam: x.isOnTeam,
          deviationCount: x.deviationCount,
          totalCompletedSteps: x.totalCompletedSteps,
          totalSteps: x.totalSteps,
          completionPercentage: x.completionPercentage,
        }))
      ].sort((a, b) => b.lastActionDate.getTime() - a.lastActionDate.getTime());
    }

    yield put(setMyJobs(searchResults));
  } catch (err: any) {
    if (err instanceof OfflineFetchError) {
      yield put(setHasOfflineError(true));
      return;
    }

    yield put(setError(getResponseErrorMessage(err)));
  } finally {
    yield put(setIsFetching(false));
  }
}

function* watchFilters() {
  yield takeEvery([
    setSearchTerm,
    addOrgFilter,
    removeOrgFilter,
    addCountryFilter,
    removeCountryFilter,
  ], persistFilters);
}

function* persistFilters(action: Action) {
  let filterSet = ((action as any)?.payload?.filterSet as (MyJobsTabs | undefined))

  if (!filterSet) {
    return;
  }

  const myJobs: IMyJobsState = yield select((store: RootState) => store.myJobs);
  let filters: IJobFilters;
  let persistKey: string = "";

  if (filterSet === MyJobsTabs.Active) {
    filters = myJobs.activeJobFilters;
    persistKey = ACTIVE_JOBS_FILTER_KEY;
  } else if (filterSet === MyJobsTabs.Completed) {
    filters = myJobs.completedJobFilters;
    persistKey = COMPLETED_JOBS_FILTER_KEY;
  } else if (filterSet === MyJobsTabs.Recurring) {
    filters = myJobs.completedJobFilters;
    persistKey = RECURRING_JOBS_FILTER_KEY;
  } else {
    return;
  }

  localStorage.setItem(persistKey,
    JSON.stringify(filters));
}

function* duplicateJobAsync(action: Action) {
  if (!duplicateJob.match(action)) {
    return;
  }

  yield put(setIsUpdating(true));

  try {
    const isOnline: Connectivity = yield select((store: RootState) => store.offline.isOnline);
    const jobResponse: ISWPJob = isOnline === Connectivity.Online
      ? yield call(JobsApi.getJob, action.payload.jobId)
      : yield call([IdbApi, IdbApi.getCachedJob], action.payload.jobId);

    if (isOnline === Connectivity.Online) {
      const updateResult: ISWPJobCreationResponse = yield call(JobsApi.duplicateJob, jobResponse);

      if (updateResult.success) {
        yield put(getJob({ jobId: updateResult.jobId }));
        yield put(showSuccessToast(t('Job duplicated successfully')));
        history.push(Routes.EditJob.replace(":id", updateResult.jobId.toString()));
      }
      else if (updateResult.message) {
        yield put(showErrorToast(updateResult.message));
      }
    }
    else {
      yield put(cloneTemplate({
        template: {
          jobNumber: jobResponse.jobNumber,
          id: jobResponse.id,
          canEdit: true,
          title: jobResponse.title,
          countryGuid: jobResponse.country?.guid ? jobResponse.country?.guid : "",
          geoUnitCode: jobResponse.geoUnit?.code ? jobResponse.geoUnit?.code : "",
          subBusinessLineCode: jobResponse.subBusinessLine?.code ? jobResponse.subBusinessLine?.code : "",
        },
        countryGuid: undefined,
        geoUnitCode: undefined,
        subBusinessLineCode: undefined,
      }));
    }

    yield put(setIsUpdating(false));
  } catch (err: any) {
    yield put(showErrorToast(getResponseErrorMessage(err)));
    yield put(setIsUpdating(false));
  }
}