import Banner, { BannerType } from "components/common/Banner";
import Modal from "components/common/Modal";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setJobModal } from "store/myJobs/myJobsActions";
import { cacheJobOffline } from "store/offline/offlineActions";
import useSelector from "store/useSelector";

const CacheJobModal: React.FC = () => {
  const modalData = useSelector(store => store.myJobs.cacheModal);
  const dispatch = useDispatch();
  const { t } = useTranslation('myJobs');

  const onCacheClick = () => {
    dispatch(cacheJobOffline({
      jobId: modalData.jobId,
    }));

    onClose();
  }

  const onClose = () => {
    dispatch(setJobModal({
      modal: "cacheModal",
      data: {
        isOpen: false,
        jobId: 0,
        jobDisplay: "",
      },
    }));
  }

  return (
    <Modal
      isOpen={true}
      header={t(`Save Job - {jobDisplay}`, {jobDisplay: modalData.jobDisplay})}
      controls={
        <>
          <button
            className="secondary-button"
            onClick={onClose}
          >
            {t('Cancel')}
          </button>
          <button
            className="primary-button"
            onClick={onCacheClick}
          >
            {t('Save Job')}
          </button>
        </>
      }
    >
      <Banner
        type={BannerType.Warn}
      >
        {t('Warning This job will only be downloaded to ')}<b>{t('this browser')}</b> {t('on')} <b>{t('this device')}</b>.<br />
        <br />
        {t("Do not clear your browser's cache while executing a job offline. Clearing your cache will delete all your job data.")}
      </Banner>
    </Modal>
  );
};

export default CacheJobModal;