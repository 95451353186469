import React, { useRef, useEffect } from "react";
import "./UserSearch.scoped.scss";
import searchIcon from "media/icons/dls/search.svg";
import { useTranslation } from "react-i18next";

interface IUserSearchProps {
  value: string,
  isDisabled: boolean,
  onChange: (value: string) => void,
  onClick : (searchTerm : string,abort : boolean) => void,
  autoFocus?: boolean,
}

const UserSearch: React.FC<IUserSearchProps> = ({ value, isDisabled, autoFocus, onChange,onClick }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation('userSearch');

  useEffect(() => {
    if (autoFocus) {
      inputRef.current?.focus();
    }
  }, [inputRef, autoFocus])

  return (
    <div
      className="search-area"
    >
      <input
        ref={inputRef}
        type="text"
        placeholder={t("Search by name or alias...")}
        onChange={isDisabled ? undefined : (e) => onChange(e.target.value)}
        disabled={isDisabled}
              value={value}
              onKeyPress={e => e.key === "Enter" ? onClick(value, false) : null}
        autoFocus
          />
          <button
            onClick={()=>onClick(value,false)}
            className="secondary-button"
            type="submit">
            <img
              src={searchIcon}
              alt="Search"
                className="icon-small" />
          </button>

    </div>
  )
}

export default UserSearch;