import Banner, { BannerType } from "components/common/Banner";
import Modal from "components/common/Modal";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setJobTemplateModal } from "store/jobTemplates/jobTemplatesActions";
import { deleteCachedJobs } from "store/offline/offlineActions";
import useSelector from "store/useSelector";

const UnCacheTemplateModal: React.FC = () => {
  const {
    jobTemplates: {
      templateUnCacheModal,
    },
  } = useSelector(store => store);
  const dispatch = useDispatch();
  const { t } = useTranslation('uncacheJob');

  if (!templateUnCacheModal.template) {
    return null;
  }

  const template = templateUnCacheModal.template;

  const onUnCacheClick = () => {
    dispatch(deleteCachedJobs({
      jobIds: [
        template.id,
      ],
    }));

    onClose();
  }

  const onClose = () => {
    dispatch(setJobTemplateModal({
      modal: "unCacheModal",
      data: {
        isOpen: false,
        template: undefined,
      },
    }));
  }

  return (
    <Modal
      isOpen={true}
      header={`${t('Remove Template')} - ${(template.jobNumber
        ? `${template.jobNumber}: `
        : "") + template.title}`
      }
      controls={
        <>
          <button
            className="secondary-button"
            onClick={onClose}
          >
            {t('Cancel')}
          </button>
          <button
            className="primary-button"
            onClick={onUnCacheClick}
          >
            {t('Remove')}
          </button>
        </>
      }
    >
      <Banner
        type={BannerType.Warn}
      >
        {t('If the template is removed from your device, you will no longer be')}
        {t('able to create jobs from it while offline.')}
      </Banner>
    </Modal>
  );
};

export default UnCacheTemplateModal;