import React, { useState, useEffect } from "react";
import "./TeamForm.scoped.scss";
import UserSearch from "./UserSearch";
import UserList from "./UserList";
import UserApi from "apis/user/UserApi";
import cloneDeep from "lodash/cloneDeep";
import { ISWPJobTeamMember } from "interfaces/jobs/JobInterfaces";
import { IAADUser, Roles } from "interfaces/user/UserInterfaces";
import FlowLayout from "components/layout/FlowLayout";
import ModalSpinner from "components/common/ModalSpinner";
import { useTranslation } from "react-i18next";
import useSelector from "store/useSelector";
import { Connectivity } from "interfaces/execution/executionInterfaces";
import Banner, { BannerType } from "components/common/Banner";

interface ITeamFormProps {
  onAddTeamMember(user: IAADUser): void,
  onRemoveTeamMember(email: string): void,
  teamTitle: string,
  team: ISWPJobTeamMember[],
}

interface ITeamFormState {
  isSearching: boolean,
  searchResult: IAADUser[] | null,
}

const TeamForm: React.FC<ITeamFormProps> = ({
  onAddTeamMember,
  onRemoveTeamMember,
  teamTitle,
  team,
}) => {
  const { t } = useTranslation('createJob');
  const [searchEntry, setSearchEntry] = useState("");
  const [searchState, setSearchState] = useState<ITeamFormState>({
    isSearching: false,
    searchResult: null,
  });
  const {
    isOnline,
  } = useSelector(state => state.offline);

  const {
    roles
  } = useSelector(state => state.auth.currentUser);

  const {
    job 
  } = useSelector(state => state.manageJob);

  const isNewJob = job.id == null;// if Id is null/undefined the job is being created not edited
  const isSysAdmin = roles.indexOf(Roles.SysAdmin) > -1;

  const search = async (searchTerm: string, aborted: boolean) => {
    if (aborted) {
      return;
    }
    if (!searchTerm) {
      setSearchState({
        isSearching: false,
        searchResult: null,
      });
      return;
    }
    setSearchState({
      ...cloneDeep(searchState),
      isSearching: true,
    });
    const searchResult = await UserApi.searchAADUsers(searchTerm);
    setSearchState({
      ...cloneDeep(searchState),
      searchResult,
      isSearching: false,
    });

  }
  useEffect(() => {
    let aborted = false;

    search(searchEntry, aborted);

    return () => {
      aborted = true;
      search(searchEntry, aborted);
    };
  }, []);

  return (
    <FlowLayout
      header={isOnline === Connectivity.Online
        ? (<UserSearch
          value={searchEntry}
          onChange={value => setSearchEntry(value)}
          isDisabled={searchState.isSearching}
          onClick={search}
        />)
        : (<Banner
          type={BannerType.Warn}>
          Search is not available when offline.
        </Banner>)}
    >
      <div
        className="team-form"
      >
        {isOnline === Connectivity.Online &&
          <UserList
            label={t("Search Results")}
            showSelf={false}
            users={searchState.searchResult || []}
            onAddClicked={onAddTeamMember}
          />
        }
        <UserList
          label={teamTitle}
          showSelf={isNewJob ? true : (!isSysAdmin)}
          users={team}
          onRemoveClicked={onRemoveTeamMember}
        />
      </div>
      {searchState.isSearching &&
        <ModalSpinner />
      }
    </FlowLayout>
  );
}
export default TeamForm;