import IdbApi from "apis/idb/IdbApi";
import ImageModal from "components/common/ImageModal";
import Modal from "components/common/Modal";
import { IFileData } from "interfaces/files/fileInterfaces";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { closePaperExecuteSW, paperExecuteSW, saveExecutionData } from "store/execution/executionActions";
import { ExecutionMode, IPaperExecuteModalData } from "store/execution/executionTypes";
import { showErrorToast } from "store/toast/toastActions";
import useSelector from "store/useSelector";
import { validateAndReadFile } from "utilities/fileUtilities";
import { getSWInstanceTitle } from "utilities/swUtilities";
import "./PaperExecuteModal.scoped.scss";
import AddImageButton from "./steps/components/photoInput/AddImageButton";
import CameraModal from "./steps/components/photoInput/CameraModal";

const PaperExecuteModal: React.FC<IPaperExecuteModalData> = ({
  jobSWId,
  isVisible,
}) => {
  const {
    auth: {
      currentUser: {
        email,
      },
    },
    execution: {
      job,
      mode,
    },
  } = useSelector(store => store);
  const dispatch = useDispatch();

  const [paperExecutedFileName, setPaperExecutedFileName] = useState("");
  const [paperExecutedData, setPaperExecutedData] = useState("");
  const [isCameraVisible, setIsCameraVisible] = useState(false);
  const [isImageModalVisible, setIsImageModalVisible] = useState(false);
  const { t } = useTranslation('executionSW');

  if (!isVisible
    || !job) {
    return null;
  }

  const sw = job.sws.find(x => x.jobSWId === jobSWId);

  if (!sw) {
    return null;
  }

  const onSaveClick = async () => {
    // Save the chosen image to the Idb.
    if (!await saveFileToCache(paperExecutedData,
      paperExecutedFileName)) {
      return;
    }

    dispatch(paperExecuteSW({
      jobSWId,
      userEmail: email,
      imageFilename: paperExecutedFileName,
    }));

    if (mode === ExecutionMode.Offline) {
      dispatch(saveExecutionData());
    }

    dispatch(closePaperExecuteSW());
  }

  const onFileChosen = async (event: React.FormEvent<HTMLInputElement>) => {
    let tgt = event.target as HTMLInputElement;

    if (!tgt || !tgt.files || !tgt.files.length) {
      return;
    }

    try {
      const fileData = await validateAndReadFile(tgt.files[0]);
      setPaperExecutedData(fileData.dataUri);
      setPaperExecutedFileName(fileData.filename);
    } catch (err: any) {
      dispatch(showErrorToast(err.message || err));
    }
  }

  const onPhotoTaken = (fileData: IFileData) => {
    try {
      setPaperExecutedData(fileData.dataUri);
      setPaperExecutedFileName(fileData.filename);
    } catch (err: any) {
      dispatch(showErrorToast(err.message || err));
    } finally {
      setIsCameraVisible(false);
    }
  }

  const saveFileToCache = async (imgDataUri: string,
    filename: string) => {
    try {
      let cacheResponse = await IdbApi.cacheUserImageData(filename,
        job.id,
        false,
        imgDataUri);

      if (!cacheResponse) {
        dispatch(showErrorToast(t("Failed to save image to cache.")));
        return false;
      }
    } catch (err: any) {
      dispatch(showErrorToast(err.message || err));
      return false;
    }

    return true;
  }

  const onRemoveImgClick = () => {
    setPaperExecutedFileName("");
    setPaperExecutedData("");
  }

  const openPDFFile = (fileUrl: string) => {
    const mfurl = fileUrl.replace("data:application/pdf;base64,", "");
    var byteCharacters = atob(mfurl);
    var byteNumbers = new Array(byteCharacters.length);
    for (var i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    var byteArray = new Uint8Array(byteNumbers);
    var file = new Blob([byteArray], {
      type: 'application/pdf;base64'
    });
    var fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  }

  return (
    <Modal
      isOpen={true}
      header={t(`Paper Execution`)}
      controls={
        <>
          <button
            className="secondary-button"
            onClick={() => dispatch(closePaperExecuteSW())}
          >
            {t('Cancel')}
          </button>
          <button
            className="primary-button"
            onClick={onSaveClick}
            disabled={!paperExecutedData
              || !paperExecutedFileName
            }
          >
            {t('Save')}
          </button>
        </>
      }
    >
      <p>
        {t('You are about to mark the following Standard Work as being executed on paper')}
      </p>

      <p
        className="sw-details"
      >
        <span
          className="title"
        >
          {getSWInstanceTitle(jobSWId, job.sws)}
        </span>
        {!!sw.description &&
          <span
            className="desc"
          >
            {sw.description}
          </span>
        }
      </p>

      <p>
        {t('Paper executing this Standard Work will complete its steps and lock it.')}{" "}
        {t('Submitting proof via photo upload will require the user to provide a photo of the first page, SIGNED, of your paper execution.')}{" "}
        {t('Keep a record of your full documentation for potential audits.')}
        {t('This operation cannot be undone.')}
      </p>

      <div
        className="img-row"
      >
        {!!paperExecutedData &&
          <>
            {paperExecutedFileName.slice(paperExecutedFileName.lastIndexOf(".") + 1).toUpperCase() !== "PDF"
              && <img
                src={paperExecutedData}
                alt=""
                className="proof-image"
                onClick={() => setIsImageModalVisible(true)}
              />}
            {paperExecutedFileName.slice(paperExecutedFileName.lastIndexOf(".") + 1).toUpperCase() === "PDF"
              && <div>
                File Name :<button className="" onClick={() => openPDFFile(paperExecutedData)}>{paperExecutedFileName}</button>
              </div>}
            <button
              className="tertiary-button"
              onClick={onRemoveImgClick}
            >
              {t('Remove Attachment')}
            </button>
          </>
        }

        {!paperExecutedData &&
          <AddImageButton
            onShowCamera={() => setIsCameraVisible(true)}
            onFileChosen={onFileChosen}
          />
        }
      </div>

      {isCameraVisible &&
        <CameraModal
          onPhotoConfirmed={onPhotoTaken}
          onClose={() => setIsCameraVisible(false)}
        />
      }

      {isImageModalVisible &&
        <ImageModal
          title={t("Paper Execution Image")}
          imgSrc={paperExecutedData}
          fileName={paperExecutedFileName}
          onClose={() => setIsImageModalVisible(false)}
        />
      }
    </Modal>
  );
};

export default PaperExecuteModal;