import React from "react";
import Modal from "components/common/Modal";
import useSelector from "store/useSelector";
import Banner, { BannerType } from "components/common/Banner";
import { useDispatch } from "react-redux";
import { startNewJob, setSaveError, setSaveSuccess, setCurrentJobView, setCountry, setOrg, setGeoUnit, setCustomer, setSubBusinessLine } from "store/manageJob/manageJobActions";
import { Link } from "react-router-dom";
import { Routes } from "components/routing/Routing";
import { ManageJobViews } from "store/manageJob/manageJobTypes";
import { useTranslation } from "react-i18next";
import { IMasterDataItem } from "interfaces/masterData/masterDataInterfaces";

interface ISaveJobResultModal {
  myOrganization: IMasterDataItem | undefined,
  myCountry: IMasterDataItem | undefined,
  myGeoUnit: IMasterDataItem | undefined,
  myCustomer: IMasterDataItem | undefined,
  mySubBusinessLine: IMasterDataItem | undefined,
}

const SaveJobResultModal: React.FC<ISaveJobResultModal> = (props) => {
  const state = useSelector(state => state.manageJob);
  const dispatch = useDispatch();
  const { t } = useTranslation('createJob')

  const clearErrorState = () => {
    dispatch(setSaveError(""));
    dispatch(setSaveSuccess(null));
  }

  let controls: JSX.Element | null = null;
  let header = state.wasSaveSuccessful
    ? t("Job Saved")
    : t("Error");

  if (state.wasSaveSuccessful === false) {
    controls =
      <button
        className="primary-button"
        onClick={clearErrorState}
      >
        {('OK')}
      </button>;
  } else if (state.wasSaveSuccessful === true) {
    controls =
      <>
        <Link
          to={Routes.CreateJob}
          onClick={() => {
            dispatch(startNewJob());
            dispatch(setCountry(props.myCountry));
            dispatch(setOrg(props.myOrganization));
            dispatch(setGeoUnit(props.myGeoUnit));
            dispatch(setSubBusinessLine(props.mySubBusinessLine));
            dispatch(setCustomer(props.myCustomer));
            dispatch(setCurrentJobView(ManageJobViews.Attrs));
          }
          }
        >
          <button
            className="secondary-button"
          >
            {t('Create New Job')}
          </button>
        </Link>
        <Link
          to={Routes.MyJobs}
          onClick={() => dispatch(startNewJob())}
        >
          <button
            className="primary-button"
          >
            {('My Jobs')}
          </button>
        </Link>
      </>;
  }

  return (
    <Modal
      header={header}
      isOpen={state.saveErrorMessage !== ""
        || state.wasSaveSuccessful !== null}
      controls={controls}
    >
      {state.wasSaveSuccessful === false &&
        <>
          <Banner type={BannerType.Error}>
            <p>{('Failed to save job.')}</p>
            <p>
              {state.saveErrorMessage}
            </p>
          </Banner>
        </>
      }
      {state.wasSaveSuccessful === true &&
        <p>{('Job saved successfully!')}</p>
      }
    </Modal>
  );
}

export default SaveJobResultModal;