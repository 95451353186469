import React from "react";
import { useDispatch } from "react-redux";
import { IStepComponent, StepComponentTypes } from "interfaces/sw/SWInterfaces";
import { setComponentResponse } from "store/execution/executionActions";
import { ComponentResponseType } from "interfaces/execution/executionInterfaces";
import useSelector from "store/useSelector";
import TextOrNumberBox, { TextInputTypes } from "components/execution/sw/controls/TextOrNumberBox";
import { IStepIdentifier } from "store/execution/executionTypes";
import { getComponentResponse } from "utilities/swUtilities";

interface ITextInputProps {
  component: IStepComponent,
  isDisabled: boolean,
  stepInfo: IStepIdentifier,
}

const TextInput: React.FC<ITextInputProps> = ({ component, isDisabled, stepInfo }) => {
  const dispatch = useDispatch();
  const {
    auth: {
      currentUser: {
        email,
      },
    },
    execution: {
      stepResponses,
      executor,
    },
  } = useSelector(store => store);

  if (component.type !== StepComponentTypes.TextInput) {
    return null;
  }

  const onValueChange = (value: string) => {
    if (isDisabled) {
      return;
    }

    dispatch(setComponentResponse({
      response: {
        id: component.id,
        type: ComponentResponseType.Text,
        values: [value],
        isDeviation: false,
      },
      stepInfo,
      userEmail: executor?.email || email,
    }));
  }

  const compResponseValues = getComponentResponse(stepInfo,
    component.id,
    stepResponses)
    ?.values;

  return (
    <div>
      <label>{component.label}</label>
      <TextOrNumberBox
        disabled={isDisabled}
        value={compResponseValues?.[0] || ""}
        onResponseChange={val => onValueChange(val)}
        inputType={TextInputTypes.Text}
      />
    </div>
  );
}

export default TextInput;