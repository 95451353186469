import Modal from "components/common/Modal";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import "./PINValidationModal.scoped.scss";

interface IPINValidationModalProps {
  pinEntry: string,
  onPINEntryChange: (val: string) => void,
  pinEntryError: string,
  onCancelPIN: () => void,
  onSubmitPIN: (event: React.FormEvent<HTMLFormElement>) => void
}

const PINValidationModal: React.FC<IPINValidationModalProps> = ({
  pinEntry,
  onPINEntryChange,
  pinEntryError,
  onCancelPIN,
  onSubmitPIN,
}) => {
  const pinRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation('executor');
  useEffect(() => {
    pinRef.current?.focus();
  }, []);

  return (
    <Modal
      isOpen={true}
      header={t("PIN")}
      controls={(
        <>
          <button
            className="secondary-button"
            onClick={onCancelPIN}
          >
            {t('Cancel')}
          </button>
          <button
            className="primary-button"
            form="switch-pin-entry"
          >
            {t('Authorize')}
          </button>
        </>
      )}
    >
      <form
        id="switch-pin-entry"
        onSubmit={onSubmitPIN}
      >
        <div
          className="pin-row"
        >
          <label>
            {t('Enter PIN')}
          </label>
          <input
            type="password"
            value={pinEntry}
            onChange={(e) => onPINEntryChange(e.target.value)}
            pattern="[0-9]*"
            inputMode="numeric"
            maxLength={6}
            minLength={6}
            ref={pinRef}
          />
        </div>
        <div
          className="error"
        >
          {pinEntryError}
        </div>
      </form>
    </Modal>
  );
};

export default PINValidationModal;