import React from "react";
import "./PPEList.scoped.scss";
import { IPPE } from "interfaces/sw/SWInterfaces";
import PPEImage from "./PPEImage";
import { useTranslation } from "react-i18next";

interface IPPEListProps {
  ppe: IPPE[],
  swId: string,
  swVersion: string,
}

const PPEList: React.FC<IPPEListProps> = ({ ppe, swId, swVersion }) =>
{
  const { t } = useTranslation('executionSW');
  return(
    <div
      className="ppe-section"
    >
      <span
        className="text"
      >
        {t('Necessary Equipment')}
      </span>
      <span
        className="images"
      >
        {ppe.map((ppe, ix) => (
          <PPEImage
            key={ppe.ppe}
            ppe={ppe}
            sortOrder={ix}
            swId={swId}
            swVersion={swVersion}
          />
        ))}
      </span>
    </div>);
}

export default PPEList;