import React, { useState } from "react";
import sweetImporterApi from "apis/sweetimporter/sweetimporterApi";
import "./SweetImporter.scoped.scss";
import BlockSpinner from "components/common/BlockSpinner";
import Banner, { BannerType } from "components/common/Banner";

interface ISweetImporterState {
  isUploading: boolean,
  wasUploadSuccessful?: boolean,
}

const SweetImporterUpload: React.FC = () => {
  let fileInput: HTMLInputElement | null = null;

  const [state, setState] = useState<ISweetImporterState>({
    isUploading: false,
    wasUploadSuccessful: undefined,
  });

  const onFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (fileInput?.files?.length && !state.isUploading) {
      setState({
        ...state,
        isUploading: true,
      });

      try {
        const wasUploadSuccessful = await sweetImporterApi.postFile(fileInput.files[0]);
        setState({
          isUploading: false,
          wasUploadSuccessful,
        });
      } catch  {
        setState({
          isUploading: false,
          wasUploadSuccessful: false
        });
      }
    }
  };

  return (
    <>
      <h2 className="page-title">Sweet File Uploader</h2>
      <form onSubmit={onFormSubmit}>
        <label>Select SLDita File</label>
        <input
          id="upload"
          type="file"
          className="upload"
          ref={(input) => { fileInput = input }}
          disabled={state.isUploading} />
        <button
          type="submit"
          className="primary-button"
          disabled={state.isUploading} >
          Upload
        </button>
      </form>

      {state.isUploading &&
        <BlockSpinner />
      }

      {!state.isUploading
        && state.wasUploadSuccessful === false &&
        <Banner type={BannerType.Error}>File was not uploaded.</Banner>
      }

      {!state.isUploading
        && state.wasUploadSuccessful &&
        <Banner type={BannerType.Success}>File was uploaded.</Banner>
      }
    </>
  );
};

export default SweetImporterUpload;