import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import useSelector from "store/useSelector";
import { RouteComponentProps, useRouteMatch } from "react-router-dom";
import { loadExecution, resetExecution } from "store/execution/executionActions";
import ExecutionLoadErrors from "./ExecutionLoadErrors";
import { Routes } from "components/routing/Routing";
import { ExecutionMode } from "store/execution/executionTypes";
import ModalSpinner from "components/common/ModalSpinner";
import ExecutionScreen from "./ExecutionScreen";
import { useTranslation } from "react-i18next";

interface IExecutionLoaderProps {
  id: string,
}

const ExecutionLoader: React.FC<RouteComponentProps<IExecutionLoaderProps>> = (props) => {
  const { loadOperation, job, mode } = useSelector(store => store.execution);
  const dispatch = useDispatch();
  const [hasInvalidJobId, setHasInvalidJobId] = useState(false);
  const idParam = props.match.params.id;
  const modeParam = useRouteMatch(Routes.ExecuteOfflineJob)
    ? ExecutionMode.Offline
    : ExecutionMode.Online;

  const { t } = useTranslation('execution');

  useEffect(() => {
    let jobId = -1;

    if (idParam !== null
      && idParam !== undefined
      && idParam !== ''
      && !isNaN(Number(idParam))) {
      jobId = Number(idParam);
    } else {
      setHasInvalidJobId(true);
    }

    if (loadOperation) {
      // There is already a load operation.
      if (loadOperation.jobId === jobId) {
        // There is a load operation for this job.
        if (loadOperation.mode === modeParam) {
          // It is the same mode. No need to do anything.
          return;
        }
      }
    } else if (job?.id === jobId
      && mode === modeParam) {
      // This job is already fully loaded and in the requested mode.
      return;
    }

    // Start loading the requested job in the requested mode.
    dispatch(loadExecution({
      jobId: jobId,
      executionMode: modeParam,
    }));
  }, [loadOperation, job, idParam, modeParam, mode, setHasInvalidJobId, dispatch]);

  const isLoaded = loadOperation === null
    && job?.id === Number(idParam)
    && mode === modeParam;

  if (hasInvalidJobId) {
    return (
      <ExecutionLoadErrors
        errorMessages={[t("An invalid job Id was specified in the url.")]}
      />
    );
  } else if (loadOperation?.isLoading) {
    return <ModalSpinner />;
  } else if (loadOperation
    && loadOperation.loadErrors.length > 0) {
    return (
      <div>
        <ExecutionLoadErrors errorMessages={loadOperation.loadErrors} />
        <button
          className="primary-button"
          onClick={() => dispatch(resetExecution())}
        >
          {t('Try Reloading')}
        </button>
      </div>
    );
  } else if (isLoaded) {
    return <ExecutionScreen />;
  } else {
    return null;
  }
}

export default ExecutionLoader;