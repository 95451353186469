import React, { useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import JobToolbar from "./JobToolbar";
import JobAttributesForm from "./attributes/JobAttributesForm";
import TeamForm from "./team/TeamForm";
import ManageSWForm from "./sw/ManageSWForm";
import SearchSWForm from "./sw/SearchSWForm";
import "./ManageJob.scoped.scss";
import ModalSpinner from "components/common/ModalSpinner";
import { useDispatch } from "react-redux";
import useSelector from "store/useSelector";
import { startNewJob, getJob, saveJob, setCountry, setOrg, removeTeamMember, addTeamMember, setGeoUnit, setCurrentJobView, setCustomer, setSubBusinessLine } from "store/manageJob/manageJobActions";
import SaveJobResultModal from "./SaveJobResultModal";
import JobLoadErrorMessageModal from "./JobLoadErrorMessageModal";
import arrowRight2Icon from "media/icons/dls/arrow-right-2.svg";
import FlowLayout from "components/layout/FlowLayout";
import { SWPJobType } from "interfaces/jobs/JobInterfaces";
import { IAADUser } from "interfaces/user/UserInterfaces";
import { ManageJobViews } from "store/manageJob/manageJobTypes";
import { useTranslation } from "react-i18next";
import JobDocsForm from "./docs/JobDocsForm";
import { Connectivity } from "interfaces/execution/executionInterfaces";
import { saveJobOffline } from "store/offline/offlineActions";

interface IManageJobProps {
  id: string,
}

const ManageJob: React.FC<RouteComponentProps<IManageJobProps>> = (props) => {
  const {
    myOrganization,
    myCountry,
    myGeoUnit,
    myCustomer,
    mySubBusinessLine,
  } = useSelector(state => state.auth.currentUser);

  const {
    isFetching,
    isSaving,
    job,
    currentView,
  } = useSelector(state => state.manageJob)

  const {
    isOnline,
  } = useSelector(state => state.offline)

  const dispatch = useDispatch();
  const { t } = useTranslation('createJob');
  const jobId = props.match.params.id;
  const isNewJob = jobId === undefined;
  const {
    type,
    team,
  } = job;
  let nextButtonText: string = t("Next");

  let title = t("New Job");

  if (job.type === SWPJobType.Template) {
    title = t("New Job Template")
  } else if (job.type === SWPJobType.Recurring) {
    title = t("New Recurring Job")
  }

  if (!isNewJob) {
    title = job.title || t("Update Job");
  }

  // Dispatch Action for getting Job details if job is not new Job.
  useEffect(() => {
    const Id: number = parseInt(jobId, 10);

    if (jobId
      && !isNaN(Number(Id))) {
      dispatch(getJob({ jobId: Id }));
    } else if (!jobId) {
      dispatch(startNewJob());
      dispatch(setCountry(myCountry));
      dispatch(setOrg(myOrganization));
      dispatch(setGeoUnit(myGeoUnit));
      dispatch(setCustomer(myCustomer));
      dispatch(setSubBusinessLine(mySubBusinessLine));
    }
    dispatch(setCurrentJobView(ManageJobViews.Attrs));
  }, [jobId, dispatch, myCountry, myOrganization, myGeoUnit, myCustomer, mySubBusinessLine]);

  let currentViewComponent: JSX.Element | null = null;
  let showDoneButton: boolean = false;
  let nextView: ManageJobViews = ManageJobViews.Attrs;

  switch (currentView) {
    case ManageJobViews.Attrs: {
      currentViewComponent = <JobAttributesForm />;
      nextView = ManageJobViews.Search;
      break;
    }
    case ManageJobViews.Search: {
      currentViewComponent = <SearchSWForm />;
      nextView = ManageJobViews.SW;
      break;
    }
    case ManageJobViews.SW: {
      currentViewComponent = <ManageSWForm />;
      if (type === SWPJobType.Template) {
        nextView = ManageJobViews.Team;
      } else {
        nextView = ManageJobViews.Docs;
      }
      break;
    }
    case ManageJobViews.Docs: {
      currentViewComponent = <JobDocsForm />;
      nextView = ManageJobViews.Team;
      break;
    }
    case ManageJobViews.Team: {
      const teamTitle = type === SWPJobType.Template
        ? t("Template Editors")
        : t("Team");

      const onAddTeamMember = (user: IAADUser) => {
        dispatch(addTeamMember({
          ...user,
          offlinePIN: null,
        }));
      };

      const onRemoveTeamMember = (email: string) => {
        dispatch(removeTeamMember({ email }));
      }

      currentViewComponent = (
        <TeamForm
          onAddTeamMember={onAddTeamMember}
          onRemoveTeamMember={onRemoveTeamMember}
          teamTitle={teamTitle}
          team={team}
        />
      );
      showDoneButton = true;
      if (!isNewJob) {
        nextButtonText = t("Update")
      } else {
        nextButtonText = t("Save")
      }
      break;
    }
  }

  const goToNextView = () => {
    dispatch(setCurrentJobView(nextView));
  }

  return (
    <>
      <FlowLayout
        header={
          <>
            <h2 className="page-title">{title}</h2>
            <JobToolbar
              numSWsInJob={job.sws.length}
              numJobDocs={job.jobDocs.length}
              isTemplate={job.type === SWPJobType.Template}
            />
          </>
        }
        footer={
          <div
            className="footer"
          >
            {(!showDoneButton && !isNewJob) &&
              <button
                className="primary-button next-button"
                onClick={() => dispatch(isOnline === Connectivity.Online
                  ? saveJob()
                  : saveJobOffline({ jobId: parseInt(jobId, 10) }))}>
                {t('Update')}
              </button>
            }
            <button
              className="primary-button next-button"
              onClick={showDoneButton
                ? () => dispatch(isOnline === Connectivity.Online
                  ? saveJob()
                  : saveJobOffline({ jobId: parseInt(jobId, 10) }))
                : goToNextView}
            >
              <span>
                {nextButtonText}
              </span>
              {!showDoneButton &&
                <img
                  className="black-to-white-filter"
                  src={arrowRight2Icon}
                  alt=""
                />
              }
            </button>
          </div>
        }
      >
        <div
          className="center"
        >
          {currentViewComponent}
        </div>
      </FlowLayout>
      <SaveJobResultModal
        myCountry={myCountry}
        myOrganization={myOrganization}
        myGeoUnit={myGeoUnit}
        myCustomer={myCustomer}
        mySubBusinessLine={mySubBusinessLine}
      />
      <JobLoadErrorMessageModal />
      {(isSaving || isFetching) &&
        <ModalSpinner />
      }
    </>
  );
}

export default ManageJob;