import { combineReducers } from "redux";
import myJobsReducer from "./myJobs/myJobsReducer";
import manageJobReducer from "./manageJob/manageJobReducer";
import executionReducer from "./execution/executionReducer";
import toastReducer from "./toast/toastReducer";
import offlineReducer from "./offline/offlineReducer";
import authReducer from "./auth/authReducer";
import masterDataReducer from "./masterData/masterDataReducer";
import jobTemplatesReducer from "./jobTemplates/jobTemplatesReducers";
import userManagementReducer from "./userManagement/userManagementReducer";

export const rootReducer = combineReducers({
  myJobs: myJobsReducer,
  manageJob: manageJobReducer,
  execution: executionReducer,
  toasts: toastReducer,
  offline: offlineReducer,
  auth: authReducer,
  masterData: masterDataReducer,
  jobTemplates: jobTemplatesReducer,
  userManagement: userManagementReducer,
});

export type RootState = ReturnType<typeof rootReducer>;