import IdbApi from "apis/idb/IdbApi";
import ImageModal from "components/common/ImageModal";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { showErrorToast } from "store/toast/toastActions";
import "./PrintImage.scoped.scss";

interface IPrintImageProps {
  filename: string,
  isSignature?: boolean,
  source: PrintImageSource,
}

export enum PrintImageSource {
  UserImage,
  CommentAttachment
}

const PrintImage: React.FC<IPrintImageProps> = ({
  filename,
  isSignature,
  source,
}) => {
  const [imgData, setImgData] = useState<{
    filename: string,
    imgData: string,
  } | undefined>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation('printView');

  useEffect(() => {
    let aborted = false;

    const loadData = async () => {
      // Load the initial signature from the Idb into the state if it exists.
      if (filename) {
        try {
          const imgData = source === PrintImageSource.UserImage
            ? (await IdbApi.getUserImageData(filename))?.data
            : (await IdbApi.getStepCommentAttData(filename))?.data;

          if (!imgData) {
            throw new Error(t("Image not found in browser cache."));
          }

          if (!aborted) {
            setImgData({
              filename,
              imgData,
            });
          }
        } catch (err: any) {
          if (!aborted) {
            dispatch(showErrorToast(
              t('Failed to load existing signature {message}', { message: (err?.message || err?.toString()) })));
          }
        }
      } else {
        if (!aborted) {
          setImgData(undefined);
        }
      }
    };

    loadData();

    return () => {
      aborted = true;
    };
  }, [filename, source, dispatch, t]);

  if (!imgData
    || !imgData?.filename
    || !imgData?.imgData) {
    return (
      <span>
        {t('(Invalid image)')}
      </span>
    );
  } else {
    return (
      <>
        {imgData.filename.slice(imgData.filename.lastIndexOf(".") + 1).toUpperCase() !== "PDF"
          && <img
            src={imgData.imgData}
            alt=""
            title={imgData.filename}
            onClick={() => setIsModalVisible(true)}
          />}
        {imgData.filename.slice(imgData.filename.lastIndexOf(".") + 1).toUpperCase() === "PDF"
          && <div className="pdfViewer">
            File Name :<button className="" onClick={() => setIsModalVisible(true)}>{imgData.filename}</button>
          </div>}
        {isModalVisible &&
          <ImageModal
            imgSrc={imgData.imgData}
            title={imgData.filename}
            fileName={imgData.filename}
            onClose={() => setIsModalVisible(false)}
            imgBgColor={isSignature
              ? "#fff"
              : undefined
            }
          />
        }
      </>
    );
  }
};

export default PrintImage;