import React, { useEffect, useState } from "react";
import IdbApi from "apis/idb/IdbApi";
import "./NoticeImage.scoped.scss";

interface INoticeImageProps {
    swId: string,
    swVersion: string,
    filename?: string
}

const NoticeImage: React.FC<INoticeImageProps> = ({ swId, swVersion, filename }) => {
    const [imageData, setImageData] = useState<string | undefined>();
    const [errMsg, setErrMsg] = useState<string | undefined>();

    useEffect(() => {
        let aborted = false;

        const fetchImage = async () => {
            try {
                if (filename) {
                    const img = await IdbApi.getSWImageData(swId, swVersion, filename);
                    if (!aborted) {
                        setImageData(img?.data);
                    }
                }
            } catch (err: any) {
                if (!aborted) {
                    setErrMsg(err?.message || err.toString())
                }
            }
        }

        fetchImage();

        return () => {
            aborted = true;
        };
    }, [swId, swVersion, filename, setImageData, setErrMsg]);

    if (imageData) {
        return (
            <div
                className="image-holder"
            >
                <img
                    src={imageData}
                    alt=""
                />
            </div>
        );
    } else if (errMsg) {
        return (
            <div
                className="image-holder"
            >
                <span>
                    {errMsg}
                </span>
            </div>
        );
    } else {
        return null;
    }
}

export default NoticeImage;
