import React, { useEffect, useState } from "react";
import IdbApi from "apis/idb/IdbApi";
import { IAudioComponentData, IStepComponent } from "interfaces/sw/SWInterfaces";
import "./Audio.scoped.scss";

interface IAudioProps {
    swId: string,
    swVersion: string,
    audio: IStepComponent,
}

const Audio: React.FC<IAudioProps> = ({ swId, swVersion, audio }) => {
    const [audioData, setAudioData] = useState<string | undefined>();
    const [errMsg, setErrMsg] = useState<string | undefined>();

    const {
        data,
    } = audio;

    let filename = "";

    if (isAudioData(data)) {
        filename = data.filename;
    }

    useEffect(() => {
        let aborted = false;

        const fetchVideo = async () => {
            try {
                if (filename) {
                    const audio = await IdbApi.getSWImageData(swId, swVersion, filename);
                    if (!aborted) {
                        setAudioData(audio?.data);
                    }
                }
            } catch (err: any) {
                if (!aborted) {
                    setErrMsg(err?.message || err.toString())
                }
            }
        }

        fetchVideo();

        return () => {
            aborted = true;
        };
    }, [swId, swVersion, filename, setAudioData, setErrMsg]);

    if (audioData) {
        return (
            <audio controls className="embed">
                <source src={audioData} />
            </audio>
        );
    } else if (errMsg) {
        return (
            <div
                className="embed empty"
            >
                <span>
                    {errMsg}
                </span>
            </div>
        );
    } else {
        return null;
    }
}

export default Audio;

function isAudioData(data: any): data is IAudioComponentData {
    return data !== null
        && data !== undefined
        && 'filename' in data;
}