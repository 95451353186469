import React from "react";
import { ISW, IReferenceDoc, IRefDocTableComponent } from "interfaces/sw/SWInterfaces";
import Banner, { BannerType } from "components/common/Banner";
import "./JobRefDocsList.scoped.scss";
import IdbApi from "apis/idb/IdbApi";
import { showErrorToast } from "store/toast/toastActions";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import RefDocTableCell from "./RefDocTableCell";

interface IJobRefDocsListProps {
  SWs: ISW[],
}

const JobRefDocsList: React.FC<IJobRefDocsListProps> = ({ SWs }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('executionSW');

  const refDocCount = SWs.map(x => x.refDocs.length)
    .reduce((acc, val) => acc + val, 0);

  if (!refDocCount) {
    return (
      <Banner
        type={BannerType.Info}
      >
        {t('There are no reference documents attached to any of the standard works in this job.')}
      </Banner>
    );
  }

  const downloadRefDoc = async (refDoc: IReferenceDoc, sw: ISW) => {
    try {
      const refDocData = await IdbApi.getSWRefDocData(sw.id, sw.version, refDoc.refDocData);

      if (refDocData) {
        const link = document.createElement("a");
        link.download = refDoc.refDocData;
        link.href = refDocData.data;
        link.click();
      } else {
        dispatch(showErrorToast(t("RefDoc not found in browser cache.")));
      }
    } catch (err: any) {
      dispatch(showErrorToast(err?.message || err.toString()));
    }
  }

  const refDocSorter = (a: IReferenceDoc, b: IReferenceDoc) => {
    if (a.sortOrder
      && b.sortOrder) {
      return a.sortOrder < b.sortOrder
        ? -1
        : 1;
    } else if (a.sortOrder) {
      return -1;
    } else if (b.sortOrder) {
      return 1;
    } else {
      return a.label < b.label
        ? -1
        : 1;
    }
  }

  const initial: ISW[] = [];
  const uniqueSWs = SWs
    .filter(sw => sw.refDocs.length > 0)
    .reduce((acc, val) =>
      acc.find(x => x.id === val.id
        && x.version === val.version)
        ? acc
        : acc.concat(val),
      initial);

  const renderTable = (tableData: IRefDocTableComponent, refDocGuid: string, swId: string, swVersion: string) => {
    //build a 2d array here and one by one build objects with colSpan, rowSpan, isDeleted
    let cellArray = new Array(tableData.rowCount);
    for (var r = 0; r < cellArray.length; r++) {
      cellArray[r] = new Array(tableData.colCount);
      for (var c = 0; c < cellArray[r].length; c++) {
        let cIx = c;
        let rIx = r;
        
        let cell = tableData.cells.filter(x => x.rowIndex === rIx && x.colIndex === cIx)[0];
        if (cell !== undefined) {
          cellArray[rIx][cIx] = {colSpan: cell.colSpan ? cell.colSpan : 1, rowSpan: cell.rowSpan ? cell.rowSpan : 1, deleted: false};
        } else {
          cellArray[rIx][cIx] = {colSpan: 1, rowSpan: 1, deleted: false};
        }
      }
    }

    // figure out which cells should be deleted to handle col spans and row spans
    for (var ra = 0; ra < tableData.rowCount; ra++) {
      for (var ca = 0; ca < tableData.colCount; ca++) {
        let cell = cellArray[ra][ca];
        if (cell.deleted) {
          continue;
        }

        if (cell.colSpan > 1 && cell.rowSpan > 1) {
          // disable columns and rows??
          for (var i = ca + 1; i < ca + cell.colSpan; i++) {
            let cellToDisable = cellArray[ra][i];
            if (cellToDisable) {
              cellToDisable.deleted = true;
              cellToDisable.rowSpan = 1;
              cellToDisable.colSpan = 1;
            }
          }
          for (var j = ra + 1; j < ra + cell.rowSpan; j++) {
            let cellToDisable = cellArray[j][ca];
            if (cellToDisable) {
              cellToDisable.deleted = true;
              cellToDisable.rowSpan = 1;
              cellToDisable.colSpan = 1;
            }

            for (var z = 1; z < cell.colSpan; z++) {
              let cellToDisable = cellArray[j][ca + z];
              if (cellToDisable) {
                cellToDisable.deleted = true;
                cellToDisable.rowSpan = 1;
                cellToDisable.colSpan = 1;
              }
            }
          }
        } else if (cell.colSpan > 1) {
          // disable columns
          for (var ii = ca + 1; ii < ca + cell.colSpan; ii++) {
            let cellToDisable = cellArray[ra][ii];
            if (cellToDisable) {
              cellToDisable.deleted = true;
              cellToDisable.rowSpan = 1;
              cellToDisable.colSpan = 1;
            }
          }
        } else if (cell.rowSpan > 1) {
          // disable rows
          for (var jj = ra + 1; jj < ra + cell.rowSpan; jj++) {
            let cellToDisable = cellArray[jj][ca];
            if (cellToDisable) {
              cellToDisable.deleted = true;
              cellToDisable.rowSpan = 1;
              cellToDisable.colSpan = 1;
            }
          }
        }
      }
    }

    const colWidth = 100 / tableData.colCount;

    return (
      <table>
        <colgroup>
          {
            [...new Array(tableData.colCount)].map((_, cIx) => {
              return <col key={cIx} style={{width: colWidth + '%'}}/>
            })
          }
        </colgroup>
        <tbody>
        {
          [...new Array(tableData.rowCount)].map((_, ixr) => (
            <tr key={"r" + ixr}>
              {
                [...new Array(tableData.colCount)].map((_, ixc) => {
                  let cellFormatter = cellArray[ixr][ixc]; // is this cell deleted?
                  if (!cellFormatter.deleted) {
                    let cell = tableData.cells.filter(x => x.rowIndex === ixr && x.colIndex === ixc)[0];
                    let cellColSpan = cell !== undefined ? cell.colSpan : 1;
                    let cellRowSpan = cell !== undefined ? cell.rowSpan : 1;

                    return (                
                      <td key={"c" + ixc} colSpan={cellColSpan} rowSpan={cellRowSpan}>
                        <RefDocTableCell
                          rowKey={ixr}
                          colKey={ixc}
                          cells={tableData.cells}
                          swId={swId}
                          swVersion={swVersion}
                          refDocGuid={refDocGuid}
                        />
                      </td>);
                  } else {
                    return undefined;
                  }
                })
              }
            </tr>
          ))
        }
        </tbody>
      </table>
    );
  }

  return (
    <>
      {uniqueSWs
        .map(sw => (
          <div
            key={sw.id + sw.version}
            className="sw-ref-docs"
          >
            <h4>{sw.title}</h4>
            {sw.refDocs
              .slice()
              .sort(refDocSorter)
              .map(rd => {
                if (rd.type === 'File' || rd.type === undefined)
                  return (
                    <button
                      key={rd.id}
                      onClick={() => downloadRefDoc(rd, sw)}
                      className="tertiary-button ref-doc"
                    >
                      {rd.label}
                    </button>
                  )
                else if (rd.type === 'Link')
                  return (
                    <div
                      className="row"
                      key={rd.id}
                    >
                      <span>
                        <a
                          href={rd.refDocData}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {rd.label}
                        </a>
                      </span>
                    </div>
                  )
                else if (rd.tableData)
                return (
                  <div
                    className="tableRow"
                    key={rd.id}
                  >
                    <label>{rd.label}</label>
                    {renderTable(rd.tableData, rd.id, sw.id, sw.version)}
                  </div>
                )
                else
                  return undefined;
              })
            }
          </div>
        ))}
    </>
  );
}

export default JobRefDocsList;