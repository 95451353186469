import React from "react";
import "./UserList.scoped.scss";
import defaultAddIcon from "media/icons/dls/add.svg";
import useSelector from "store/useSelector";
import { IAADUser } from "interfaces/user/UserInterfaces";
import FlowLayout from "components/layout/FlowLayout";
import deleteIcon from "media/icons/custom/delete-circle.svg";

interface IUserListProps {
  showSelf: boolean,
  users: IAADUser[],
  label?: string,
  addIcon?: string,
  addTitle?: string,
  onAddClicked?: (user: IAADUser) => void,
  onRemoveClicked?: (oid: string) => void,
}

const UserList: React.FC<IUserListProps> = ({
  label,
  showSelf,
  users,
  addIcon,
  addTitle,
  onAddClicked,
  onRemoveClicked,
}) => {
  const { currentUser } = useSelector(store => store.auth);
  return (
    <div className="user-list-container no-text-select">
      <FlowLayout
        header={label
          ? (
            <label
              className="header"
            >
              {label}
            </label>
          ) : undefined
        }
      >
        {showSelf && (
          <span
            className="user input"
          >
            {currentUser.name}
            <span
              className="email"
            >
              ({currentUser.email})
            </span>
          </span>
        )}
        {users.map((u) => (
          u.email.toLowerCase() !== currentUser.email.toLowerCase() &&
          <span
            key={u.email}
            className="user input"
          >
            <span>
              {u.name}
              <span className="email">({u.email})</span>
            </span>
            {onRemoveClicked && (
              <img
                src={deleteIcon}
                className="icon-medium delete-icon"
                alt="Remove"
                title="Remove"
                onClick={() => onRemoveClicked(u.email)}
              />
            )}
            {onAddClicked && (
              <img
                src={addIcon || defaultAddIcon}
                className="icon-medium"
                alt={addTitle || "Add"}
                title={addTitle || "Add"}
                onClick={() => onAddClicked(u)}
              />
            )}
          </span>
        ))}
      </FlowLayout>
    </div>
  );
};

export default UserList;
