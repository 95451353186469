import { Routes } from "components/routing/Routing";
import React from "react";
import { Switch, Route } from "react-router-dom";
import { ExecutionMode } from "store/execution/executionTypes";
import useSelector from "store/useSelector";
import RealtimeCompletionModal from "./completion/RealtimeCompletionModal";
import Execution from "./Execution";
import PrintView from "./printView/PrintView";
import RealtimeExecution from "./RealtimeExecution";

const ExecutionScreen: React.FC = () => {
  const {
    execution: {
      mode,
      isRealtimeJobCompletionModalVisible,
      jobExecutor,
    },
    auth:
    {
      currentUser,
    }
  } = useSelector(store => store);

  let execComponent: JSX.Element;

  if (mode === ExecutionMode.Offline) {
    execComponent = (
      <Switch>
        <Route exact path={Routes.PrintViewJob} component={PrintView} />
        <Route exact path={Routes.PrintViewOfflineJob} component={PrintView} />
        <Route component={Execution} />
      </Switch>
    );
  } else {
    execComponent = (
      <RealtimeExecution
        currentUser={currentUser.email}
      />
    );
  }

  return (
    <>
      {execComponent}
      {isRealtimeJobCompletionModalVisible &&
        <RealtimeCompletionModal
          jobExecutor={jobExecutor} />
      }
    </>
  );
};

export default ExecutionScreen;