import Modal from "components/common/Modal";
import { IFileData } from "interfaces/files/fileInterfaces";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { showErrorToast } from "store/toast/toastActions";
import { imagesFilter, validateAndReadFile } from "utilities/fileUtilities";
import CameraModal from "../components/photoInput/CameraModal";
import "./AddAttachmentModal.scoped.scss";

interface IAddAttachmentModalProps {
  onClose(): void,
  onAttachmentChosen(fileData: IFileData): void,
}

const AddAttachmentModal: React.FC<IAddAttachmentModalProps> = ({
  onClose,
  onAttachmentChosen,
}) => {
  const dispatch = useDispatch();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isCameraVisible, setIsCameraVisible] = useState(false);
  const { t } = useTranslation('comments');

  const onFileChosen = async (file: File) => {
    try {
      const fileData = await validateAndReadFile(file);

      onAttachmentChosen(fileData);
    } catch (err: any) {
      dispatch(showErrorToast(err.message));
    }
  }

  return (
    <Modal
      isOpen={true}
      header={t("Add Attachment")}
      controls={(
        <>
          <button
            className="primary-button"
            onClick={onClose}
          >
            {t('Cancel')}
          </button>
        </>
      )}
    >
      <input
        type="file"
        accept={`${imagesFilter},.csv,.txt,.pdf,.doc,.docx,.xls,.xlsx`}
        ref={fileInputRef}
        onChange={(x) => x.target.files?.length
          ? onFileChosen(x.target.files[0])
          : null
        }
      />

      <div
        className="buttons"
      >
        <button
          className="secondary-button"
          onClick={() => fileInputRef.current?.click()}
        >
          {t('Choose File')}
        </button>
        <button
          className="secondary-button"
          onClick={() => setIsCameraVisible(true)}
        >
          {t('Take Photo')}
        </button>
      </div>

      {isCameraVisible &&
        <CameraModal
          onClose={() => setIsCameraVisible(false)}
          onPhotoConfirmed={onAttachmentChosen}
        />
      }
    </Modal>
  );
}

export default AddAttachmentModal;