import { IGetSWResponse, ISWSearchResult, ISearchSWsOptions } from "interfaces/sw/SWInterfaces";
import config from "config";
import { authGetJson, authPostJson } from "utilities/msalFetches";
import { formatSearchResults, formatGetSWResponse, formatSW, formatSearchSWRequest } from "./SWFormatters";
import { throwIfResponseError } from "apis/apiUtilities";
import { ISW } from "interfaces/sw/SWInterfaces";

class SWApi {
  public async searchSWs(searchOptions: ISearchSWsOptions, newIndex: number): Promise<ISWSearchResult> {
    let endpoint = config.endpoints.searchSWs;
    const response = await authPostJson(
      endpoint,
      JSON.stringify(formatSearchSWRequest(searchOptions, newIndex))
    );

    await throwIfResponseError(response);

    let json = await response.json();
    let searchResults = formatSearchResults(json);
    return searchResults;
  }

  public async getSW(id: string, version: string): Promise<IGetSWResponse> {
    let response = await authGetJson(config.endpoints.getSW.replace("{id}", id) + `?version=${version}`);

    await throwIfResponseError(response);

    let json = await response.json();
    let swResponse = formatGetSWResponse(json);

    return swResponse;
  }

  public async getSWDocument(uri: string): Promise<ISW> {
    let response = await authGetJson(uri);

    await throwIfResponseError(response);

    let json = await response.json();
    let sw = formatSW(json);

    return sw;
  }

  public async getSWDocumentJsonString(uri: string): Promise<string> {
    let response = await authGetJson(uri);

    await throwIfResponseError(response);

    return await response.json();
  }
}

export default new SWApi();