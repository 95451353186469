import Banner, { BannerType } from "components/common/Banner";
import Modal from "components/common/Modal";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { retireTemplate, setJobTemplateModal } from "store/jobTemplates/jobTemplatesActions";
import { showErrorToast } from "store/toast/toastActions";
import useSelector from "store/useSelector";

const RetireTemplateModal: React.FC = () => {
  const {
    jobTemplates: {
      retireTemplateModal,
    },
    auth: {
      currentUser,
    },
  } = useSelector(store => store);
  const dispatch = useDispatch();
  const { t } = useTranslation('retireTemplate');

  const onRetireTemplateClick = () => {
    if (!retireTemplateModal.template) {
      dispatch(showErrorToast(t("No template loaded in modal!")));
      return;
    }

    dispatch(retireTemplate({
      template: retireTemplateModal.template,
      timestamp: new Date(),
      userEmail: currentUser.email,
    }));

    onClose();
  }

  const onClose = () => {
    dispatch(setJobTemplateModal({
      modal: "retireModal",
      data: {
        isOpen: false,
        template: undefined,
      },
    }));
  }

  return (
    <Modal
      isOpen={true}
      header={t(`Retire Template - {title}`, {title: retireTemplateModal.template?.title})}
      controls={
        <>
          <button
            className="secondary-button"
            onClick={onClose}
          >
            {t('Dismiss')}
          </button>
          <button
            className="primary-button"
            onClick={onRetireTemplateClick}
          >
            {t('Retire Template')}
          </button>
        </>
      }
    >
      <Banner
        type={BannerType.Warn}
      >
        {t('Warning This job will be ')}<b>{t('RETIRED')}</b>{t(' and will no longer be accessible in WorkRight.')}<br />
        <br />
        {t('Are you sure? If you do not want to ')}<b>{t('RETIRE')}</b>{t(' this job, click ')}<b>{t('DISMISS')}</b>.
      </Banner>
    </Modal>
  );
};

export default RetireTemplateModal;