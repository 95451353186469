import React, { useState } from "react";
import { IJobTemplateHeader } from "interfaces/jobs/JobInterfaces";
import "./JobHeader.scoped.scss";
import { RouteComponentProps } from "react-router-dom";
import { Routes } from "components/routing/Routing";
import editIcon from "media/icons/dls/edit-1.svg";
import cloneIcon from "media/icons/dls/new-screen.svg";
import moreIcon from "media/icons/dls/more.svg";
import removeIcon from "media/icons/dls/remove.svg"
import useSelector from "store/useSelector";
import { useDispatch } from "react-redux";
import { cloneTemplate, setJobTemplateModal } from "store/jobTemplates/jobTemplatesActions";
import deleteIcon from "media/icons/dls/delete.svg";
import downloadIcon from "media/icons/dls/download.svg";
import JobActionsModal from "../JobActionsModal";
import { useTranslation } from "react-i18next";

interface IJobTemplateHeaderProps {
  jobHeader: IJobTemplateHeader,
  isOffline: boolean,
}

const JobTemplateHeader: React.FC<RouteComponentProps & IJobTemplateHeaderProps> = ({
  jobHeader,
  isOffline,
  history,
}) => {
  const {
    offline: {
      cachedJobIds,
      isOnline,
    },
  } = useSelector(store => store);
  const dispatch = useDispatch();
  const [isActionModalVisible, setIsActionModalVisible] = useState(false);
  const isCached = cachedJobIds.indexOf(jobHeader.id) > -1;

  const { t } = useTranslation('jobHeader');

  const jobDisplay = (jobHeader.jobNumber
    ? `${jobHeader.jobNumber}: `
    : "") + jobHeader.title;

  const editClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    history.push(Routes.EditJob.replace(":id", jobHeader.id.toString()))
  }

  const cacheJobClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    dispatch(setJobTemplateModal({
      modal: "cacheModal",
      data: {
        isOpen: true,
        template: jobHeader,
      },
    }));
  }

  const uncacheJobClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    dispatch(setJobTemplateModal({
      modal: "unCacheModal",
      data: {
        isOpen: true,
        template: jobHeader,
      },
    }));
  }

  const cloneButton = (
    <button
      className="secondary-button action-button"
      onClick={() => {
        dispatch(cloneTemplate({
          template: jobHeader,
          countryGuid: jobHeader.countryGuid,
          geoUnitCode: jobHeader.geoUnitCode,
          subBusinessLineCode: jobHeader.subBusinessLineCode,
        }));
      }}
    >
      <img
        src={cloneIcon}
        alt=""
      />
      <span>
        {t('create job')}
      </span>
    </button>
  );

  const editButton = isOffline
    ? undefined
    : (
      <button
        className="secondary-button action-button"
        onClick={editClick}
        disabled={!jobHeader.canEdit}
      >
        <img
          src={editIcon}
          alt=""
        />
        <span>
          {t('edit job template')}
      </span>
      </button>
    );

  const cacheButton = isCached
    ? (
      <button
        className="secondary-button action-button"
        onClick={uncacheJobClick}
      >
        <img
          src={deleteIcon}
          alt=""
        />
        <span>
          {t('remove from device')}
        </span>
      </button>
    ) : (
      <button
        className="secondary-button action-button"
        onClick={cacheJobClick}
      >
        <img
          src={downloadIcon}
          alt=""
        />
        <span>
          {t('save to device')}
        </span>
      </button>
    );

  const retireClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setIsActionModalVisible(false);
    dispatch(setJobTemplateModal({
      modal: "retireModal",
      data: {
        isOpen: true,
        template: jobHeader,
      },
    }));
  }

  const cancelButton = jobHeader.canEdit
    ? (
      <button
        className="secondary-button action-button"
        onClick={retireClick}
        disabled={!isOnline}
      >
        <img
          src={removeIcon}
          alt=""
        />
        <span>
          {t('retire template')}
        </span>
      </button>
    ) : undefined;

  const onMoreButtonClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setIsActionModalVisible(true);
  }

  const moreButton = (
    <button
      className="secondary-button action-button"
      onClick={onMoreButtonClick}
    >
      <img
        src={moreIcon}
        alt="actions"
      />
    </button>
  );

  return (
    <div
      className="job-row template"
    >
      <span
        className="info"
      >
        <span
          className="job-title"
        >
          {jobDisplay}
        </span>
      </span>
      <span
        className="actions"
      >
        {cloneButton}
        {moreButton}
      </span>
      {isActionModalVisible &&
        <JobActionsModal
          onClose={() => setIsActionModalVisible(false)}
          jobDisplay={jobDisplay}
          header={t("Job Actions")}
        >
          {cloneButton}
          {cacheButton}
          {editButton}
          {cancelButton}
        </JobActionsModal>
      }
    </div>
  );
}

export default JobTemplateHeader;