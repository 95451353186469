import { openDB, DBSchema, IDBPDatabase, deleteDB, OpenDBCallbacks, wrap } from 'idb';
import { IUserImageData, OfflineStepResponse, ComponentResponseType, ISWImageData, IJobCompletion, ISWRefDocData, IIdbStepComments, IIdbStepCommentAttachment, IJobCancellation, IIdbPaperExecution, IOfflineSWUserFeedback, IIdbResetSW, IIdbJobPaperExecution, IStepComment, IJobDocData } from "interfaces/execution/executionInterfaces";
import { IOfflineSW } from 'interfaces/sw/SWInterfaces';
import { ISWPJob, IOfflineSWPJobLog, ISWPJobLogItem, SWPJobStatus, SWPJobType, IOfflineJobShowAllECLs, ISWPJobSW, IOfflineJobDuplicateJobSW, ISWUserFeedback, ISWPJobDoc } from 'interfaces/jobs/JobInterfaces';
import { CannotCreateDbError } from 'utilities/errors/CannotCreateDbError';
import { applyIdbMigrations } from "./idbMigrations";
import { DbBlockedError } from 'utilities/errors/DbBlockedError';
import { DbBlockingError } from 'utilities/errors/DbBlockingError';
import { DbDeletionError } from 'utilities/errors/DbDeletionError';
import config from 'config';
import { IOldNewJobSWIdMapping } from 'store/offline/offlineTypes';
import { IInProgressJob, IManageJobSW } from 'store/manageJob/manageJobTypes';

export const DB_VERSION = 17;

export interface ISWPOfflineDb extends DBSchema {
  "user-image-data": {
    key: string,
    value: IUserImageData,
    indexes: {
      "by-job": number,
    }
  },
  "job-docs": {
    key: string,
    value: IJobDocData,
    indexes: {
      "by-job": number,
    }
  },
  "sw-image-data": {
    key: [string, string, string],
    value: ISWImageData,
    indexes: {
      "by-sw": [string, string]
    },
  },
  "sw-refdoc-data": {
    key: [string, string, string],
    value: ISWRefDocData,
    indexes: {
      "by-sw": [string, string]
    },
  },
  "sw-documents": {
    key: [number, string, string],
    value: IOfflineSW,
    indexes: {
      "by-job": number,
    },
  },
  // Legacy properties from old versions of the idb.
  // This is the old format of the idb store for
  // the job responses. It will be deleted soon.
  "job-responses": {
    key: [number, string],
    value: OfflineStepResponse,
    indexes: {
      "by-job": number,
    },
  },
  "step-responses": {
    key: [number, string],
    value: OfflineStepResponse,
    indexes: {
      "by-job": number,
    },
  },
  "jobs": {
    key: number,
    value: ISWPJob,
    indexes: {
      "by-type": SWPJobType,
    }
  },
  "duplicate-sws": {
    key: number,
    value: IOfflineJobDuplicateJobSW,
    indexes: {
      "by-duplicatesSW": number,
    },
  },
  "show-all-ecls-updates": {
    key: number,
    value: IOfflineJobShowAllECLs,
  },
  "job-cancellations": {
    key: number,
    value: IJobCancellation,
  },
  "job-completions": {
    key: number,
    value: IJobCompletion,
  },
  "job-history-log": {
    key: number,
    value: IOfflineSWPJobLog,
  },
  "step-comment-att-data": {
    key: string,
    value: IIdbStepCommentAttachment,
  },
  "step-comments": {
    key: number,
    value: IIdbStepComments,
  },
  "paper-executions": {
    key: number,
    value: IIdbPaperExecution,
    indexes: {
      "by-job": number,
    },
  },
  "sw-user-feedback": {
    key: number,
    value: IOfflineSWUserFeedback,
  },
  "reseted-sw": {
    key: number,
    value: IIdbResetSW,
  },
  "job-paper-execution": {
    key: number,
    value: IIdbJobPaperExecution,
    indexes: {
      "by-job": number,
    }
  }
};

export enum DbStoreNames {
  UserImageData = "user-image-data",
  SWImageData = "sw-image-data",
  SWRefDocData = "sw-refdoc-data",
  SWs = "sw-documents",
  JobResponses = "step-responses",
  Jobs = "jobs",
  JobCancellations = "job-cancellations",
  JobCompletions = "job-completions",
  JobHistoryLog = "job-history-log",
  StepCommentAttData = "step-comment-att-data",
  StepComments = "step-comments",
  PaperExecutions = "paper-executions",
  JobPaperExecution = "job-paper-execution",
  SWUserFeedback = "sw-user-feedback",
  ResetedSW = "reseted-sw",
  ShowAllEclsJobUpdates = "show-all-ecls-updates",
  DuplicateSWs = "duplicate-sws",
  JobDocs = "job-docs"
};

const dbName = "swp-cache";

class IdbApi {
  db: IDBPDatabase<ISWPOfflineDb> | null = null;

  public async cacheUserImageData(filename: string,
    jobId: number,
    isOnServer: boolean,
    data: string): Promise<boolean> {
    const db = await this.getDb();

    let expirationDate = this.getNewExpirationDate();

    await db.put(DbStoreNames.UserImageData, {
      filename,
      jobId,
      isOnServer,
      data,
      expirationDate: expirationDate,
    });

    return true;
  }

  public async cacheJobDoc(filename: string,
    jobId: number,
    data: string): Promise<boolean> {
    const db = await this.getDb();

    let expirationDate = this.getNewExpirationDate();

    await db.put(DbStoreNames.JobDocs, {
      filename,
      jobId,
      data,
      expirationDate
    });

    return true;
  }

  public async cacheAllUserImageData(userImages: IUserImageData[]): Promise<void> {
    const db = await this.getDb();

    let expirationDate = this.getNewExpirationDate();

    userImages.forEach(i => i.expirationDate = expirationDate);

    const tx = db.transaction(DbStoreNames.UserImageData, "readwrite");
    await userImages.forEach(async i => {
      await tx.store.put(i);
    });

    await tx.done;
  }

  public async cacheSWImageData(swId: string, version: string, filename: string, data: string): Promise<boolean> {
    const db = await this.getDb();

    let expirationDate = this.getNewExpirationDate();

    // Read the data if the file is already present.


    await db.put(DbStoreNames.SWImageData, {
      swId,
      version,
      filename,
      data,
      expirationDate: expirationDate,
    });

    return true;
  }

  public async cacheJobPaperExecution(jobPaperExecution: IIdbJobPaperExecution): Promise<boolean> {
    const db = await this.getDb();
    await db.put(DbStoreNames.JobPaperExecution, jobPaperExecution);
    return true;
  }

  public async cachePaperExecutions(paperExecutions: IIdbPaperExecution[]): Promise<void> {
    const db = await this.getDb();

    const tx = db.transaction(DbStoreNames.PaperExecutions, "readwrite");
    for (let i = 0; i < paperExecutions.length; i++) {
      await tx.store.put(paperExecutions[i]);
    }

    await tx.done;
  }

  public async cacheStepComments(comments: IIdbStepComments): Promise<void> {
    const db = await this.getDb();
    await db.put(DbStoreNames.StepComments,
      comments);
  }

  public async cacheStepCommentAttData(filename: string,
    data: string): Promise<void> {
    const db = await this.getDb();

    let expirationDate = this.getNewExpirationDate();

    await db.put(DbStoreNames.StepCommentAttData, {
      filename,
      data,
      expirationDate: expirationDate,
    });
  }

  public async cacheDuplicateSWs(duplicateSWs: ISWPJobSW[] | undefined, jobId: number): Promise<void> {
    const db = await this.getDb();
    const tx = db.transaction([
      DbStoreNames.DuplicateSWs,
      DbStoreNames.Jobs,
    ], "readwrite");

    let job = await tx.objectStore(DbStoreNames.Jobs)
      .get(jobId);

    if (duplicateSWs) {
      for (let i = 0; i < duplicateSWs.length; i++) {
        await tx.objectStore(DbStoreNames.DuplicateSWs).put({
          jobId: jobId,
          jobSWId: duplicateSWs[i].jobSWId,
          swId: duplicateSWs[i].swId,
          title: duplicateSWs[i].title,
          description: duplicateSWs[i].description,
          version: duplicateSWs[i].version,
          sortOrder: duplicateSWs[i].sortOrder,
          isOnServer: false,
        });
        if (job) {
          job.sws.push(duplicateSWs[i]);
        }
      }
      if (job) {
        await tx.objectStore(DbStoreNames.Jobs)
          .put(job);
      }
    }
    await tx.done;
  }

  public async cacheRefDocData(swId: string,
    version: string,
    filename: string,
    data: string): Promise<boolean> {
    const db = await this.getDb();

    let expirationDate = this.getNewExpirationDate();

    await db.put(DbStoreNames.SWRefDocData, {
      swId,
      version,
      filename,
      data,
      expirationDate: expirationDate,
    });

    return true;
  }

  public async cacheAllSWImageData(swImages: ISWImageData[]): Promise<boolean> {
    const db = await this.getDb();

    let expirationDate = this.getNewExpirationDate();

    swImages.forEach(i => i.expirationDate = expirationDate);

    const tx = db.transaction(DbStoreNames.SWImageData, "readwrite");
    await swImages.forEach(async i => {
      await tx.store.put(i);
    });

    await tx.done;
    return true;
  }

  public async cacheAllSWRefDocData(swImages: ISWRefDocData[]): Promise<boolean> {
    const db = await this.getDb();

    let expirationDate = this.getNewExpirationDate();

    swImages.forEach(i => i.expirationDate = expirationDate);

    const tx = db.transaction(DbStoreNames.SWRefDocData, "readwrite");
    await swImages.forEach(async i => {
      await tx.store.put(i);
    });

    await tx.done;
    return true;
  }

  public async cacheAllJobDocs(jobDocs: IJobDocData[]): Promise<boolean> {
    const db = await this.getDb();

    let expirationDate = this.getNewExpirationDate();

    jobDocs.forEach(i => i.expirationDate = expirationDate);

    const tx = db.transaction(DbStoreNames.JobDocs, "readwrite");
    await jobDocs.forEach(async i => {
      await tx.store.put(i);
    });

    await tx.done;
    return true;
  }

  public async cacheSWs(sws: IOfflineSW[]): Promise<boolean> {
    const db = await this.getDb();
    const tx = db.transaction(DbStoreNames.SWs, "readwrite");

    for (let i = 0; i < sws.length; i++) {
      await tx.store.put(sws[i]);
    }

    await tx.done;
    return true;
  }

  public async cacheJobResponses(offlineStepResponses: OfflineStepResponse[]): Promise<void> {
    const db = await this.getDb();

    const tx = db.transaction([
      DbStoreNames.JobResponses,
      DbStoreNames.Jobs,
    ], "readwrite");

    // Save all the job responses.
    for (let i = 0; i < offlineStepResponses.length; i++) {
      await tx.objectStore(DbStoreNames.JobResponses).put(offlineStepResponses[i]);
    }

    // Update each of the jobs with the most recent lastActionDate from the responses.
    const jobIdsAndLastActions: Map<number, Date> = new Map<number, Date>();

    offlineStepResponses.forEach(r => {
      let lastActionDate = jobIdsAndLastActions.get(r.jobId);
      if (!lastActionDate
        || r.timestamp > lastActionDate) {
        jobIdsAndLastActions.set(r.jobId, r.timestamp);
      }
    });

    const jobKeyArray = Array.from(jobIdsAndLastActions.keys());

    for (let i = 0; i < jobKeyArray.length; i++) {
      const jobId = jobKeyArray[i];

      let lastActionDate = jobIdsAndLastActions.get(jobId);
      if (!lastActionDate) {
        return;
      }

      let job = await tx.objectStore(DbStoreNames.Jobs)
        .get(jobId);

      if (job) {
        job.lastActionDate = lastActionDate;    

        //get all step cache responses for the job id
        let cacheJobResponses = await tx.objectStore(DbStoreNames.JobResponses).index('by-job').getAll(jobId);

        // calculate completed steps percentage
        let completedSteps = 0;
        cacheJobResponses.forEach(cacheResponse => {   
          if(cacheResponse.isComplete) {
            completedSteps++;
          }
        });

        job.totalCompletedSteps = completedSteps;
        job.completionPercentage = Math.trunc((job.totalCompletedSteps / job.totalSteps) * 100);
        await tx.objectStore(DbStoreNames.Jobs)
          .put(job);
      }
    }

    await tx.done;
  }

  public async cacheJob(job: ISWPJob): Promise<void> {
    const db = await this.getDb();
    await db.put(DbStoreNames.Jobs, job);
  }

  public async cacheJobCompletion(jobCompletion: IJobCompletion): Promise<void> {
    const db = await this.getDb();
    const tx = db.transaction([DbStoreNames.JobCompletions, DbStoreNames.Jobs], "readwrite");

    await tx.objectStore(DbStoreNames.JobCompletions).put(jobCompletion);

    // Get the job. If the completion's timestamp is newer than the job's
    // last action date, update the job.
    let job = await tx.objectStore(DbStoreNames.Jobs).get(jobCompletion.jobId);
    if (job) {
      job.lastActionDate = jobCompletion.timestamp;
      await tx.objectStore(DbStoreNames.Jobs).put(job);
    }

    await tx.done;
  }

  public async reopenJob(jobId: number): Promise<void> {
    const db = await this.getDb();
    const tx = db.transaction([DbStoreNames.Jobs, DbStoreNames.JobCompletions], "readwrite");

    let jobCompletions = await tx.objectStore(DbStoreNames.JobCompletions).get(jobId);
    if (jobCompletions) {
      await tx.objectStore(DbStoreNames.JobCompletions).delete(jobId);

      let job = await tx.objectStore(DbStoreNames.Jobs).get(jobId);
      if (job) {
        job.lastActionDate = new Date();
        await tx.objectStore(DbStoreNames.Jobs).put(job);
      }
    }
    await tx.done;
  }

  public async deleteCachedDuplicateSWsForJob(jobId: number): Promise<void> {
    const db = await this.getDb();
    const tx = db.transaction(DbStoreNames.DuplicateSWs, "readwrite");
    let responseCursor = await tx.objectStore(DbStoreNames.DuplicateSWs)
      .index("by-duplicatesSW")
      .openCursor(jobId);

    while (responseCursor) {
      await tx.objectStore(DbStoreNames.DuplicateSWs).delete(responseCursor.primaryKey);
      responseCursor = await responseCursor.continue();
    }
    await tx.done;
  }

  public async updateJob(jobId: number, updatedJob: ISWPJob): Promise<void> {
    const db = await this.getDb();
    const tx = db.transaction([DbStoreNames.Jobs], "readwrite");
    let job = await tx.objectStore(DbStoreNames.Jobs).get(jobId);
    if (job) {
      job.sws = updatedJob.sws;
      await tx.objectStore(DbStoreNames.Jobs).put(job);
    }
    await tx.done;
  }

  public async updateCachedJob(updatedJob: ISWPJob): Promise<void> {
    const db = await this.getDb();
    const tx = db.transaction([DbStoreNames.Jobs], "readwrite");
    await tx.objectStore(DbStoreNames.Jobs).put(updatedJob);
    await tx.done;
  }

  public async updatePaperExecution(jobId: number, updatedPaperExecs: IIdbPaperExecution[]): Promise<void> {
    const db = await this.getDb();
    const tx = db.transaction([DbStoreNames.PaperExecutions], "readwrite");
    for (let i = 0; i < updatedPaperExecs.length; i++) {
      await tx.objectStore(DbStoreNames.PaperExecutions).put(updatedPaperExecs[i]);
    }
    await tx.done;
  }

  public async updateStepResponses(jobId: number, updatedStepResponses: OfflineStepResponse[]): Promise<void> {
    const db = await this.getDb();
    const tx = db.transaction([DbStoreNames.JobResponses], "readwrite");
    for (let i = 0; i < updatedStepResponses.length; i++) {
      await tx.objectStore(DbStoreNames.JobResponses).put(updatedStepResponses[i]);
    }
    await tx.done;
  }

  public async updateComments(jobId: number, updatedComments: IStepComment[]): Promise<void> {
    const db = await this.getDb();
    const tx = db.transaction(DbStoreNames.StepComments, "readwrite");
    let jobComments = await tx.store.get(jobId);

    if (jobComments) {
      jobComments.comments = updatedComments;
      await tx.store.put(jobComments);
    }

    await tx.done;
  }

  public async UpdateShowAllECLsForJob(jobId: number, showAllECLs: boolean): Promise<void> {
    const db = await this.getDb();
    const tx = db.transaction([DbStoreNames.Jobs, DbStoreNames.ShowAllEclsJobUpdates], "readwrite");
    let job = await tx.objectStore(DbStoreNames.Jobs).get(jobId);
    if (job) {
      job.lastActionDate = new Date();
      job.showAllECLs = showAllECLs;
      await tx.objectStore(DbStoreNames.Jobs).put(job);

      let showAllECLsJob = await tx.objectStore(DbStoreNames.ShowAllEclsJobUpdates).get(jobId);
      if (showAllECLsJob) {
        await tx.objectStore(DbStoreNames.ShowAllEclsJobUpdates).delete(jobId);
      }
      const jobShowAllECLs: IOfflineJobShowAllECLs = { jobId: job.id, showAllECLs: job.showAllECLs, isOnServer: false };
      await db.put(DbStoreNames.ShowAllEclsJobUpdates, jobShowAllECLs);
    }

    await tx.done;
  }

  public async cacheJobCancellation(jobCancellation: IJobCancellation): Promise<void> {
    const db = await this.getDb();
    const tx = db.transaction([DbStoreNames.JobCancellations, DbStoreNames.Jobs], "readwrite");

    await tx.objectStore(DbStoreNames.JobCancellations).put(jobCancellation);

    // Get the job. If the cancellation's timestamp is newer than the job's
    // last action date and the job is not comleted, update the job.
    let job = await tx.objectStore(DbStoreNames.Jobs).get(jobCancellation.jobId);
    if (job
      && job.status !== SWPJobStatus.Completed
      && jobCancellation.timestamp > job.lastActionDate) {
      job.lastActionDate = jobCancellation.timestamp;
      await tx.objectStore(DbStoreNames.Jobs).put(job);
    }

    await tx.done;
  }

  public async cacheJobLog(jobLog: IOfflineSWPJobLog): Promise<void> {
    const db = await this.getDb();
    await db.put(DbStoreNames.JobHistoryLog, jobLog);
  }

  public async cacheJobLogItem(jobLogItem: ISWPJobLogItem): Promise<void> {
    const db = await this.getDb();
    const tx = db.transaction(DbStoreNames.JobHistoryLog, "readwrite");
    const existingLog = await tx.store.get(jobLogItem.jobId);
    if (!existingLog) {
      await tx.done;
      throw new Error(`No job history log for job id ${jobLogItem.jobId} exists in the cache!`);
    }

    existingLog.log.push(jobLogItem);

    await tx.store.put(existingLog);

    await tx.done;
  }

  public async getJobDoc(filename: string): Promise<IJobDocData | undefined> {
    const db = await this.getDb();
    const tx = db.transaction(DbStoreNames.JobDocs, "readwrite");
    const jobDoc = await tx.store.get(filename);

    if (jobDoc) {
      // Update the expirationDate of the image.
      let expirationDate = this.getNewExpirationDate();
      jobDoc.expirationDate = expirationDate;
      tx.store.put(jobDoc);
    }
    return jobDoc
  }

  public async getUserImageData(filename: string): Promise<IUserImageData | undefined> {
    const db = await this.getDb();
    const tx = db.transaction(DbStoreNames.UserImageData, "readwrite");
    const img = await tx.store.get(filename);

    if (img) {
      // Update the expirationDate of the image.
      let expirationDate = this.getNewExpirationDate();
      img.expirationDate = expirationDate;
      tx.store.put(img);
    }

    return img;
  }

  public async getSWImageData(swId: string, version: string, filename: string): Promise<ISWImageData | undefined> {
    const db = await this.getDb();
    const tx = db.transaction(DbStoreNames.SWImageData, "readwrite");

    const img = await tx.store.get([swId, version, filename]);

    if (img) {
      // Update the expirationDate of the image.
      let expirationDate = this.getNewExpirationDate();
      img.expirationDate = expirationDate;
      tx.store.put(img);
    }

    await tx.done;

    return img;
  }

  public async getStepCommentAttData(filename: string): Promise<IIdbStepCommentAttachment | undefined> {
    const db = await this.getDb();
    const tx = db.transaction(DbStoreNames.StepCommentAttData, "readwrite");
    const att = await tx.store.get(filename);

    if (att) {
      // Update the expirationDate of the att.
      let expirationDate = this.getNewExpirationDate();
      att.expirationDate = expirationDate;
      tx.store.put(att);
    }

    return att;
  }

  public async getSWRefDocData(swId: string, version: string, filename: string): Promise<ISWImageData | undefined> {
    const db = await this.getDb();
    const tx = db.transaction(DbStoreNames.SWRefDocData, "readwrite");

    const refDoc = await tx.store.get([swId, version, filename]);

    if (refDoc) {
      // Update the expirationDate of the image.
      let expirationDate = this.getNewExpirationDate();
      refDoc.expirationDate = expirationDate;
      tx.store.put(refDoc);
    }

    await tx.done;

    return refDoc;
  }

  public async getCachedSWs(jobId: number): Promise<IOfflineSW[]> {
    const db = await this.getDb();
    return await db.getAllFromIndex(DbStoreNames.SWs, "by-job", jobId);
  }

  public async getCachedJobIds(): Promise<number[]> {
    const db = await this.getDb();
    return await db.getAllKeys(DbStoreNames.Jobs);
  }

  public async getCachedUserSWFeedback(jobId: number): Promise<ISWUserFeedback[]> {
    const db = await this.getDb();
    var output = await db.get(DbStoreNames.SWUserFeedback, jobId);
    if (output !== undefined) {
      return output.feedbacks;
    } else {
      return [];
    }
  }

  public async getCachedJobs(cacheOnlyJobs?: boolean): Promise<ISWPJob[]> {
    const db = await this.getDb();
    let jobs = await db.getAllFromIndex(DbStoreNames.Jobs, "by-type", SWPJobType.Standard);

    if (cacheOnlyJobs) {
      jobs = jobs.filter(x => x.id < 0);
    }

    // Format all the dates.
    jobs.forEach(x => {
      if (typeof x.lastActionDate === "string") {
        x.lastActionDate = new Date(x.lastActionDate);
      }
    });

    return jobs;
  }

  public async getCachedTemplates(): Promise<ISWPJob[]> {
    const db = await this.getDb();
    let jobs = await db.getAllFromIndex(DbStoreNames.Jobs, "by-type", SWPJobType.Template);

    // Format all the dates.
    jobs.forEach(x => {
      if (typeof x.lastActionDate === "string") {
        x.lastActionDate = new Date(x.lastActionDate);
      }
    });

    return jobs;
  }

  public async getCachedJob(jobId: number): Promise<ISWPJob | undefined> {
    const db = await this.getDb();
    return await db.get(DbStoreNames.Jobs, jobId);
  }

  public async getCachedJobResponses(jobId: number): Promise<OfflineStepResponse[]> {
    const db = await this.getDb();
    return await db.getAllFromIndex(DbStoreNames.JobResponses, "by-job", jobId);
  }

  public async getCachedStepComments(jobId: number): Promise<IIdbStepComments | undefined> {
    const db = await this.getDb();
    return await db.get(DbStoreNames.StepComments, jobId);
  }

  public async getCachedUnSynchedStepComments(): Promise<IIdbStepComments[]> {
    const db = await this.getDb();
    const tx = db.transaction(DbStoreNames.StepComments, "readonly");

    const unsyncedJobComments: IIdbStepComments[] = [];

    // Check if any comments are not yet synced to the server.
    let commentCursor = await tx.store.openCursor();

    while (commentCursor) {
      if (commentCursor.value.comments.find(x => !x.isOnServer)) {
        unsyncedJobComments.push(commentCursor.value);
      }

      commentCursor = await commentCursor.continue();
    }

    await tx.done;

    return unsyncedJobComments;
  }

  public async getAllCachedPaperExecutions(): Promise<IIdbPaperExecution[]> {
    const db = await this.getDb();
    return await db.getAll(DbStoreNames.PaperExecutions);
  }

  public async IsUserImageReferred(jobSWID: number): Promise<boolean> {
    let isReferred: boolean = false;
    const db = await this.getDb();
    let filename: string = "";
    // Get filename from the resetted jobSWID

    const tx = db.transaction(DbStoreNames.PaperExecutions, "readonly");
    let paperExecutionCursor = await tx.store.openCursor();
    while (paperExecutionCursor) {
      if (paperExecutionCursor.value.jobSWId === jobSWID) {
        filename = paperExecutionCursor.value.imageFilename;
        break;
      }
      paperExecutionCursor = await paperExecutionCursor.continue();
    }

    // Check the reference in Job Paper Execute Cache
    const tran = db.transaction(DbStoreNames.JobPaperExecution, "readonly");

    let jobPaperExecutionCursor = await tran.store.openCursor();
    while (jobPaperExecutionCursor) {
      if (jobPaperExecutionCursor.value.jobId !== jobSWID
        && jobPaperExecutionCursor.value.imageFilename === filename) {
        isReferred = true;
        break;
      }
      jobPaperExecutionCursor = await jobPaperExecutionCursor.continue();
    }

    if (!isReferred) {
      // Check the reference in Paper Execute Cache
      const tran2 = db.transaction(DbStoreNames.PaperExecutions, "readonly");

      let paperExecutionCursor = await tran2.store.openCursor();
      while (paperExecutionCursor) {
        if (paperExecutionCursor.value.imageFilename === filename
          && paperExecutionCursor.value.jobSWId !== jobSWID) {
          isReferred = true;
          break;
        }
        paperExecutionCursor = await paperExecutionCursor.continue();
      }
    }
    return isReferred;

  }

  public async getAllCachedJobPaperExecutions(): Promise<IIdbJobPaperExecution[]> {
    const db = await this.getDb();
    return await db.getAll(DbStoreNames.JobPaperExecution);
  }

  public async getCachedJobPaperExecution(jobId: number): Promise<IIdbJobPaperExecution | undefined> {
    const db = await this.getDb();
    return await db.get(DbStoreNames.JobPaperExecution, jobId);
  }

  public async getCachedJobDocs(jobId: number): Promise<IJobDocData[] | undefined> {
    const db = await this.getDb();
    return await db.getAllFromIndex(DbStoreNames.JobDocs, "by-job", jobId);
  }

  public async getCachedPaperExecutions(jobId: number): Promise<IIdbPaperExecution[]> {
    const db = await this.getDb();
    return await db.getAllFromIndex(DbStoreNames.PaperExecutions, "by-job", jobId);
  }

  public async getCachedJobCompletion(jobId: number): Promise<IJobCompletion | undefined> {
    const db = await this.getDb();
    return await db.get(DbStoreNames.JobCompletions, jobId);
  }

  public async getCachedJobCancellation(jobId: number): Promise<IJobCancellation | undefined> {
    const db = await this.getDb();
    return await db.get(DbStoreNames.JobCancellations, jobId);
  }

  public async getCachedJobLog(jobId: number): Promise<IOfflineSWPJobLog | undefined> {
    const db = await this.getDb();
    return db.get(DbStoreNames.JobHistoryLog, jobId);
  }

  public async getAllCachedJobResponses(): Promise<OfflineStepResponse[]> {
    const db = await this.getDb();
    return await db.getAll(DbStoreNames.JobResponses);
  }

  public async getAllCachedJobCompletions(): Promise<IJobCompletion[]> {
    const db = await this.getDb();
    return await db.getAll(DbStoreNames.JobCompletions);
  }

  public async getAllShowAllECLsUpdates(): Promise<IOfflineJobShowAllECLs[]> {
    const db = await this.getDb();
    return await db.getAll(DbStoreNames.ShowAllEclsJobUpdates);
  }

  public async getAllDuplicateSWs(): Promise<IOfflineJobDuplicateJobSW[]> {
    const db = await this.getDb();
    return await db.getAll(DbStoreNames.DuplicateSWs);
  }

  public async getAllCachedJobCancellations(): Promise<IJobCancellation[]> {
    const db = await this.getDb();
    return await db.getAll(DbStoreNames.JobCancellations);
  }

  public async getAllCachedJobLogs(): Promise<IOfflineSWPJobLog[] | undefined> {
    const db = await this.getDb();
    return db.getAll(DbStoreNames.JobHistoryLog);
  }

  public async getMinimumJobId(): Promise<number | undefined> {
    const db = await this.getDb();
    const tx = db.transaction(DbStoreNames.Jobs);
    const cursor = await tx.store.openKeyCursor(undefined, "next");

    let lowestId = cursor?.key;
    await tx.done;

    return lowestId;
  }

  public async getMinimumJobSWId(): Promise<number | undefined> {
    const db = await this.getDb();
    const tx = db.transaction(DbStoreNames.Jobs);
    let cursor = await tx.store.openCursor(undefined, "next");
    let lowestJobSWId: number | undefined = undefined;

    while (cursor) {
      let lowestInJob = Math.min.apply(null,
        cursor.value.sws.map(x => x.jobSWId));

      if (lowestJobSWId === undefined
        || lowestInJob < lowestJobSWId) {
        lowestJobSWId = lowestInJob;
      }
      cursor = await cursor.continue();
    }

    await tx.done;
    return lowestJobSWId;
  }

  public async isUserImageInCache(filename: string): Promise<boolean> {
    const db = await this.getDb();
    const image = await db.get(DbStoreNames.UserImageData, filename);

    if (image) {
      image.expirationDate = this.getNewExpirationDate();
      await db.put(DbStoreNames.UserImageData, image);
    }

    return image !== undefined;
  }

  public async isJobDocInCache(filename: string): Promise<boolean> {
    const db = await this.getDb();
    const jobDoc = await db.get(DbStoreNames.JobDocs, filename);
    if (jobDoc) {
      jobDoc.expirationDate = this.getNewExpirationDate();
      await db.put(DbStoreNames.JobDocs, jobDoc);
    }

    return jobDoc !== undefined;
  }

  public async isSWImageInCache(swId: string, version: string, filename: string): Promise<boolean> {
    const db = await this.getDb();
    const image = await db.get(DbStoreNames.SWImageData, [swId, version, filename]);

    if (image) {
      image.expirationDate = this.getNewExpirationDate();
      await db.put(DbStoreNames.SWImageData, image);
    }

    return image !== undefined;
  }

  public async isStepCommentAttInCache(filename: string): Promise<boolean> {
    const db = await this.getDb();
    const att = await db.get(DbStoreNames.StepCommentAttData, filename);

    if (att) {
      att.expirationDate = this.getNewExpirationDate();
      await db.put(DbStoreNames.StepCommentAttData, att);
    }

    return att !== undefined;
  }

  public async isSWRefDocInCache(swId: string,
    version: string,
    filename: string): Promise<boolean> {
    const db = await this.getDb();
    return !!(await db.getKey(DbStoreNames.SWRefDocData, [swId, version, filename]));
  }

  public async isJobAwaitingSync(jobId: number): Promise<boolean> {
    const db = await this.getDb();

    const tx = db.transaction([
      DbStoreNames.JobResponses,
      DbStoreNames.JobCompletions,
      DbStoreNames.JobCancellations,
      DbStoreNames.StepComments,
      DbStoreNames.PaperExecutions,
      DbStoreNames.Jobs,
    ], "readonly");

    let responseCursor = await tx.objectStore(DbStoreNames.JobResponses)
      .index("by-job")
      .openCursor(jobId);

    while (responseCursor) {
      if (!responseCursor.value.isOnServer) {
        await tx.done;
        return true;
      }
      responseCursor = await responseCursor.continue();
    }

    let completion = await tx.objectStore(DbStoreNames.JobCompletions)
      .get(jobId);

    if (completion?.isOnServer === false) {
      await tx.done;
      return true;
    }

    let cancellation = await tx.objectStore(DbStoreNames.JobCancellations)
      .get(jobId);

    if (cancellation?.isOnServer === false) {
      await tx.done;
      return true;
    }

    let comments = await tx.objectStore(DbStoreNames.StepComments)
      .get(jobId);

    if (comments?.comments.find(x => !x.isOnServer)) {
      await tx.done;
      return true;
    }

    let paperExecs = await tx.objectStore(DbStoreNames.PaperExecutions)
      .index("by-job")
      .getAll(jobId);

    if (paperExecs.find(x => !x.isOnServer)) {
      await tx.done;
      return true;
    }

    let job = await tx.objectStore(DbStoreNames.Jobs)
      .get(jobId);

    if (job) {
      if (job.isDirty !== undefined) {
        if (job.isDirty) {
          await tx.done;
          return true;
        }
      }
    }

    await tx.done;
    return false;
  }

  public async isAnythingAwaitingSync(): Promise<boolean> {
    const db = await this.getDb();

    const tx = db.transaction([
      DbStoreNames.Jobs,
      DbStoreNames.JobResponses,
      DbStoreNames.JobCompletions,
      DbStoreNames.JobCancellations,
      DbStoreNames.StepComments,
      DbStoreNames.PaperExecutions,
      DbStoreNames.JobPaperExecution,
      DbStoreNames.SWUserFeedback,
      DbStoreNames.ResetedSW,
      DbStoreNames.ShowAllEclsJobUpdates,
      DbStoreNames.DuplicateSWs,
    ], "readonly");

    // Check if there are any Jobs with negative Ids (offline jobs
    // that haven't been sent to the server yet).
    let jobCursor = await tx.objectStore(DbStoreNames.Jobs)
      .openCursor(undefined, "next");

    // Minimum jobId in idb.
    let lowestId = jobCursor?.key;

    if (lowestId !== undefined
      && lowestId < 0) {
      await tx.done;
      return true;
    }

    // Check if there are any JobResponses not synced to the server yet.
    let responseCursor = await tx.objectStore(DbStoreNames.JobResponses)
      .openCursor();

    while (responseCursor) {
      if (!responseCursor.value.isOnServer) {
        await tx.done;
        return true;
      }
      responseCursor = await responseCursor.continue();
    }

    // Check if there are any JobCompletions not synced to the server yet.
    let completionCursor = await tx.objectStore(DbStoreNames.JobCompletions)
      .openCursor();

    while (completionCursor) {
      if (!completionCursor.value.isOnServer) {
        await tx.done;
        return true;
      }
      completionCursor = await completionCursor.continue();
    }

    // Check if there are any JobCancellation not synced to the server yet.
    let cancellationCursor = await tx.objectStore(DbStoreNames.JobCancellations)
      .openCursor();

    while (cancellationCursor) {
      if (!cancellationCursor.value.isOnServer) {
        await tx.done;
        return true;
      }
      cancellationCursor = await cancellationCursor.continue();
    }

    // Check if any comments are not yet synced to the server.
    let commentCursor = await tx.objectStore(DbStoreNames.StepComments)
      .openCursor();

    while (commentCursor) {
      if (commentCursor.value.comments.find(x => !x.isOnServer)) {
        await tx.done;
        return true;
      }

      commentCursor = await commentCursor.continue();
    }

    // Check if any Job Paper executions are not yet synced to the server.
    let jobPaperExecCursor = await tx.objectStore(DbStoreNames.JobPaperExecution)
      .openCursor();

    while (jobPaperExecCursor) {
      if (!jobPaperExecCursor.value.isOnServer) {
        await tx.done;
        return true;
      }

      jobPaperExecCursor = await jobPaperExecCursor.continue();
    }

    // Check if any Duplicate SWS are not yet synced to the server.
    let duplicateSWsExecCursor = await tx.objectStore(DbStoreNames.DuplicateSWs)
      .openCursor();

    while (duplicateSWsExecCursor) {
      if (duplicateSWsExecCursor) {
        await tx.done;
        return true;
      }
    }

    // Check if any paper executions are not yet synced to the server.
    let paperExecCursor = await tx.objectStore(DbStoreNames.PaperExecutions)
      .openCursor();

    while (paperExecCursor) {
      if (!paperExecCursor.value.isOnServer) {
        await tx.done;
        return true;
      }

      paperExecCursor = await paperExecCursor.continue();
    }

    // Check if there are any SWUserFeedback not synced to the server yet.
    let swUSerFeedbackCursor = await tx.objectStore(DbStoreNames.SWUserFeedback)
      .openCursor();

    while (swUSerFeedbackCursor) {
      if (!swUSerFeedbackCursor.value.isOnServer) {
        await tx.done;
        return true;
      }
      swUSerFeedbackCursor = await swUSerFeedbackCursor.continue();
    }

    // Check if there are any reseted SW not synced to the server yet.
    let resetedSWCursor = await tx.objectStore(DbStoreNames.ResetedSW)
      .openCursor();

    if (resetedSWCursor) {
      await tx.done;
      return true;
    }

    // Check if there are any showAllECLs updates not synced to the server yet.
    let showAllEclsJobsCursor = await tx.objectStore(DbStoreNames.ShowAllEclsJobUpdates)
      .openCursor();

    while (showAllEclsJobsCursor) {
      if (!showAllEclsJobsCursor.value.isOnServer) {
        await tx.done;
        return true;
      }
      showAllEclsJobsCursor = await showAllEclsJobsCursor.continue();
    }

    // Check if there are any jobs not synced to the server yet.
    let cahedJobCursor = await tx.objectStore(DbStoreNames.Jobs)
      .openCursor();

    while (cahedJobCursor) {
      if (cahedJobCursor.value.isDirty) {
        await tx.done;
        return true;
      }
      cahedJobCursor = await cahedJobCursor.continue();
    }

    await tx.done;
    return false;
  }

  public async updateJobResponsesAreOnServer(jobId: number,
    stepIds: string[],
    isOnServer: boolean): Promise<number> {
    let recordsUpdated = 0;

    const db = await this.getDb();

    const tx = db.transaction(DbStoreNames.JobResponses, "readwrite");
    let cursor = await tx.store.index("by-job").openCursor(jobId);

    while (cursor) {
      if (stepIds.indexOf(cursor.primaryKey[1]) > -1) {
        cursor.value.isOnServer = isOnServer;
        await tx.store.put(cursor.value);
      }

      cursor = await cursor.continue();
    }

    await tx.done;

    return recordsUpdated;
  }

  public async updateUserImagesAreOnServer(filenames: string[],
    isOnServer: boolean): Promise<number> {
    let recordsUpdated = 0;

    const db = await this.getDb();

    const tx = db.transaction(DbStoreNames.UserImageData, "readwrite");
    let cursor = await tx.store.openCursor();

    let expirationDate = this.getNewExpirationDate();

    while (cursor) {
      if (filenames.indexOf(cursor.key) > -1) {
        cursor.value.isOnServer = isOnServer;
        cursor.value.expirationDate = expirationDate;
        tx.store.put(cursor.value);
      }

      cursor = await cursor.continue();
    }

    await tx.done;

    return recordsUpdated;
  }

  public async updateJobHistoryLogIsOnServer(jobId: number): Promise<void> {
    const db = await this.getDb();

    const tx = db.transaction(DbStoreNames.JobHistoryLog, "readwrite");
    let historyLog = await tx.store.get(jobId);

    if (historyLog) {
      historyLog.log.forEach(l => l.isOnServer = true);
      await tx.store.put(historyLog);
    }

    await tx.done;
  }

  public async updateStepCommentsIsOnServer(jobId: number): Promise<void> {
    const db = await this.getDb();

    const tx = db.transaction(DbStoreNames.StepComments, "readwrite");
    let jobComments = await tx.store.get(jobId);

    if (jobComments) {
      jobComments.comments.forEach(l => l.isOnServer = true);
      await tx.store.put(jobComments);
    }

    await tx.done;
  }

  public async updateJobPaperExecutionIsOnServer(jobId: number): Promise<void> {
    const db = await this.getDb();

    const tx = db.transaction(DbStoreNames.JobPaperExecution, "readwrite");
    let jobPaperExecs = await tx.store.get(jobId);

    if (jobPaperExecs) {
      jobPaperExecs.isOnServer = true;
      await tx.store.put(jobPaperExecs);
    }

    await tx.done;
  }

  public async updatePaperExecutionsIsOnServer(jobId: number): Promise<void> {
    const db = await this.getDb();

    const tx = db.transaction(DbStoreNames.PaperExecutions, "readwrite");
    let paperExecs = await tx.store.index("by-job").getAll(jobId);

    paperExecs.forEach(l => l.isOnServer = true);

    for (let i = 0; i < paperExecs.length; i++) {
      await tx.store.put(paperExecs[i]);
    }

    await tx.done;
  }

  public async updateJobCompletionIsOnServer(jobId: number,
    isOnServer: boolean): Promise<void> {
    const db = await this.getDb();

    const tx = db.transaction(DbStoreNames.JobCompletions, "readwrite");
    let completion = await tx.store.get(jobId);

    if (completion) {
      completion.isOnServer = isOnServer;
      await tx.store.put(completion);
    }

    await tx.done;
  }

  public async updateJobCancellationIsOnServer(jobId: number,
    isOnServer: boolean): Promise<void> {
    const db = await this.getDb();

    const tx = db.transaction(DbStoreNames.JobCancellations, "readwrite");
    let cancellation = await tx.store.get(jobId);

    if (cancellation) {
      cancellation.isOnServer = isOnServer;
      await tx.store.put(cancellation);
    }

    await tx.done;
  }

  public async deleteCachedJob(jobId: number): Promise<void> {
    const db = await this.getDb();
    const tx = db.transaction([
      DbStoreNames.Jobs,
      DbStoreNames.SWs,
      DbStoreNames.SWImageData,
      DbStoreNames.SWRefDocData,
      DbStoreNames.JobResponses,
      DbStoreNames.JobCompletions,
      DbStoreNames.JobCancellations,
      DbStoreNames.UserImageData,
      DbStoreNames.JobHistoryLog,
      DbStoreNames.StepComments,
      DbStoreNames.StepCommentAttData,
      DbStoreNames.PaperExecutions,
      DbStoreNames.JobPaperExecution,
      DbStoreNames.SWUserFeedback,
      DbStoreNames.ShowAllEclsJobUpdates,
      DbStoreNames.DuplicateSWs,
      DbStoreNames.JobDocs,
    ], "readwrite");

    // Delete the specified job.
    await tx.objectStore(DbStoreNames.Jobs).delete(jobId);

    let deletedSWKeys: [number, string, string][] = [];
    let swCursor = await tx.objectStore(DbStoreNames.SWs)
      .index("by-job")
      .openKeyCursor(jobId);

    while (swCursor) {
      await tx.objectStore(DbStoreNames.SWs)
        .delete(swCursor.primaryKey);
      deletedSWKeys.push(swCursor.primaryKey);
      swCursor = await swCursor.continue();
    }

    // Delete the Images for the SWs.
    deletedSWKeys.forEach(async (swKey) => {
      let responseCursor = await tx.objectStore(DbStoreNames.SWImageData)
        .index("by-sw")
        .openKeyCursor([swKey[1], swKey[2]]);

      while (responseCursor) {
        await tx.objectStore(DbStoreNames.SWImageData)
          .delete(responseCursor.primaryKey);
        responseCursor = await responseCursor.continue();
      }
    });

    // Delete the Ref Docs for the SWs.
    deletedSWKeys.forEach(async (swKey) => {
      let responseCursor = await tx.objectStore(DbStoreNames.SWRefDocData)
        .index("by-sw")
        .openKeyCursor([swKey[1], swKey[2]]);

      while (responseCursor) {
        await tx.objectStore(DbStoreNames.SWRefDocData)
          .delete(responseCursor.primaryKey);
        responseCursor = await responseCursor.continue();
      }
    });

    // Delete all Job Responses for the jobs that are already on the server.
    let responseCursor = await tx.objectStore(DbStoreNames.JobResponses)
      .index("by-job")
      .openCursor(jobId);

    while (responseCursor) {
      if (responseCursor.value.isOnServer) {
        await tx.objectStore(DbStoreNames.JobResponses).delete(responseCursor.primaryKey);
      }
      responseCursor = await responseCursor.continue();
    }

    // Delete all duplicate sws that are already on the server. 
    let duplicateCursor = await tx.objectStore(DbStoreNames.DuplicateSWs)
      .index("by-duplicatesSW")
      .openCursor(jobId);

    while (duplicateCursor) {
      await tx.objectStore(DbStoreNames.DuplicateSWs).delete(duplicateCursor.primaryKey);
      duplicateCursor = await duplicateCursor.continue();
    }

    // Delete all user images from the deleted job responses.
    let userImagesCursor = await tx.objectStore(DbStoreNames.UserImageData)
      .index("by-job")
      .openKeyCursor(jobId);

    while (userImagesCursor) {
      await tx.objectStore(DbStoreNames.UserImageData).delete(userImagesCursor.primaryKey);
      userImagesCursor = await userImagesCursor.continue();
    }

    // Delete all Job Completions for the jobs that are already on the server.
    let jobCompletion = await tx.objectStore(DbStoreNames.JobCompletions)
      .get(jobId);

    if (jobCompletion
      && jobCompletion.isOnServer) {
      await tx.objectStore(DbStoreNames.JobCompletions)
        .delete(jobId);
    }

    // show all ECLs Job Updates thar are already on the server.
    let showAllEclsJobUpdates = await tx.objectStore(DbStoreNames.ShowAllEclsJobUpdates)
      .get(jobId);

    if (showAllEclsJobUpdates
      && showAllEclsJobUpdates.isOnServer) {
      await tx.objectStore(DbStoreNames.ShowAllEclsJobUpdates)
        .delete(jobId);
    }

    // Delete all Job Cancellations for the jobs that are already on the server.
    let jobCancellation = await tx.objectStore(DbStoreNames.JobCancellations)
      .get(jobId);

    if (jobCancellation
      && jobCancellation.isOnServer) {
      await tx.objectStore(DbStoreNames.JobCancellations)
        .delete(jobId);
    }

    // Delete all job history log items that are already on the server.
    let jobHistoryLog = await tx.objectStore(DbStoreNames.JobHistoryLog)
      .get(jobId);

    if (jobHistoryLog) {
      if (!jobHistoryLog.log.find(h => !h.isOnServer)) {
        // All log items are already on the server.
        // Delete the entire array.
        await tx.objectStore(DbStoreNames.JobHistoryLog)
          .delete(jobId);
      } else {
        // Delete all log items that are already on the server.
        // Keep the rest.
        jobHistoryLog.log = jobHistoryLog.log
          .filter(l => !l.isOnServer);

        await tx.objectStore(DbStoreNames.JobHistoryLog)
          .put(jobHistoryLog);
      }
    }

    // Delete all step comments for the job that are already on the server.
    let jobStepComments = await tx.objectStore(DbStoreNames.StepComments)
      .get(jobId);

    if (jobStepComments) {
      // Find the comment atts filenames that will need to be
      // deleted.
      const commentsWithAttsToDelete = jobStepComments
        .comments
        .filter(x => x.isOnServer
          && x.attachments.length > 0);

      // First, handle all the comments themselves.
      if (!jobStepComments.comments.find(x => !x.isOnServer)) {
        // All step comments are already on the server.
        // Delete the entire object.
        await tx.objectStore(DbStoreNames.StepComments)
          .delete(jobId);
      } else {
        // Delete the step comments that are already on the server
        // and their associated atts. Keep the rest.
        jobStepComments.comments = jobStepComments.comments
          .filter(x => !x.isOnServer);

        await tx.objectStore(DbStoreNames.StepComments)
          .put(jobStepComments);
      }

      // Now delete all the atts that are associated with the
      // step comments that were removed.
      for (let c = 0; c < commentsWithAttsToDelete.length; c++) {
        for (let i = 0; i < commentsWithAttsToDelete[c].attachments.length; i++) {
          await tx.objectStore(DbStoreNames.StepCommentAttData)
            .delete(commentsWithAttsToDelete[c].attachments[i].filename);
        }
      }
    }
    // Delete job Paper execution
    await tx.objectStore(DbStoreNames.JobPaperExecution).delete(jobId);

    let paperExecCursor = await tx.objectStore(DbStoreNames.PaperExecutions)
      .index("by-job")
      .openCursor(jobId);

    while (paperExecCursor) {
      if (paperExecCursor.value.isOnServer) {
        await paperExecCursor.delete();
      }

      paperExecCursor = await paperExecCursor.continue();
    }

    // Delete all Job docs.
    let jobDocCursor = await tx.objectStore(DbStoreNames.JobDocs)
      .index("by-job")
      .openCursor(jobId);

    while (jobDocCursor) {
      await tx.objectStore(DbStoreNames.JobDocs).delete(jobDocCursor.primaryKey);
      jobDocCursor = await jobDocCursor.continue();
    }

    await tx.done;
  }

  public async deleteCachedJobResponses(jobId: number, stepIds: string[]): Promise<void> {
    const db = await this.getDb();

    const tx = db.transaction(DbStoreNames.JobResponses, "readwrite");
    let cursor = await tx.store.index("by-job").openKeyCursor(jobId);

    while (cursor) {
      if (stepIds.indexOf(cursor.primaryKey[1]) > -1) {
        await tx.objectStore(DbStoreNames.JobResponses)
          .delete(cursor.primaryKey);
      }
      cursor = await cursor.continue();
    }

    await tx.done;
  }

  public async deleteCachedStepCommentsAndAtts(jobId: number,
    attFilenames: string[]): Promise<void> {
    const db = await this.getDb();

    const tx = db.transaction([
      DbStoreNames.StepComments,
      DbStoreNames.StepCommentAttData,
    ], "readwrite");

    await tx.objectStore(DbStoreNames.StepComments)
      .delete(jobId);

    for (let i = 0; i < attFilenames.length; i++) {
      await tx.objectStore(DbStoreNames.StepCommentAttData)
        .delete(attFilenames[i]);
    }

    await tx.done;
  }

  public async deleteCachedUserImages(imageFilenames: string[]): Promise<void> {
    const db = await this.getDb();
    const tx = db.transaction(DbStoreNames.UserImageData, "readwrite");

    let cursor = await tx.objectStore(DbStoreNames.UserImageData)
      .openKeyCursor();

    while (cursor) {
      if (imageFilenames.indexOf(cursor.primaryKey) > -1) {
        await tx.objectStore(DbStoreNames.UserImageData)
          .delete(cursor.primaryKey);
      }
      cursor = await cursor.continue();
    }

    await tx.done;
  }

  public async deleteCachedJobHistoryLog(jobId: number): Promise<void> {
    const db = await this.getDb();
    await db.delete(DbStoreNames.JobHistoryLog, jobId);
  }

  public async deleteCachedJobCompletion(jobId: number): Promise<void> {
    const db = await this.getDb();
    await db.delete(DbStoreNames.JobCompletions, jobId);
  }

  public async deleteCachedJobCancellation(jobId: number): Promise<void> {
    const db = await this.getDb();
    await db.delete(DbStoreNames.JobCancellations, jobId);
  }

  public async deleteStepCommentAtt(filename: string): Promise<void> {
    const db = await this.getDb();
    await db.delete(DbStoreNames.StepCommentAttData, filename);
  }

  public async deleteCachedPaperExecutions(jobId: number): Promise<void> {
    const db = await this.getDb();
    const keys = await db.getAllKeysFromIndex(DbStoreNames.PaperExecutions,
      "by-job",
      jobId);

    const tx = db.transaction(DbStoreNames.PaperExecutions,
      "readwrite");

    for (let i = 0; i < keys.length; i++) {
      await tx.store.delete(keys[i]);
    }

    await tx.done;
  }

  public async cleanupUnusedImageData() {
    const db = await this.getDb();
    const tx = db.transaction([
      DbStoreNames.Jobs,
      DbStoreNames.JobResponses,
      DbStoreNames.UserImageData,
      DbStoreNames.SWs,
      DbStoreNames.SWImageData,
      DbStoreNames.SWRefDocData,
      DbStoreNames.StepCommentAttData,
      DbStoreNames.StepComments,
      DbStoreNames.PaperExecutions,
    ], "readwrite");

    let imgIdsToKeep: string[] = [];

    // Open a db cursor and start iterating over cached responses
    // to find any referenced image id.
    let responseCursor = await tx.objectStore(DbStoreNames.JobResponses)
      .openCursor();

    while (responseCursor) {
      // If the offline response has component responses,
      // find any image responses and extract the imgIds.
      responseCursor
        .value
        ?.componentResponses
        ?.filter(sa => sa.type === ComponentResponseType.Image)
        .forEach(imgResponse => {
          // Store the image filenames.
          imgResponse.values.forEach(imgId => imgIdsToKeep.push(imgId));
        });

      responseCursor = await responseCursor.continue();
    }

    // Find all referenced Paper Execution images.
    let paperExecCursor = await tx.objectStore(DbStoreNames.PaperExecutions)
      .openCursor();

    while (paperExecCursor) {
      imgIdsToKeep.push(paperExecCursor
        .value
        .imageFilename);

      paperExecCursor = await paperExecCursor.continue();
    }

    const now = new Date();

    // Get all the user img keys in the db.
    const userImageKeys = await tx.objectStore(DbStoreNames.UserImageData)
      .getAllKeys();

    for (let i = 0; i < userImageKeys.length; i++) {
      const userImgFilename = userImageKeys[i];

      if (imgIdsToKeep.indexOf(userImgFilename) > -1) {
        // An offline response is using this. Keep it.
        continue;
      }

      const img = await tx.objectStore(DbStoreNames.UserImageData)
        .get(userImgFilename);

      if (!img) {
        // Image not found in db for some reason. (Impossible)
        continue;
      }

      if (now > img.expirationDate) {
        // Image has expired. Remove from cache.
        await tx.objectStore(DbStoreNames.UserImageData).delete(userImgFilename);
      }
    }

    // Get the list of jobIds from the Idb.
    const cachedJobIds = await tx.objectStore(DbStoreNames.Jobs)
      .getAllKeys();

    // Find all the SWs that are not in any offline jobs and delete them.
    const swKeys = await tx.objectStore(DbStoreNames.SWs)
      .getAllKeys();

    let validSWKeys: [string, string][] = [];

    for (let i = 0; i < swKeys.length; i++) {
      if (cachedJobIds.indexOf(swKeys[i][0]) === -1) {
        // This ISW's job is not in the cache.
        await tx.objectStore(DbStoreNames.SWs).delete(swKeys[i]);

        console.log(`Cached ISW has missing job. Deleted ISW: ${swKeys[i]}`);
      } else {
        // This ISW's job is still in the cache. It is valid.
        validSWKeys.push([swKeys[i][1], swKeys[i][2]]);
      }
    }

    // Purge unused SWImageData by checking the swId and version
    // against any offline jobs.
    const swImageKeys = await tx.objectStore(DbStoreNames.SWImageData).getAllKeys();

    for (let i = 0; i < swImageKeys.length; i++) {
      const swImgKey = swImageKeys[i];

      if (!validSWKeys.find(swKey => swKey[0] === swImgKey[0]
        && swKey[1] === swImgKey[1])) {

        // Check the expiration date.
        const swImg = await tx.objectStore(DbStoreNames.SWImageData).get(swImgKey);

        if (!swImg) {
          continue;
        }

        if (now > swImg.expirationDate) {
          // This sw img has no sw in the db and it's expired.
          await tx.objectStore(DbStoreNames.SWImageData).delete(swImgKey);
          console.log(`Cached SW image has missing SW. Deleted SW image: ${swImgKey}`);
        }
      }
    }

    // Purge unused RefDocs by checking the swId and version
    // against any offline jobs.
    const swRefDocKeys = await tx.objectStore(DbStoreNames.SWRefDocData).getAllKeys();

    for (let i = 0; i < swRefDocKeys.length; i++) {
      const swRefDocKey = swRefDocKeys[i];

      if (!validSWKeys.find(swKey => swKey[0] === swRefDocKey[0]
        && swKey[1] === swRefDocKey[1])) {

        // Check the expiration date.
        const swRefDoc = await tx.objectStore(DbStoreNames.SWRefDocData).get(swRefDocKey);

        if (!swRefDoc) {
          continue;
        }

        if (now > swRefDoc.expirationDate) {
          // This sw img has no sw in the db and it's expired.
          await tx.objectStore(DbStoreNames.SWRefDocData).delete(swRefDocKey);
          console.log(`Cached refDoc has missing SW. Deleted SW image: ${swRefDocKey}`);
        }
      }
    }

    // Find all the step comment att filenames that should be kept.
    let stepCommentsCursor = await tx.objectStore(DbStoreNames.StepComments)
      .openCursor();

    const stepCommentAttsToKeep: string[] = [];

    while (stepCommentsCursor) {
      if (cachedJobIds.indexOf(stepCommentsCursor.value.jobId) === -1) {
        // This set of comments doesn't have a cached job.
        // Gather the list of att filenames that should be kept.
        stepCommentsCursor.value.comments
          .filter(x => !x.isOnServer
            && x.attachments.length)
          .forEach(comment => {
            comment.attachments.forEach(i => stepCommentAttsToKeep.push(i.filename));
          });
      } else {
        // This set of comments DOES have a cached job.
        // All of their atts should be kept.
        stepCommentsCursor.value.comments
          .filter(x => x.attachments.length)
          .forEach(comment => {
            comment.attachments.forEach(i => stepCommentAttsToKeep.push(i.filename));
          });
      }

      stepCommentsCursor = await stepCommentsCursor.continue();
    }

    // Now delete all the step comment atts that should be deleted.
    let stepCommentAttCursor = await tx.objectStore(DbStoreNames.StepCommentAttData)
      .openCursor();

    while (stepCommentAttCursor) {
      if (stepCommentAttsToKeep
        .indexOf(stepCommentAttCursor.value.filename) === -1
        && now > stepCommentAttCursor.value.expirationDate) {
        // This att isn't one that should be kept.
        // If it's past its expiration date, remove it.
        stepCommentAttCursor.delete();
        console.log(`Cached step comment attachment is missing job. `
          + `Deleted: ${stepCommentAttCursor.value.filename}`);
      }

      stepCommentAttCursor = await stepCommentAttCursor.continue();
    }

    await tx.done;
  }

  public async migrateJobIds(oldJobId: number,
    newJobId: number,
    jobSWIdMappings: IOldNewJobSWIdMapping[]) {
    const db = await this.getDb();
    const tx = db.transaction([
      DbStoreNames.JobCancellations,
      DbStoreNames.JobCompletions,
      DbStoreNames.JobHistoryLog,
      DbStoreNames.Jobs,
      DbStoreNames.PaperExecutions,
      DbStoreNames.JobResponses,
      DbStoreNames.StepComments,
      DbStoreNames.SWs,
      DbStoreNames.UserImageData,
    ], "readwrite");

    // Create an entity updater that can be used with all the different entities
    // that will need JobSWIds updated.
    const jobSWIdUpdater = (entity: { jobSWId: number }, entityName: string) => {
      const newJobSWId = jobSWIdMappings.find(x => x.oldJobSWId === entity.jobSWId);

      if (!newJobSWId) {
        throw new Error(`JobSWId ${entity.jobSWId} not found in updated mappings when updating ${entityName}.`);
      }

      entity.jobSWId = newJobSWId.newJobSWId;
    };

    // Migrate the Job.
    {
      const jobStore = tx.objectStore(DbStoreNames.Jobs);
      let job = await jobStore.get(oldJobId);

      if (job) {
        job.id = newJobId;
        job.sws.forEach(jsw => jobSWIdUpdater(jsw, `JobSW ${jsw.jobSWId}`));
        await jobStore.put(job);
        await jobStore.delete(oldJobId);
      }
    }

    // Migrate the Job Cancellation.
    {
      const cancellationStore = tx.objectStore(DbStoreNames.JobCancellations);
      let jobCancellation = await cancellationStore.get(oldJobId);

      if (jobCancellation) {
        jobCancellation.jobId = newJobId;
        await cancellationStore.put(jobCancellation);
        await cancellationStore.delete(oldJobId);
      }
    }

    // Migrate the Job Completion.
    {
      const completionStore = tx.objectStore(DbStoreNames.JobCompletions);
      let jobCompletion = await completionStore.get(oldJobId);

      if (jobCompletion) {
        jobCompletion.jobId = newJobId;
        await completionStore.put(jobCompletion);
        await completionStore.delete(oldJobId);
      }
    }

    // Migrate the Job History Log.
    {
      const historyStore = tx.objectStore(DbStoreNames.JobHistoryLog);
      let history = await historyStore.get(oldJobId);

      if (history) {
        history.jobId = newJobId;
        await historyStore.put(history);
        await historyStore.delete(oldJobId);
      }
    }

    // Migrate the Paper Executions.
    {
      const paperExecStore = tx.objectStore(DbStoreNames.PaperExecutions);
      let paperExecs = await paperExecStore.index("by-job").getAll(oldJobId);
      const oldPEJobSWIds = paperExecs.map(x => x.jobSWId);

      if (paperExecs.length) {
        paperExecs.forEach(x => {
          jobSWIdUpdater(x, `PaperExecution ${x.jobSWId}`);
          x.jobId = newJobId;
        });

        for (let i = 0; i < paperExecs.length; i++) {
          await paperExecStore.put(paperExecs[i]);
        }

        // Delete the old paper execs.
        for (let i = 0; i < oldPEJobSWIds.length; i++) {
          await paperExecStore.delete(oldPEJobSWIds[i]);
        }
      }
    }

    // Migrate the Job Responses.
    {
      const jobResponseStore = tx.objectStore(DbStoreNames.JobResponses);
      let jobResponses = await jobResponseStore.index("by-job").getAll(oldJobId);

      let jobResponseKeys = jobResponses.map(x => ({
        jobSWId: x.jobSWId,
        stepId: x.stepId,
      }));

      if (jobResponses.length) {
        jobResponses.forEach(x => {
          jobSWIdUpdater(x, `StepResponse ${x.jobSWId}`);
          x.jobId = newJobId;
        });

        for (let i = 0; i < jobResponses.length; i++) {
          await jobResponseStore.put(jobResponses[i]);
        }

        // Delete the old paper execs.
        for (let i = 0; i < jobResponseKeys.length; i++) {
          await jobResponseStore.delete([
            jobResponseKeys[i].jobSWId,
            jobResponseKeys[i].stepId,
          ]);
        }
      }
    }

    // Migrate the Job Step Comments.
    {
      const commentStore = tx.objectStore(DbStoreNames.StepComments);
      let comments = await commentStore.get(oldJobId);

      if (comments) {
        comments.jobId = newJobId;
        comments.comments.forEach(comm => jobSWIdUpdater(comm, `StepComment ${comm.guid}`));

        await commentStore.put(comments);
        await commentStore.delete(oldJobId);
      }
    }

    // Migrate the Job SWs.
    {
      const swStore = tx.objectStore(DbStoreNames.SWs);
      let sws = await swStore.index("by-job").getAll(oldJobId);

      let swKeys = sws.map(x => ({
        jobId: x.jobId,
        swId: x.swId,
        swVersion: x.swVersion,
      }));

      if (sws.length) {
        sws.forEach(x => x.jobId = newJobId);

        for (let i = 0; i < sws.length; i++) {
          await swStore.put(sws[i]);
        }

        // Delete the old paper execs.
        for (let i = 0; i < swKeys.length; i++) {
          await swStore.delete([
            swKeys[i].jobId,
            swKeys[i].swId,
            swKeys[i].swVersion,
          ]);
        }
      }
    }

    // Migrate the Job User Image Data.
    {
      const userImageStore = tx.objectStore(DbStoreNames.UserImageData);
      let userImages = await userImageStore.index("by-job").getAll(oldJobId);

      if (userImages.length) {
        userImages.forEach(x => x.jobId = newJobId);
        for (let i = 0; i < userImages.length; i++) {
          // Since the key is the same, it should just overwrite the record.
          await userImageStore.put(userImages[i]);
        }
      }
    }

    await tx.done;
  }

  public async isAnythingMissingJobSWId() {
    const db = await this.getDb();
    const tx = db.transaction([
      DbStoreNames.JobResponses,
      DbStoreNames.Jobs,
      DbStoreNames.StepComments,
    ]);

    const allJobs = await tx.objectStore(DbStoreNames.Jobs).getAll();

    for (let i = 0; i < allJobs.length; i++) {
      if (allJobs[i].sws.find(x => x.jobSWId === undefined)) {
        await tx.done;
        return true;
      }
    }

    const allResponses = await tx.objectStore(DbStoreNames.JobResponses).getAll();
    for (let i = 0; i < allResponses.length; i++) {
      if (!allResponses[i].jobSWId) {
        await tx.done;
        return true;
      }
    }

    const allComments = await tx.objectStore(DbStoreNames.StepComments).getAll();
    for (let i = 0; i < allComments.length; i++) {
      if (allComments[i].comments.find(x => x.jobSWId === undefined)) {
        await tx.done;
        return true;
      }
    }

    await tx.done;
    return false;
  }

  public async migrateDb(onDbError: (err: Error) => void) {
    await this.getDb(onDbError);
  }

  async getDb(onDbError?: (err: Error) => void) {
    if (this.db) {
      return this.db;
    }

    if (config.indexedDb.dropCreateOnVersionDiff) {
      let existingVersion = 0;

      try {
        existingVersion = await this.getDbVersion(dbName, {
          blocked: () => {
            if (onDbError) {
              onDbError(new DbBlockedError());
            } else {
              throw new DbBlockedError();
            }
          },
        });
      } catch (err: any) {
        throw new DbDeletionError(err);
      }

      if (existingVersion !== DB_VERSION) {
        try {
          await deleteDB(dbName);
        } catch (err: any) {
          throw new DbDeletionError(err.message);
        }
      }
    }

    try {
      this.db = await openDB<ISWPOfflineDb>(dbName, DB_VERSION, {
        upgrade: (db, oldVersion, newVersion, transaction) => {
          if (newVersion === null) {
            if (onDbError) {
              onDbError(new Error("Invalid DB_VERSION specified: null"));
            } else {
              throw new Error("Invalid DB_VERSION specified: null");
            }
          } else if (oldVersion < newVersion) {
            // DB is old and should be migrated.
            try {
              applyIdbMigrations(db, transaction, oldVersion, newVersion);
            } catch (err: any) {
              if (onDbError) {
                onDbError(err);
              } else {
                throw err;
              }
            }
          }
        },
        terminated: () => {
          if (this.db) {
            this.db.close();
          }
        },
        blocked: () => {
          if (onDbError) {
            onDbError(new DbBlockedError());
          } else {
            throw new DbBlockedError();
          }
        },
        blocking: () => {
          if (onDbError) {
            onDbError(new DbBlockingError());
          } else {
            throw new DbBlockingError();
          }
        },
      });
    } catch (err: any) {
      throw new CannotCreateDbError(err.message);
    }

    return this.db;
  }

  async getDbVersion<DBTypes extends DBSchema | unknown = unknown>(
    name: string,
    { blocked }: OpenDBCallbacks<DBTypes> = {},
  ): Promise<number> {
    const request = indexedDB.open(name);
    const openPromise = wrap(request) as Promise<IDBPDatabase<DBTypes>>;

    if (blocked) {
      request.addEventListener('blocked',
        () => blocked);
    }

    let db = await openPromise;
    let dbVersion = db.version;
    db.close();

    return dbVersion;
  }

  getNewExpirationDate() {
    let expDate = new Date();
    expDate.setDate(expDate.getDate() + 7);
    return expDate;
  }

  public async exportToJson(includeImages: boolean): Promise<string> {
    let db = await this.getDb();

    let idbData: any = {
      idbVersion: DB_VERSION,
    };

    for (let i = 0; i < db.objectStoreNames.length; i++) {
      if (db.objectStoreNames[i] === DbStoreNames.SWImageData
        || db.objectStoreNames[i] === DbStoreNames.SWRefDocData
        || db.objectStoreNames[i] === DbStoreNames.SWs
        || (!includeImages &&
          (db.objectStoreNames[i] === DbStoreNames.UserImageData
            || db.objectStoreNames[i] === DbStoreNames.StepCommentAttData))) {
        continue;
      }

      const tx = db.transaction(db.objectStoreNames[i], "readonly");

      let items: any[] = [];

      let cursor = await tx.store.openCursor();
      while (cursor) {
        let exportThisItem = true;

        if (db.objectStoreNames[i] === DbStoreNames.JobResponses
          && (cursor.value as OfflineStepResponse).isOnServer) {
          exportThisItem = false;
        } else if (db.objectStoreNames[i] === DbStoreNames.UserImageData
          && (cursor.value as IUserImageData).isOnServer) {
          exportThisItem = false;
        } else if (db.objectStoreNames[i] === DbStoreNames.StepComments) {
          const unSyncedComments = (cursor.value as IIdbStepComments).comments
            .filter(x => !x.isOnServer);

          if (unSyncedComments.length) {
            let commentList = (cursor.value as IIdbStepComments);
            commentList.comments = unSyncedComments;
            items.push(commentList);
          }

          cursor = await cursor.continue();
          continue;
        } else if (db.objectStoreNames[i] === DbStoreNames.JobHistoryLog) {
          const unsyncedItems = (cursor.value as IOfflineSWPJobLog).log
            .filter(x => !x.isOnServer);

          if (unsyncedItems.length) {
            let item = (cursor.value as IOfflineSWPJobLog);
            item.log = unsyncedItems;
            items.push(item);
          }

          cursor = await cursor.continue();
          continue;
        } else if (db.objectStoreNames[i] === DbStoreNames.Jobs
          && (cursor.value as ISWPJob).id > 0) {
          exportThisItem = false;
        }

        if (exportThisItem) {
          items.push(cursor.value);
        }
        cursor = await cursor.continue();
      }

      idbData[db.objectStoreNames[i]] = items;
      await tx.done;
    }

    return JSON.stringify(idbData);
  }

  public async cacheSWUserFeedback(feedbacks: IOfflineSWUserFeedback): Promise<void> {
    const db = await this.getDb();
    await db.put(DbStoreNames.SWUserFeedback,
      feedbacks);
  }

  public async getAllCachedSWUserFeedbacks(): Promise<IOfflineSWUserFeedback[]> {
    const db = await this.getDb();
    return await db.getAll(DbStoreNames.SWUserFeedback);
  }

  public async getAllCachedJobs(): Promise<ISWPJob[]> {
    const db = await this.getDb();
    return await db.getAll(DbStoreNames.Jobs);
  }

  public async deleteCachedJobDocs(jobId: number): Promise<void> {
    const db = await this.getDb();
    const keys = await db.getAllKeysFromIndex(DbStoreNames.JobDocs,
      "by-job",
      jobId);

    const tx = db.transaction(DbStoreNames.JobDocs,
      "readwrite");

    for (let i = 0; i < keys.length; i++) {
      await tx.store.delete(keys[i]);
    }

    await tx.done;
  }

  public async deleteCachedSWUserFeedback(jobId: number): Promise<void> {
    const db = await this.getDb();
    await db.delete(DbStoreNames.SWUserFeedback, jobId);
  }

  public async deleteCachedShowAllECLsForJob(jobId: number): Promise<void> {
    const db = await this.getDb();
    await db.delete(DbStoreNames.ShowAllEclsJobUpdates, jobId);
  }

  public async getCachedResetedSW(jobId: number): Promise<IIdbResetSW | undefined> {
    const db = await this.getDb();
    return await db.get(DbStoreNames.ResetedSW, jobId);
  }

  public async getAllCachedResetedSW(): Promise<IIdbResetSW[]> {
    const db = await this.getDb();
    return await db.getAll(DbStoreNames.ResetedSW);
  }

  public async cacheResetedSW(jobSW: IIdbResetSW): Promise<void> {
    const db = await this.getDb();
    await db.put(DbStoreNames.ResetedSW,
      jobSW);
  }

  public async deleteCachedJobPaperExecution(jobID: number): Promise<void> {
    const db = await this.getDb();
    await db.delete(DbStoreNames.JobPaperExecution, jobID);
  }

  public async deleteCachedResetedSWs(jobId: number): Promise<void> {
    const db = await this.getDb();
    await db.delete(DbStoreNames.ResetedSW, jobId);
  }

  public async deleteCachedUserImage(filename: string): Promise<void> {
    const db = await this.getDb();
    const tx = db.transaction(DbStoreNames.UserImageData, "readwrite");
    await tx.objectStore(DbStoreNames.UserImageData)
      .delete(filename);
  }

  public async deleteSWCahedData(jobId: number, jobSWId: number): Promise<void> {
    const isUserimageReferred = await this.IsUserImageReferred(jobSWId);
    const db = await this.getDb();
    const tx = db.transaction([
      DbStoreNames.JobResponses,
      DbStoreNames.StepComments,
      DbStoreNames.UserImageData,
      DbStoreNames.StepCommentAttData,
      DbStoreNames.PaperExecutions,
    ], "readwrite");

    let responseCursor = await tx.objectStore(DbStoreNames.JobResponses)
      .index("by-job")
      .openCursor(jobId);

    while (responseCursor) {
      if (responseCursor.value.jobSWId === jobSWId) {
        await tx.objectStore(DbStoreNames.JobResponses).delete(responseCursor.primaryKey);
      }
      responseCursor = await responseCursor.continue();
    }

    let jobStepComments = await tx.objectStore(DbStoreNames.StepComments)
      .get(jobId);

    if (jobStepComments) {

      const commentsWithAttsToDelete = jobStepComments
        .comments
        .filter(x => x.jobSWId === jobSWId
          && x.attachments.length > 0);

      jobStepComments.comments = jobStepComments.comments
        .filter(x => x.jobSWId !== jobSWId);

      await tx.objectStore(DbStoreNames.StepComments)
        .put(jobStepComments);

      for (let c = 0; c < commentsWithAttsToDelete.length; c++) {
        for (let i = 0; i < commentsWithAttsToDelete[c].attachments.length; i++) {
          await tx.objectStore(DbStoreNames.UserImageData)
            .delete(commentsWithAttsToDelete[c].attachments[i].filename);
          await tx.objectStore(DbStoreNames.StepCommentAttData)
            .delete(commentsWithAttsToDelete[c].attachments[i].filename);
        }
      }
    }

    let paperExecCursor = await tx.objectStore(DbStoreNames.PaperExecutions)
      .index("by-job")
      .openCursor(jobId);

    while (paperExecCursor) {
      if (paperExecCursor.value.jobSWId === jobSWId) {
        if (!isUserimageReferred) {
          await tx.objectStore(DbStoreNames.UserImageData)
            .delete(paperExecCursor.value.imageFilename);
        }

        await paperExecCursor.delete();
      }
      paperExecCursor = await paperExecCursor.continue();
    }
  }

  public async updateJobOffline(job: ISWPJob, inMemoryJob: IInProgressJob): Promise<void> {

    // Update job docs.
    if (inMemoryJob.jobDocs.length > 0) {
      if (job.jobDocs.length === 0) {
        for (let i = 0; i < inMemoryJob.jobDocs.length; i++) {
          let jobDoc: ISWPJobDoc = inMemoryJob.jobDocs[i];
          await this.cacheJobDoc(jobDoc.fileName, job.id, jobDoc.fileContent);
        }
      }
      else {
        let toDelete: ISWPJobDoc[] = [];
        let toAdd: ISWPJobDoc[] = [];

        for (let i = 0; i < job.jobDocs.length; i++) {
          let memoryJobDoc: ISWPJobDoc | undefined = inMemoryJob.jobDocs.find(x => x.fileName === job.jobDocs[i].fileName);

          if (memoryJobDoc === null || memoryJobDoc === undefined) {
            toDelete.push(job.jobDocs[i]);
          }
        }

        for (let i = 0; i < inMemoryJob.jobDocs.length; i++) {
          let dbJobDoc: ISWPJobDoc | undefined = job.jobDocs.find(x => x.fileName === inMemoryJob.jobDocs[i].fileName);

          if (dbJobDoc === null || dbJobDoc === undefined) {
            toAdd.push(inMemoryJob.jobDocs[i]);
          }
        }

        if (toDelete.length > 0) {
          for (let i = 0; i < toDelete.length; i++) {
            await this.deleteCachedJobDoc(job.id, toDelete[i].fileName);
          }
        }

        if (toAdd.length > 0) {
          for (let i = 0; i < toAdd.length; i++) {
            let jobDoc: ISWPJobDoc = toAdd[i];
            await this.cacheJobDoc(jobDoc.fileName, job.id, jobDoc.fileContent);
          }
        }
      }
    }
    else {
      for (let i = 0; i < job.jobDocs.length; i++) {
        await this.deleteCachedJobDoc(job.id, job.jobDocs[i].fileName);
      }
    }

    // Update job.
    let sws: ISWPJobSW[] = [];
    for (let i = 0; i < job.sws.length; i++) {
      let memorySW: IManageJobSW | undefined = inMemoryJob.sws.find(x => x.jobSWId === job.sws[i].jobSWId);
      if (memorySW !== null && memorySW !== undefined) {
        let dbSW: ISWPJobSW | undefined = job.sws.find(x => x.jobSWId === job.sws[i].jobSWId);
        if (dbSW !== null && dbSW !== undefined) {
          sws.push(dbSW);
        }
      }
      else {
        await this.deleteSWCahedData(job.id, job.sws[i].jobSWId);
      }
    }

    job.sws = sws;
    job.team = inMemoryJob.team;
    job.jobDocs = inMemoryJob.jobDocs;
    job.isDirty = true;

    const db = await this.getDb();
    const tx = db.transaction([
      DbStoreNames.Jobs,
      DbStoreNames.JobDocs,
    ], "readwrite");

    await tx.objectStore(DbStoreNames.Jobs).put(job);
  }

  public async deleteCachedJobDoc(jobId: number, fileName: string): Promise<boolean> {
    const db = await this.getDb();
    const tx = db.transaction([
      DbStoreNames.JobDocs,
    ], "readwrite");

    let cursor = await tx.objectStore(DbStoreNames.JobDocs)
      .index("by-job")
      .openCursor(jobId);

    while (cursor) {
      if (cursor.value.filename === fileName) {
        await tx.objectStore(DbStoreNames.JobDocs).delete(cursor.primaryKey);
      }
      cursor = await cursor.continue();
    }

    return true;
  }
}

export default new IdbApi();