import React, { useEffect } from "react";
import useSelector from "store/useSelector";

import { useDispatch } from "react-redux";
import { setJobPDFData } from "store/myJobs/myJobsActions";

const GeneratePDF: React.FC = () => {
  const {
    myJobs: {
      jobPDFData,
    },
  } = useSelector(store => store);

  const dispatch = useDispatch();
  const wasSuccessful = jobPDFData?.printJobOperation?.wasSuccessful;
  const pdfUrl = jobPDFData?.pdfUrl;
  const pdfData = jobPDFData?.pdfData;

  useEffect(() => {
    if (wasSuccessful && pdfData) {
      const mfurl = pdfData.replace("data:application/pdf;base64,", "");
      var byteCharacters = atob(mfurl);
      var byteNumbers = new Array(byteCharacters.length);
      for (var i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      var file = new Blob([byteArray], {
        type: 'application/pdf;base64'
      });
      var fileURL = URL.createObjectURL(file);
      //window.open(fileURL);

      //create some element to download file
      const aElement = document.createElement('a');
      aElement.setAttribute('download', 'job_pdf.pdf');
      aElement.href = fileURL;
      aElement.setAttribute('target', '_blank');
      aElement.click();
      URL.revokeObjectURL(fileURL);

      dispatch(
        setJobPDFData({
          pdfUrl: "",
          pdfData: "",
          printJobOperation: {
            isWorking: false,
          },
        })
      );
    }
  }, [wasSuccessful, pdfUrl, dispatch]);

  return null;
}

export default GeneratePDF