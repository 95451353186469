import React from "react";
import Banner, { BannerType } from "components/common/Banner";
import WarningIcon from "media/icons/dls/warning.svg";
import ErrorIcon from "media/icons/dls/error.svg";
import InfoIcon from "media/icons/dls/info.svg";
import { IStepComponent, StepComponentTypes, NoticeTypes, INoticeComponentData } from "interfaces/sw/SWInterfaces";
import "./Notice.scoped.scss";
import NoticeImage from "./NoticeImage";
import NoticeRichText from "./NoticeRichText";

interface INoticeProps {
  notice: IStepComponent,
  swId: string,
  swVersion: string,
}

const Notice: React.FC<INoticeProps> = ({ notice, swId, swVersion }) => {
  if (notice.type !== StepComponentTypes.Notice
    || !isNoticeData(notice.data)) {
    return null;
  }

  let noticeTextArr: (string | JSX.Element)[] = [];
  let label = notice.label;

  if (label) {
    const reg = /<a.+?href="(?<url>.+?)".*?>(?<text>.+?)<\/a>/g;
    let match;
    let cursorIx = 0;

    while ((match = reg.exec(label))) {
      if (cursorIx !== match.index) {
        noticeTextArr.push(
          label.substr(cursorIx, match.index - cursorIx)
            .replace(/ /g, " ")
        );
      }

      noticeTextArr.push(
        <a href={match[1]}>{match[2].replace(/ /g, " ")}</a>
      );

      cursorIx = match.index + match[0].length;
    }

    if (cursorIx < label.length) {
      noticeTextArr.push(
        label.substr(cursorIx)
          .replace(/ /g, " ")
      );
    }
  }

  return (
    <Banner type={getBannerType(notice.data.type)}>
      <div className="inner">
        {getIcon(notice.data.type)}
        <NoticeRichText component={notice}/>
      </div>
      {
        <div>
          <NoticeImage
            swId={swId}
            swVersion={swVersion}
            filename={notice.data.filename}
          />
        </div>
      }
    </Banner>
  );
};

function isNoticeData(data: any): data is INoticeComponentData {
  return data !== null
    && data !== undefined
    && ('type' in data || 'filename' in data);
}

const getBannerType = (type: NoticeTypes): BannerType => {
  switch (type) {
    case NoticeTypes.Caution: return BannerType.Warn;
    case NoticeTypes.Warning: return BannerType.Error;
    default: return BannerType.Info;
  }
}

const getIcon = (type: NoticeTypes) => {
  if (type === NoticeTypes.Caution) {
    return (
      <img
        src={WarningIcon}
        className={`icon-medium type-icon ${type}`}
        alt="Caution"
      />
    );
  } else if (type === NoticeTypes.Warning) {
    return (
      <img
        src={ErrorIcon}
        className={`icon-medium type-icon ${type}`}
        alt="Warning"
      />
    );
  } else if (type === NoticeTypes.Info) {
    return (
      <img
        src={InfoIcon}
        className={`icon-medium type-icon ${type}`}
        alt="Info"
      />
    );
  }
  return null;
}

export default Notice;