import React, { useEffect, useState } from "react";
import searchIcon from "media/icons/dls/search.svg";
import "./SearchBar.scoped.scss";
import { useDispatch } from "react-redux";
import { toggleAddUserModal, setSearchManageUsersText } from "store/userManagement/userManagementActions";
import useSelector from "store/useSelector";
import useDebounce from "utilities/useDebounce";
import { useTranslation } from "react-i18next";

const SearchBar: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 750);
  const manageUsersData = useSelector(store => store.userManagement.manageUsersData);
  const dispatch = useDispatch();
  const { t } = useTranslation('userSearch');

  useEffect(() => {
    dispatch(setSearchManageUsersText(debouncedSearchTerm.trim()));
  }, [debouncedSearchTerm, dispatch]);

  return (
    <div className="search-bar">
      <input
        type="text"
        id="sw-search"
        style={{
          backgroundImage: `url("${searchIcon}")`,
        }}
        placeholder={t("Search by name or email...")}
        disabled={manageUsersData.getUsersOperation?.isWorking}
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <div className="add-holder">
        <button
          className="primary-button"
          onClick={() => dispatch(toggleAddUserModal(true))}
        >
          {t('Add User')}
      </button>
      </div>
    </div>
  );
}

export default SearchBar;