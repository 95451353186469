import React, { useEffect } from "react";
import Banner, { BannerType } from "components/common/Banner";
import useSelector from "store/useSelector";
import { useDispatch } from "react-redux";
import { getMyJobs, setMyJobsTab } from "store/myJobs/myJobsActions";
import "./MyJobs.scoped.scss";
import { MyJobsTabs } from "interfaces/jobs/JobInterfaces";
import refreshIcon from "media/icons/dls/refresh.svg";
import MyJobsStatusFilter from "./toolbar/MyJobsToolbar";
import { RouteComponentProps } from "react-router-dom";
import CacheJobModal from "./CacheJobModal";
import ReopenJobModal from "./ReopenJobModal";
import ModalSpinner from "components/common/ModalSpinner";
import UnCacheJobModal from "./UnCacheJobModal";
import OfflineJobsList from "./OfflineJobsList";
import FlowLayout from "components/layout/FlowLayout";
import JobHeader from "./jobHeaders/JobHeader";
import JobTemplates from "./templates/JobTemplates";
import JobFilters from "./JobFilters";
import { IJobFilters } from "store/jobTemplates/jobTemplatesTypes";
import CancelJobModal from "./CancelJobModal";
import JobTemplateAttributeModal from "./JobTemplateAttributesModal";
import CacheTemplateModal from "./templates/CacheTemplateModal";
import UnCacheTemplateModal from "./templates/UnCacheTemplateModal";
import GeneratePDF from "./GeneratePDF";
import RetireTemplateModal from "./RetireTemplateModal";
import RecurringJobHeader from "./jobHeaders/RecurringJobHeader";
import { useTranslation } from "react-i18next";

const MyJobs: React.FC<RouteComponentProps> = (props) => {
  const {
    isFetching,
    jobHeaders,
    errorMessage,
    hasOfflineError,
    currentTab,
    cacheModal,
    unCacheModal,
    cancelJobModal,
    reopenJobModal,
    activeJobFilters,
    completedJobFilters,
    cancelJobOperation,
    isUpdating,
    jobPDFData,
  } = useSelector(state => state.myJobs);

  const {
    templateCacheModal,
    templateUnCacheModal,
    filters: jobTemplatesFilters,
    attributePopup,
    retireTemplateModal,
    retireTemplateOperation,
  } = useSelector(state => state.jobTemplates);

  const {
    jobsAwaitingCompletion,
    cacheOperation,
    deleteOperation,
    cacheTemplateOperation,
  } = useSelector(state => state.offline);
  
  const dispatch = useDispatch();
  const { t } = useTranslation('myJobs');
  useEffect(() => {
    dispatch(getMyJobs());
  }, [dispatch, jobsAwaitingCompletion]);

  const refreshMyJobs = async () => {
    dispatch(getMyJobs());
  }

  const changeTab = (newFilter: MyJobsTabs) => {
    if (newFilter !== currentTab) {
      dispatch(setMyJobsTab(newFilter));
    }
  }

  let displayComponent: JSX.Element | null = null;

  if (errorMessage) {
    displayComponent = (
      <Banner type={BannerType.Error}>
        {errorMessage}
      </Banner>
    );
  } else {
    if (currentTab === MyJobsTabs.Templates) {
      displayComponent = (
        <JobTemplates
          {...props}
        />
      );
    } else if (currentTab === MyJobsTabs.Recurring
      && hasOfflineError) {
      displayComponent = (
        <Banner
          type={BannerType.Error}
        >
          The recurring jobs list is not available while offline.
        </Banner>
      );
    } 
    else if (currentTab === MyJobsTabs.Completed
      && hasOfflineError) {
      displayComponent = (
        <Banner
          type={BannerType.Error}
        >
          The completed jobs list is not available while offline.
        </Banner>
      );
    }
    else {
      if (hasOfflineError) {
        // Show offline results instead!
        displayComponent = (
          <OfflineJobsList
            {...props}
          />
        );
      } else if (jobHeaders!?.length === 0) {
        displayComponent = (
          <Banner type={BannerType.Info}>
            {t('There are no {currentTab} jobs assigned to you.', { currentTab: currentTab.toLowerCase() })}
          </Banner>
        );
      } else if (jobHeaders!?.length > 0) {
        if (currentTab === MyJobsTabs.Recurring) {
          displayComponent = (
            <>
              {jobHeaders!?.map(job =>
                <RecurringJobHeader
                  key={job.id}
                  jobHeader={job}
                  {...props}
                />
              )}
            </>
          );
        } else {
          displayComponent = (
            <>
              {jobHeaders!?.map(job =>
                <JobHeader
                  key={job.id}
                  showCacheButton={true}
                  isOfflineMode={false}
                  showExtraButtons={true}
                  {...props}
                  jobHeader={job}
                  tab={currentTab}
                />
              )}
            </>
          );
        }
      }
    }
  }

  let filters: IJobFilters | undefined;

  if (currentTab === MyJobsTabs.Active) {
    filters = activeJobFilters;
  } else if (currentTab === MyJobsTabs.Completed) {
    filters = completedJobFilters;
  } else if (currentTab === MyJobsTabs.Templates) {
    filters = jobTemplatesFilters;
  }

  return (
    <FlowLayout
      header={
        <>
          <h2 className="page-title">
          {t('My Jobs')}
            {currentTab !== MyJobsTabs.Templates &&
              <img
                src={refreshIcon}
                className="icon-medium refresh-button hover-gray-bg"
                title={t('Refresh')}
                alt={t('Refresh')}
                onClick={refreshMyJobs}
              />
            }
          </h2>
          <MyJobsStatusFilter
            onTabChange={changeTab}
            currentTab={currentTab}
          />
          {filters &&
            <JobFilters
              filters={filters}
              currentTab={currentTab}
            />
          }
        </>
      }
    >
      {displayComponent}
      {cacheModal.isOpen &&
        <CacheJobModal />
      }
      {unCacheModal.isOpen &&
        <UnCacheJobModal />
      }
      {cancelJobModal.isOpen &&
        <CancelJobModal />
      }
      {retireTemplateModal?.isOpen &&
        <RetireTemplateModal />
      }
      {reopenJobModal.isOpen &&
        <ReopenJobModal />
      }
      {attributePopup.isOpen &&
        <JobTemplateAttributeModal />}
      {templateCacheModal.isOpen &&
        <CacheTemplateModal />
      }
      {templateUnCacheModal.isOpen &&
        <UnCacheTemplateModal />
      }
      {jobPDFData?.pdfUrl &&
        <GeneratePDF />
      }

      {(cacheOperation?.isWorking
        || deleteOperation?.isWorking
        || cancelJobOperation?.isWorking
        || retireTemplateOperation?.isWorking
        || isUpdating
        || isFetching
        || jobPDFData?.printJobOperation?.isWorking
        || cacheTemplateOperation?.isWorking) &&
        <ModalSpinner />
      }

    </FlowLayout>
  );
}
export default MyJobs;
