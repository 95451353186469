import React from "react";
import { IRichTextParagraphData, IStepComponent } from "interfaces/sw/SWInterfaces";
import "./RichTextParagraph.scoped.scss";
import sanitizeHtml from 'sanitize-html-react';

interface IRichTextComponentProps {
  component: IStepComponent,
}

const RichTextParagraph: React.FC<IRichTextComponentProps> = ({ component }) => {
  if (!isRichTextData(component.data)) {
    return null;
  }

  return (
    <div
      className="html-content"
      dangerouslySetInnerHTML={createMarkup(component.data.html)}
    />
  )
}

export default RichTextParagraph;

function isRichTextData(data: any): data is IRichTextParagraphData {
  return data !== null
    && data !== undefined
    && 'html' in data;
}

function createMarkup(data: string) {
  const cleanHtml = sanitizeHtml(data, {
    allowedTags: ['p', 'ul', 'li', 'ol', 'b', 'i', 'a', 'strong', 'em', 'ins'],
    allowedAttributes: {
      'a': ['href', 'name', 'target']
    },
  });

  return { __html: cleanHtml };
}