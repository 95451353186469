import Modal from "components/common/Modal";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import "./PINManagement.scoped.scss";

interface IPINManagementProps {
  onSubmit(pin: string): void,
  onCancel(): void,
}

const PINManagement: React.FC<IPINManagementProps> = ({
  onSubmit,
  onCancel,
}) => {
  const [pin1, setPin1] = useState("");
  const [pin2, setPin2] = useState("");
  const [error, setError] = useState("");
  const pin1Ref = useRef<HTMLInputElement>(null);
  const { t } = useTranslation('userProfile');

  useEffect(() => {
    pin1Ref.current?.focus();
  }, []);

  const onEntryChange = (value: string, setState: React.Dispatch<React.SetStateAction<string>>) => {
    const isOnlyNums = /^[0-9]*$/.test(value);

    if (!isOnlyNums) {
      setError(t("PINs can contain only numbers."));
      return;
    }

    setState(value);

    if (value.length !== 6) {
      setError(t("PINs must be exactly 6 numbers long."));
    } else {
      if ((setState === setPin1
          && value !== pin2)
        || (setState === setPin2
          && value !== pin1)) {
        setError(t("PINs do not match."));
      } else {
        setError("");
      }
    }
  }

  const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!(/^[0-9]*$/.test(pin1))
      || !(/^[0-9]*$/.test(pin2))
      || pin1 !== pin2) {
      setError(t("Invalid PIN entries. Please try again."));
      return;
    }

    onSubmit(pin1);
  }

  const isSetPINDisabled = !pin1.trim()
    || !pin2.trim()
    || pin1 !== pin2;

  return (
    <Modal
      isOpen={true}
      header={t("PIN Management")}
      controls={(
        <>
          <button
            className="secondary-button"
            onClick={onCancel}
          >
            {t('Cancel')}
          </button>
          <button
            className="primary-button"
            form="pin-management"
            disabled={isSetPINDisabled}
          >
            {t('Set PIN')}
          </button>
        </>
      )}
    >
      <form
        id="pin-management"
        onSubmit={onFormSubmit}
      >
        <div>
          <label>
            {t('New PIN')}
          </label>
          <input
            type="password"
            value={pin1}
            onChange={(e) => onEntryChange(e.target.value, setPin1)}
            pattern="[0-9]*"
            inputMode="numeric"
            maxLength={6}
            minLength={6}
            ref={pin1Ref}
          />
        </div>
        <div>
          <label>
            {t('New PIN (confirm)')}
          </label>
          <input
            type="password"
            value={pin2}
            onChange={(e) => onEntryChange(e.target.value, setPin2)}
            pattern="[0-9]*"
            inputMode="numeric"
            maxLength={6}
            minLength={6}
          />
        </div>
        <div
          className="error"
        >
          {error}
        </div>
      </form>
    </Modal>
  );
};

export default PINManagement;