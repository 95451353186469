import React, { useEffect, useRef } from "react";
import "./Step.scoped.scss";
import { useDispatch } from "react-redux";
import useSelector from "store/useSelector";
import { QuestionResponse } from "interfaces/execution/executionInterfaces";
import StepCompletionIcon, { StepCompletionIcons } from "components/execution/sw/steps/StepCompletionIcon";
import { setCurrentExecutionStep } from "store/execution/executionActions";
import { IStep, SWTypes } from "interfaces/sw/SWInterfaces";
import StepBody from "./StepBody";
import { getStepResponse } from "utilities/swUtilities";

interface IStepProps {
  jobSWId: number,
  showCritical: boolean,
  step: IStep,
  focusSWHeader?(): void,
}

const Step: React.FC<IStepProps> = ({
  jobSWId,
  step,
  showCritical,
  focusSWHeader,
}) => {
  const {
    currentLocation,
    stepResponses,
  } = useSelector(store => store.execution);
  const sws = useSelector(store => store.execution.job?.sws);

  const dispatch = useDispatch();
  const headerRef = useRef<HTMLLIElement>(null);
  let stepResponse = getStepResponse({
    jobSWId,
    stepId: step.id,
  }, stepResponses);

  const currJobSWId = currentLocation?.jobSWId;
  const currStepId = currentLocation?.stepId;
  const currSW = sws?.find(x => x.jobSWId === currJobSWId);

  const isCurrStep = currJobSWId === jobSWId
    && currStepId === step.id;

  const isFirstStep = currStepId === currSW?.steps[0].id;

  useEffect(() => {
    if (isFirstStep
      && focusSWHeader) {
      focusSWHeader();
    } else if (headerRef.current
      && isCurrStep) {
      headerRef.current.scrollIntoView();
    }
  }, [isCurrStep, isFirstStep, focusSWHeader]);

  const onStepClick = () => {
    dispatch(setCurrentExecutionStep({
      jobSWId,
      stepId: step.id,
    }));
  }

  const titleClassName = "step-title " + (isCurrStep ? "current" : "");

  let stepIcon = StepCompletionIcons.Empty;

  if (stepResponse?.isComplete) {
    if (stepResponse.questionResponse === QuestionResponse.Pass) {
      stepIcon = StepCompletionIcons.GreenCheck;
    } else if (stepResponse.questionResponse === QuestionResponse.Fail) {
      stepIcon = StepCompletionIcons.RedX;
    } else if (stepResponse.isNA) {
      stepIcon = StepCompletionIcons.NotApplicable;
    } else if (stepResponse?.isDeviation)
    {
      stepIcon = StepCompletionIcons.RedCheck
    }
     else {
      stepIcon = StepCompletionIcons.GreenCheck;
    }
  } 
  else if (stepResponse?.isDeviation) {
    stepIcon = StepCompletionIcons.RedIncomplete;
  }
  else {
    stepIcon = StepCompletionIcons.Empty;
  }

  let subSteps = null;

  if (step.children.length) {
    let subStepListClassName = "";

    if (step.isConditional) {
      // Check the conditional response to see if substeps should be disabled.
      if (!stepResponse?.conditionalResponse) {
        subStepListClassName = "conditional-disabled";
      }
    }

    subSteps = (
      <ul className={subStepListClassName}>
        {step.children.map(subStep =>
          subStep.showStep &&
          <Step
            step={subStep}
            jobSWId={jobSWId}
            showCritical={showCritical}
            key={subStep.id}
          />
        )}
      </ul>
    );
  }

  return (
    <li
      ref={headerRef}
    >
      <span
        onClick={onStepClick}
        className={titleClassName}>
        <StepCompletionIcon
          icon={stepIcon}
        />
        <span
          className="text"
        >
          {step.number}. {step.isMemoryStep ? "*" : undefined} {step.title}
        </span>        
      </span>
      {(isCurrStep || (currSW?.type === SWTypes.CL || currSW?.type === SWTypes.LCL)) &&
        <StepBody
          jobSWId={jobSWId}
          step={step}
        />
      }
      {subSteps}
    </li>
  );
}

export default Step;