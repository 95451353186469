import React from "react";
import "./NoticeList.scoped.scss";
import { INotice, StepComponentTypes } from "interfaces/sw/SWInterfaces";
import Notice from "./steps/components/Notice";

interface INoticeListProps {
  notices: INotice[],
  swId: string,
  swVersion: string,
}

const NoticeList: React.FC<INoticeListProps> = ({ notices, swId, swVersion }) =>
  <div
    className="notice-list"
  >
    {notices.map(notice => (
      <Notice
        key={notice.id}
        notice={{
          label: notice.text,
          sortOrder: notice.sortOrder,
          id: notice.id,
          type: StepComponentTypes.Notice,
          data: {
            type: notice.type,
            filename: notice.filename,
          },
        }}
        swId={swId}
        swVersion={swVersion}
      />
    ))}
  </div>;

export default NoticeList;