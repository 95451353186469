import React from "react";

interface IExecutionLoadErrorsProps {
  errorMessages: string[],
}

const ExecutionLoadErrors: React.FC<IExecutionLoadErrorsProps> = ({ errorMessages }) =>
  <>
    {errorMessages.map((err, ix) =>
      <p key={ix}>
        {err}
      </p>
    )}
  </>;

export default ExecutionLoadErrors;