import { createAction } from "@reduxjs/toolkit";
import { IAddUserModalData, IManageUsersData, IEditUserData } from "./userManagementTypes";
import { Roles, IManageUserUser, IAADUser, UserJobsTabs } from "interfaces/user/UserInterfaces";
import { IOperation } from "interfaces/operations/OperationInterfaces";
import { ISWPJobHeader } from "interfaces/jobs/JobInterfaces";

export const resetManageUsersData = createAction("userManagement/resetManageUsersData");
export const resetAddUserModalData = createAction("userManagement/resetAddUserModalData");
export const resetEditUserData = createAction("userManagement/resetEditUserData");

export const toggleAddUserModal = createAction<boolean>("userManagement/toggleAddUserModal");
export const getManageUsersList = createAction("userManagement/getManageUsersList");
export const setAddUserSearchText = createAction<string>("userManagement/setAddUserSearchText");
export const setAddUserModalData = createAction<IAddUserModalData>("userManagement/setAddUserModalData");
export const setManageUsersData = createAction<IManageUsersData>("userManagement/setManageUsersData");
export const setSearchManageUsersText = createAction<string>("userManagement/setSearchManageUsersText");

export const getUserForEdit = createAction<string>("userManagement/getUserForEdit");
export const setEditUserData = createAction<IEditUserData>("userManagement/setEditUserData");
export const setUsersJobsOperation = createAction<IOperation>("userManagement/setUsersJobsOperation");
export const toggleRole = createAction<Roles>("userManagement/toggleRole");
export const createUser = createAction<{
  user: IAADUser,
  refreshManageUsersList: boolean,
}>("userManagement/createUser");
export const updateUser = createAction<IManageUserUser>("userManagement/updateUser");

export const setUsersJobsTab = createAction<UserJobsTabs>("userManagement/setUsersJobsTab");
export const getUsersJobs = createAction("userManagement/getUsersJobs");
export const setUsersJobs = createAction<ISWPJobHeader[]>("userManagement/setUsersJobs");
export const setJobSearchTerm = createAction<string>("userManagement/setJobSearchTerm");