import React from "react";
import Portal from "components/layout/Portal";
import "./ImageModal.scoped.scss";

interface IImageModalProps {
  title?: string,
  imgSrc: string,
  fileName: string,
  imgBgColor?: string,
  onClose(): void,
}

const ImageModal: React.FC<IImageModalProps> = ({
  title,
  imgSrc,
  fileName,
  imgBgColor,
  onClose,
}) => {
  const openPDFFile = (fileUrl: string) => {
    const mfurl = fileUrl.replace("data:application/pdf;base64,", "");
    var byteCharacters = atob(mfurl);
    var byteNumbers = new Array(byteCharacters.length);
    for (var i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    var byteArray = new Uint8Array(byteNumbers);
    var file = new Blob([byteArray], {
      type: 'application/pdf;base64'
    });
    var fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  }
  return (
    <Portal>
      <div
        className="dark-fade"
      >
      </div>
      <div
        className="img-modal"
      >
        <div
          className="title"
        >
          <span>
            {title}
          </span>
          <span
            className="close-button"
            onClick={onClose}
          >
            X
          </span>
        </div>
        <div
          className="img-holder"
        >
          {fileName.slice(fileName.lastIndexOf(".") + 1).toUpperCase() !== "PDF"
            && <img
              src={imgSrc}
              alt=""
              style={imgBgColor
                ? {
                  backgroundColor: imgBgColor,
                } : undefined
              }
            />}
          {fileName.slice(fileName.lastIndexOf(".") + 1).toUpperCase() === "PDF"
            && <div className="pdfViewer">
              File Name :<button className="" onClick={() => openPDFFile(imgSrc)}>{fileName}</button>
            </div>}
        </div>
      </div>
    </Portal>
  )
};

export default ImageModal;