import React, { useEffect, useState } from "react";
import filterIcon from "media/icons/dls/filter.svg";
import "./SearchBar.scoped.scss";
import { useDispatch } from "react-redux";
import { addFilterItem, applyFilter, clearFilter, removeFilterItem, setSearchText, setStringFilter } from "store/manageJob/manageJobActions";
import useSelector from "store/useSelector";
import FilterModal from "../../../common/FilterModal";
import ItemPicker from "components/common/itemPicker/ItemPicker";
import { IMasterDataItem } from "interfaces/masterData/masterDataInterfaces";
import {
  loadCountries,
  loadGeoUnits,
  loadLanguages,
  loadOrganizations,
  loadSafetyCategories,
  searchActivityWorkflows,
  searchBusinessRoles,
  searchCompetencyElements,
  searchEpicEquipment,
  searchEquipment,
  searchEquivalentSWs,
  searchOperatingEnvironments,
  searchProductCenters,
  searchServiceTypes,
  searchTasks,
  searchCustomers,
  loadSubBusinessLines,
  searchMaterials,
  searchWorkCenters,
  loadOwningPlants,
  loadPerformingPlants,
  clearSearchedMasterDataItems,
} from "store/masterData/masterDataActions";
import { MetaDataNames } from "store/masterData/masterDataTypes";
import searchIcon from "media/icons/dls/search.svg";
import { useTranslation } from "react-i18next";
import { Connectivity } from "interfaces/execution/executionInterfaces";
import Banner, { BannerType } from "components/common/Banner";
import { getFilterCount } from "utilities/filteringUtilities";

const SearchBar: React.FC = () => {
  const {
    activityWorkflows,
    competencyElements,
    countries,
    equipment,
    epicEquipment,
    customers,
    productCenters,
    businessRoles,
    equivalentSWs,
    languages,
    operatingEnvironments,
    organizations,
    subBusinessLines,
    safetyCategories,
    geoUnits,
    serviceTypes,
    tasks,
    taskAlignments,
    types,
    globalOrLocal,
    owningPlants,
    performingPlants,
    workCenters,
    materials,
  } = useSelector(store => store.masterData);

  const {
    searchSWData: {
      filterFields,
      swData: {
        loadOperation,
      }
    },
  } = useSelector(store => store.manageJob);

  const {
    isOnline,
  } = useSelector(store => store.offline);

  const dispatch = useDispatch();
  const { t } = useTranslation('createJob');
  const [searchTerm, setSearchTerm] = useState(filterFields.searchTerm);
  const [isModalOpen, setIsModalOpen] = useState(false);


  const onAddItem = (item: IMasterDataItem, name: MetaDataNames) => {
    dispatch(
      addFilterItem({
        metaDataName: name,
        item,
      })
    );
  }

  const onRemoveItem = (item: IMasterDataItem, name: MetaDataNames) => {
    dispatch(
      removeFilterItem({
        metaDataName: name,
        item,
      })
    );
  }

  const clearSearchFilter = () => {
    dispatch(clearFilter());
    if (searchTerm === "") {
      dispatch(applyFilter());
    }
    else {
      setSearchTerm("");
    }
    dispatch(clearSearchedMasterDataItems()); 
  }
    const searchSW = () => {
        dispatch(setSearchText(searchTerm.trim()))
    }
  useEffect(() => {
    dispatch(setSearchText(searchTerm.trim()));
  }, [ dispatch]);

  const numFilters = getFilterCount(filterFields, ["searchTerm"]);

  return (
    <>
      {isOnline === Connectivity.Offline &&
        <Banner type = {BannerType.Warn}>
          Search is not available when offline.
        </Banner>
      }
      <div className="search-bar">     
        <input
          type="text"
          id="sw-search"
          placeholder={t("Search by title or description...")}
          disabled={loadOperation?.isWorking || isOnline === Connectivity.Offline}
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
          onKeyPress={e => e.key === "Enter" ? searchSW() : null }
          autoFocus
              />
          <button className="secondary-button" type="submit" onClick={searchSW}  >
             <img
                src={searchIcon}
                alt="Search"
                className="icon-small" />
         </button>
        <span
          className="filter-button hover-gray-bg"
          onClick={() => {
            dispatch(clearSearchedMasterDataItems()); 
            setIsModalOpen(true)
          }}
        >
          <img
            src={filterIcon}
            alt={t("Filters")}
            className="icon-medium"
            title={t("Filters")}
          />
          {numFilters > 0 &&
            <label className="filter-count-label">
              {numFilters}
            </label>
          }
        </span>
      </div>
      {isModalOpen &&
        <FilterModal
          applyFilter={() =>
            {
              dispatch(applyFilter()); 
              dispatch(clearSearchedMasterDataItems());
            } 
          }
          clearFilter={clearSearchFilter}
          setIsModalOpen={setIsModalOpen}
          filterControls={
            <>
              <div>
                <ItemPicker
                  label="Types"
                  allowMultiple={true}
                  selectedItems={filterFields.typesFilter}
                  masterDataSection={types}
                  loadItems={() => void 0}
                  onAddItem={(item: IMasterDataItem) => onAddItem(item, "types")}
                  onRemoveItem={(item: IMasterDataItem) => onRemoveItem(item, "types")}
                  itemFormatter={(item: IMasterDataItem) => item.value}
                />
              </div>
              <div>
                <ItemPicker
                  label="Global or Local"
                  allowMultiple={true}
                  selectedItems={filterFields.globalOrLocalFilter}
                  masterDataSection={globalOrLocal}
                  loadItems={() => void 0}
                  onAddItem={(item: IMasterDataItem) => onAddItem(item, "globalOrLocal")}
                  onRemoveItem={(item: IMasterDataItem) => onRemoveItem(item, "globalOrLocal")}
                  itemFormatter={(item: IMasterDataItem) => item.value}
                />
              </div>
              <div>
                <ItemPicker
                  label="Task Alignments"
                  allowMultiple={true}
                  selectedItems={filterFields.alignmentsFilter}
                  masterDataSection={taskAlignments}
                  loadItems={() => void 0}
                  onAddItem={(item: IMasterDataItem) => onAddItem(item, "taskAlignments")}
                  onRemoveItem={(item: IMasterDataItem) => onRemoveItem(item, "taskAlignments")}
                  itemFormatter={(item: IMasterDataItem) => item.value}
                />
              </div>
              <div>
                <ItemPicker
                  label="Language"
                  selectedItems={filterFields.languagesFilter}
                  allowMultiple={true}
                  onAddItem={(item: IMasterDataItem) => onAddItem(item, "languages")}
                  onRemoveItem={(item: IMasterDataItem) => onRemoveItem(item, "languages")}
                  loadItems={() => dispatch(loadLanguages())}
                  masterDataSection={languages}
                  itemFormatter={(item: IMasterDataItem) => item.value}
                />
              </div>
              <div>
                <ItemPicker
                  label={"Equivalent SW"}
                  isAsyncSearch={true}
                  selectedItems={filterFields.equivalentSWsFilter}
                  allowMultiple={true}
                  onAddItem={(item: IMasterDataItem) => onAddItem(item, "equivalentSWs")}
                  onRemoveItem={(item: IMasterDataItem) => onRemoveItem(item, "equivalentSWs")}
                  loadItems={(searchTerm: string) => dispatch(searchEquivalentSWs(searchTerm))}
                  masterDataSection={equivalentSWs}
                  itemFormatter={(item: IMasterDataItem) => (
                    <span>
                      {item.value}
                      <br />
                      {item.code}
                    </span>
                  )}
                />
              </div>
              <div>
                <ItemPicker
                  label={"Tasks"}
                  isAsyncSearch={true}
                  selectedItems={filterFields.tasksFilter}
                  allowMultiple={true}
                  onAddItem={(item: IMasterDataItem) => onAddItem(item, "tasks")}
                  onRemoveItem={(item: IMasterDataItem) => onRemoveItem(item, "tasks")}
                  loadItems={(searchTerm: string) => dispatch(searchTasks(searchTerm))}
                  masterDataSection={tasks}
                  itemFormatter={(item: IMasterDataItem) => item.value}
                />
              </div>
              <div>
                <ItemPicker
                  label={"Activity Workflows"}
                  isMandatory={false}
                  isAsyncSearch={true}
                  selectedItems={filterFields.activityWorkflowsFilter}
                  allowMultiple={true}
                  onAddItem={(item: IMasterDataItem) => onAddItem(item, "activityWorkflows")}
                  onRemoveItem={(item: IMasterDataItem) => onRemoveItem(item, "activityWorkflows")}
                  loadItems={(searchTerm: string) => dispatch(searchActivityWorkflows(searchTerm))}
                  masterDataSection={activityWorkflows}
                  itemFormatter={(item: IMasterDataItem) => (
                    <span>
                      {item.value}
                      <br />
                      {item.code}
                    </span>
                  )}
                />
              </div>
              <div>
                <ItemPicker
                  label={"Customers"}
                  isAsyncSearch={true}
                  loadOnStart={true}
                  selectedItems={filterFields.customers}
                  allowMultiple={true}
                  onAddItem={(item: IMasterDataItem) => onAddItem(item, "customers")}
                  onRemoveItem={(item: IMasterDataItem) => onRemoveItem(item, "customers")}
                  loadItems={(searchTerm: string) => dispatch(searchCustomers({ searchTerm: searchTerm, includeAll: false }))}
                  masterDataSection={customers}
                  itemFormatter={(item: IMasterDataItem) => item.value}
                />
              </div>
              <div>
                <ItemPicker
                  label="Owning Organizations"
                  allowMultiple={true}
                  loadOnStart={true}
                  selectedItems={filterFields.owningOrgsFilter}
                  masterDataSection={organizations}
                  loadItems={() => dispatch(loadOrganizations())}
                  onAddItem={(item: IMasterDataItem) => onAddItem(item, "owningOrgs")}
                  onRemoveItem={(item: IMasterDataItem) => onRemoveItem(item, "owningOrgs")}
                  itemFormatter={(item: IMasterDataItem) =>
                    `${item.code} - ${item.value}`
                  }
                />
              </div>
              <div>
                <ItemPicker
                  label="Sub BusinessLines"
                  allowMultiple={true}
                  loadOnStart={true}
                  selectedItems={filterFields.subBusinessLinesFilter}
                  masterDataSection={subBusinessLines}
                  loadItems={() => dispatch(loadSubBusinessLines())}
                  onAddItem={(item: IMasterDataItem) => onAddItem(item, "subBusinessLines")}
                  onRemoveItem={(item: IMasterDataItem) => onRemoveItem(item, "subBusinessLines")}
                  itemFormatter={(item: IMasterDataItem) =>
                    `${item.code} - ${item.value}`
                  }
                />
              </div>
              <div>
                <ItemPicker
                  label="Performing Organizations"
                  allowMultiple={true}
                  loadOnStart={true}
                  selectedItems={filterFields.performingOrgsFilter}
                  masterDataSection={organizations}
                  loadItems={() => dispatch(loadOrganizations())}
                  onAddItem={(item: IMasterDataItem) => onAddItem(item, "performingOrgs")}
                  onRemoveItem={(item: IMasterDataItem) => onRemoveItem(item, "performingOrgs")}
                  itemFormatter={(item: IMasterDataItem) =>
                    `${item.code} - ${item.value}`
                  }
                />
              </div>
              <div>
                <ItemPicker
                  label={"Service Type"}
                  isAsyncSearch={true}
                  selectedItems={filterFields.serviceTypesFilter}
                  allowMultiple={true}
                  onAddItem={(item: IMasterDataItem) => onAddItem(item, "serviceTypes")}
                  onRemoveItem={(item: IMasterDataItem) => onRemoveItem(item, "serviceTypes")}
                  loadItems={(searchTerm: string) => dispatch(searchServiceTypes(searchTerm))}
                  masterDataSection={serviceTypes}
                  itemFormatter={(item: IMasterDataItem) => item.value}
                />
              </div>
              <div>
                <ItemPicker
                  label={"Operating Environments"}
                  isAsyncSearch={true}
                  selectedItems={filterFields.operatingEnvironmentsFilter}
                  allowMultiple={true}
                  onAddItem={(item: IMasterDataItem) => onAddItem(item, "operatingEnvironments")}
                  onRemoveItem={(item: IMasterDataItem) => onRemoveItem(item, "operatingEnvironments")}
                  loadItems={(searchTerm: string) => dispatch(searchOperatingEnvironments(searchTerm))}
                  masterDataSection={operatingEnvironments}
                  itemFormatter={(item: IMasterDataItem) => item.value}
                />
              </div>
              <div>
                <ItemPicker
                  label={"Equipment"}
                  isAsyncSearch={true}
                  selectedItems={filterFields.equipmentFilter}
                  allowMultiple={true}
                  onAddItem={(item: IMasterDataItem) => onAddItem(item, "equipment")}
                  onRemoveItem={(item: IMasterDataItem) => onRemoveItem(item, "equipment")}
                  loadItems={(searchTerm: string) => dispatch(searchEquipment(searchTerm))}
                  masterDataSection={equipment}
                  itemFormatter={(item: IMasterDataItem) => item.value}
                />
              </div>
              <div>
                <ItemPicker
                  label="Countries"
                  allowMultiple={true}
                  loadOnStart={true}
                  selectedItems={filterFields.countriesFilter}
                  masterDataSection={countries}
                  loadItems={() => dispatch(loadCountries({ includeAll: false }))}
                  onAddItem={(item: IMasterDataItem) => onAddItem(item, "countries")}
                  onRemoveItem={(item: IMasterDataItem) => onRemoveItem(item, "countries")}
                  itemFormatter={(item: IMasterDataItem) => item.value}
                />
              </div>
              <div>
                <ItemPicker
                  label={"Competency Elements"}
                  isAsyncSearch={true}
                  selectedItems={filterFields.competencyElementsFilter}
                  allowMultiple={true}
                  onAddItem={(item: IMasterDataItem) => onAddItem(item, "competencyElements")}
                  onRemoveItem={(item: IMasterDataItem) => onRemoveItem(item, "competencyElements")}
                  loadItems={(searchTerm: string) => dispatch(searchCompetencyElements(searchTerm))}
                  masterDataSection={competencyElements}
                  itemFormatter={(item: IMasterDataItem) => item.value}
                />
              </div>
              <div>
                <ItemPicker
                  label={"Safety Categories"}
                  selectedItems={filterFields.safetyCategoriesFilter}
                  allowMultiple={true}
                  onAddItem={(item: IMasterDataItem) => onAddItem(item, "safetyCategories")}
                  onRemoveItem={(item: IMasterDataItem) => onRemoveItem(item, "safetyCategories")}
                  loadItems={() => dispatch(loadSafetyCategories())}
                  masterDataSection={safetyCategories}
                  itemFormatter={(item: IMasterDataItem) => item.value}
                />
              </div>
              <div>
                <ItemPicker
                  label={"Product Centers"}
                  isAsyncSearch={true}
                  selectedItems={filterFields.productCentersFilter}
                  allowMultiple={true}
                  onAddItem={(item: IMasterDataItem) => onAddItem(item, "productCenters")}
                  onRemoveItem={(item: IMasterDataItem) => onRemoveItem(item, "productCenters")}
                  loadItems={(searchTerm: string) => dispatch(searchProductCenters(searchTerm))}
                  masterDataSection={productCenters}
                  itemFormatter={(item: IMasterDataItem) => item.value}
                />
              </div>
              <div>
                <ItemPicker
                  label={"Business Roles"}
                  isAsyncSearch={true}
                  selectedItems={filterFields.businessRolesFilter}
                  allowMultiple={true}
                  onAddItem={(item: IMasterDataItem) => onAddItem(item, "businessRoles")}
                  onRemoveItem={(item: IMasterDataItem) => onRemoveItem(item, "businessRoles")}
                  loadItems={(searchTerm: string) => dispatch(searchBusinessRoles(searchTerm))}
                  masterDataSection={businessRoles}
                  itemFormatter={(item: IMasterDataItem) => item.value}
                />
              </div>
              <div>
                <label className="filterLabel">Maintenance Task</label>
                <input
                  type="text"
                  className="filterInput"
                  value={filterFields.maintenanceTaskIdsFilter}
                  onChange={(e) =>
                    dispatch(
                      setStringFilter({
                        attributeName: "maintenanceTaskIds",
                        value: e.target.value,
                      })
                    )
                  }
                />
              </div>
              <div>
                <ItemPicker
                  label={"EPIC Equipment"}
                  isAsyncSearch={true}
                  selectedItems={filterFields.epicEquipmentFilter}
                  allowMultiple={true}
                  onAddItem={(item: IMasterDataItem) => onAddItem(item, "epicEquipment")}
                  onRemoveItem={(item: IMasterDataItem) => onRemoveItem(item, "epicEquipment")}
                  loadItems={(searchTerm: string) => dispatch(searchEpicEquipment(searchTerm))}
                  masterDataSection={epicEquipment}
                  itemFormatter={(item: IMasterDataItem) => item.value}
                />
              </div>
              <div>
                <ItemPicker
                  label={"GeoUnits"}
                  selectedItems={filterFields.geoUnitsFilter}
                  allowMultiple={true}
                  onAddItem={(item: IMasterDataItem) => onAddItem(item, "geoUnits")}
                  onRemoveItem={(item: IMasterDataItem) => onRemoveItem(item, "geoUnits")}
                  loadItems={() => dispatch(loadGeoUnits({
                    includeAll: true,
                  }))}
                  masterDataSection={geoUnits}
                  itemFormatter={(item: IMasterDataItem) => item.value}
                />
              </div>
              <div>
                <ItemPicker
                  label={"Owning Plants"}
                  selectedItems={filterFields.owningPlants}
                  allowMultiple={true}
                  onAddItem={(item: IMasterDataItem) => onAddItem(item, "owningPlants")}
                  onRemoveItem={(item: IMasterDataItem) => onRemoveItem(item, "owningPlants")}
                  loadItems={() => dispatch(loadOwningPlants())}
                  masterDataSection={owningPlants}
                  itemFormatter={(item: IMasterDataItem) => item.value}
                />
              </div>
              <div>
                <ItemPicker
                  label={"Performing Plants"}
                  selectedItems={filterFields.performingPlants}
                  allowMultiple={true}
                  onAddItem={(item: IMasterDataItem) => onAddItem(item, "performingPlants")}
                  onRemoveItem={(item: IMasterDataItem) => onRemoveItem(item, "performingPlants")}
                  loadItems={() => dispatch(loadPerformingPlants())}
                  masterDataSection={performingPlants}
                  itemFormatter={(item: IMasterDataItem) => item.value}
                />
              </div>
              <div>
                <ItemPicker
                  label={"Work Centers"}
                  isAsyncSearch={true}
                  selectedItems={filterFields.workCenters}
                  allowMultiple={true}
                  onAddItem={(item: IMasterDataItem) => onAddItem(item, "workCenters")}
                  onRemoveItem={(item: IMasterDataItem) => onRemoveItem(item, "workCenters")}
                  loadItems={(searchTerm: string) => dispatch(searchWorkCenters(searchTerm))}
                  masterDataSection={workCenters}
                  itemFormatter={(item: IMasterDataItem) => item.value}
                />
              </div>
              <div>
                <ItemPicker
                  label={"Materials"}
                  isAsyncSearch={true}
                  selectedItems={filterFields.materials}
                  allowMultiple={true}
                  onAddItem={(item: IMasterDataItem) => onAddItem(item, "materials")}
                  onRemoveItem={(item: IMasterDataItem) => onRemoveItem(item, "materials")}
                  loadItems={(searchTerm: string) => dispatch(searchMaterials(searchTerm))}
                  masterDataSection={materials}
                  itemFormatter={(item: IMasterDataItem) => item.value}
                />
              </div>
            </>
          }
        />
      }
    </>
  );
}


export default SearchBar;