import React from "react";
import UserRow from "./UserRow";
import "./UserList.scoped.scss";
import useSelector from "store/useSelector";
import BlockSpinner from "components/common/BlockSpinner";
import FlowLayout from "components/layout/FlowLayout";
import { useTranslation } from "react-i18next";

const UserList: React.FC = () => {
  const { t } = useTranslation('userSearch');
  const { getUsersOperation, userList } = useSelector(store => store
    .userManagement
    .manageUsersData);

  let component: JSX.Element | undefined;
  let rowHeaderComponent: JSX.Element | undefined;

  if (getUsersOperation?.isWorking) {
    component = (
      <BlockSpinner />
    );
  } else if (getUsersOperation?.errorMessage) {
    component = (
      <label className="error">
        {getUsersOperation.errorMessage}
      </label>
    );
  } else if (!userList.length) {
    component = (
      <label>{t('No users found.')}</label>
    );
  } else {
    component = (
      <div className="list-body">
        {userList.map(u =>
          <UserRow
            user={u}
            key={u.email}
          />
        )}
      </div>
    );
    rowHeaderComponent = (
      <div className="row header">
        <div className="cell name">
          {t('User')}
        </div>
        <div className="cell roles">
          {t('Roles')}
        </div>
      </div>
    );
  }

  return (
    <FlowLayout
      header={rowHeaderComponent}
    >
      {component}
    </FlowLayout>
  );
}

export default UserList;