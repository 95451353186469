import IdbApi from "apis/idb/IdbApi";
import Banner, { BannerType } from "components/common/Banner";
import Modal from "components/common/Modal";
import { Connectivity } from "interfaces/execution/executionInterfaces";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { IAuthState } from "store/auth/authTypes";
import { showErrorToast, showSuccessToast } from "store/toast/toastActions";
import useSelector from "store/useSelector";
import "./OfflineDataReport.scoped.scss";

interface IOfflineDataReport {
  onClose(): void,
}

const OfflineDataReport: React.FC<IOfflineDataReport> = ({
  onClose,
}) => {
  const {
    offline: {
      isOnline,
    },
    auth,
  } = useSelector(x => x);
  const [cacheSize, setCacheSize] = useState<undefined | StorageEstimate>(undefined);
  const dispatch = useDispatch();
  const deviceInfoRef = useRef<HTMLTextAreaElement>(null);
  const { t } = useTranslation('dataReport');

  useEffect(() => {
    let aborted = false;

    if (navigator.storage
      && navigator.storage.estimate) {
      navigator
        .storage
        .estimate()
        .then(x => {
          if (!aborted) {
            setCacheSize(x);
          }
        });
    };

    return () => {
      aborted = true;
    };
  }, [setCacheSize]);

  const getDeviceInfoText = () => {
    let data: {
      userAgent: string,
      reduxNetworkStatus: Connectivity,
      cacheInfo: StorageEstimate | null,
      localStorage: any,
      authContents: IAuthState,
    } = {
      userAgent: "",
      reduxNetworkStatus: isOnline,
      cacheInfo: null,
      localStorage: null,
      authContents: auth,
    }

    data.userAgent = navigator.userAgent;

    if (cacheSize) {
      data.cacheInfo = cacheSize;
    }

    data.localStorage = getLocalStorageProps();

    return JSON.stringify(data);
  }

  const getLocalStorageProps = (): any => {
    let data: any = {};

    for (let i = 0; i < localStorage.length; i++) {
      let key = localStorage.key(i);
      if (key) {
        data[key] = localStorage.getItem(key);
      }
    }

    return data;
  }

  const exportIdb = async (includeImages: boolean) => {
    try {
      const json = await IdbApi.exportToJson(includeImages);

      download(`workright-offline-data.json`, json);
    } catch (err: any) {
      dispatch(showErrorToast(err.toString()));
    }
  }

  function download(filename: string, text: string) {
    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  const copyDeviceInfo = () => {
    if (deviceInfoRef.current) {
      deviceInfoRef.current.select();
      document.execCommand('copy');
      dispatch(showSuccessToast(t("Copied to clipboard!")));
    }
  }

  return (
    <Modal
      isOpen={true}
      header={t("Device Info and Offline Data")}
      controls={(
        <button
          className="primary-button"
          onClick={onClose}
        >
          {t('Close')}
        </button>
      )}
    >
      <div
        className="modal-body"
      >
        <h3>{t('Device info')}</h3>
        <Banner
          type={BannerType.Warn}
        >
          {t('Warning The following device information contains sensitive information.')}
        </Banner>
        <textarea
          value={getDeviceInfoText()}
          readOnly={true}
          ref={deviceInfoRef}
        />
        <button
          className="primary-button"
          onClick={copyDeviceInfo}
        >
          {t('Copy Device Info to Clipboard')}
        </button>
        <h3>{t('Export Offline Data')}</h3>
        <div>
          <button
            className="primary-button"
            onClick={() => exportIdb(true)}
          >
            {t('Export With Images')}
          </button>
          &nbsp;
          <button
            className="primary-button"
            onClick={() => exportIdb(false)}
          >
            {t('Export Without Images')}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default OfflineDataReport;