import { setCurrentUser, setLoadOperation, setSetMyPINOperation, setTCCInfo, setTCCInfoOperation, setMyCountry, setMyOrg, setMyPreferenceOperation, setMyGeoUnit, setMyCustomer, setMySubBusinessLine } from "./authActions";
import { createReducer } from "@reduxjs/toolkit";
import { IAuthState } from "./authTypes";

const initialState: IAuthState = {
  currentUser: {
    email: "",
    name: "Unknown User",
    roles: [],
    myCountry: undefined,
    myOrganization: undefined,
    myGeoUnit: undefined,
    myCustomer: undefined,
    mySubBusinessLine: undefined,
    hasOfflinePIN: undefined,
  },
  loadOperation: undefined,
  setMyPINOperation: undefined,
};

const authReducer = createReducer(initialState, builder => {
  // Set Load Operation
  builder.addCase(setLoadOperation, (state, action) => {
    state.loadOperation = action.payload;
  });

  // Set Current User
  builder.addCase(setCurrentUser, (state, action) => {
    state.currentUser = action.payload;
  });

  // Set TCC Info
  builder.addCase(setTCCInfo, (state, action) => {
    state.currentUser.tccInfo = action.payload;
  });

  // Set Default Country
  builder.addCase(setMyCountry, (state, action) => {
    state.currentUser.myCountry = action.payload;
  });

  // Set Default Organization
  builder.addCase(setMyOrg, (state, action) => {
    state.currentUser.myOrganization = action.payload;
  });

  // Set Default GeoUnit
  builder.addCase(setMyGeoUnit, (state, action) => {
    state.currentUser.myGeoUnit = action.payload;
  });

  // Set Default Customer
  builder.addCase(setMyCustomer, (state, action) => {
    state.currentUser.myCustomer = action.payload;
  });

  // Set Default sub Businessline
  builder.addCase(setMySubBusinessLine, (state, action) => {
    state.currentUser.mySubBusinessLine = action.payload;
  });

  // Set TCC Info Operation
  builder.addCase(setTCCInfoOperation, (state, action) => {
    if (state.currentUser.tccInfo) {
      state.currentUser.tccInfo.loadOperation = action.payload
    }
  });

  // Set My PIN
  builder.addCase(setSetMyPINOperation, (state, { payload }) => {
    state.setMyPINOperation = payload;
  });

  // Set My Preference Operation
  builder.addCase(setMyPreferenceOperation, (state, { payload }) => {
    state.setMyPreferenceOperation = payload;
  });

});

export default authReducer;