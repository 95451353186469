import React from "react";
import "./JobDocsForm.scoped.scss";
import useSelector from "store/useSelector";
import { useDispatch } from "react-redux";
import { addJobDocs, getJobDocPdf, removeJobDocs } from "store/manageJob/manageJobActions";
import { filesFilter, isFilePDF, validateAndReadFile } from "utilities/fileUtilities";
import removeIcon from "media/icons/dls/delete.svg";
import { showErrorToast } from "store/toast/toastActions";
import ModalSpinner from "components/common/ModalSpinner";

const JobDocsForm: React.FC = () => {
  const {
    job: {
      jobDocs,
    },
    jobDocPdfOperation
  } = useSelector(state => state.manageJob);
  
  const dispatch = useDispatch();
  let pdfFileInputElement: HTMLInputElement | null = null;

  const showChoosePDFFile = () => {
    pdfFileInputElement?.click();
  }

  const onFileChosen = async (event: React.FormEvent<HTMLInputElement>) => {
    let tgt = event.target as HTMLInputElement;

    if (!tgt || !tgt.files || !tgt.files.length) {
      return;
    }

    try {
      const fileData = await validateAndReadFile(tgt.files[0]);

      //Verify file uploaded is PDF only
      if (!isFilePDF(fileData.filename)) {
        dispatch(showErrorToast("Only PDF file can be uploaded"));
        return;
      }

      //alert(tgt.files[0].size);20971520
      const totalSize = jobDocs.reduce((sum, current) => sum + current.fileSize, 0);
      if (totalSize + tgt.files[0].size > 20971520) {
        dispatch(showErrorToast("You cannot add more than 20 mb of Files"));
      } else {
        dispatch(addJobDocs({
          fileName: fileData.filename,
          fileSize: tgt.files[0].size,
          fileContent: fileData.dataUri,
        }));
      }
      tgt.value = "";
    } catch (err: any) {
      dispatch(showErrorToast(err.message || err));
    }
  }

  const removeJobDoc = (filename: string) => {
    dispatch(removeJobDocs({ filename: filename }));
  }

  const openPDF = (fileUrl: string) => {
    if (fileUrl.startsWith("data:")) {
      const mfurl = fileUrl.replace("data:application/pdf;base64,", "");
      var byteCharacters = atob(mfurl);
      var byteNumbers = new Array(byteCharacters.length);
      for (var i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      var file = new Blob([byteArray], {
        type: 'application/pdf;base64'
      });
      var fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    } else {
      dispatch(getJobDocPdf({fileUrl: fileUrl}))
    }
  }

  return (
    <>
      <div className="divItems">
        <input
          type="file"
          accept={filesFilter}
          ref={(input) => { pdfFileInputElement = input }}
          onChange={onFileChosen}
        />
        <button
          className="primary-button pdfButton"
          title="Upload Image"
          onClick={showChoosePDFFile}
        >
          +Add PDF
        </button>
        {jobDocs.map(x => (
          <div
            className={`sw-row input`}
            key={x.fileName}>
            <div
              className="sw-info"
              key={x.fileName}
            >
              <div
                className="title"
                key={x.fileName}
                onClick={() => openPDF(x.fileContent)}
              >
                {x.fileName}
              </div>
            </div>
            <span
              className={`toggle-sw-button input Remove`}
              title="Remove"
              onClick={() => removeJobDoc(x.fileName)}
            >
              <img
                className="icon-small"
                src={removeIcon}
                alt="Remove File"
              />
            </span>

          </div>
        ))}
         {(jobDocPdfOperation?.isWorking) &&
          <ModalSpinner />
          }
      </div>
    </>
  );
}

export default JobDocsForm;