import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { IPassFailComponentData, IStepComponent, StepComponentTypes } from "interfaces/sw/SWInterfaces";
import { logJobAction, setComponentResponse } from "store/execution/executionActions";
import { ComponentResponseType } from "interfaces/execution/executionInterfaces";
import useSelector from "store/useSelector";
import ToggleButtonInput from "components/execution/sw/controls/ToggleButtonInput";
import { IStepIdentifier } from "store/execution/executionTypes";
import { getComponentResponse } from "utilities/swUtilities";
import Banner, { BannerType } from "components/common/Banner";

interface IPassFailInputProps {
    component: IStepComponent,
    isDisabled: boolean,
    stepInfo: IStepIdentifier,
}

const PassFailInput: React.FC<IPassFailInputProps> = ({ component, isDisabled, stepInfo }) => {
    const dispatch = useDispatch();
    const {
        auth: {
            currentUser: {
                email,
                name,
            },
        },
        execution: {
            stepResponses,
            executor,
        },
    } = useSelector(store => store);

    const [isExpectedValue, setIsExpectedValue] = useState(true);

    if (component.type !== StepComponentTypes.PassFailInput) {
        return null;
    }

    const onValueChange = (value: string) => {
        setIsExpectedValue(true);
        if (isDisabled) {
            return;
        }

        let isDeviation = false;

        if (expectedValue !== undefined) {
            if (value !== expectedValue) {
                setIsExpectedValue(false);
                isDeviation = true;
            }
        }

        dispatch(setComponentResponse({
            response: {
                id: component.id,
                type: ComponentResponseType.PassFail,
                values: [value],
                isDeviation: isDeviation,
            },
            stepInfo,
            userEmail: executor?.email || email,
        }));

        if (isDeviation) {
            dispatch(logJobAction({
                action: `Component response for ${component.label} was completed with deviation.`,
                timestamp: new Date(),
                userName: executor?.name || name,
                userEmail: executor?.email || email,
            }));
        }
    }

    const compResponseValues = getComponentResponse(stepInfo,
        component.id,
        stepResponses)
        ?.values;


    let nonConform: string = "";
    let expectedValue: string | undefined = undefined;

    if (isPassFailData(component.data)) {
        nonConform = component.data.nonConform || "";
        expectedValue = component.data.expectedValue ? component.data.expectedValue : "Pass";
    }
    else {
        expectedValue = "Pass";
    }

    let shouldShowConformInfo = expectedValue !== undefined;

    let existingResponseNonConform = false;
    if (compResponseValues
        && (compResponseValues?.[0] !== ""
            && compResponseValues?.[0] !== undefined)) {
        let selectedValue = compResponseValues[0];

        if ((expectedValue !== undefined
            && selectedValue !== expectedValue)) {
            existingResponseNonConform = true;
        }
    }

    return (
        <div>
            {shouldShowConformInfo &&
                <Banner
                    type={BannerType.Info}
                >
                    <div className="conformInfo">
                        <label>Expected Value:&nbsp;</label>
                        <span>{expectedValue}</span>
                    </div>
                </Banner>
            }
            {shouldShowConformInfo
                && (!isExpectedValue
                    || existingResponseNonConform) &&
                <Banner
                    type={BannerType.Warn}
                >
                    {nonConform}
                </Banner>
            }
            <label>{component.label}</label>
            <ToggleButtonInput
                options={["Pass", "Fail"]}
                onAnswerChosen={value => onValueChange(value)}
                disabled={isDisabled}
                currentValue={compResponseValues?.[0]}
            />
        </div>
    );
}

export default PassFailInput;

function isPassFailData(data: any): data is IPassFailComponentData {
    return data !== null
        && data !== undefined
        && ('expectedValue' in data || 'nonConform' in data);
}