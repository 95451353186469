import { IJobTemplatesState } from "./jobTemplatesTypes";
import { createReducer } from "@reduxjs/toolkit";
import {
  addCountryFilter,
  addOrgFilter,
  clearFilters,
  hydrateJobTemplateFilters,
  removeCountryFilter,
  removeOrgFilter,
  setCloneOperation,
  setHasOfflineError,
  setLoadOperation,
  setSearchTerm,
  setTemplates,
  setAttributePopup,
  setJobTemplateModal,
  setRetireTemplateOperation
} from "./jobTemplatesActions";

export const JOB_TEMPLATES_FILTER_KEY = 'MyJobsCompletedFilters';

const initialState: IJobTemplatesState = {
  templates: [],
  hasOfflineError: false,
  loadOperation: undefined,
  retireTemplateOperation: undefined,
  filters: {
    searchTerm: "",
    orgs: [],
    countries: [],
  },
  cloneOperation: undefined,
  attributePopup: {
    isOpen: false,
    country: undefined,
    geoUnit: undefined,
    subBusinessLine: undefined,
    template: undefined,
  },
  templateCacheModal: {
    isOpen: false,
    template: undefined,
  },
  templateUnCacheModal: {
    isOpen: false,
    template: undefined,
  },
  retireTemplateModal: {
    isOpen: false,
    template: undefined,
  },
}

const jobTemplatesReducer = createReducer(initialState, builder => {
  // Set Load Operation
  builder.addCase(setLoadOperation, (state, { payload }) => {
    state.loadOperation = payload;
  });

  // Set Clone Operation
  builder.addCase(setCloneOperation, (state, { payload }) => {
    state.cloneOperation = payload;
  });
  
  // Set Retire Template Operation
  builder.addCase(setRetireTemplateOperation, (state, { payload }) => {
    state.retireTemplateOperation = payload;
  });

  // Set Templates
  builder.addCase(setTemplates, (state, { payload }) => {
    state.templates = payload;
  });

  // Set Has Offline error
  builder.addCase(setHasOfflineError, (state, { payload }) => {
    state.hasOfflineError = payload;
  });

  // Hydrate Job Template Filters
  builder.addCase(hydrateJobTemplateFilters, (state) => {
    let json: any;
    try {
      const data = localStorage.getItem(JOB_TEMPLATES_FILTER_KEY);
      if (!data) {
        return;
      }
      json = JSON.parse(data);
    } catch {
      return;
    }

    if (json.searchTerm !== undefined
      && json.searchTerm !== null
      && Array.isArray(json.countries)
      && Array.isArray(json.orgs)
      && !json
        .countries
        .find((x: any) => !x.guid
          || !x.code
          || !x.value)
      && !json
        .orgs
        .find((x: any) => !x.guid
          || !x.code
          || !x.value)) {
      state.filters = json;
    }
  });

  // Clear Filters
  builder.addCase(clearFilters, state => {
    state.filters = initialState.filters;
  });

  // Add Org Filter
  builder.addCase(addOrgFilter, (state, { payload }) => {
    if (state.filters.orgs
      .find(x => x.guid === payload.guid)) {
      return;
    }

    state.filters.orgs.push({ ...payload });
  });

  // Add Country Filter
  builder.addCase(addCountryFilter, (state, { payload }) => {
    if (state.filters.countries
      .find(x => x.guid === payload.guid)) {
      return;
    }

    state.filters.countries.push({ ...payload });
  });

  // Remove Org Filter
  builder.addCase(removeOrgFilter, (state, { payload }) => {
    state.filters.orgs =
      state.filters.orgs
        .filter(x => x.guid !== payload.guid);
  });

  // Remove Country Filter
  builder.addCase(removeCountryFilter, (state, { payload }) => {
    state.filters.countries =
      state.filters.countries
        .filter(x => x.guid !== payload.guid);
  });

  // Set Search Term
  builder.addCase(setSearchTerm, (state, { payload }) => {
    state.filters.searchTerm = payload;
  });

  // Set Attribute Popup
  builder.addCase(setAttributePopup, (state, { payload }) => {
    state.attributePopup = payload;
  });

  // Set Job Template Modal
  builder.addCase(setJobTemplateModal, (state, { payload }) => {
    if (payload.modal === "cacheModal") {
      state.templateCacheModal = payload.data;
    } else if (payload.modal === "unCacheModal") {
      state.templateUnCacheModal = payload.data;
    } else if (payload.modal === "retireModal") {
      state.retireTemplateModal = payload.data;
    }
  });
});

export default jobTemplatesReducer;