import React, { useEffect } from "react";
import UserList from "./UserList";
import SearchBar from "./SearchBar";
import useSelector from "store/useSelector";
import AddUserModal from "./AddUserModal";
import { useDispatch } from "react-redux";
import { getManageUsersList, resetManageUsersData } from "store/userManagement/userManagementActions";
import FlowLayout from "components/layout/FlowLayout";

const UserManagement: React.FC = () => {
  const modalData = useSelector(store => store.userManagement.addUserModalData);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getManageUsersList());

    return () => {
      dispatch(resetManageUsersData());
    };
  }, [dispatch]);

  return (
    <FlowLayout
      header={(
        <SearchBar />
      )}
    >
      <UserList />
      {modalData.isOpen &&
        <AddUserModal />
      }
    </FlowLayout>
  );
}

export default UserManagement;