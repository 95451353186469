import React from "react";
import { useTranslation } from "react-i18next";
import Banner, { BannerType } from "./Banner";

const Error403: React.FC = () =>
{
  const { t } = useTranslation('');
  return(
    <Banner
      type={BannerType.Error}
    >
      {t('You do not have the necessary permissions to view this area.')}
    </Banner>);
}

export default Error403;