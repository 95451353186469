import IdbApi from "apis/idb/IdbApi";
import Banner, { BannerType } from "components/common/Banner";
import FlowLayout from "components/layout/FlowLayout";
import { SWPJobStatus } from "interfaces/jobs/JobInterfaces";
import { IStep } from "interfaces/sw/SWInterfaces";
import { cloneDeep } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { ExecutionMode } from "store/execution/executionTypes";
import { showErrorToast } from "store/toast/toastActions";
import useSelector from "store/useSelector";
import formatDate, { getDateDiff } from "utilities/formatDate";
import { getSWInstanceTitle } from "utilities/swUtilities";
import JobIsCompletedBanner from "../completion/JobIsCompletedBanner";
import JobHistoryList from "../jobHistory/JobHistoryList";
import PrintImage, { PrintImageSource } from "./PrintImage";
import PrintStep from "./PrintStep";
import "./PrintView.scoped.scss";

const PrintView: React.FC = () => {
  const {
    job,
    stepResponses,
    stepComments,
    mode,
    paperExecutions,
  } = useSelector(store => store.execution);
  const printSectionRef = useRef<HTMLDivElement>(null);
  const jobNameRef = useRef<HTMLHeadingElement>(null);
  const dispatch = useDispatch();
  const { t } = useTranslation('printView');
  const [offlineJobStart, setOfflineJobStart] = useState<Date | undefined>();
  const [offlineJobEnd, setOfflineJobEnd] = useState<Date | undefined>();

  useEffect(() => {
    if (!job) {
      return;
    }

    let aborted = false;

    if (mode === ExecutionMode.Online) {
      setOfflineJobStart(undefined);
      setOfflineJobEnd(undefined);
    } else {
      const timeFetch = async () => {
        const orderedResponses = (await IdbApi.getCachedJobResponses(job.id))
          .sort((a, b) => a.timestamp.getTime() - b.timestamp.getTime());

        const end = (await IdbApi.getCachedJobCompletion(job.id))?.timestamp;

        if (!aborted) {
          setOfflineJobStart(orderedResponses.length
            ? orderedResponses[0].timestamp
            : undefined);
          setOfflineJobEnd(end);
        }
      }

      timeFetch();

      return () => {
        aborted = true;
      };
    }
  }, [job, mode])

  if (!job) {
    return (
      <Banner
        type={BannerType.Error}
      >
        {t('No job is currently loaded.')}
      </Banner>
    )
  }

  const getShowOnlyCriticalSteps = (swCriticalSteps: IStep[], swId: string, jobSWId: number) => {
    var showOnlyCriticalSteps = true;
    for (let i = 0; i < stepResponses.length; i++) {
      var executedSWs = job.sws.filter(esw => esw.id === swId
        && esw.jobSWId === jobSWId
        && esw.jobSWId === stepResponses[i].jobSWId);
      if (executedSWs.length > 0 && stepResponses[i].isComplete && !stepResponses[i].isNA) {
        var criticalStepsFound = stepFoundinSwSteps(swCriticalSteps, stepResponses[i].stepId);
        if (!criticalStepsFound) {
          showOnlyCriticalSteps = false;
          break;
        }
      }
    }
    return showOnlyCriticalSteps;
  }

  const stepFoundinSwSteps = (swSteps: IStep[], stepId: string) => {
    var stepFound = false;
    if (swSteps.find(r => r.id === stepId))
      return true;

    for (var i = 0; i < swSteps.length; i++) {
      var childSteps = swSteps[i].children;
      if (childSteps
        && childSteps.length > 0
        && childSteps.find(r => r.id === stepId)) {
        stepFound = true;
        break;
      }
      for (var j = 0; j < childSteps.length; j++) {
        var gChildSteps = childSteps[j].children;
        if (gChildSteps
          && gChildSteps.length > 0
          && gChildSteps.find(r => r.id === stepId)) {
          stepFound = true;
          break;
        }
      }
      if (stepFound) {
        break;
      }
    }
    return stepFound;
  }

  const printSection = () => {
    if (!printSectionRef.current
      || !jobNameRef.current) {
      return;
    }

    const openWindow = window.open("", "Job Data");
    if (!openWindow) {
      dispatch(showErrorToast(t("Popups must be enabled to use the print feature.")));
      return;
    }

    const jobNameHtml = jobNameRef.current.outerHTML;
    const html = printSectionRef.current.outerHTML;

    if (!html
      || !jobNameHtml) {
      dispatch(showErrorToast(t("Error preparing print.")));
      return;
    }

    // Find any inline styles applied to the current page
    // and copy them to the print window.
    const styleTags = document.getElementsByTagName("style");

    for (let i = 0; i < styleTags.length; i++) {
      const item = styleTags.item(i);

      if (item) {
        openWindow.document.write(item.outerHTML);
      }
    }

    // Find any linked stylesheets applied to the current page
    // and copy them to the print window.
    const stylesheets = document.getElementsByTagName("link");

    for (let i = 0; i < stylesheets.length; i++) {
      const item = stylesheets.item(i);

      if (item
        && item.rel === "stylesheet") {
        openWindow.document.write(item.outerHTML);
      }
    }

    // Write the job name and the main section html to the print window.
    openWindow.document.write(jobNameHtml);
    openWindow.document.write(html);

    // Update the body tag to remove some styling that prevents multiple
    // page rendering.
    const bodyTags = openWindow.document.getElementsByTagName("body");
    if (bodyTags.length) {
      const body = bodyTags.item(0);
      if (body) {
        body.style.overflow = "auto";
        body.style.width = "initial";
        body.style.height = "initial";
        body.style.display = "initial";
        body.style.alignContent = "initial";

        if (body.firstChild) {
          (body.firstChild as HTMLDivElement).style.width = "100%";
        }
      }
    } else {
      dispatch(showErrorToast(t("Error preparing print.")));
      return;
    }

    // Update the body tag to remove some styling that prevents multiple
    // page rendering.
    const htmlTags = openWindow.document.getElementsByTagName("html");
    if (htmlTags.length) {
      const item = htmlTags.item(0);
      if (item) {
        item.style.overflow = "auto";
        item.style.height = "initial";
        item.style.width = "initial";
      }
    }

    openWindow.document.close();
    openWindow.print();
    openWindow.focus();
  }

  const jobStart = mode === ExecutionMode.Offline
    ? offlineJobStart
    : job.startTime;

  const jobEnd = mode === ExecutionMode.Offline
    ? offlineJobEnd
    : job.endTime;

  const getTotalDeviationCount = () =>{
      let count = 0;
      let activeJobSWIds = job.sws;
      let stepsWithDeviations = stepResponses.filter(r => r.isDeviation
         && activeJobSWIds.find(p => p.jobSWId === r.jobSWId) !== undefined);
        if (stepsWithDeviations 
          && stepsWithDeviations.length > 0)
        {
          count = stepsWithDeviations.length;
        }
      return count;
  }

  function populateStepNumber(step: IStep, hierarchy: string) {
    const number = hierarchy
      ? `${hierarchy}.${step.sortOrder}`
      : step.sortOrder.toString();

    step.number = number;

    if (step.children.length > 0) {
      step.children.forEach(child => {
        populateStepNumber(child, number)
      });
    }

  }

  const getSWRenderSteps = (originalSteps: IStep[], swId: string, jobSWId: number) => {
    var swCriticalSteps = getSWCriticalSteps(originalSteps);
    if (swCriticalSteps.length === 0) {
      return originalSteps;
    }

    var showCriticalStepsOnly = getShowOnlyCriticalSteps(swCriticalSteps, swId, jobSWId);
    if (!showCriticalStepsOnly) {
      return originalSteps;
    }
    return swCriticalSteps;
  }

  const getSWCriticalSteps = (originalSteps: IStep[]) => {
    var renderSteps: IStep[] = [];
    var renderStepPosition = 1;
    for (var i = 0; i < originalSteps.length; i++) {
      var childSteps = originalSteps[i].children;
      var renderChildSteps: IStep[] = [];
      var renderChildPosition = 1;
      for (var j = 0; j < childSteps.length; j++) {
        var gChildSteps = childSteps[j].children;
        var renderGChildSteps: IStep[] = [];
        var gchildPosition = 1;
        for (var k = 0; k < gChildSteps.length; k++) {
          if (gChildSteps[k].isCritical === true) {
            var grandChild: IStep = cloneDeep(gChildSteps[k]);
            grandChild.sortOrder = gchildPosition;
            renderGChildSteps.push(grandChild);
            gchildPosition++;
          }
        }
        if (childSteps[j].isCritical === true
          || renderGChildSteps.length > 0) {
          var child: IStep = cloneDeep(childSteps[j]);
          child.sortOrder = renderChildPosition;
          child.children = [];
          child.children = renderGChildSteps;
          renderChildSteps.push(child);
          renderChildPosition++;
        }
      }
      if (originalSteps[i].isCritical === true
        || renderChildSteps.length > 0) {
        var parent: IStep = cloneDeep(originalSteps[i]);
        parent.sortOrder = renderStepPosition;
        parent.children = [];
        parent.children = renderChildSteps;
        renderSteps.push(parent);
        renderStepPosition++;
      }
    }

    renderSteps.forEach(rStep => {
      populateStepNumber(rStep, "");
    });
    return renderSteps;
  }

  return (
    <FlowLayout
      header={(
        <>
          {job.status === SWPJobStatus.Completed &&
            <JobIsCompletedBanner
              job={job}
            />
          }
          <div
            className="header"
            ref={jobNameRef}
          >
            <h2 className="title">
              {job.jobNumber &&
                <>
                  {job.jobNumber}:
                </>
              }
              {job.title}
            </h2>
            <div className="run-time">
              <div>
                <label>{t('StartTime ')}</label>
                {formatDate(jobStart, true, true, "short")}
              </div>
              <div>
                <label>{t('EndTime ')}</label>
                {formatDate(jobEnd, true, true, "short")}
              </div>
              <div>
                <label>{t('TotalTime ')}</label>
                {getDateDiff(jobEnd, jobStart)}
              </div>
              <div>
                <label>{t('TotalDeviations ')}</label>
                {getTotalDeviationCount()}
              </div>
            </div>
          </div>
        </>
      )}
      footer={(
        <div
          className="footer"
        >
          <button
            className="primary-button"
            onClick={printSection}
          >
            {t('Print')}
          </button>
        </div>
      )}
    >
      <div
        ref={printSectionRef}
      >
        {job.sws.map(sw => {
          const paperExec = paperExecutions.find(x => x.jobSWId === sw.jobSWId);
          const swSteps = getSWRenderSteps(sw.steps, sw.id, sw.jobSWId);
          return (
            <div
              className="sw-steps"
              key={sw.jobSWId}
            >
              <h4>{getSWInstanceTitle(sw.jobSWId, job.sws)}</h4>

              {paperExec &&
                <Banner
                  type={BannerType.Info}
                >
                  {t('This Standard Work was executed manually on paper and uploaded ')}
                  {t('by {who} on {when}.', { who: paperExec.userEmail, when: formatDate(paperExec.timestamp) })}
                  {t('The following file was submitted as proof')}
                  <br />
                  <PrintImage
                    filename={paperExec.imageFilename}
                    source={PrintImageSource.UserImage}
                  />
                </Banner>
              }

              {
                swSteps.map(step => (
                  <PrintStep
                    key={step.id}
                    jobSWId={sw.jobSWId}
                    step={step}
                    allResponses={stepResponses}
                    allComments={stepComments}
                    isConditionallyDisabled={false}
                  />
                ))}
            </div>
          );
        })}

        <h4>{t('Job History')}</h4>
        <JobHistoryList />
      </div>
    </FlowLayout>
  );
};

export default PrintView;