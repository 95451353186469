import { ISearchSWsOptions } from "interfaces/sw/SWInterfaces";

/** Gets the filter count. You can optionally exclude properties from being used in the filtering condition.
  * The filter count is calculated based on the length of the string or array properties.
  */
export function getFilterCount(filteringOptions: ISearchSWsOptions, excludedProperties: string[] = []): number
{
    let filterCount= 0;
    Object.keys(filteringOptions).forEach(key => {
        let descriptor= Object.getOwnPropertyDescriptor(filteringOptions, key);
        if(!excludedProperties.includes(key) && descriptor?.value.length)
            filterCount++;
    }); 
    return filterCount;
}