import EllipsesDropdown, { IEllipsesDropdownItem } from "components/common/EllipsesDropdown";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { clearJobPaperExecution, clearSWPaperExecution, clearSWStepComments, clearSWStepResponses, logJobAction, openPaperExecuteJob, resetJobPaperExecution, resetSWResponses, saveShowAllECLs, startSwitchExecutor } from "store/execution/executionActions";
import useSelector from "store/useSelector";
import "./SwitchExecutorButton.scoped.scss";
import downArrowIcon from "media/icons/dls/arrow-down-2.svg";
import JobPaperExecuteModal from "./JobPaperExecuteModal";
import IdbApi from "apis/idb/IdbApi";
import { showErrorToast, showSuccessToast } from "store/toast/toastActions";
import ImageModal from "components/common/ImageModal";
import Modal from "components/common/Modal";
import { SWTypes } from "interfaces/sw/SWInterfaces";

const SwitchExecutorButton: React.FC = () => {
  const {
    execution: {
      job,
      jobPaperExecuteModalData,
      jobPaperExecution,
      executor,
    },
    auth: {
      currentUser: {
        name,
        email,
      },
    },
  } = useSelector(store => store);
  const dispatch = useDispatch();
  const { t } = useTranslation('executor');
  const [visiblePaperExecutionImage, setVisiblePaperExecutionImage] = useState("");
  const [paperExecuteFileName, setPaperExecuteFileName] = useState("");
  const [isResetJobPERespModalVisible, setIsResetJobPERespModalVisible] = useState(false);

  if (!job?.team.find(x =>
    x.email.toLowerCase() === email.toLowerCase())) {
    return null;
  }

  const dispatchSwithUser = () => {
    dispatch(startSwitchExecutor());
  }

  const onPaperExecuteClicked = () => {
    dispatch(openPaperExecuteJob({
      jobId: job.id
    }))
  }

  const viewPaperExecutionImage = async () => {
    if (!jobPaperExecution?.imageFilename) {
      alert("No File");
      return;
    }

    try {
      const imgData = await IdbApi.getUserImageData(jobPaperExecution.imageFilename);

      if (!imgData?.data) {
        dispatch(showErrorToast(t("Paper execution image not found!")));
        return;
      }

      setPaperExecuteFileName(imgData.filename);
      setVisiblePaperExecutionImage(imgData.data);

    } catch (err: any) {
      dispatch(showErrorToast(t(`Failed to load paper execution image {message}`, { message: err.message || err })));
      return;
    }
  }

  const saveShowAllECLsClick = () => {
    dispatch(saveShowAllECLs({
      jobId: job.id,
      showAllECLs: true,
      timestamp: new Date(),
      userName: executor?.name || name,
      userEmail: executor?.email || email,
    }))
  }

  const resetJobPaperExec = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    setIsResetJobPERespModalVisible(true);
    setPaperExecuteFileName("");
  }

  const resetOnlineJobPaperExecution = () => {
    dispatch(resetJobPaperExecution({
      jobId: job.id,
    }));

    dispatch(clearJobPaperExecution());

    job.sws.forEach(a => {
      dispatch(clearSWStepResponses(
        {
          jobSWId: a.jobSWId,
        }));

      dispatch(clearSWStepComments(
        {
          jobSWId: a.jobSWId,
        }));

      dispatch(clearSWPaperExecution(
        {
          jobSWId: a.jobSWId,
        }));

      dispatch(logJobAction({
        action: `Reset due to need to complete again – ${a.title}`,
        timestamp: new Date(),
        userName: executor?.name || name,
        userEmail: executor?.email || email,
      }));

      dispatch(resetSWResponses(
        {
          jobId: job.id,
          jobSWId: a.jobSWId,
        }));
    });
    setIsResetJobPERespModalVisible(false);

    dispatch(showSuccessToast(t("All responses were reset succesfully")));

  }

  var eclAddedToJob = job.sws.filter(r => r.type === SWTypes.ECL).length > 0;
  var showAllECLs = (job.showAllECLs === undefined || job.showAllECLs === null || job.showAllECLs === true);

  let papExecItemList: (IEllipsesDropdownItem | undefined)[] = [{
    key: "SWITCH USER",
    label: t("SWITCH USER"),
    onClick: dispatchSwithUser,
  },
  {
    key: "Paper Execute",
    label: t("Paper Execute"),
    onClick: onPaperExecuteClicked,
  },
  eclAddedToJob
    && !showAllECLs ? ({
      key: "Show All ECLs",
      label: t("Show All ECLs"),
      onClick: saveShowAllECLsClick,
    }) : undefined
  ];

  let viewPaperExecItemList: (IEllipsesDropdownItem | undefined)[] = [{
    key: "SWITCH USER",
    label: t("SWITCH USER"),
    onClick: dispatchSwithUser,
  },
  {
    key: "View Paper Execution",
    label: t("View Paper Execution"),
    onClick: viewPaperExecutionImage,
  },
  {
    key: "Reset Job",
    label: t("Reset Job"),
    onClick: resetJobPaperExec,
  },

  ];

  return (
    <div>
      <EllipsesDropdown
        label={t("Actions")}
        icon={downArrowIcon}
        itemTextAlign="left"
        items={jobPaperExecution.jobId === 0 ? papExecItemList : viewPaperExecItemList}
      />
      {isResetJobPERespModalVisible &&
        <Modal
          header={t("Reset Job Paper Execution")}
          isOpen={true}
          controls={(
            <>
              <button
                className="secondary-button"
                onClick={() => setIsResetJobPERespModalVisible(false)}
              >
                {t('Cancel')}
              </button>
              <button
                className="primary-button"
                onClick={resetOnlineJobPaperExecution}
              >
                {t('Reset Job Paper Execution')}
              </button>
            </>
          )}
        >
          {t('Are you sure you want to clear all the Job responses?')}
        </Modal>
      }
      {jobPaperExecuteModalData.isVisible &&
        <JobPaperExecuteModal
        />}
      {!!visiblePaperExecutionImage &&
        <ImageModal
          title={t("Paper Execution Image")}
          imgSrc={visiblePaperExecutionImage}
          fileName={paperExecuteFileName}
          onClose={() => setVisiblePaperExecutionImage("")}
        />
      }
    </div>
  );
};

export default SwitchExecutorButton;