import React from "react";
import "./BlockSpinner.scoped.scss";
import SpinnerIcon from "media/Pulse-1s-131px.svg";
interface IBlockSpinnerProps {
  width?: number,
}

const BlockSpinner: React.FC<IBlockSpinnerProps> = ({ width }) =>
  <img
    src={SpinnerIcon}
    alt="Searching..."
    className="spinner"
    style={{ width: width }}
  />;

export default BlockSpinner;