import React, { useEffect, useState } from "react";
import { ISWPJob } from "interfaces/jobs/JobInterfaces";
import IdbApi from "apis/idb/IdbApi";
import Banner, { BannerType } from "components/common/Banner";
import BlockSpinner from "components/common/BlockSpinner";
import JobHeader from "./jobHeaders/JobHeader";
import useSelector from "store/useSelector";
import { RouteComponentProps } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface IOfflineJobsListState {
  offlineJobs: ISWPJob[],
  offlineCompletedJobIds: number[],
  isLoading: boolean,
  loadError?: string,
}

const OfflineJobsList: React.FC<RouteComponentProps> = (props) => {
  // Pull the list of offline job ids so that when they change, the list of offline
  // jobs here also changes. It is passed as a dependency of useEffect.
  const offlineJobIds = useSelector(store => store.offline.cachedJobIds);
  const { t } = useTranslation('myJobs');
  const [state, setState] = useState<IOfflineJobsListState>({
    offlineJobs: [],
    offlineCompletedJobIds: [],
    isLoading: false,
  });

  useEffect(() => {
    setState({
      offlineJobs: [],
      offlineCompletedJobIds: [],
      isLoading: true,
      loadError: undefined,
    });

    let aborted = false;

    const loadCachedJobs = async () => {
      try {
        const offlineJobs = (await IdbApi.getCachedJobs())
          .sort((a, b) => b.lastActionDate.getTime() - a.lastActionDate.getTime());

        let completedJobIds: number[] = [];

        if (!aborted) {
          completedJobIds = (await IdbApi.getAllCachedJobCompletions())
            .filter(x => !x.isOnServer)
            .map(x => x.jobId);
        }

        if (!aborted) {
          setState({
            offlineJobs: offlineJobs,
            offlineCompletedJobIds: completedJobIds,
            isLoading: false,
            loadError: undefined,
          });
        }
      } catch (err: any) {
        if (!aborted) {
          setState({
            offlineJobs: [],
            offlineCompletedJobIds: [],
            isLoading: false,
            loadError: err.message || err.toString(),
          });
        }
      }
    };

    loadCachedJobs();

    return () => {
      aborted = true;
    };
  }, [setState, offlineJobIds]);

  let mainComponent: JSX.Element | undefined;

  if (state.isLoading) {
    mainComponent = <BlockSpinner />;
  } else if (state.loadError) {
    mainComponent = (
      <Banner
        type={BannerType.Error}
      >
        {state.loadError}
      </Banner>
    );
  } else if (state.offlineJobs.length === 0) {
    mainComponent = (
      <Banner
        type={BannerType.Info}
      >
        {t('You have no jobs cached for offline use.')}
      </Banner>
    );
  } else {
    // Show list of jobs!
    mainComponent = (
      <>
        {state
          .offlineJobs
          .map(job =>
            <JobHeader
              key={job.id}
              showCacheButton={true}
              isOfflineMode={true}
              showExtraButtons={true}
              jobHeader={job}
              {...props}
            />
          )}
      </>
    );
  }

  return (
    <>
      <Banner
        type={BannerType.Warn}
      >
        {t('You have no network connectivity.')}
        {t('Below is your list of offline jobs.')}
        {t('To retrieve your full list of jobs, click the reload button')}
        {t('at the top of the page when you regain network connectivity.')}
      </Banner>
      {mainComponent}
    </>
  );
}

export default OfflineJobsList;