import React from "react";
import "./UserJobsToolbar.scoped.scss";
import listIcon from "media/icons/dls/list.svg";
import checklistIcon from "media/icons/dls/checklist.svg";
import Tab from "../../common/Tab";
import { UserJobsTabs } from "interfaces/user/UserInterfaces";
import { useTranslation } from "react-i18next";

interface IUserJobsToolbarProps {
  currentTab: UserJobsTabs,
  onTabChange(tab: UserJobsTabs): void,
}

const UserJobsToolbar: React.FC<IUserJobsToolbarProps> = ({ currentTab, onTabChange }) =>
{
  const { t } = useTranslation('userSearch');
  return(
    <div className="user-jobs-tabs">
      <Tab
        label={t("Active")}
        icon={listIcon}
        isActive={currentTab === UserJobsTabs.Active}
        onClick={() => onTabChange(UserJobsTabs.Active)}
      />
      <Tab
        label={t("Completed")}
        icon={checklistIcon}
        isActive={currentTab === UserJobsTabs.Completed}
        onClick={() => onTabChange(UserJobsTabs.Completed)}
      />
    </div>);
}

export default UserJobsToolbar;