import { throwIfResponseError } from "apis/apiUtilities";
import config from "config";
import { ISWPJobHeader } from "interfaces/jobs/JobInterfaces";
import { OfflineFetchError } from "utilities/errors/OfflineFetchError";
import { IAADUser, IManageUserUser, ITCCInfo, TCCComplianceStatuses, IUserJobCreationPreference, UserJobsTabs, IEmailPINPair } from "interfaces/user/UserInterfaces";
import { authGetJson, authPostJson } from "utilities/msalFetches";
import { formatConfirmTCCRequest, formatCreateUserRequest, formatGetManageUsersList, formatGetUserResponse, formatSearchAADUsersResponse, formatSetUserPINRequest, formatTCCInfo, formatUpdateUserRequest, formatUserJobCreationPreferences, formatUpdateUserJobCreationPreferences, formatUsersJobsResponse, formatGetUserPINsResponse } from "./UserFormatters";

class UserApi {
  public async getManageUsersList(searchTerm: string): Promise<IManageUserUser[]> {
    let endpoint = `${config.endpoints.users.getUsers}?searchTerm=${window.encodeURIComponent(searchTerm) || ""}`;
    let response = await authGetJson(endpoint);

    await throwIfResponseError(response);

    let json = await response.json();
    let searchResults = formatGetManageUsersList(json);
    return searchResults;
  }

  public async getUserJobCreationPreference(email: string): Promise<IUserJobCreationPreference> {
    if (!email) {
      throw new Error("Email must be supplied when loading user preferences.");
    }

    let endpoint = `${config.endpoints.users.getUserJobCreationPreference}?email=${window.encodeURIComponent(email)}`;
    let response = await authGetJson(endpoint);

    await throwIfResponseError(response);

    let json = await response.json();
    let preference = formatUserJobCreationPreferences(json);
    return preference;
  }

  public async getUser(email: string): Promise<IManageUserUser> {
    if (!email) {
      throw new Error("Email must be supplied when loading a user for edit.");
    }

    let endpoint = config.endpoints.users.getUser.replace("{email}", window.encodeURIComponent(email));
    let response = await authGetJson(endpoint);

    await throwIfResponseError(response);

    let json = await response.json();
    let user = formatGetUserResponse(json);
    return user;
  }

  public async getUsersJobs(statusFilter: UserJobsTabs,
    searchTerm: string,
    teamMemberEmail: string): Promise<ISWPJobHeader[]> {
    let response: Response;
    try {
      response = await authPostJson(
        config.endpoints.users.getUsersJobs.replace("{email}", window.encodeURIComponent(teamMemberEmail)),
        JSON.stringify({
          SearchTerm: searchTerm,
          JobStatus: statusFilter,
          TeamMemberEmail: teamMemberEmail,
        }));
    } catch (err: any) {
      throw new OfflineFetchError(err.message);
    }

    await throwIfResponseError(response);

    let responseObj = await response.json();

    return formatUsersJobsResponse(responseObj);
  }

  public async searchAADUsers(searchTerm: string): Promise<IAADUser[]> {
    if (!searchTerm) {
      throw new Error("Search term must be supplied when searching users.");
    }

    let endpoint = `${config.endpoints.users.getAADUsers}?searchTerm=${window.encodeURIComponent(searchTerm)}`;
    let response = await authGetJson(endpoint);

    await throwIfResponseError(response);

    let json = await response.json();
    let searchResults = formatSearchAADUsersResponse(json);
    return searchResults;
  }

  public async updateUser(user: IManageUserUser): Promise<boolean> {
    let response = await authPostJson(
      config.endpoints.users.updateUser.replace("{email}", window.encodeURIComponent(user.email)),
      JSON.stringify(formatUpdateUserRequest(user)),
    );

    await throwIfResponseError(response);

    return true;
  }

  public async updateUserJobCreationPreference(myCountry: string | undefined,
    myOrg: string | undefined,
    myGeoUnit: string | undefined,
    myCustomer: string | undefined,
    mySubBusinessLine: string | undefined): Promise<boolean> {
    let response = await authPostJson(
      config.endpoints.users.updateUserJobCreationPreference,
      JSON.stringify(formatUpdateUserJobCreationPreferences(myCountry, myOrg, myGeoUnit, myCustomer, mySubBusinessLine))
    )
    await throwIfResponseError(response);
    return true;
  }

  public async setUserPIN(email: string, pin: string): Promise<void> {
    let response = await authPostJson(
      config.endpoints.users.setUserPIN.replace("{email}",
        window.encodeURIComponent(email)),
      JSON.stringify(formatSetUserPINRequest(email, pin)),
    );

    await throwIfResponseError(response);
  }

  public async submitTCCResponse(tccId: number, status: TCCComplianceStatuses): Promise<ITCCInfo> {
    let response = await authPostJson(
      config.endpoints.users.confirmTCC,
      JSON.stringify(formatConfirmTCCRequest(tccId, status)),
    );

    await throwIfResponseError(response);

    let json = await response.json();
    return formatTCCInfo(json.TCCInfo);
  }

  public async createUser(user: IAADUser): Promise<boolean> {
    let response = await authPostJson(
      config.endpoints.users.createUser,
      JSON.stringify(formatCreateUserRequest(user)),
    );

    await throwIfResponseError(response);

    return true;
  }

  public async getUserPINs(emails: string[]): Promise<IEmailPINPair[]> {
    let response = await authPostJson(
      config.endpoints.users.getUserPINs,
      JSON.stringify({
        Emails: emails,
      }),
    );

    await throwIfResponseError(response);

    return formatGetUserPINsResponse(await response.json());
  }
}

export default new UserApi();