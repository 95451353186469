import React, { useState, useEffect } from "react";
import SignaturePad from "react-signature-pad-wrapper";
import "./SignatureInput.scoped.scss";
import IdbApi from "apis/idb/IdbApi";
import { v4 as uuidv4 } from "uuid";
import { useDispatch } from "react-redux";
import { showErrorToast } from "store/toast/toastActions";
import { IStepComponent } from "interfaces/sw/SWInterfaces";
import useSelector from "store/useSelector";
import { setComponentResponse } from "store/execution/executionActions";
import { ComponentResponseType } from "interfaces/execution/executionInterfaces";
import { IStepIdentifier } from "store/execution/executionTypes";
import { getComponentResponse } from "utilities/swUtilities";
import { useTranslation } from "react-i18next";

interface ISignatureInputProps {
  component: IStepComponent,
  isDisabled: boolean,
  stepInfo: IStepIdentifier,
  jobId: number,
}

interface ISignatureInputState {
  isPadVisible: boolean,
  signatureDataUri?: string,
  initialDataUri?: string,
}

interface ISignaturePadRef {
  clear(): void,
  fromData(data: any): void,
  toData(): any,
  isEmpty(): boolean,
  toDataURL(): string,
  fromDataURL(uri: string): void,
}

const SignatureInput: React.FC<ISignatureInputProps> = ({ component, isDisabled, stepInfo, jobId }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('components');
  const [state, setState] = useState<ISignatureInputState>({
    isPadVisible: false,
  });
  const {
    auth: {
      currentUser: {
        email,
      },
    },
    execution: {
      stepResponses,
      executor,
    },
  } = useSelector(store => store);
  let signatureRef: ISignaturePadRef | null = null;
  const buttonText = state.isPadVisible
    ? t("Done")
    : t("Sign");

  const compResponseValues = getComponentResponse(stepInfo,
    component.id,
    stepResponses)
    ?.values;
  const initialFilename = compResponseValues?.[0];

  useEffect(() => {
    let aborted = false;

    const loadData = async () => {
      // Load the initial signature from the Idb into the state if it exists.
      if (initialFilename) {
        try {
          const userImage = await IdbApi.getUserImageData(initialFilename);

          if (!aborted) {
            setState(s => ({
              ...s,
              initialDataUri: userImage?.data,
            }));
          }
        } catch (err: any) {
          if (!aborted) {
            dispatch(showErrorToast(
              t("Failed to load existing signature {message}", {message: (err?.message || err?.toString())})));
          }
        }
      } else {
        if (!aborted) {
          setState(s => ({
            ...s,
            initialDataUri: undefined,
          }));
        }
      }
    };

    loadData();

    return () => {
      aborted = true;
    };
  }, [initialFilename, dispatch, t]);

  const onSignatureDone = (sigFilename: string) => {
    if (isDisabled) {
      return;
    }

    dispatch(setComponentResponse({
      response: {
        id: component.id,
        type: ComponentResponseType.Signature,
        values: [sigFilename],
        isDeviation: false,
      },
      stepInfo,
      userEmail: executor?.email || email,
    }));
  }

  const onTogglePadClick = async () => {
    if (state.isPadVisible) {
      if (signatureRef) {
        const dataUri = signatureRef.toDataURL();

        try {
          const sigFilename = uuidv4() + ".png";
          await IdbApi.cacheUserImageData(sigFilename, jobId, false, dataUri);

          setState({
            ...state,
            signatureDataUri: dataUri,
            isPadVisible: false,
          });

          onSignatureDone(sigFilename);
        } catch (err: any) {
          dispatch(showErrorToast(t(`Failed to save signature {message}`, {message: err.message || err.toString()})));
        }
      }
    } else {
      setState({
        ...state,
        isPadVisible: true,
      });
    }
  }

  const onResetClick = () => {
    if (signatureRef) {
      signatureRef.clear();
    }
  }

  return (
    <>
      <label>{component.label}</label>
      <div className="signature-input">
        <div className={"pad-wrapper " + (state.isPadVisible ? "pad-active" : "")}>
          {state.isPadVisible &&
            <SignaturePad
              ref={(ref: ISignaturePadRef | null) => signatureRef = ref}
            />
          }
          {!state.isPadVisible
            && (state.signatureDataUri || state.initialDataUri) &&
            <img
              className="signature-image"
              src={state.signatureDataUri || state.initialDataUri}
              alt="Signature"
            />
          }
        </div>

        <div className="buttons">
          {state.isPadVisible &&
            <button
              className="secondary-button"
              onClick={isDisabled ? undefined : onResetClick}
              disabled={isDisabled}
            >
              {t('Start Over')}
            </button>
          }
          <button
            className="primary-button"
            onClick={isDisabled ? undefined : onTogglePadClick}
            disabled={isDisabled}
          >
            {buttonText}
          </button>
        </div>
      </div>
    </>
  );
}

export default SignatureInput;