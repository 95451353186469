import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { IJobTemplateHeader, ISWPJob, MyJobsTabs, SWPJobType } from 'interfaces/jobs/JobInterfaces';
import JobsApi from 'apis/jobs/JobsApi';
import { getResponseErrorMessage } from 'utilities/validationErrorHelpers';
import { OfflineFetchError } from 'utilities/errors/OfflineFetchError';
import { RootState } from 'store/rootStore';
import { cloneTemplate, searchJobTemplates, setCloneOperation, setHasOfflineError, setLoadOperation, setTemplates, setAttributePopup, retireTemplate, setRetireTemplateOperation } from './jobTemplatesActions';
import { Action } from '@reduxjs/toolkit';
import { showErrorToast, showSuccessToast } from 'store/toast/toastActions';
import IdbApi from 'apis/idb/IdbApi';
import { cloneDeep } from 'lodash';
import { IOfflineSW } from 'interfaces/sw/SWInterfaces';
import { addCachedJobIds, refreshAwaitingSync } from 'store/offline/offlineActions';
import i18n from 'i18n';

const t = i18n.getFixedT(null, 'sagas');

export default function* watchJobTemplatesSagas() {
  yield all([
    watchSearchTemplates(),
    watchCloneTemplate(),
    watchRetireTemplate(),
  ]);
}

function* watchSearchTemplates() {
  yield takeLatest(searchJobTemplates, searchTemplatesAsync);
}

export function* searchTemplatesAsync() {
  const {
    myJobs: {
      currentTab
    },
    jobTemplates: {
      filters,
    },
  }: RootState = yield select((store: RootState) => store);

  if (currentTab !== MyJobsTabs.Templates) {
    return;
  }

  yield put(setLoadOperation({
    isWorking: true,
  }));
  yield put(setHasOfflineError(false));

  try {
    const searchResults: IJobTemplateHeader[] = yield call(JobsApi.getJobTemplates, filters);
    yield put(setTemplates(searchResults));
    yield put(setLoadOperation({
      isWorking: false,
    }));
  } catch (err: any) {
    if (err instanceof OfflineFetchError) {
      yield put(setHasOfflineError(true));
      yield put(setLoadOperation({
        isWorking: false,
      }));
      return;
    } else {
      yield put(setLoadOperation({
        isWorking: false,
        errorMessage: getResponseErrorMessage(err),
      }));
    }
  }
}

function* watchCloneTemplate() {
  yield takeLatest(cloneTemplate, cloneTemplateAsync);
}

function* cloneTemplateAsync(action: Action) {
  if (!cloneTemplate.match(action)) {
    return;
  }

  // Check if this template is cached for offline use.
  const cachedTemplate: ISWPJob = yield call([IdbApi, IdbApi.getCachedJob], action.payload.template.id);

  if (cachedTemplate) {
    yield call(cloneTemplateFromCacheAsync, cachedTemplate);
    return;
  }

  if (!action.payload.countryGuid 
    || !action.payload.geoUnitCode
    || !action.payload.subBusinessLineCode) {
    yield put(setAttributePopup({
      isOpen: true,
      country: undefined,
      geoUnit: undefined,
      subBusinessLine: undefined,
      template: action.payload.template,
    }));

    return;
  }

  try {
    yield put(setCloneOperation({
      isWorking: true,
    }));

    const clonedJobId: number = yield call(JobsApi.cloneJobTemplate, 
      action.payload.template.id,
      action.payload.countryGuid, 
      action.payload.geoUnitCode,
      action.payload.subBusinessLineCode);

    yield put(setCloneOperation({
      isWorking: false,
      wasSuccessful: true,
      payload: clonedJobId,
    }));
  } catch (err: any) {
    yield put(showErrorToast(getResponseErrorMessage(err)));

    yield put(setCloneOperation({
      isWorking: false,
    }));
  }
}

function* cloneTemplateFromCacheAsync(cachedTemplate: ISWPJob) {
  yield put(setCloneOperation({
    isWorking: true,
  }));

  try {
    // Find the lowest ID from the jobs table.
    const minJobId: number | undefined = yield call([IdbApi, IdbApi.getMinimumJobId]);
    let newJobId = (minJobId
      && minJobId < 0)
      ? minJobId - 1
      : -1;

    // Find lowest JobSWId in the db.
    let minJobSWId: number = (yield call([IdbApi, IdbApi.getMinimumJobSWId])) || 0;
    if (minJobSWId > 0) {
      minJobSWId = 0;
    }

    const newJob = cloneDeep(cachedTemplate);
    newJob.id = newJobId;
    newJob.type = SWPJobType.Standard;
    newJob.lastActionDate = new Date();
    newJob.sws.forEach(x => {
      // Subtract 1 from the minJobSWId and use it.
      x.jobSWId = --minJobSWId;
    });

    const templateSWs: IOfflineSW[] = yield call([IdbApi, IdbApi.getCachedSWs], cachedTemplate.id);
    const newSWs = cloneDeep(templateSWs);
    newSWs.forEach(x => x.jobId = newJobId);

    // Clone the SWs for this job.
    yield call([IdbApi, IdbApi.cacheSWs], newSWs);

    // Cache no step comments.
    yield call([IdbApi, IdbApi.cacheStepComments], {
      jobId: newJobId,
      comments: [],
    });

    // Cache no history log.
    yield call([IdbApi, IdbApi.cacheJobLog], {
      jobId: newJobId,
      log: [],
    });

    // Cache the job itself.
    yield call([IdbApi, IdbApi.cacheJob],
      newJob);

    yield put(addCachedJobIds({ jobIds: [newJob.id] }));

    yield put(refreshAwaitingSync());

    yield put(showSuccessToast(t("Job successfully created for offline use.")));
  } catch (err: any) {
    yield put(showErrorToast(getResponseErrorMessage(err)));
  } finally {
    yield put(setCloneOperation({
      isWorking: false,
    }));
  }
}

function* watchRetireTemplate() {
  yield takeLatest(retireTemplate, retireTemplateAsync);
}

function* retireTemplateAsync(action: Action) {
  if (!retireTemplate.match(action)) {
    return;
  }

  const isOnline: boolean = yield select((store: RootState) => store.offline.isOnline);

  if (!isOnline) {
    yield put(showErrorToast(t("Template can only be retired while online.")));
    return;
  }

  yield put(setRetireTemplateOperation({
    isWorking: true,
  }));

  try {
    yield call(JobsApi.cancelJob, {
      jobId: action.payload.template.id,
      timestamp: action.payload.timestamp,
      userEmail: action.payload.userEmail,
    });
    yield put(showSuccessToast(t('Template retired successfully.')));

    yield put(setRetireTemplateOperation({
      isWorking: false,
      wasSuccessful: true,
    }));

    // Refresh the templates list.
    yield call(searchTemplatesAsync);
  } catch (err: any) {
    yield put(setRetireTemplateOperation({
      isWorking: false,
      wasSuccessful: false,
      errorMessage: err,
    }));
  }
}