import {
  IExecutionState,
  IExecutionLocation,
} from "./executionTypes";
import { findNextIncompleteStep, findTaskThatNeedsResponse, getResponsesForAllSteps, getStepFromSwSteps, getStepResponse } from "utilities/swUtilities";
import { createReducer } from "@reduxjs/toolkit";
import {
  resetExecution,
  switchToExecutionSW,
  setCurrentExecutionStep,
  setConditionalResponse,
  setComponentResponse,
  setStepCompletion,
  setStepIsNotApplicable,
  setIsUploadingStepResponses,
  setResponsesAreDirty,
  setIsJobCompleting,
  setIsCompleteJobModalVisible,
  setIsJobCompletedModalVisible,
  setCompleteJobError,
  setUploadResponsesError,
  setIsDeviationReasonRequired,
  setHasCantCompleteWithDirtyResponsesError,
  setJobStatus,
  logJobAction,
  beginLoading,
  finishLoading,
  loadingFailed,
  setLog,
  setStepCommentModalData,
  addComment,
  setComments,
  markSWAsNA,
  setCommentsAreOnServer,
  setExecutor,
  startSwitchExecutor,
  beginRealtimeStepLoads,
  finishRealtimeStepLoads,
  finishRealtimeCommentLoads,
  finishRealtimeJobHistoryLoad,
  handleRealtimeJobCompletion,
  paperExecuteSW,
  openPaperExecuteSW,
  closePaperExecuteSW,
  setPaperExecutions,
  setPaperExecutionsAreOnServer,
  finishRealtimePaperExecLoad,
  beginRealtimePaperExecLoad,
  setShowAllSteps,
  setOnlineSWUserFeedback,
  addOfflineSWUserFeedback,
  addSWUserFeedback,
  clearSWStepResponses,
  clearSWStepComments,
  clearSWPaperExecution,
  paperExecuteJob,
  clearJobPaperExecution,
  finishRealtimeJobPaperExecLoad,
  setJobPaperExecutions,
  setJobPaperExecutionIsOnServer,
  finisheRealtimeResetSW,
  setShowAllECLs,
  setIsSavingShowAllECLs,
  handleRealtimeResetSW,
  openPaperExecuteJob,
  closePaperExecuteJob,
  resetSW,
  clearResetSW,
  duplicateSW,
  updateJobSwidForDuplicate,
  updateStepResponsesJobSWidForDuplicate,
  updatePaperExecsJobSWidForDuplicate,
  updateCommentsJobSWidForDuplicate,
  updateIsDirtyForDuplicateSW,
  updateResetSWJobSWidForDuplicate,
  loadJobOnly,
  loadJobSW,
  addJobDoc,
  removeJobDoc,
  setjobExecutor,
  setTableCellImage,
  setRefDocTableCellImage
} from "./executionActions";
import { cloneDeep } from "lodash";
import { IStep, ITableComponentData, StepComponentTypes, SWTypes } from "interfaces/sw/SWInterfaces";

const initialState: IExecutionState = {
  loadOperation: null,
  job: null,
  executor: null,
  isSwitchExecutorVisible: false,
  mode: null,
  currentLocation: null,
  stepResponses: [],
  log: [],
  isShowingRecap: false,
  isUploadingResponses: false,
  isSavingShowAllECLs: false,
  isJobCompleting: false,
  isCompleteJobModalVisible: false,
  isJobCompletedModalVisible: false,
  completeJobError: undefined,
  uploadResponsesError: undefined,
  isDeviationReasonRequired: false,
  hasCantCompleteWithDirtyResponsesError: false,
  stepCommentModalData: {
    isVisible: false,
    stepIdentifier: {
      jobSWId: 0,
      stepId: "",
    },
  },
  stepComments: [],
  realtimeStepsLoading: [],
  isRealtimeJobCompletionModalVisible: false,
  paperExecuteModalData: {
    isVisible: false,
    jobSWId: 0,
  },
  jobPaperExecuteModalData: {
    isVisible: false,
    jobId: 0,
  },
  paperExecutions: [],
  jobPaperExecution: {
    imageFilename: "",
    jobId: 0,
    isDirty: false,
    isOnServer: false,
    userEmail: "",
    timestamp: new Date(),
  },
  realtimeSWPaperExecsLoading: [],
  swUserFeedback: [],
  resetJobSWIds: [],
  jobExecutor: "",
};

const executionReducer = createReducer(initialState, builder => {
  // Reset State
  builder.addCase(resetExecution, () => initialState);

  // Begin Loading
  builder.addCase(beginLoading, (_, action) => {
    let newState = cloneDeep(initialState);

    newState.loadOperation = {
      isLoading: true,
      loadErrors: [],
      mode: action.payload.executionMode,
      jobId: action.payload.jobId,
    };

    return newState;
  });

  // Load Job Only
  builder.addCase(loadJobOnly, (state, action) => {
    state.loadOperation = null;
    state.job = action.payload.job;
    state.log = action.payload.log;
    state.mode = action.payload.mode;
    state.stepResponses = action.payload.stepResponses;
    state.stepComments = action.payload.comments;
    state.paperExecutions = action.payload.paperExecutions;
    state.jobPaperExecution = action.payload.jobPaperExecution;

    if (!state.job.sws.length) {
      state.currentLocation = null;
      return;
    }

    // Also move user to the first incomplete step (if available).
    const nextStepLocation = findNextIncompleteStep(state.job,
      null,
      state.stepResponses);

    if (nextStepLocation) {
      state.currentLocation = {
        jobSWId: nextStepLocation.jobSWId,
        stepId: nextStepLocation.stepId,
      };
    }
  });

  builder.addCase(setTableCellImage, (state, action) => {
    if (!state.job) {
      return state;
    }

    let swsToUpdate = state.job?.sws.filter(x => x.id === action.payload.swId && x.version === action.payload.swVersion);
    if (swsToUpdate.length > 0) {
      for(let i = 0; i < swsToUpdate.length; i++) {
        // find step
        let step = getStepFromSwSteps(swsToUpdate[i].steps, action.payload.stepInfo.stepId);
        if (!step) {
          return;
        }

        //find component
        let component = step.components.find(comp => comp.id === action.payload.componentId);
        if (!component || component.type !== StepComponentTypes.Table || !component.data) {
          return;
        }

        // find cell
        let cell = (component.data as ITableComponentData).cells.find((c: any) => c.rowIndex === action.payload.rowKey && c.colIndex === action.payload.colKey);
        if (!cell) {
          return;
        }

        // set src to the data url
        cell.value = cell.value.replace(/<img([^>]*)\ssrc=('|")([^>]*)\2\s([^>]*)\/>/gi, `<img src="${action.payload.imageData}" $4/>`);
      }
    }
  });

  builder.addCase(setRefDocTableCellImage, (state, action) => {
    if (!state.job) {
      return state;
    }

    let swsToUpdate = state.job?.sws.filter(x => x.id === action.payload.swId && x.version === action.payload.swVersion);
    if (swsToUpdate.length > 0) {
      for(let i = 0; i < swsToUpdate.length; i++) {
        //find component
        let component = swsToUpdate[i].refDocs.find(x => x.id === action.payload.refDocId)
        if (!component || component.type !== 'Table' || !component.tableData) {
          return;
        }

        // find cell
        let cell = (component.tableData as ITableComponentData).cells.find((c: any) => c.rowIndex === action.payload.rowKey && c.colIndex === action.payload.colKey);
        if (!cell) {
          return;
        }

        // set src to the data url
        cell.value = cell.value.replace(/<img([^>]*)\ssrc=('|")([^>]*)\2\s([^>]*)\/>/gi, `<img src="${action.payload.imageData}" $4/>`);
      }
    }
  });

  // Load Job SW
  builder.addCase(loadJobSW, (state, action) => {
    state.loadOperation = null;
    let newSWData = action.payload.sw;
    let swToUpdate = state.job?.sws.find(x => x.jobSWId === action.payload.jobSWId);
    if (!swToUpdate) {
      return;
    }

    swToUpdate.docType = newSWData.docType;
    swToUpdate.docVersion = newSWData.docVersion;
    swToUpdate.hasCriticalSteps = newSWData.hasCriticalSteps;
    swToUpdate.notices = newSWData.notices;
    swToUpdate.ppe = newSWData.ppe;
    swToUpdate.refDocs = newSWData.refDocs;
    swToUpdate.steps = newSWData.steps;
    swToUpdate.type = newSWData.type;
  });

  // Finish Loading
  builder.addCase(finishLoading, (state, action) => {
    state.loadOperation = null;
    state.job = action.payload.job;
    state.log = action.payload.log;
    state.mode = action.payload.mode;
    state.stepResponses = action.payload.stepResponses;
    state.stepComments = action.payload.comments;
    state.paperExecutions = action.payload.paperExecutions;
    state.jobPaperExecution = action.payload.jobPaperExecution;

    if (!state.job.sws.length) {
      state.currentLocation = null;
      return;
    }

    // Also move user to the first incomplete step (if available).
    let firstStepLocation: IExecutionLocation = {
      jobSWId: state.job.sws[0].jobSWId,
      stepId: state.job.sws[0].steps[0].id,
    };

    const nextStepLocation = findNextIncompleteStep(state.job,
      null,
      state.stepResponses);

    if (nextStepLocation) {
      firstStepLocation = {
        jobSWId: nextStepLocation.jobSWId,
        stepId: nextStepLocation.stepId,
      }
    }

    state.currentLocation = firstStepLocation;
  });

  // Loading Failed
  builder.addCase(loadingFailed, (state, action) => {
    if (!state.loadOperation) {
      return;
    }

    state.loadOperation.loadErrors = action.payload.loadErrors;
    state.loadOperation.isLoading = false;
  });

  //add jobdocs to state
  builder.addCase(addJobDoc, (state, action) => {
    state.job?.jobDocs.push(action.payload.jobDoc);
  });

  // remove jobDoc from the state
  builder.addCase(removeJobDoc, (state, action) => {
    if (state.job) {
      for (let index = 0; index < state.job.jobDocs.length; index++) {
        const element = state.job.jobDocs[index];
        if (element.fileName === action.payload.filename) {
          state.job.jobDocs.splice(index, 1);
        }
      }
    }
  });

  // Switch To Execution SW
  builder.addCase(switchToExecutionSW, (state, action) => {
    if (state.job === null
      || state.job.sws.length === 0) {
      return state;
    }

    if (state.currentLocation?.jobSWId === action.payload.jobSWId) {
      state.currentLocation = null;
      return;
    }

    const sw = state.job.sws.find(sw => sw.jobSWId === action.payload.jobSWId);

    if (!sw) {
      return state;
    }

    let newCurrentLocation: IExecutionLocation = {
      jobSWId: action.payload.jobSWId,
      stepId: sw.steps[0]?.id,
    };

    state.currentLocation = newCurrentLocation;

    return state;
  });

  // Set Current Execution Step
  builder.addCase(setShowAllECLs, (state, action) => {
    if (state.job === null
      || state.job.sws.length === 0) {
      return state;
    }

    state.job.showAllECLs = action.payload.showAllECLs;

    return state;
  });

  // Set reset SW
  builder.addCase(resetSW, (state, action) => {
    if (state.job === null
      || state.job.sws.length === 0) {
      return state;
    }

    var resetJobSWIdsFound = state.resetJobSWIds.filter(r => r === action.payload.jobSWId);
    if (resetJobSWIdsFound.length === 0) {
      state.resetJobSWIds.push(action.payload.jobSWId);
    }
    return state;
  });

  // clear reset SW
  builder.addCase(clearResetSW, (state, action) => {
    state.resetJobSWIds = [];
    return state;
  });

  // Set Show All Steps
  builder.addCase(setShowAllSteps, (state, action) => {
    if (state.job === null
      || state.job.sws.length === 0) {
      return state;
    }

    const sw = state.job.sws.find(sw => sw.jobSWId === action.payload.jobSWId);

    if (!sw) {
      alert("no sw");
      return state;
    }

    sw.showCriticalSteps = !sw.showCriticalSteps;
    if (sw.showCriticalSteps && sw.hasCriticalSteps) {
      for (let i = 0; i < sw.steps.length; i++) {
        if (!sw.steps[i].isCritical) {
          sw.steps[i].showStep = false;
        }
        for (let j = 0; j < sw.steps[i].children.length; j++) {

          if (sw.steps[i].children[j].isCritical) {
            sw.steps[i].children[j].showStep = true;
            sw.steps[i].showStep = true;
          } else {
            sw.steps[i].children[j].showStep = false;
          }

          for (let k = 0; k < sw.steps[i].children[j].children.length; k++) {
            if (sw.steps[i].children[j].children[k].isCritical) {
              sw.steps[i].children[j].children[k].showStep = true;
              sw.steps[i].children[j].showStep = true;
              sw.steps[i].showStep = true;
            } else {
              sw.steps[i].children[j].children[k].showStep = false;
            }

            for (let l = 0; l < sw.steps[i].children[j].children[k].children.length; l++) {
              if (sw.steps[i].children[j].children[k].children[l].isCritical) {
                sw.steps[i].children[j].children[k].children[l].showStep = true;
                sw.steps[i].children[j].children[k].showStep = true;
                sw.steps[i].children[j].showStep = true;
                sw.steps[i].showStep = true;
              } else {
                sw.steps[i].children[j].children[k].children[l].showStep = false;
              }
            }

          }
        }
      }

      // set numbers for critical Steps.
      const criticalSteps: IStep[] = sw.steps.filter(a => a.showStep);
      for (let i = 0; i < criticalSteps.length; i++) {
        criticalSteps[i].number = (i + 1).toString();

        if (criticalSteps[i].children) {
          const criticalChildSteps: IStep[] = criticalSteps[i].children.filter(a => a.showStep);
          for (let j = 0; j < criticalChildSteps.length; j++) {
            criticalChildSteps[j].number = (i + 1).toString() + "." + (j + 1).toString();

            if (criticalChildSteps[j].children) {
              const criticalGrandChildSteps: IStep[] = criticalChildSteps[j].children.filter(a => a.showStep);
              for (let k = 0; k < criticalGrandChildSteps.length; k++) {
                criticalGrandChildSteps[k].number = (i + 1).toString() + "." + (j + 1).toString() + "." + (k + 1).toString();

                if (criticalGrandChildSteps[k].children) {
                  const criticalGreatGrandChildSteps: IStep[] = criticalGrandChildSteps[k].children.filter(a => a.showStep);
                  for (let l = 0; l < criticalGreatGrandChildSteps.length; l++) {
                    criticalGreatGrandChildSteps[l].number = (i + 1).toString() + "." + (j + 1).toString() + "." + (k + 1).toString() + "." + (l + 1).toString();
                  }
                }
              }
            }
          }
        }
      }

    } else {

      for (let i = 0; i < sw.steps.length; i++) {
        sw.steps[i].showStep = true;
        sw.steps[i].number = (i + 1).toString();

        for (let j = 0; j < sw.steps[i].children.length; j++) {
          sw.steps[i].children[j].showStep = true;
          sw.steps[i].children[j].number = (i + 1).toString() + "." + (j + 1).toString();

          for (let k = 0; k < sw.steps[i].children[j].children.length; k++) {

            sw.steps[i].children[j].children[k].showStep = true;
            sw.steps[i].children[j].children[k].number = (i + 1).toString() + "." + (j + 1).toString() + "." + (k + 1).toString();

            for (let l = 0; l < sw.steps[i].children[j].children[k].children.length; l++) {
              sw.steps[i].children[j].children[k].children[l].showStep = true;
              sw.steps[i].children[j].children[k].children[l].number = (i + 1).toString() + "." + (j + 1).toString() + "." + (k + 1).toString() + "." + (l + 1).toString();
            }
          }
        }
      }
    }
    return state;
  });

  // Set Current Execution Step
  builder.addCase(setCurrentExecutionStep, (state, action) => {
    if (state.job === null
      || state.job.sws.length === 0) {
      return state;
    }

    state.currentLocation = action.payload;

    return state;
  });

  // Set Conditional Response
  builder.addCase(setConditionalResponse, (state, action) => {
    // Find if there's already a response for this step.
    let oldResponse = getStepResponse(action.payload.stepInfo,
      state.stepResponses);

    if (oldResponse) {
      oldResponse.conditionalResponse = action.payload.response;
      oldResponse.isDirty = true;
      oldResponse.timestamp = new Date();
      oldResponse.userEmail = action.payload.userEmail
    } else {
      state.stepResponses.push({
        stepId: action.payload.stepInfo.stepId,
        jobSWId: action.payload.stepInfo.jobSWId,
        isComplete: false,
        isNA: false,
        conditionalResponse: action.payload.response,
        isDirty: true,
        timestamp: new Date(),
        userEmail: action.payload.userEmail,
        isDeviation: false,
      });
    }

    return state;
  });

  // Set Service Actual Response
  builder.addCase(setComponentResponse, (state, action) => {
    let response = getStepResponse(action.payload.stepInfo,
      state.stepResponses);

    if (!response) {
      state.stepResponses.push({
        stepId: action.payload.stepInfo.stepId,
        jobSWId: action.payload.stepInfo.jobSWId,
        componentResponses: [{ ...action.payload.response }],
        isComplete: false,
        isNA: false,
        isDirty: true,
        timestamp: new Date(),
        userEmail: action.payload.userEmail,
        isDeviation: action.payload.response.isDeviation,
      });
    } else {
      response.timestamp = new Date();
      response.isDirty = true;
      response.userEmail = action.payload.userEmail;
      let anyDeviationCompsInStep = response.componentResponses?.filter(r=>r.id !== action.payload.response.id
          && r.isDeviation)
      if((anyDeviationCompsInStep && anyDeviationCompsInStep?.length > 0) 
        || action.payload.response.isDeviation)
      {
        response.isDeviation = true;
      }
      else{
        response.isDeviation = false;
      }      

      if (!response.componentResponses) {
        response.componentResponses = [];
      }

      let compResponse = response.componentResponses.find(sa => sa.id === action.payload.response.id);

      if (compResponse) {
        compResponse.type = action.payload.response.type;
        compResponse.values = action.payload.response.values;
        compResponse.isDeviation = action.payload.response.isDeviation;
      } else {
        response.componentResponses.push({
          ...action.payload.response,
        });
      }
    }

    return state;
  });

  // Set Step Completion
  builder.addCase(setStepCompletion, (state, action) => {
    if (!state.job) {
      return state;
    }

    // Find if there's already a response for this step.
    let oldResponse = getStepResponse(action.payload.stepInfo,
      state.stepResponses);

    if (oldResponse) {
      oldResponse.isComplete = action.payload.isComplete;
      oldResponse.timestamp = new Date();
      oldResponse.isDirty = true;
      oldResponse.userEmail = action.payload.userEmail;      
      // if we are reopening a step  for a CL clear the responses.
      let sw = state.job.sws.find(x => x.jobSWId === action.payload.stepInfo.jobSWId);
      if (!action.payload.isComplete && (sw?.type === SWTypes.CL || sw?.type === SWTypes.LCL)) {
        oldResponse.componentResponses = [];
        oldResponse.conditionalResponse = undefined;
        oldResponse.isDeviation = false;
      }
    } else {
      state.stepResponses.push({
        stepId: action.payload.stepInfo.stepId,
        jobSWId: action.payload.stepInfo.jobSWId,
        isComplete: action.payload.isComplete,
        isNA: false,
        isDirty: true,
        timestamp: new Date(),
        userEmail: action.payload.userEmail,
        isDeviation: false,
      });
    }

    // figure out if we need to complete or open the task?
    let thisStepsTLMSW = state.job.sws.find(x => x.jobSWId === action.payload.stepInfo.jobSWId && x.type === SWTypes.TLMSWI);
    if (thisStepsTLMSW) {
      let task = findTaskThatNeedsResponse(thisStepsTLMSW,
        action.payload.stepInfo.stepId,
        state.stepResponses,
        action.payload.isComplete);

      if (task) {
        // Find if there's already a response for this step.
        let oldResponse = getStepResponse(task, state.stepResponses);

        if (oldResponse) {
          oldResponse.isComplete = action.payload.isComplete;
          oldResponse.timestamp = new Date();
          oldResponse.isDirty = true;
          oldResponse.userEmail = action.payload.userEmail;
        } else {
          state.stepResponses.push({
            stepId: task.stepId,
            jobSWId: task.jobSWId,
            isComplete: action.payload.isComplete,
            isNA: false,
            isDirty: true,
            timestamp: new Date(),
            userEmail: action.payload.userEmail,
            isDeviation: false,
          });
        }
      }
    }

    if (!action.payload.isComplete) {
      // If the step was re-opened, no need to figure out what the next step
      // should be.
      return state;
    }

    let getNextStepResult = findNextIncompleteStep(state.job,
      state.currentLocation,
      state.stepResponses);

    if (!getNextStepResult) {
      // No next step after the complete button.
      // Is there an incomplete step before it?
      getNextStepResult = findNextIncompleteStep(state.job,
        null,
        state.stepResponses)
    }

    if (!getNextStepResult) {
      // No more incomplete steps in the job.
      state.isShowingRecap = true;
      state.currentLocation = null;
    } else {
      state.currentLocation = {
        jobSWId: getNextStepResult.jobSWId,
        stepId: getNextStepResult.stepId,
      };
    }

    return state;
  });

  // Set Step Is Not Applicable
  builder.addCase(setStepIsNotApplicable, (state, action) => {
    if (!state.job) {
      return state;
    }

    // Find if there's already a response for this step.
    let oldResponse = getStepResponse(action.payload.stepInfo,
      state.stepResponses);

    if (oldResponse) {
      oldResponse.isNA = action.payload.isNA;
      oldResponse.isDirty = true;
      oldResponse.timestamp = new Date();
      oldResponse.userEmail = action.payload.userEmail;
    } else {
      state.stepResponses.push({
        stepId: action.payload.stepInfo.stepId,
        jobSWId: action.payload.stepInfo.jobSWId,
        isComplete: false,
        isNA: action.payload.isNA,
        isDirty: true,
        timestamp: new Date(),
        userEmail: action.payload.userEmail,
        isDeviation: false,
      });
    }

    const sw = state.job.sws.find(x => x.jobSWId === action.payload.stepInfo.jobSWId);
    let step = sw?.steps.find(x => x.id === action.payload.stepInfo.stepId);
    let stepFound = false;
    if (sw && step === undefined)
    {
      for(let i = 0; i <sw?.steps.length; i++)
      {
        if(sw?.steps[i].children) {
          for(let j = 0; j < sw?.steps[i].children.length; j++) {
            step = sw?.steps[i].children.find(x => x.id === action.payload.stepInfo.stepId);
            if(step)
            {
              stepFound = true;
              break;
            }
          }
        }
        if(stepFound)
        {
          break;
        }
      }
    }
    if(step && step.isConditional)
    {
      for (let i = 0; i < step.children.length; i++) {
        // Find if there's already a response for this step.
        let stepChildResponse = state.stepResponses.find(x => x.stepId === step?.children[i].id
                  && x.jobSWId === action.payload.stepInfo.jobSWId);
  
        if (stepChildResponse) {
          stepChildResponse.isNA = action.payload.isNA;
          stepChildResponse.isDirty = true;
          stepChildResponse.timestamp = new Date();
          stepChildResponse.isComplete = action.payload.isNA ? true: false;
          stepChildResponse.userEmail = action.payload.userEmail;
        } else {
          state.stepResponses.push({
            stepId: step?.children[i].id,
            jobSWId: action.payload.stepInfo.jobSWId,
            isComplete: action.payload.isNA ? true: false,
            isNA: action.payload.isNA,
            isDirty: true,
            timestamp: new Date(),
            userEmail: action.payload.userEmail,
            isDeviation: false,
          });
        }
      }
    }
    

    return state;
  });

  // Set Is Uploading Step Responses
  builder.addCase(setIsUploadingStepResponses, (state, action) => {
    state.isUploadingResponses = action.payload;

    return state;
  });

  // Set Is saving Show ALL ECL Responses
  builder.addCase(setIsSavingShowAllECLs, (state, action) => {
    state.isSavingShowAllECLs = action.payload;

    return state;
  });

  // Set Responses Are Dirty
  builder.addCase(setResponsesAreDirty, (state, action) => {
    action.payload.steps.forEach(si => {
      const oldResponse = getStepResponse(si,
        state.stepResponses);

      if (oldResponse) {
        oldResponse.isDirty = action.payload.isDirty;
      }
    });

    return state;
  });

  // Set Is Job Completing
  builder.addCase(setIsJobCompleting, (state, action) => {
    state.isJobCompleting = action.payload;
    return state;
  });

  // Set Is Complete Job Modal Visible
  builder.addCase(setIsCompleteJobModalVisible, (state, action) => {
    state.isCompleteJobModalVisible = action.payload;
    return state;
  });

  // Set Is Job Completed Modal Visible
  builder.addCase(setIsJobCompletedModalVisible, (state, action) => {
    state.isJobCompletedModalVisible = action.payload;
    return state;
  });

  // Set Complete Job Error
  builder.addCase(setCompleteJobError, (state, action) => {
    state.completeJobError = action.payload;
    return state;
  });

  // Set Upload Responses Error
  builder.addCase(setUploadResponsesError, (state, action) => {
    state.uploadResponsesError = action.payload;
    return state;
  });

  // Set Is Deviation Reason Required
  builder.addCase(setIsDeviationReasonRequired, (state, action) => {
    state.isDeviationReasonRequired = action.payload;
    return state;
  });

  // Set Has Can't Complete With Dirty Responses Error
  builder.addCase(setHasCantCompleteWithDirtyResponsesError, (state, action) => {
    state.hasCantCompleteWithDirtyResponsesError = action.payload;
    return state;
  });

  builder.addCase(setLog, (state, action) => {
    state.log = action.payload;
    return state;
  });

  // Set Job Status
  builder.addCase(setJobStatus, (state, action) => {
    if (!state.job) {
      return state;
    }

    state.job.status = action.payload;

    return state;
  });

  // Log Job Action
  builder.addCase(logJobAction, (state, action) => {
    if (!state.job) {
      return;
    }

    state.log.push({
      ...action.payload,
      isOnServer: false,
      jobId: state.job.id,
      isDirty: action.payload.isDirty === undefined
        ? true
        : action.payload.isDirty,
    });
  });

  // Set Step Comment Modal Data
  builder.addCase(setStepCommentModalData, (state, action) => {
    if (!state.job) {
      return;
    }

    state.stepCommentModalData = action.payload;
  });

  // Add Comment
  builder.addCase(addComment, (state, action) => {
    if (!state.job) {
      return;
    }

    state.stepComments.push(action.payload);
  });

  // Set Comments
  builder.addCase(setComments, (state, action) => {
    if (!state.job) {
      return;
    }

    state.stepComments = [
      ...action.payload,
    ];
  });

  // Set Comments are on server
  builder.addCase(setCommentsAreOnServer, (state, action) => {
    state
      .stepComments
      .filter(c => action
        .payload
        .commentIds
        .indexOf(c.guid) > -1)
      .forEach(c => c.isOnServer = action.payload.isOnServer);
  });

  //update isdirty field for Duplicate SW in Job
  builder.addCase(updateIsDirtyForDuplicateSW, (state, action) => {
    let sws = state.job?.sws.filter(x => x.jobSWId < 0 && x.isDirty);
    if (sws) {
      if (action.payload.jobSWIds) {
        sws.forEach(x => {
          let jobswid = action.payload.jobSWIds?.find(y => y === x.jobSWId);
          if (jobswid) {
            x.isDirty = action.payload.isDirty;
          }
        });
      }

    }
  });

  //Add Duplicate SW in Job
  builder.addCase(duplicateSW, (state, action) => {
    let sw = Object.assign({}, state.job?.sws.find(x => x.jobSWId === action.payload.oldjobSWid));
    sw.jobSWId = action.payload.jobSWId;
    sw.sortOrder = action.payload.sortOrder;
    sw.isDirty = true;
    state.job?.sws.push(sw);
  });

  //Update new JobSWId for Job
  builder.addCase(updateJobSwidForDuplicate, (state, action) => {
    let sws = state.job?.sws.filter(x => x.jobSWId < 0);
    if (sws) {
      sws.forEach(x => {
        let jobswid = action.payload.newSWs.find(y => y.OldJobSWId === x.jobSWId)?.JobSWId;
        if (jobswid) {
          x.jobSWId = jobswid;
        }
      });
    }
  });

  //Update new JobSWId for Reset
  builder.addCase(updateResetSWJobSWidForDuplicate, (state, action) => {
    state.resetJobSWIds = state.resetJobSWIds?.map(x => {
      let jobswid = action.payload.newSWs.find(y => y.OldJobSWId === x)?.JobSWId;
      return jobswid ?? x;
    });
  });

  //Update jobswid for Step Responses
  builder.addCase(updateStepResponsesJobSWidForDuplicate, (state, action) => {
    state.stepResponses.forEach(x => {
      let jobswid = action.payload.newSWs.find(y => y.OldJobSWId === x.jobSWId)?.JobSWId;
      if (jobswid) {
        x.jobSWId = jobswid;
      }
    });
  });

  //Update jobswid for Paper Execs
  builder.addCase(updatePaperExecsJobSWidForDuplicate, (state, action) => {
    state.paperExecutions.forEach(x => {
      let jobswid = action.payload.newSWs.find(y => y.OldJobSWId === x.jobSWId)?.JobSWId;
      if (jobswid) {
        x.jobSWId = jobswid;
      }
    });
  });

  //Update jobswid for Step Comments
  builder.addCase(updateCommentsJobSWidForDuplicate, (state, action) => {
    state.stepComments.forEach(x => {
      let jobswid = action.payload.newSWs.find(y => y.OldJobSWId === x.jobSWId)?.JobSWId;
      if (jobswid) {
        x.jobSWId = jobswid;
      }
    });
  });

  // Mark SW as N/A
  builder.addCase(markSWAsNA, (state, action) => {
    const sw = state.job?.sws.find(x => x.jobSWId === action.payload.jobSWId);

    if (!sw) {
      return;
    }

    // Filter out all existing responses for this SW.
    const swResponses = getResponsesForAllSteps(sw, action.payload.userEmail);
    swResponses.forEach(x => {
      x.isComplete = true;
      x.isNA = true;
    });

    state.stepResponses = [
      ...state.stepResponses
        .filter(x => x.jobSWId !== action.payload.jobSWId),
      ...swResponses,
    ];
  });

  //if job has executed by other user
  builder.addCase(setjobExecutor, (state, action) => {
    const executor = action.payload;

    if (executor) {
      state.jobExecutor = executor;
    }
  })

  // Start Switch Executor
  builder.addCase(startSwitchExecutor, (state, action) => {
    if (!state.job) {
      return;
    }

    state.isSwitchExecutorVisible = true;
  });

  // Set Executor
  builder.addCase(setExecutor, (state, action) => {
    if (!state.job) {
      return;
    }

    state.isSwitchExecutorVisible = false;

    const executor = action.payload;

    if (!executor) {
      state.executor = executor;
      return;
    }

    if (!state.job.team.find(x => x.email === executor.email)) {
      // Team member not in team list.
      return;
    }

    state.executor = executor;
  });

  // Begin Realtime Step Loads
  builder.addCase(beginRealtimeStepLoads, (state, action) => {
    action.payload.forEach(x => state
      .realtimeStepsLoading
      .find(z => z.jobSWId === x.jobSWId
        && z.stepId === x.stepId)
      ? undefined
      : state.realtimeStepsLoading.push(x)
    );
  });

  //finish Realtime Reset SW
  builder.addCase(finisheRealtimeResetSW, (state, action) => {

    for (let index = 0; index < state.stepResponses.length; index++) {
      const element = state.stepResponses[index];
      if (element.jobSWId === action.payload.jobSWID) {
        state.stepResponses.splice(index, 1);
      }
    }
  });

  // Finish Realtime Step Loads
  builder.addCase(finishRealtimeStepLoads, (state, action) => {
    action.payload.forEach(stepResponse => {
      const existingIx = state
        .stepResponses
        .findIndex(x => x.jobSWId === stepResponse.jobSWId
          && x.stepId === stepResponse.stepId);

      if (existingIx > -1) {
        // Replace the step response.
        state.stepResponses[existingIx] = stepResponse;
      } else {
        // Insert the step response.
        state.stepResponses.push(stepResponse);
      }

      const loadIx = state
        .realtimeStepsLoading
        .findIndex(x => x.jobSWId === stepResponse.jobSWId
          && x.stepId === stepResponse.stepId);

      if (loadIx > -1) {
        state.realtimeStepsLoading.splice(loadIx, 1);
      }
    });
  });

  // Finish Realtime Comment Loads
  builder.addCase(finishRealtimeCommentLoads, (state, action) => {
    action.payload.forEach(comm => {
      const existingIx = state
        .stepComments
        .findIndex(x => x.guid === comm.guid);

      if (existingIx > -1) {
        state.stepComments[existingIx] = comm;
      } else {
        state.stepComments.push(comm);
      }
    });
  });

  // Finish Realtime Job History Load
  builder.addCase(finishRealtimeJobHistoryLoad, (state, action) => {
    state.log = action.payload.concat(state.log.filter(x => !x.isOnServer));
  });

  // Handle Reset SW
  builder.addCase(handleRealtimeResetSW, (state, action) => {
    state.stepResponses = state.stepResponses.filter(a => a.jobSWId !== action.payload.jobSWID);
    state.paperExecutions = state.paperExecutions.filter(a => a.jobSWId !== action.payload.jobSWID);
  });

  // Handle Realtime Job Completion
  builder.addCase(handleRealtimeJobCompletion, (state, { payload: { jobId } }) => {
    if (state.job?.id !== jobId) {
      return;
    }

    state.isRealtimeJobCompletionModalVisible = true;
  });

  // Open Paper Execute SW
  builder.addCase(openPaperExecuteSW, (state, { payload }) => {
    state.paperExecuteModalData = {
      jobSWId: payload.jobSWId,
      isVisible: true,
    };
  });

  // Open Job Paper Execute
  builder.addCase(openPaperExecuteJob, (state, { payload }) => {
    state.jobPaperExecuteModalData = {
      jobId: payload.jobId,
      isVisible: true,
    };
  });

  // Close Job Paper Execute
  builder.addCase(closePaperExecuteJob, (state) => {
    state.jobPaperExecuteModalData = {
      jobId: 0,
      isVisible: false,
    }
  });
  // Close Paper Execute SW
  builder.addCase(closePaperExecuteSW, (state) => {
    state.paperExecuteModalData = {
      jobSWId: 0,
      isVisible: false,
    };
  });

  // Set Paper Execution for Job.
  builder.addCase(paperExecuteJob, (state, { payload }) => {
    if (!state.job) {
      return;
    }
    state.job.sws.forEach(sw => {
      // Create new "completed" responses for all steps in this sw.
      if (sw.type === SWTypes.ECL && !payload.showAllEcls) {
        return;
      }
      const swResponses = getResponsesForAllSteps(sw, payload.userEmail);
      swResponses.forEach(x => {
        x.isComplete = true;
        x.conditionalResponse = sw.steps.find(y => y.id === x.stepId)?.isConditional ? true : x.conditionalResponse;
      });
  
      swResponses.forEach(x => {
        sw.steps?.forEach(parent => {
            const subStep  = parent.children?.find(sub => sub.id === x.stepId);
            if (subStep ){
              x.isComplete = true;
              x.conditionalResponse = subStep.isConditional;  
            }
        });
      });
  
      // Remove all old step responses for this jobSWId
      // and replace with the new ones.
      state.stepResponses = [
        ...state.stepResponses
          .filter(x => x.jobSWId !== sw.jobSWId),
        ...swResponses,
      ];

      state.paperExecutions.push({
        userEmail: payload.userEmail,
        jobSWId: sw.jobSWId,
        timestamp: new Date(),
        imageFilename: payload.paperExecutedFileName,
        isOnServer: false,
        isDirty: true,
      });
    });

    state.jobPaperExecution.imageFilename = payload.paperExecutedFileName;
    state.jobPaperExecution.jobId = payload.jobId;
    state.jobPaperExecution.userEmail = payload.userEmail;
    state.jobPaperExecution.isDirty = true;
    state.jobPaperExecution.isOnServer = false;
    state.jobPaperExecution.timestamp = new Date();
  });

  // Set Paper Execution
  builder.addCase(paperExecuteSW, (state, { payload }) => {
    if (!state.job
      || !state.job.sws.find(x => x.jobSWId === payload.jobSWId)
      || state.paperExecutions.find(x => x.jobSWId === payload.jobSWId)) {
      return;
    }

    const sw = state.job.sws.find(x => x.jobSWId === payload.jobSWId);

    if (!sw) {
      return;
    }

    // Create new "completed" responses for all steps in this sw.
    const swResponses = getResponsesForAllSteps(sw, payload.userEmail);
    swResponses.forEach(x => {
      x.isComplete = true;
      x.conditionalResponse = sw.steps.find(y => y.id === x.stepId)?.isConditional ? true : x.conditionalResponse;
    });

    swResponses.forEach(x => {
      sw.steps?.forEach(parent => {
          const subStep  = parent.children?.find(sub => sub.id === x.stepId);
          if (subStep ){
            x.isComplete = true;
            x.conditionalResponse = subStep.isConditional;  
          }
      });
    });

    // Remove all old step responses for this jobSWId
    // and replace with the new ones.
    state.stepResponses = [
      ...state.stepResponses
        .filter(x => x.jobSWId !== payload.jobSWId),
      ...swResponses,
    ];

    // Add the paper execution to the list.
    state.paperExecutions.push({
      userEmail: payload.userEmail,
      jobSWId: payload.jobSWId,
      timestamp: new Date(),
      imageFilename: payload.imageFilename,
      isOnServer: false,
      isDirty: true,
    });
  });

  // Set Paper Executions
  builder.addCase(setPaperExecutions, (state, { payload }) => {
    state.paperExecutions = payload;
  });

  // Set Job Paper Execution.
  builder.addCase(setJobPaperExecutions, (state, { payload }) => {
    state.jobPaperExecution = payload;
  });

  // Set Job Paper Execution Is On Server
  builder.addCase(setJobPaperExecutionIsOnServer, (state, { payload }) => {
    state.jobPaperExecution.isOnServer = payload.isOnServer;
  });

  // Set Paper Executions Are On Server
  builder.addCase(setPaperExecutionsAreOnServer, (state, { payload }) => {
    state.paperExecutions.forEach(paperEx => {
      if (payload.jobSWIds.some(jobSWId => jobSWId === paperEx.jobSWId)) {
        paperEx.isOnServer = payload.isOnServer;
      }
    })
  });

  // Begin Realtime Paper Exec Load.
  builder.addCase(beginRealtimePaperExecLoad, (state, { payload }) => {
    if (state.realtimeSWPaperExecsLoading.indexOf(payload.jobSWId) === -1) {
      state.realtimeSWPaperExecsLoading.push(payload.jobSWId);
    }
  });

  // Finish Realtime Paper Exec Load.
  builder.addCase(finishRealtimePaperExecLoad, (state, { payload }) => {
    state.paperExecutions.push(payload);

    state.realtimeSWPaperExecsLoading = state
      .realtimeSWPaperExecsLoading
      .filter(x => x !== payload.jobSWId);
  });

  // Finish Realtime Job Paper Exec Load.
  builder.addCase(finishRealtimeJobPaperExecLoad, (state, { payload }) => {
    state.jobPaperExecution = payload;
  });
  // Set Online SW User Feedback
  builder.addCase(setOnlineSWUserFeedback, (state, action) => {
    if (!state.job) {
      return;
    }

    state.job.swUserFeedback = [
      ...action.payload.feedbacks,
    ];
  });

  // Add offline SW User Feedbacks
  builder.addCase(addOfflineSWUserFeedback, (state, action) => {
    if (!state.job) {
      return;
    }

    state.swUserFeedback = [
      ...action.payload.feedbacks,
    ];
  });

  // Add SW User Feedback
  builder.addCase(addSWUserFeedback, (state, action) => {
    if (!state.job) {
      return;
    }

    const feedbacks = cloneDeep(state.job.swUserFeedback);

    let index = feedbacks.findIndex(x => x.jobId === action.payload.feedback.jobId &&
      x.swId === action.payload.feedback.swId &&
      x.version === action.payload.feedback.version &&
      x.createdBy === action.payload.feedback.createdBy)

    if (index >= 0) {
      feedbacks.splice(index, 1);
    }
    feedbacks.push(action.payload.feedback);

    state.job.swUserFeedback = [
      ...feedbacks,
    ];
  });

  // Clear SW Step Responses for JobSWId
  builder.addCase(clearSWStepResponses, (state, action) => {
    state.stepResponses = [
      ...state.stepResponses
        .filter(x => x.jobSWId !== action.payload.jobSWId)
    ];
  });

  // Clear SW Step Comments for JobSWId
  builder.addCase(clearSWStepComments, (state, action) => {
    state.stepComments = [
      ...state.stepComments
        .filter(x => x.jobSWId !== action.payload.jobSWId)
    ];
  });

  // Clear Paper Execution for JobSWId
  builder.addCase(clearSWPaperExecution, (state, action) => {
    state.paperExecutions = [
      ...state.paperExecutions
        .filter(x => x.jobSWId !== action.payload.jobSWId)
    ];
  });

  // Clear Job Paper Execution.
  builder.addCase(clearJobPaperExecution, (state, action) => {
    state.jobPaperExecution.imageFilename = "";
    state.jobPaperExecution.jobId = 0;
    state.jobPaperExecution.userEmail = "";
    state.jobPaperExecution.isOnServer = false;
    state.jobPaperExecution.isDirty = false;
    state.jobPaperExecution.timestamp = new Date();
  });
});

export default executionReducer;