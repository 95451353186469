import IdbApi from "apis/idb/IdbApi";
import Banner, { BannerType } from "components/common/Banner";
import BlockSpinner from "components/common/BlockSpinner";
import Modal from "components/common/Modal";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { resetExecution } from "store/execution/executionActions";
import { setJobModal } from "store/myJobs/myJobsActions";
import { deleteCachedJobs } from "store/offline/offlineActions";
import { showErrorToast } from "store/toast/toastActions";
import useSelector from "store/useSelector";

const UnCacheJobModal: React.FC = () => {
  const {
    myJobs: {
      unCacheModal,
    },
  } = useSelector(store => store);
  const dispatch = useDispatch();
  const [isWorking, setIsWorking] = useState(false);
  const [isJobPending, setIsJobPending] = useState(false);
  const { t } = useTranslation('uncacheJob');

  const jobId = unCacheModal.jobId;

  useEffect(() => {
    let aborted = false;
    setIsWorking(true);

    const checkJob = async () => {
      try {
        const isPending = await IdbApi.isJobAwaitingSync(jobId);

        if (!aborted) {
          setIsJobPending(isPending);
          setIsWorking(false);
        }
      } catch (err: any) {
        dispatch(showErrorToast(err.message || err));
      }
    };

    checkJob();

    return () => {
      aborted = true;
    };
  }, [jobId, setIsWorking, dispatch]);

  const onUnCacheClick = () => {
    dispatch(deleteCachedJobs({
      jobIds: [
        unCacheModal.jobId
      ]
    }));
    dispatch(resetExecution());

    onClose();
  }

  const onClose = () => {
    dispatch(setJobModal({
      modal: "unCacheModal",
      data: {
        isOpen: false,
        jobId: 0,
        jobDisplay: "",
      },
    }));
  }

  return (
    <Modal
      isOpen={true}
      header={t(`Remove Job - {jobDisplay}`, { jobDisplay: unCacheModal.jobDisplay })}
      controls={
        <>
          <button
            className="secondary-button"
            onClick={onClose}
          >
            {t('Cancel')}
          </button>
          <button
            className="primary-button"
            onClick={onUnCacheClick}
            disabled={isWorking
              || isJobPending
            }
          >
            {t('Remove')}
          </button>
        </>
      }
    >
      {(() => {
        if (isWorking) {
          return (
            <BlockSpinner />
          );
        } else if (isJobPending) {
          return (
            <Banner
              type={BannerType.Error}
            >
              {t('This job cannot be removed from your device because there is ')}
              {t('pending execution data that must first be synced. Please sync ')}
              {t('your data before removing this job.')}
            </Banner>
          );
        } else {
          return (
            <Banner
              type={BannerType.Warn}
            >
              {jobId < 0
                ? t("This is an offline-created job. If it is removed from your device, it will be deleted permanently.")
                : t("If the job is removed from your device, it will no longer be available for offline execution.")
              }
            </Banner>
          );
        }
      })()}
    </Modal>
  );
};

export default UnCacheJobModal;