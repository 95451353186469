import React, { useState } from "react";
import "./ExecutionJobHeader.scoped.scss";
import useSelector from "store/useSelector";
import JobIsCompletedBanner from "./completion/JobIsCompletedBanner";
import { SWPJobStatus } from "interfaces/jobs/JobInterfaces";
import ExecutionToolbar from "./toolbar/ExecutionToolbar";
import { ExecutionViews } from "./Execution";
import IdbApi from "apis/idb/IdbApi";
import { showErrorToast } from "store/toast/toastActions";
import { useDispatch } from "react-redux";
import refreshIcon from "media/icons/dls/refresh.svg";
import SwitchExecutorButton from "./executor/SwitchExecutorButton";
import ExecutorBanner from "./executor/ExecutorBanner";
import SwitchExecutorModal from "./executor/SwitchExecutorModal";
import { useTranslation } from "react-i18next";

interface IJobHeaderProps {
  currentView: ExecutionViews,
  setCurrView(view: ExecutionViews): void,
}

const JobHeader: React.FC<IJobHeaderProps> = ({ currentView, setCurrView }) => {
  const {
    job,
    executor,
    stepResponses
  } = useSelector(store => store.execution);
  const dispatch = useDispatch();
  const [isPending, setIsPending] = useState(false);
  const { t } = useTranslation('execution');

  if (!job) {
    return (
      <span>{t('No job is currently loaded.')}</span>
    );
  }

  let jobDisplay = (job.jobNumber ? job.jobNumber + ": " : "") + job.title;
  const jobId = job.id;
  
  const getTotalDeviationCount = () =>{
    let count = 0;
    let activeJobSWIds = job.sws;
    let stepsWithDeviations = stepResponses.filter(r => r.isDeviation
       && activeJobSWIds.find(p => p.jobSWId === r.jobSWId) !== undefined);
      if (stepsWithDeviations 
        && stepsWithDeviations.length > 0)
      {
        count = stepsWithDeviations.length;
      }
    return count;
  }

  const checkIfJobIsPending = async () => {
    try {
      // Ask IdbApi if job is pending sync.
      setIsPending(await IdbApi.isJobAwaitingSync(jobId));
    } catch (err: any) {
      dispatch(showErrorToast(err.message));
    }
  }

  checkIfJobIsPending();

  return (
    <>
      {job?.status === SWPJobStatus.Completed &&
        <JobIsCompletedBanner
          job={job}
        />
      }
      <h2 className="page-title">
        <span
          className="job-name"
        >
          {jobDisplay}
        </span>
        <span
          className="total-deviations"
        >
          <span>
            {t('TotalDeviations ')}
            {getTotalDeviationCount()}
          </span>          
        </span>
        {isPending &&
          <span
            className="pending-sync"
          >
            <img
              src={refreshIcon}
              alt=""
            />
            <span>
              {t('Pending sync')}
            </span>
          </span>
        }
      </h2>
      {executor &&
        <div
          className="executor-banner"
        >
          <ExecutorBanner />
        </div>
      }
      <div className="controls">
        <div className="buttons">
          <ExecutionToolbar
            currentView={currentView}
            onViewButtonClick={(view: ExecutionViews) => setCurrView(view)}
          />
        </div>
        {job.status !== SWPJobStatus.Completed 
          && job.status !== SWPJobStatus.Cancelled &&
          <SwitchExecutorButton />
        }        
      </div>
      <SwitchExecutorModal />
    </>
  );
}

export default JobHeader;