import React from "react";
import { useDispatch } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { IStepComponent, StepComponentTypes } from "interfaces/sw/SWInterfaces";
import { setComponentResponse } from "store/execution/executionActions";
import { ComponentResponseType } from "interfaces/execution/executionInterfaces";
import useSelector from "store/useSelector";
import { IStepIdentifier } from "store/execution/executionTypes";
import { getComponentResponse } from "utilities/swUtilities";

interface IDateInputProps {
  component: IStepComponent,
  isDisabled: boolean,
  stepInfo: IStepIdentifier,
}

const DateInput: React.FC<IDateInputProps> = ({ component, isDisabled, stepInfo }) => {
  const dispatch = useDispatch();
  const {
    auth: {
      currentUser: {
        email,
      },
    },
    execution: {
      stepResponses,
      executor,
    },
  } = useSelector(store => store);

  if (component.type !== StepComponentTypes.DateInput
    && component.type !== StepComponentTypes.DateTimeInput) {
    return null;
  }

  const onDateValueChange = (value: Date | null) => {
    if (isDisabled) {
      return;
    }

    let respType: ComponentResponseType;

    if (component.type === StepComponentTypes.DateInput) {
      respType = ComponentResponseType.Date;
    } else if (component.type === StepComponentTypes.DateTimeInput) {
      respType = ComponentResponseType.DateTime;
    } else {
      throw new Error(`DateInput rendered with type: ${component.type}`);
    }

    dispatch(setComponentResponse({
      response: {
        id: component.id,
        type: respType,
        values: value === null
          ? [""]
          : [value.toJSON()],
        isDeviation: false,
      },
      stepInfo,
      userEmail: executor?.email || email,
    }));
  }

  const compResponseValues = getComponentResponse(stepInfo,
    component.id,
    stepResponses)
    ?.values;

  let selectedDate = compResponseValues?.[0]
    ? new Date(compResponseValues[0])
    : undefined;

  let control: JSX.Element | undefined;

  let datePicker = (
    <DatePicker
      id={component.id}
      selected={selectedDate}
      onChange={onDateValueChange}
      inline
      showTimeSelect={component.type === StepComponentTypes.DateTimeInput}
      timeFormat="HH:mm"
      timeIntervals={15}
      timeCaption="time"
      showMonthDropdown
      showYearDropdown
      dropdownMode="select"
    />
  );

  if (isDisabled) {
    control = (
      <div className="date-picker-holder">
        {datePicker}
        <div className="date-picker-disabler" />
      </div>
    )
  } else {
    control = datePicker;
  }

  return (
    <div>
      <label>{component.label}</label>
      {control}
    </div>
  );
}

export default DateInput;