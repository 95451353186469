import { IStepComment, IStepResponse, SWStatus } from "interfaces/execution/executionInterfaces";
import { IExecutionJob, IExecutionJobSW, IExecutionLocation, IStepIdentifier } from "store/execution/executionTypes";
import { IStep, SWTypes } from "interfaces/sw/SWInterfaces";

export function shouldStepBeDisabled(sw: IExecutionJobSW, stepId: string, stepResponses: IStepResponse[]): boolean {
  for (let stepIx = 0; stepIx < sw.steps.length; stepIx++) {
    const step = sw.steps[stepIx];
    const stepResponse = stepResponses
      .find(r => r.jobSWId === sw.jobSWId
        && r.stepId === step.id);

    if (step.id === stepId) {
      // Top level steps are never disabled by a parent.
      return stepResponse?.isComplete || false;
    }

    for (let subIx = 0; subIx < step.children.length; subIx++) {
      const subStep = step.children[subIx];

      if (subStep.id === stepId) {
        // If the parent has no positive conditionalResponse and is conditional,
        // then this step should be disabled.
        if (step.isConditional
          && !stepResponse?.conditionalResponse) {
          return true;
        } else {
          return false;
        }
      }

      const subResponse = stepResponses
        .find(r => r.jobSWId === sw.jobSWId
          && r.stepId === subStep.id);

      for (let subSubIx = 0; subSubIx < subStep.children.length; subSubIx++) {
        const subSubStep = subStep.children[subSubIx];

        if (subSubStep.id === stepId) {
          // If any ancestor has no positive conditionalResponse and is conditional,
          // then this step should be disabled.
          if ((step.isConditional
            && !stepResponse?.conditionalResponse)
            || (subStep.isConditional
              && !subResponse?.conditionalResponse)) {
            return true;
          } else {
            return false;
          }
        }
      }
    }
  }

  return false;
}

export function getSWStatus(sw: IExecutionJobSW, stepResponses: IStepResponse[]): SWStatus {
  let naCount = 0;
  let completedCount = 0;
  // Check the sw status if steps under it are critical.

  for (let stepIx = 0; stepIx < sw.steps.length; stepIx++) {
    const step = sw.steps[stepIx];

    const stepResponse = stepResponses
      .find(r => r.jobSWId === sw.jobSWId
        && r.stepId === step.id

      );

    if (!stepResponse?.isComplete
      && step.showStep) {
      return SWStatus.Incomplete;
    } else {
      completedCount++;
    }

    if (stepResponse?.isNA || stepResponse === undefined) {
      naCount++;
    }

    if (step.isConditional
      && !stepResponse?.conditionalResponse) {
      // If this is a conditional step but it has no positive
      // conditional response, then the children are disabled
      // and their responses are ignored.
      continue;
    }

    for (let subIx = 0; subIx < step.children.length; subIx++) {
      const subStep = step.children[subIx];

      const subResponse = stepResponses
        .find(r => r.jobSWId === sw.jobSWId
          && r.stepId === subStep.id
        );

      if (!subResponse?.isComplete
        && subStep.showStep) {
        return SWStatus.Incomplete;
      } else {
        completedCount++;
      }

      if (subResponse?.isNA || subResponse === undefined) {
        naCount++;
      }

      if (subStep.isConditional
        && !subResponse?.conditionalResponse) {
        // If this is a conditional step but it has no positive
        // conditional response, then the children are disabled
        // and their responses are ignored.
        continue;
      }

      for (let subSubIx = 0; subSubIx < subStep.children.length; subSubIx++) {
        const subSubStep = subStep.children[subSubIx];

        const subSubResponse = stepResponses
          .find(r => r.jobSWId === sw.jobSWId
            && r.stepId === subSubStep.id

          );

        if (!subSubResponse?.isComplete
          && subSubStep.showStep) {
          return SWStatus.Incomplete;
        } else {
          completedCount++;
        }

        if (subSubResponse?.isNA || subSubResponse === undefined) {
          naCount++;
        }
      }
    }
  }

  if (completedCount === naCount) {
    return SWStatus.NA;
  }

  return SWStatus.Completed;
}

export function findTaskThatNeedsResponse(TLMSW: IExecutionJobSW,
  stepId: string,
  stepResponses: IStepResponse[],
  isComplete: boolean): IStepIdentifier | null {
  let theTask = null;

  // foreach over the tasks
  for (let taskIx = 0; taskIx < TLMSW.steps.length; taskIx++) {
    const task = TLMSW.steps[taskIx];
    if (task.id === stepId) {
      // this is the task itself, nothing to return.
      return null;
    }

    // foreach over the top level steps
    if (task.children.length) {
      for (let stepIx = 0; stepIx < task.children.length; stepIx++) {
        const step = task.children[stepIx];
        if (step.id === stepId) {
          theTask = task;
        }

        // foreach over the sub steps
        if (step.children.length) {
          for (let subStepIx = 0; subStepIx < step.children.length; subStepIx++) {
            const subStep = step.children[subStepIx];
            if (subStep.id === stepId) {
              theTask = task;
            }

            //foreach over the sub-sub steps
            if (subStep.children.length) {
              for (let subSubStepIx = 0; subSubStepIx < subStep.children.length; subSubStepIx++) {
                const subSubStep = subStep.children[subSubStepIx];
                if (subSubStep.id === stepId) {
                  theTask = task;
                }
              }
            }
          }
        }
      }
    }
  }

  // figure out if the task has all its steps completed.
  if (theTask) {
    // if we are reopening a step, just return the task.
    if (!isComplete) {
      return {
        jobSWId: TLMSW.jobSWId,
        stepId: theTask.id,
      };
    }
    if (theTask.children.length) {
      for (let stepIx = 0; stepIx < theTask.children.length; stepIx++) {
        const step = theTask.children[stepIx];
        const stepResponse = stepResponses
          .find(r => r.jobSWId === TLMSW.jobSWId
            && r.stepId === step.id);
        if (!stepResponse?.isComplete) {
          return null;
        }

        if (step.children.length) {
          for (let subStepIx = 0; subStepIx < step.children.length; subStepIx++) {
            const subStep = step.children[subStepIx];
            const subStepResponse = stepResponses
              .find(r => r.jobSWId === TLMSW.jobSWId
                && r.stepId === subStep.id);
            if (!subStepResponse?.isComplete) {
              return null;
            }

            if (subStep.children.length) {
              for (let subSubStepIx = 0; subSubStepIx < subStep.children.length; subSubStepIx++) {
                const subSubStep = subStep.children[subSubStepIx];
                const subSubStepResponse = stepResponses
                  .find(r => r.jobSWId === TLMSW.jobSWId
                    && r.stepId === subSubStep.id);
                if (!subSubStepResponse?.isComplete && (!subStep.isConditional || subStepResponse?.conditionalResponse)) {
                  return null;
                }
              }
            }
          }
        }
      }
    }

    return {
      jobSWId: TLMSW.jobSWId,
      stepId: theTask.id,
    };
  }

  return null;
}

export function findNextIncompleteStep(job: IExecutionJob,
  currLocation: IExecutionLocation | null,
  stepResponses: IStepResponse[]): IExecutionLocation | null {
  let nextLocation: IExecutionLocation | null = null;
  let foundStartSW = currLocation === null;
  let foundStartStep = currLocation === null;

  for (let swIx = 0; swIx < job.sws.length; swIx++) {
    let sw = job.sws[swIx];

    if (!sw.type) {
      continue;
    }

    // Search until the start point is found.
    if (!foundStartSW
      && currLocation !== null) {
      if (sw.jobSWId === currLocation.jobSWId) {
        foundStartSW = true;
      } else {
        continue;
      }
    }
    for (let stepIx = 0; stepIx < sw.steps.length; stepIx++) {
      const step = sw.steps[stepIx];

      // Continue until after the starting step is found.
      if (!foundStartStep
        && currLocation !== null
        && step.showStep) {
        if (step.id === currLocation.stepId) {
          foundStartStep = true;
        }
      }

      const stepResponse = stepResponses
        .find(r => r.jobSWId === sw.jobSWId
          && r.stepId === step.id);

      // Check if this step is incomplete.
      if (!stepResponse?.isComplete
        && foundStartStep
        && step.showStep) {
        // This step appears after the current step and is incomplete.
        return {
          jobSWId: sw.jobSWId,
          stepId: step.id,
        };
      }

      // If this step has substeps and is not conditional
      // or if there's a positive conditional response,
      // check the substeps.
      if (step.children.length
        && (!step.isConditional
          || stepResponse?.conditionalResponse)) {
        for (let subIx = 0; subIx < step.children.length; subIx++) {
          const sub = step.children[subIx];

          if (!foundStartStep
            && currLocation !== null
            && sub.showStep) {
            if (sub.id === currLocation.stepId) {
              foundStartStep = true;
            }
          }

          const subResponse = stepResponses
            .find(r => r.jobSWId === sw.jobSWId
              && r.stepId === sub.id);

          if (!subResponse?.isComplete
            && foundStartStep
            && sub.showStep) {
            return {
              jobSWId: sw.jobSWId,
              stepId: sub.id,
            };
          }

          // If this substep has substeps and is not conditional
          // or if there's a positive conditional response,
          // check the substeps.
          if (sub.children.length
            && (!sub.isConditional
              || subResponse?.conditionalResponse)) {
            for (let subSubIx = 0; subSubIx < sub.children.length; subSubIx++) {
              const subSub = sub.children[subSubIx];

              if (!foundStartStep
                && currLocation !== null) {
                // Is this sub-sub-step the starting location?
                // If not, skip it.
                if (subSub.id === currLocation.stepId) {
                  foundStartStep = true;
                }
              }

              const subSubResponse = stepResponses
                .find(r => r.jobSWId === sw.jobSWId
                  && r.stepId === subSub.id);

              if (!subSubResponse?.isComplete
                && foundStartStep
                && subSub.showStep) {
                return {
                  jobSWId: sw.jobSWId,
                  stepId: subSub.id,
                };
              }

              // If this subsubstep has substeps and is not conditional
              // or if there's a positive conditional response,
              // check the substeps.
              if (subSub.children.length
                && (!subSub.isConditional
                  || subSubResponse?.conditionalResponse)) {
                for (let subSubSubIx = 0; subSubSubIx < subSub.children.length; subSubSubIx++) {
                  const subSubSub = subSub.children[subSubIx];

                  if (!foundStartStep
                    && currLocation !== null) {
                    // Is this sub-sub-sub-step the starting location?
                    // If not, skip it.
                    if (subSubSub.id === currLocation.stepId) {
                      foundStartStep = true;
                    } else {
                      continue;
                    }
                  }

                  const subSubSubResponse = stepResponses
                    .find(r => r.jobSWId === sw.jobSWId
                      && r.stepId === subSubSub.id);

                  if (!subSubSubResponse?.isComplete
                    && foundStartStep
                    && subSubSub.showStep) {
                    return {
                      jobSWId: sw.jobSWId,
                      stepId: subSubSub.id,
                    };
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  return nextLocation;
}

export function doesStepHaveIncompleteInput(step: IStep, stepResponse: IStepResponse | undefined, type: SWTypes | undefined): boolean {
  if (step.isConditional
    && stepResponse?.conditionalResponse === undefined) {
    return true;
  }

  const inputComponents = step.components
    .filter(x => x.type.endsWith("Input"));

  for (let i = 0; i < inputComponents.length; i++) {
    const compResponse = stepResponse?.componentResponses?.find(x => x.id === inputComponents[i].id);

    if (!compResponse
      || compResponse.values.length === 0
      || compResponse.values.some(x => x === "" || x === undefined || x.length === 0)) {
      return true;
    }
  }

  // if we have reopened a step for a CL/LCL 
  if ((stepResponse?.conditionalResponse === undefined
    || stepResponse?.conditionalResponse === null)
    && (type === SWTypes.CL || type === SWTypes.LCL)) {
    if (stepResponse?.componentResponses?.length === 0) {
      return true;
    }
  }

  return false;
}

export function getResponsesForAllSteps(sw: IExecutionJobSW, userEmail: string): IStepResponse[] {
  let responses: IStepResponse[] = [];

  if (sw.hasCriticalSteps) {
    getCritialResponsesRecursive(sw.steps, sw, userEmail, responses);
  }
  else {
    getResponsesRecursive(sw.steps, sw, userEmail, responses);
  }

  return responses;
}

function getResponsesRecursive(steps: IStep[], sw: IExecutionJobSW, userEmail: string, responses: IStepResponse[]) {
  for (let i = 0; i < steps.length; i++) {
    // Add a response for this step.
    responses.push({
      jobSWId: sw.jobSWId,
      stepId: steps[i].id,
      isComplete: false,
      isNA: false,
      isDirty: true,
      timestamp: new Date(),
      userEmail,
      isDeviation: false,
    });

    // If this step has children, add responses for them too.
    if (steps[i].children.length) {
      getResponsesRecursive(steps[i].children,
        sw,
        userEmail,
        responses);
    }
  }
}

function getCritialResponsesRecursive(steps: IStep[], sw: IExecutionJobSW, userEmail: string, responses: IStepResponse[]) {
  for (let i = 0; i < steps.length; i++) {

    if ((steps[i].isCritical || steps[i].children.filter(x => x.isCritical).length) && !responses.find(x => x.stepId === steps[i].id)) {
      // Add a response for this step.
      responses.push({
        jobSWId: sw.jobSWId,
        stepId: steps[i].id,
        isComplete: false,
        isNA: false,
        isDirty: true,
        timestamp: new Date(),
        userEmail,
        isDeviation: false,
      });

      if (steps[i].parentId !== undefined &&
        !responses.find(x => x.stepId === steps[i].parentId)) {
        responses.push({
          jobSWId: sw.jobSWId,
          stepId: steps[i].parentId!,
          isComplete: false,
          isNA: false,
          isDirty: true,
          timestamp: new Date(),
          userEmail,
          isDeviation: false,
        });
      }

    }

    // If this step has children, add responses for them too.
    if (steps[i].children.length) {
      getCritialResponsesRecursive(steps[i].children,
        sw,
        userEmail,
        responses);
    }
  }
}

export function getSWInstanceNumber(jobSWId: number, jobSWs: IExecutionJobSW[]) {
  const sw = jobSWs.find(x => x.jobSWId === jobSWId);

  if (!sw) {
    return 0;
  }

  const matchSWs = jobSWs.filter(x => x.id === sw.id);
  const ix = matchSWs.findIndex(x => x.jobSWId === jobSWId);

  return matchSWs.length > 1
    ? ix + 1
    : 0;
}

export function getSWInstanceTitle(jobSWId: number, jobSWs: IExecutionJobSW[]) {
  const sw = jobSWs.find(x => x.jobSWId === jobSWId);

  if (!sw) {
    return "";
  }

  const instanceNum = getSWInstanceNumber(sw.jobSWId, jobSWs);
  return `${sw.title}${instanceNum > 0
    ? ` (Instance ${instanceNum})`
    : ""
    }`;
}

export function getStepResponse(stepInfo: IStepIdentifier,
  stepResponses: IStepResponse[]) {
  return stepResponses
    .find(x => x.stepId === stepInfo.stepId
      && x.jobSWId === stepInfo.jobSWId);
}

export function getComponentResponse(stepInfo: IStepIdentifier,
  componentId: string,
  stepResponses: IStepResponse[]) {
  return getStepResponse(stepInfo, stepResponses)
    ?.componentResponses
    ?.find(x => x.id === componentId);
}

export function getStepComments(stepInfo: IStepIdentifier,
  stepComments: IStepComment[]) {
  return stepComments
    .filter(x => x.stepId === stepInfo.stepId
      && x.jobSWId === stepInfo.jobSWId);
}

export function getStepFromSwSteps(swSteps: IStep[], stepId: string) {
  var stepFound = swSteps.find(r => r.id === stepId);
  if (stepFound)
    return stepFound;

  for (var i = 0; i < swSteps.length; i++) {
    var childSteps = swSteps[i].children;
    if (childSteps
      && childSteps.length > 0
      && childSteps.find(r => r.id === stepId)) {
      stepFound = childSteps.find(r => r.id === stepId);
      break;
    }
    for (var j = 0; j < childSteps.length; j++) {
      var gChildSteps = childSteps[j].children;
      if (gChildSteps
        && gChildSteps.length > 0
        && gChildSteps.find(r => r.id === stepId)) {
        stepFound = gChildSteps.find(r => r.id === stepId);
        break;
      }
    }
    if (stepFound) {
      break;
    }
  }
  return stepFound;
}