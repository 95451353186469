import React from "react";
import Modal from "components/common/Modal";
import useSelector from "store/useSelector";
import Banner, { BannerType } from "components/common/Banner";
import { useDispatch } from "react-redux";
import { startNewJob } from "store/manageJob/manageJobActions";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const JobLoadErrorMessageModal: React.FC = () => {
  const state = useSelector(state => state.manageJob);
  const dispatch = useDispatch();
  const { t } = useTranslation('myJobs')

  if (state.getErrorMessage == null) {
    return null;
  }

  return (
    <Modal
      header="Error"
      isOpen={state.getErrorMessage !== ""}
      controls={
        <Link
          to="/jobs/mine"
          onClick={() => dispatch(startNewJob())}
        >
          <button className="primary-button">
            {t('My Jobs')}
          </button>
        </Link>
      }>
      <Banner type={BannerType.Error}>
        <p>{t('Failed to load the job.')}</p>
        <p>
          {state.getErrorMessage.toString()}
        </p>
      </Banner>
    </Modal>
  );
}

export default JobLoadErrorMessageModal;