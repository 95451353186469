import { IStepComment } from "interfaces/execution/executionInterfaces";
import React from "react";
import { isFilenameImage } from "utilities/fileUtilities";
import { formatNumericalDate } from "utilities/formatDate";
import "./PrintComment.scoped.scss";
import PrintImage, { PrintImageSource } from "./PrintImage";
import attachmentIcon from "media/icons/dls/attachment.svg";
import { useTranslation } from "react-i18next";

interface IPrintComment {
  stepComment: IStepComment,
  onClickAttachment(filename: string): void,
}

const PrintComment: React.FC<IPrintComment> = ({
  stepComment,
  onClickAttachment,
}) => {
  const { t } = useTranslation('printView');

  const images = stepComment
    .attachments
    .filter(x => isFilenameImage(x.filename));

  const attachments = stepComment
    .attachments
    .filter(x => !isFilenameImage(x.filename));

  return (
    <div
      className="step-comment"
    >
      <span
        className="header"
      >
        <span
          className="name"
        >
          {stepComment.userEmail}
        </span>
        {t('commented on {date}', { date: formatNumericalDate(stepComment.timestamp, true) })}
      </span>

      <div
        className="comment"
      >
        {stepComment
          .comment
          .split('\n')
          .map((line, ix) => (
            <div key={ix}>
              {line}
            </div>
          ))
        }
      </div>

      {images.length > 0 &&
        <div
          className="att-bar"
        >
          {images.map(x => (
            <PrintImage
              key={x.filename}
              filename={x.filename}
              source={PrintImageSource.CommentAttachment}
            />
          ))}
        </div>
      }

      {attachments.length > 0 &&
        <div
          className="att-bar"
        >
          {attachments.map(x =>
            <span
              onClick={() => onClickAttachment(x.filename)}
              key={x.filename}
              className="attachment"
            >
              <span>
                <img
                  src={attachmentIcon}
                  className="icon-small"
                  alt=""
                />
                {x.filename}
              </span>
            </span>
          )}

        </div>
      }
    </div>
  );
}

export default PrintComment;