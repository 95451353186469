import React from "react";
import { useTranslation } from "react-i18next";
import "./NotApplicableControls.scoped.css";

interface INotApplicableControlsProps {
  isStepNA: boolean,
  disabled: boolean,
  onClick(): void,
}

const NotApplicableControls: React.FC<INotApplicableControlsProps> = (props) =>
{
  const { t } = useTranslation('step');
  return(
    <button
      onClick={props.disabled
        ? undefined
        : props.onClick}
      disabled={props.disabled}
      className="secondary-button"
    >
      {props.isStepNA ? t("Unmark") : t("Mark")} {t('Step Not Applicable')}
    </button>);
}

export default NotApplicableControls;