import React, { useEffect } from "react";
import { ITableComponentCellData } from "interfaces/sw/SWInterfaces";
import "./TableComponentCell.scoped.scss";
//import sanitizeHtml from 'sanitize-html-react';
import { useDispatch } from "react-redux";
import IdbApi from "apis/idb/IdbApi";
import { showErrorToast } from "store/toast/toastActions";
import { setTableCellImage } from "store/execution/executionActions";
import { IStepIdentifier } from "store/execution/executionTypes";

interface ITableComponentCellProps {
  rowKey: number,
  colKey: number,
  cells: ITableComponentCellData[],
  swId: string,
  swVersion: string,
  stepInfo: IStepIdentifier,
  componentId: string,
}

const TableComponentCell: React.FC<ITableComponentCellProps> = ({
  rowKey,
  colKey,
  cells,
  swId,
  swVersion,
  stepInfo,
  componentId,
}) => {
  const dispatch = useDispatch();
  let existingCell = cells.find(x => x.rowIndex === rowKey && x.colIndex === colKey);
  let filename = "";

  if (existingCell?.value) {
    let cellImage = getImage(existingCell.value);

    if (cellImage) {
      filename = cellImage;
    }
  }

  useEffect(() => {
    let aborted = false;
    const fetchImage = async () => {
      try {
        const img = await IdbApi.getSWImageData(swId, swVersion, filename);
        if (!aborted) {
          dispatch(setTableCellImage({
            rowKey: rowKey,
            colKey: colKey,
            swId: swId,
            swVersion: swVersion,
            imageData: img?.data,
            stepInfo: stepInfo,
            componentId: componentId,
          }));
        }
      } catch (err: any) {
        if (!aborted) {
          showErrorToast(err?.message || err.toString())
        }
      }
    }

    if (filename !== "") {
      fetchImage();
    }

    return () => {
      aborted = true;
    };
  }, [existingCell, dispatch, filename, swId, swVersion, colKey, rowKey, componentId, stepInfo]);

  if (!existingCell) {
    return null;
  }

  return (
    <div
      className="execution-editor"
      dangerouslySetInnerHTML={createMarkup(existingCell.value)}
    />
  ); 
}

export default TableComponentCell;

function createMarkup(data: string) {
  // const cleanHtml = sanitizeHtml(data, {
  //   allowedTags: ['p', 'ul', 'li', 'ol', 'b', 'i', 'a', 'strong', 'em', 'img'],
  //   allowedAttributes: {
  //     'a': ['href', 'name', 'target'],
  //     'img': ['src', 'alt', 'style']
  //   },
  // });

  return { __html: data };
}

function getImage(html: string): string | undefined {
  const imgRex = /<img.*?alt="(.*?)"[^>]+>/g;
    let img;
    while ((img = imgRex.exec(html))) {
        return img[1];      
    }
  return undefined;
}