import { formatCountry, formatCustomer, formatGeoUnit, formatOrganization, formatSubBusinessLine, formatSubBusinessLines } from "apis/masterData/masterDataFormatters";
import { ISWPJob, ISWPJobCreationResponse, ISWPJobHeader, ISWPJobTeamMember, ISWPJobSW, ISWPUpdateJobResponse, ISWPJobLogItem, SWPJobType, IJobTemplateHeader, SWPJobStatus, ISWPCreatedJobSWResponse, IJobSchedule, JobScheduleType, ISWUserFeedback, ISWPJobDoc } from "interfaces/jobs/JobInterfaces";
import { IInProgressJob } from "store/manageJob/manageJobTypes";

export function formatJobForCreation(job: IInProgressJob,
  bypassSWVersionValidation: boolean): any {
  return {
    JobNumber: job.jobNumber,
    Title: job.title,
    IsDemo: job.isDemo,
    SWs: job.sws.map((sw, ix) => ({
      SWId: sw.swId,
      Version: sw.version,
      SortOrder: ix + 1,
    })),
    TeamMembers: job.team.map(t => ({
      Name: t.name,
      Email: t.email,
    })),
    Type: job.type,
    CountryGuid: job.country?.guid,
    OrgGuid: job.org?.guid,
    GeoUnitCode: job.geoUnit?.guid,
    SubBusinessLineCode: job.subBusinessLine?.guid,
    CustomerId: job.customer?.guid,
    BypassSWVersionValidation: bypassSWVersionValidation,
    Schedule: jobScheduleToServerObj(job.schedule),
    JobDocs: job.jobDocs.map(x => ({
      FileName: x.fileName,
      FileContent: x.fileContent,
      FileSize: x.fileSize,
    })),
    IsDuplicateJob: false,
  };
}

export function formatJobForUpdation(job: IInProgressJob): any {
  return {
    id: job.id,
    JobNumber: job.jobNumber,
    Title: job.title,
    IsDemo: job.isDemo,
    SWs: job.sws.map((sw, ix) => ({
      JobSWId: sw.jobSWId,
      SWId: sw.swId,
      Title: sw.title,
      Version: sw.version,
      SortOrder: ix + 1,
    })),
    TeamMembers: job.team.map(t => ({
      Name: t.name,
      Email: t.email,
    })),
    JobDocs: job.jobDocs.map(t => ({
      FileName: t.fileName,
      FileContent: t.fileContent,
      FileSize: t.fileSize,
    })),
    CountryGuid: job.country?.guid,
    OrgGuid: job.org?.guid,
    GeoUnitCode: job.geoUnit?.guid,
    SubBusinessLineCode: job.subBusinessLine?.guid,
    CustomerId: job.customer?.guid,
    Schedule: jobScheduleToServerObj(job.schedule),
  };
}

function jobScheduleToServerObj(schedule?: IJobSchedule): any {
  return schedule === undefined
    ? undefined : {
      Type: schedule.type,
      StartDate: schedule.startDate,
      EndDate: schedule.endDate,
      TotalOccurrences: schedule.totalOccurrences,
      EveryX: schedule.everyX,
      DayOfMonth: schedule.dayOfMonth,
      WeeklySun: schedule.weeklySun,
      WeeklyMon: schedule.weeklyMon,
      WeeklyTue: schedule.weeklyTue,
      WeeklyWed: schedule.weeklyWed,
      WeeklyThu: schedule.weeklyThu,
      WeeklyFri: schedule.weeklyFri,
      WeeklySat: schedule.weeklySat,
    };
}

export function formatCreateJobResponse(json: any): ISWPJobCreationResponse {
  return {
    jobId: json.JobId ? parseInt(json.JobId, 10) : 0,
    message: json.Message ? json.Message : null,
    success: json.Success,
    jobSWs: Array.isArray(json.JobSWs)
      ? json.JobSWs.map((x: any): ISWPCreatedJobSWResponse => ({
        jobSWId: x.JobSWId,
        swId: x.SWId,
        version: x.Version,
        sortOrder: x.SortOrder,
      }))
      : [],
  };
}

export function formatUpdateJobResponse(json: any): ISWPUpdateJobResponse {
  return {
    message: json.Message ? json.Message : null,
    success: json.Success,
  };
}

export function formatMyJobsResponse(response: any): ISWPJobHeader[] {
  if (response.JobHeaders && response.JobHeaders.length > 0) {
    return response.JobHeaders.map((job: any): ISWPJobHeader => ({
      id: job.Id,
      jobNumber: job.JobNumber,
      title: job.Title,
      isDemo: job.IsDemo,
      status: job.Status,
      lastActionDate: new Date(job.LastActionDate),
      completedBy: null,
      isOnTeam: job.IsOnTeam,
      deviationCount: job.DeviationCount,
      totalSteps: job.TotalSteps,
      totalCompletedSteps: job.TotalCompletedSteps,
      completionPercentage: job.CompletionPercentage,
    }));
  } else {
    return [];
  }
}

export function formatJobStatus(status: any): SWPJobStatus {
  if (status === SWPJobStatus.New) {
    return SWPJobStatus.New;
  } else if (status === SWPJobStatus.Completed) {
    return SWPJobStatus.Completed;
  } else if (status === SWPJobStatus.InProgress) {
    return SWPJobStatus.InProgress;
  }

  throw new Error(`Invalid job status from server: ${status}`);
}

export function formatGetJobTemplatesResponse(response: any): IJobTemplateHeader[] {
  if (response.Templates
    && response.Templates.length > 0) {
    return response.Templates.map((temp: any): IJobTemplateHeader => ({
      id: temp.Id,
      jobNumber: temp.JobNumber,
      title: temp.Title,
      canEdit: temp.CanEdit,
      countryGuid: temp.CountryGuid,
      geoUnitCode: temp.GeoUnitCode,
      subBusinessLineCode: temp.SubBusinessLineCode,
    }));
  } else {
    return [];
  }
}

export function formatGetJobResponse(response: any): ISWPJob {
  let job = response.Job;
  let sws = response.SWs
    .sort((a: any, b: any) => a.SortOrder < b.SortOrder ? -1 : 1);
  let teamMembers = response.TeamMembers;
  let userFeedback = response.SWUserFeedback;
  let jobDocs = response.JobDocs;

  return {
    id: job.Id,
    jobNumber: job.JobNumber,
    title: job.Title,
    isDemo: job.IsDemo,
    type: formatSWPJobType(job.Type),
    status: job.Status,
    team: teamMembers?.length > 0
      ? teamMembers.map((t: any) => formatJobTeamMember(t))
      : [],
    sws: sws?.length > 0
      ? sws.map((sw: any) => formatSWPJobSW(sw))
      : [],
    swUserFeedback: userFeedback?.length > 0
      ? userFeedback.map((feedback: any) => formatSWUserFeedback(feedback))
      : [],
    lastActionDate: job.LastActionDate,
    org: job.Org
      ? formatOrganization(job.Org)
      : undefined,
    country: job.Country
      ? formatCountry(job.Country)
      : undefined,
    geoUnit: job.GeoUnit
      ? formatGeoUnit(job.GeoUnit)
      : undefined,
    subBusinessLine: job.SubBusinessLine
      ? formatSubBusinessLine(job.SubBusinessLine)
      : undefined,
    customer: job.Customer
      ? formatCustomer(job.Customer)
      : undefined,
    startTime: response.StartTime
      ? new Date(response.StartTime)
      : undefined,
    endTime: response.EndTime
      ? new Date(response.EndTime)
      : undefined,
    completedBy: null,
    schedule: response.Schedule
      ? formatJobSchedule(response.Schedule)
      : undefined,
    isOnTeam: job.isOnTeam,
    showAllECLs: job.ShowAllECLs,
    foundStartJobSWId: response.FoundStartJobSWId,
    jobDocs: jobDocs?.length > 0
      ? jobDocs.map((jobDoc: any) => formatSWPJobDoc(jobDoc))
      : [],
    deviationCount: job.deviationCount ? job.deviationCount : 0,
    totalSteps: job.CompletionData ? job.CompletionData.TotalSteps : 0,
    totalCompletedSteps: job.CompletionData ? job.CompletionData.TotalCompletedSteps : 0,
    completionPercentage: job.CompletionData ? job.CompletionData.CompletionPercentage : 0,
  };
}

function formatJobSchedule(schedule: any): IJobSchedule {
  let scheduleType: JobScheduleType;
  if (schedule.Type === "Weekly"
    || schedule.Type === "Daily"
    || schedule.Type === "Monthly") {
    scheduleType = schedule.Type as JobScheduleType;
  } else {
    throw Error(`Unknown job schedule type: ${schedule.Type}`);
  }

  return {
    type: scheduleType,
    startDate: new Date(schedule.StartDate),
    endDate: schedule.EndDate
      ? new Date(schedule.EndDate)
      : undefined,
    totalOccurrences: schedule.TotalOccurrences || undefined,
    everyX: schedule.EveryX,
    dayOfMonth: schedule.DayOfMonth || undefined,
    weeklySun: schedule.WeeklySun,
    weeklyMon: schedule.WeeklyMon,
    weeklyTue: schedule.WeeklyTue,
    weeklyWed: schedule.WeeklyWed,
    weeklyThu: schedule.WeeklyThu,
    weeklyFri: schedule.WeeklyFri,
    weeklySat: schedule.WeeklySat,
  };
}

export function formatSWPJobType(type: any): SWPJobType {
  if (type === "Standard") {
    return SWPJobType.Standard;
  } else if (type === "Template") {
    return SWPJobType.Template;
  } else if (type === "Recurring") {
    return SWPJobType.Recurring;
  }

  throw new Error(`Unknown job type: ${type}`);
}

export function formatSWPJobSW(sw: any): ISWPJobSW {
  return {
    jobSWId: sw.JobSWId,
    swId: sw.SWId,
    title: sw.Title,
    version: sw.Version,
    description: sw.Description,
    sortOrder: sw.SortOrder,
  };
}

export function formatSWPJobDoc(jobDoc: any): ISWPJobDoc {
  return {
    fileName: jobDoc.FileName,
    fileContent: jobDoc.FileContent,
    fileSize: jobDoc.FileSize,
  }
}

export function formatJobTeamMember(member: any): ISWPJobTeamMember {
  return {
    name: member.Name,
    email: member.Email,
    offlinePIN: !!member.OfflinePIN
      ? member.OfflinePIN
      : null,
  }
}

export function formatGetJobHistoryLogResponse(getJobHistoryLogResponse: any): ISWPJobLogItem[] {
  if (!getJobHistoryLogResponse.JobHistoryItems
    || !getJobHistoryLogResponse.JobHistoryItems.length) {
    return [];
  }

  return getJobHistoryLogResponse
    .JobHistoryItems
    .map((i: any): ISWPJobLogItem => ({
      jobId: parseInt(i.JobId, 10),
      action: i.Action,
      userName: i.UserName,
      userEmail: i.UserEmail,
      timestamp: new Date(i.Timestamp),
      isOnServer: true,
      isDirty: false,
    }));
}

export function formatSWUserFeedback(feedback: any): ISWUserFeedback {
  return {
    feedbackId: feedback.Id,
    swId: feedback.SWId,
    version: feedback.Version,
    jobId: feedback.JobId,
    feedback: feedback.Feedback,
    vote: feedback.Vote,
    createdBy: feedback.CreatedBy,
  }
}

export function formatDuplicateJobRequest(job: ISWPJob): any {
  return {
    JobNumber: job.jobNumber,
    Title: job.title,
    IsDemo: job.isDemo,
    SWs: job.sws.map((sw, ix) => ({
      SWId: sw.swId,
      Version: sw.version,
      SortOrder: ix + 1,
    })),
    TeamMembers: job.team.map(t => ({
      Name: t.name,
      Email: t.email,
    })),
    Type: job.type,
    CountryGuid: job.country?.guid,
    OrgGuid: job.org?.guid,
    GeoUnitCode: job.geoUnit?.guid,
    SubBusinessLineCode: job.subBusinessLine?.guid,
    CustomerId: job.customer?.guid,
    BypassSWVersionValidation: true,
    Schedule: jobScheduleToServerObj(job.schedule),
    JobDocs: job.jobDocs,
    IsDuplicateJob: true,
  };
}