import React, { useState } from "react";
import useSelector from "store/useSelector";
import ImagePreview from "./ImagePreview";
import AddImageButton from "./AddImageButton";
import { useDispatch } from "react-redux";
import { setComponentResponse } from "store/execution/executionActions";
import { ComponentResponseType } from "interfaces/execution/executionInterfaces";
import "./ManageImagesModal.scoped.scss";
import 'react-html5-camera-photo/build/css/index.css';
import IdbApi from "apis/idb/IdbApi";
import Banner, { BannerType } from "components/common/Banner";
import Modal from "components/common/Modal";
import { IStepIdentifier } from "store/execution/executionTypes";
import { showErrorToast } from "store/toast/toastActions";
import CameraModal from "./CameraModal";
import { validateAndReadFile } from "utilities/fileUtilities";
import { IFileData } from "interfaces/files/fileInterfaces";
import { getComponentResponse } from "utilities/swUtilities";

interface IManageImagesModalProps {
  stepInfo: IStepIdentifier,
  componentId: string,
  onClose(): void,
  disabled: boolean,
}

const ManageImagesModal: React.FC<IManageImagesModalProps> = (props) => {
  const { 
    executor,
    stepResponses,
    job,
  } = useSelector(state => state.execution);

  const { 
    currentUser,
  } = useSelector(state => state.auth);
  const dispatch = useDispatch();

  const [errorMessage, setErrorMessage] = useState("");
  const [isCameraVisible, setIsCameraVisible] = useState(false);

  const jobId = job?.id;
  const imageFilenames = getComponentResponse(props.stepInfo,
    props.componentId,
    stepResponses)
    ?.values;

  let mainComponent: JSX.Element | undefined = undefined;
  let modalControls: JSX.Element | undefined = undefined;

  const onRemoveImage = (filename: string) => {
    if (!imageFilenames) {
      return;
    }

    dispatch(setComponentResponse({
      response: {
        id: props.componentId,
        type: ComponentResponseType.Image,
        values: imageFilenames.filter(i => i !== filename),
        isDeviation: false,
      },
      stepInfo: props.stepInfo,
      userEmail: executor?.email || currentUser.email,
    }));
  }

  const onImageAddedToCache = (filename: string) => {
    dispatch(setComponentResponse({
      response: {
        id: props.componentId,
        type: ComponentResponseType.Image,
        values: imageFilenames
          ? imageFilenames.concat(filename)
          : [filename],
        isDeviation: false,
      },
      stepInfo: props.stepInfo,
      userEmail: executor?.email || currentUser.email,
    }));
  }

  const onPhotoTaken = (fileData: IFileData) => {
    if (!jobId) {
      throw new Error("No job is loaded.");
    }

    IdbApi.cacheUserImageData(fileData.filename,
      jobId,
      false,
      fileData.dataUri)
      .then(ok => ok
        ? onImageAddedToCache(fileData.filename)
        : dispatch(showErrorToast("Error saving img to cache."))
      )
      .catch(err => dispatch(showErrorToast(err.Message || err.toString())))
      .finally(() => setIsCameraVisible(false));
  }

  const onFileChosen = async (event: React.FormEvent<HTMLInputElement>) => {
    if (!jobId) {
      throw new Error("No job is loaded.");
    }

    let tgt = event.target as HTMLInputElement;

    if (!tgt || !tgt.files || !tgt.files.length) {
      return;
    }

    try {
      const fileData = await validateAndReadFile(tgt.files[0]);

      let cacheResponse = await IdbApi.cacheUserImageData(fileData.filename,
        jobId,
        false,
        fileData.dataUri);

      if (cacheResponse) {
        onImageAddedToCache(fileData.filename);
      } else {
        setErrorMessage("Failed to upload image to cache.");
      }
    } catch (err: any) {
      setErrorMessage(err.message || err);
    }
  }

  if (!jobId) {
    mainComponent = (
      <Banner type={BannerType.Error}>
        No job is currently loaded.
      </Banner>
    );
  }

  if (!isCameraVisible) {
    mainComponent = (
      <>
        <div className="image-list">
          {imageFilenames
            && imageFilenames.map(filename =>
              <ImagePreview
                key={filename}
                filename={filename}
                onRemove={() => onRemoveImage(filename)}
                disabled={props.disabled}
              />
            )}
          {!props.disabled && (!imageFilenames || imageFilenames?.length < 5) &&
            <AddImageButton
              onShowCamera={() => setIsCameraVisible(true)}
              onFileChosen={onFileChosen}
            />
          }
        </div>
        {errorMessage &&
          <Banner type={BannerType.Error}>
            {errorMessage}
          </Banner>
        }
        <div>
          {imageFilenames
            && imageFilenames.length >= 5
            && <span className="restrict-message">Only 5 images can be uploaded.</span>
          }
        </div>
      </>
    );

    modalControls = (
      <button
        className="primary-button"
        onClick={props.onClose}
      >
        OK
      </button>
    );
  } else {
    mainComponent = (
      <CameraModal
        onPhotoConfirmed={onPhotoTaken}
        onClose={() => setIsCameraVisible(false)}
      />
    )
  }

  return (
    <Modal
      isOpen={true}
      header="Picture Input"
      controls={modalControls}
    >
      {mainComponent}
    </Modal>
  )
}

export default ManageImagesModal;