import React, { useState } from "react";
import useSelector from "store/useSelector";
import FlowLayout from "components/layout/FlowLayout";
import "./UserProfile.scoped.scss";
import { useDispatch } from "react-redux";
import PINManagement from "./PINManagement";
import { setMyPIN, setMyCountry, setMyOrg, saveMyPreferences, setMyGeoUnit, setMyCustomer, setMySubBusinessLine } from "store/auth/authActions";
import ModalSpinner from "components/common/ModalSpinner";
import ItemPicker from "components/common/itemPicker/ItemPicker";
import { IMasterDataItem } from "interfaces/masterData/masterDataInterfaces";
import { loadCountries, loadGeoUnits, loadOrganizations, loadSubBusinessLines, searchCustomers } from "store/masterData/masterDataActions";
import { showErrorToast, showInfoToast } from "store/toast/toastActions";
import OfflineDataReport from "./OfflineDataReport";
import { useTranslation } from "react-i18next";
import { useMsal } from "@azure/msal-react";

const UserProfile: React.FC = () => {
  const {
    currentUser,
    setMyPINOperation,
    setMyPreferenceOperation,
  } = useSelector(store => store.auth);
  const {
    auth: {
      currentUser: {
        myCountry,
        myOrganization,
        mySubBusinessLine,
        myGeoUnit,
        myCustomer,
      },
    },
    masterData: {
      countries,
      organizations,
      geoUnits,
      customers,
      subBusinessLines,
    },
  } = useSelector(state => state)
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation('userProfile');
  const [isPINEntryVisible, setIsPINEntryVisible] = useState(false);
  const [optionsClickCount, setOptionsClickCount] = useState(0);
  const [isOfflineReportVisible, setIsOfflineReportVisible] = useState(false);
  const { instance } = useMsal();

  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language);
  }

  const onLogoutClick = () => {
    instance.logoutRedirect();
  }

  const savePreference = () => {
    dispatch(saveMyPreferences());
  }

  if (!currentUser) {
    return (
      <FlowLayout>
        <h2>{t('Unknown User')}</h2>
        <p>
          {t('Your current application state is corrupted.')}
          {t('Please log out of the website and log back in.')}
        </p>
        <p>
          <button
            className="primary-button"
            onClick={onLogoutClick}
          >
            {t('Log Out')}
          </button>
        </p>
      </FlowLayout>
    );
  }

  const pinDisplay = !currentUser.hasOfflinePIN
    ? t("<not set>")
    : "******";

  return (
    <FlowLayout
      header={(
        <h2
          className="page-title no-text-select"
          onClick={() => {
            setOptionsClickCount(optionsClickCount + 1);
            if (optionsClickCount > 1
              && optionsClickCount < 4) {
              dispatch(showInfoToast(`Click ${4 - optionsClickCount} more time${4 - optionsClickCount !== 1
                ? "s"
                : ""} ${t('to enable dev options')}.`));
            } else if (optionsClickCount === 4) {
              dispatch(showInfoToast(t("Dev options enabled.")));
            }
          }}
        >
          {currentUser.name}
        </h2>
      )}
    >
      <div
        className="row"
      >
        <label>
          {t('Email')}
        </label>
        <span>
          {currentUser.email}
        </span>
      </div>
      <div
        className="row"
      >
        <label>
          {t('Offline PIN')}
        </label>
        <span>
          <b>{pinDisplay}</b>
          <button
            className="primary-button set-pin"
            onClick={() => setIsPINEntryVisible(true)}
          >
            {!currentUser.hasOfflinePIN
              ? t("Set")
              : t("Change")
            }
          </button>
        </span>
      </div>
      <div className="row">
        <label>
          {t("Language")}
        </label>
        <select
          value={i18n.language}
          onChange={(e) => {
            changeLanguage(e.target.value);
          }}
        >
          <option
            value="en"
          >
            {t('English')}
          </option>
          <option
            value="es"
          >
            {t('Spanish')}
          </option>
        </select>
      </div>
      <h2 className="page-title">
        {t('Job Creation Preferences')}
      </h2>
      <div
        className="row"
      >
        <label className="nonMandatoryprefLabel">
          {t('Default Customer')}
        </label>
        <ItemPicker
            isMandatory={false}
            isAsyncSearch={true}
            label=""
            selectedItems={myCustomer
              ? [myCustomer]
              : []
            }
            allowMultiple={false}
            onAddItem={(item: IMasterDataItem) => dispatch(setMyCustomer(item))}
            onRemoveItem={() => dispatch(setMyCustomer(undefined))}
            loadItems={(searchTerm: string) => dispatch(searchCustomers({searchTerm: searchTerm, includeAll: true}))}
            masterDataSection={customers}
            itemFormatter={(item: IMasterDataItem) => item.value}
          />
      </div>
      <div
        className="row"
      >
        <label className="prefLabel">
          {t('Default GeoUnit')}
        </label>
        <ItemPicker
          label=""
          selectedItems={myGeoUnit
            ? [myGeoUnit]
            : []
          }
          onAddItem={(item: IMasterDataItem) => dispatch(setMyGeoUnit(item))}
          onRemoveItem={() => dispatch(showErrorToast("GeoUnit field is mandatory, it can be changed by clicking on + icon."))}
          loadItems={() => dispatch(loadGeoUnits({ includeAll: true }))}
          masterDataSection={geoUnits}
          loadOnStart={true}
          itemFormatter={(item: IMasterDataItem) => item.value}
        />
      </div>
      <div
        className="row"
      >
        <label className="prefLabel">
          {t('Default Country')}
        </label>
        <ItemPicker
          label=""
          selectedItems={myCountry
            ? [myCountry]
            : []
          }
          onAddItem={(item: IMasterDataItem) => dispatch(setMyCountry(item))}
          onRemoveItem={() => dispatch(showErrorToast("Country field is mandatory, it can be changed by clicking on + icon."))}
          loadItems={() => dispatch(loadCountries({ includeAll: true }))}
          masterDataSection={countries}
          loadOnStart={true}
          itemFormatter={(item: IMasterDataItem) => item.value} />
      </div>
      <div
        className="row"
      >
        <label className="prefLabel">
          {t('Default Organization')}
        </label>
        <ItemPicker
          label=""
          selectedItems={myOrganization
            ? [myOrganization]
            : []
          }
          onAddItem={(item: IMasterDataItem) => dispatch(setMyOrg(item))}
          onRemoveItem={() => dispatch(showErrorToast("Organization field is mandatory, it can be changed by clicking on + icon."))}
          loadItems={() => dispatch(loadOrganizations({ includeAll: true }))}
          masterDataSection={organizations}
          loadOnStart={true}
          itemFormatter={(item: IMasterDataItem) => `${item.code} - ${item.value}`} />
      </div>
      <div
        className="row"
      >
        <label className="prefLabel">
          {t('Default SubBusinessLine')}
        </label>
        <ItemPicker
          label=""
          selectedItems={mySubBusinessLine
            ? [mySubBusinessLine]
            : []
          }
          onAddItem={(item: IMasterDataItem) => dispatch(setMySubBusinessLine(item))}
          onRemoveItem={() => dispatch(showErrorToast("Sub Businessline field is mandatory, it can be changed by clicking on + icon."))}
          loadItems={() => dispatch(loadSubBusinessLines({ includeAll: true }))}
          masterDataSection={subBusinessLines}
          loadOnStart={true}
          itemFormatter={(item: IMasterDataItem) => `${item.code} - ${item.value}`} />
      </div>

      <p>
        <button
          className="primary-button"
          onClick={savePreference}
        >
          {t('Save Preferences')}
        </button>
      </p>

      {optionsClickCount >= 5 && (
        <>
          <h3>{t('Dev Options')}</h3>
          <div
            className="row"
          >
            <label>
              {t('Offline Data')}
            </label>
            <span>
              <button
                className="primary-button"
                onClick={() => setIsOfflineReportVisible(true)}
              >
                {t('View')}
              </button>
            </span>
          </div>
        </>
      )}
      <p>
        <button
          className="primary-button"
          onClick={onLogoutClick}
        >
          {t('Log Out')}
        </button>
      </p>
      {
        isPINEntryVisible &&
        <PINManagement
          onCancel={() => setIsPINEntryVisible(false)}
          onSubmit={(pin: string) => {
            dispatch(setMyPIN(pin));
            setIsPINEntryVisible(false);
          }}
        />
      }
      {(setMyPINOperation?.isWorking ||
        setMyPreferenceOperation?.isWorking) &&
        <ModalSpinner />
      }
      {isOfflineReportVisible &&
        <OfflineDataReport
          onClose={() => setIsOfflineReportVisible(false)}
        />
      }
    </FlowLayout>
  );
}

export default UserProfile;