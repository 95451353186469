import React, { useEffect, useRef, useState } from "react";
import "./VideoInput.scoped.scss";
import useSelector from "store/useSelector";
import { IStepComponent } from "interfaces/sw/SWInterfaces";
import { IStepIdentifier } from "store/execution/executionTypes";
import { getComponentResponse } from "utilities/swUtilities";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { showErrorToast } from "store/toast/toastActions";
import { validateAndReadVideoFile } from "utilities/fileUtilities";
import IdbApi from "apis/idb/IdbApi";
import { setComponentResponse } from "store/execution/executionActions";
import { ComponentResponseType } from "interfaces/execution/executionInterfaces";

interface IVideoInputProps {
  stepInfo: IStepIdentifier,
  component: IStepComponent,
  isDisabled: boolean,
  isJobCompleted: boolean,
}

const VideoInput: React.FC<IVideoInputProps> = ({ stepInfo, component, isDisabled, isJobCompleted }) => {
  const stepResponses = useSelector(store => store
    .execution
    .stepResponses);

  const { t } = useTranslation('components');
  const dispatch = useDispatch();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const { 
    executor,
    job,
  } = useSelector(state => state.execution);

  const { 
    currentUser,
  } = useSelector(state => state.auth);

  const [videoData, setVideoData] = useState<string | undefined>("");
  const [videoFilename, setVideoFilename] = useState("");

  useEffect(() => {
    const videoFileNames = getComponentResponse(stepInfo,
      component.id,
      stepResponses)
      ?.values;
    
    if (!videoFileNames || videoFileNames.length === 0) {
      return;
    }

    setVideoFilename(videoFileNames[0]);

    let aborted = false;

    const imageFetch = async () => {
      try {
        const img = await IdbApi.getUserImageData(videoFileNames[0]);
        if (!aborted) {
          setVideoData(img?.data);
        }
      } catch (err: any) {
        if (!aborted) {
          dispatch(showErrorToast(err?.message || err.toString()));
        }
      }
    }

    imageFetch();

    return () => {
      aborted = true;
    };
  }, [setVideoData, component.id, dispatch, stepInfo, stepResponses, videoData]);

  const jobId = job?.id;

  const onRemoveVideo = () => {
    dispatch(setComponentResponse({
      response: {
        id: component.id,
        type: ComponentResponseType.Image,
        values: [],
        isDeviation: false,
      },
      stepInfo: stepInfo,
      userEmail: executor?.email || currentUser.email,
    }));

    IdbApi.deleteCachedUserImages([videoFilename]);
    setVideoData("");
    setVideoFilename("");
  }

  const onVideoAddedToCache = (filename: string) => {
    dispatch(setComponentResponse({
      response: {
        id: component.id,
        type: ComponentResponseType.Image,
        values: [filename],
        isDeviation: false,
      },
      stepInfo: stepInfo,
      userEmail: executor?.email || currentUser.email,
    }));
  }

  const onFileChosen = async (event: React.FormEvent<HTMLInputElement>) => {
    if (!jobId) {
      throw new Error("No job is loaded.");
    }

    let tgt = event.target as HTMLInputElement;

    if (!tgt || !tgt.files || !tgt.files.length) {
      return;
    }

    let file = tgt.files[0];

    try {
      const fileData = await validateAndReadVideoFile(file);
      let cacheResponse = await IdbApi.cacheUserImageData(fileData.filename,
        jobId,
        false,
        fileData.dataUri);

      if (cacheResponse) {
        onVideoAddedToCache(fileData.filename);
        setVideoData(fileData.dataUri);
        setVideoFilename(fileData.filename);
      } else {
        dispatch(showErrorToast("Failed to upload video to cache."));
      }
    } catch (err: any) {
      dispatch(showErrorToast(err.message || err));
    }
  }

  let vid: JSX.Element | undefined;

  if (videoData) {
      vid = (
          <video controls className="embed">
              <source src={videoData}  type="video/mp4"/>
          </video>
      );
  }

  return (
    <>
      <div>
          <div>{component.label}</div>          
          {vid}
          <input
              type="file"
              accept=".mp4,.mov"
              ref={fileInputRef}
              onChange={onFileChosen}
              disabled={isDisabled}
          />
          <div
              className="controls"
          >
              {!videoData &&
                <button
                    className="primary-button choose-video"
                    onClick={() => fileInputRef.current?.click()}
                    disabled={isDisabled}
                >
                    {t("Choose Video")}
                </button>
              }
              
              {videoData && 
                <button
                    className="primary-button choose-video"
                    onClick={() => onRemoveVideo()}
                    disabled={isDisabled}
                >
                    {t("Remove Video")}
                </button>
              }
          </div>
      </div>
    </>
  );
}

export default VideoInput;