import React from "react";
import "./JobHistoryLogItem.scoped.scss";
import { ISWPJobLogItem } from "interfaces/jobs/JobInterfaces";
import { formatNumericalDate } from "utilities/formatDate";
import calendarIcon from "media/icons/dls/calendar.svg";

const JobHistoryLogItem: React.FC<ISWPJobLogItem> = (props) => {
  let displayName = props.userName || props.userEmail;

  return (
    <div className="history-item">
      <div className="info">
        <span
          className={"user " + (props.userName ? "tooltip" : "")}
          title={props.userName ? props.userEmail : undefined}
        >
          {displayName}
        </span>
      </div>
      <div
        className="log"
      >
        <span
          className="text"
        >
          {props.action}
        </span>

        <span className="timestamp-info">
          <img
            src={calendarIcon}
            alt=""
            className="icon-medium"
          />

          <span
            className="timestamp"
          >
            {formatNumericalDate(props.timestamp, true)}
          </span>
        </span>
      </div>
    </div>
  );
}

export default JobHistoryLogItem;