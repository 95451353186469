import React from "react";
import Modal from "components/common/Modal";
import useSelector from "store/useSelector";

const UpdatedServiceWorkerModal: React.FC = () => {
  const { updatedServiceWorker } = useSelector(store => store.offline);

  if (!updatedServiceWorker) {
    return null;
  }

  const onUpdateClick = () => {
    const registrationWaiting = updatedServiceWorker.waiting;

    if (registrationWaiting) {
      registrationWaiting.postMessage({ type: 'SKIP_WAITING' });

      registrationWaiting.addEventListener('statechange', (e: any) => {
        if (e.target.state === 'activated') {
          window.location.reload();
        }
      });
    } else {
      window.location.reload();
    }
  }

  return (
    <Modal
      header="Update Available"
      isOpen={true}
      controls={
        <button
          className="primary-button"
          onClick={onUpdateClick}
        >
          Update
      </button>
      }
    >
      A new version of the website is available.
    </Modal>
  );
}

export default UpdatedServiceWorkerModal;