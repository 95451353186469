import {
  IExecutionLocation,
  ILogJobActionPayload,
  ILoadExecutionPayload,
  ICompleteJobPayload,
  IFinishLoadingPayload,
  IStepIdentifier,
  IStepCommentModalData,
  IJobDocs,
} from "./executionTypes";
import { SWPJobStatus, ISWPJobLogItem, ISWPJobTeamMember, ISWUserFeedback } from "interfaces/jobs/JobInterfaces";
import { IComponentResponse, IRealtimeStepCommentInfo, IRealtimeStepResponseInfo, IStepComment, IStepResponse, IPaperExecution, IRealtimePaperExecutionInfo, IJobPaperExecution, IDuplicateJobSW } from "interfaces/execution/executionInterfaces";
import { createAction } from "@reduxjs/toolkit";
import { ISW } from "interfaces/sw/SWInterfaces";

export const resetExecution = createAction("execution/reset");
export const loadExecution = createAction<ILoadExecutionPayload>("execution/load");
export const beginLoading = createAction<ILoadExecutionPayload>("execution/beginLoading");
export const finishLoading = createAction<IFinishLoadingPayload>("execution/finishLoading");
export const loadJobOnly = createAction<IFinishLoadingPayload>("execution/loadJobOnly");
export const loadJobSW = createAction<{ jobId: number, sw: ISW, jobSWId: number }>("execution/loadJobSW");
export const loadingFailed = createAction<{ loadErrors: string[] }>("execution/loadingFailed");

export const switchToExecutionSW = createAction<{ jobSWId: number }>("execution/switchToSW");
export const setCurrentExecutionStep = createAction<IExecutionLocation>("execution/setCurrLocation");
export const setStepCompletion = createAction<{ isComplete: boolean, stepInfo: IStepIdentifier, userEmail: string }>("execution/setStepCompletion");
export const setStepIsNotApplicable = createAction<{ isNA: boolean, stepInfo: IStepIdentifier, userEmail: string }>("execution/setStepNA");
export const setConditionalResponse = createAction<{ response: boolean, stepInfo: IStepIdentifier, userEmail: string }>("execution/setConditionalResponse");
export const setComponentResponse = createAction<{ response: IComponentResponse, stepInfo: IStepIdentifier, userEmail: string }>("execution/setComponentResponse");
export const saveExecutionData = createAction("execution/saveExecutionData");
export const setIsUploadingStepResponses = createAction<boolean>("execution/setIsUploadingResponses");
export const setResponsesAreDirty = createAction<{ steps: IStepIdentifier[], isDirty: boolean }>("execution/setResponsesAreDirty");
export const setIsJobCompleting = createAction<boolean>("execution/setIsJobCompleting");
export const setIsCompleteJobModalVisible = createAction<boolean>("execution/setIsCompleteJobModalVisible");
export const setIsJobCompletedModalVisible = createAction<boolean>("execution/setIsJobCompletedModalVisible");
export const setCompleteJobError = createAction<string>("execution/setCompleteJobError");
export const setUploadResponsesError = createAction<string>("execution/setUploadResponsesError");
export const setIsDeviationReasonRequired = createAction<boolean>("execution/setIsDeviationReasonRequired");
export const setHasCantCompleteWithDirtyResponsesError = createAction<boolean>("execution/setHasCantCompleteWithDirtyResponsesError");
export const completeJob = createAction<ICompleteJobPayload>("execution/completeJob");
export const cacheCompleteJob = createAction<ICompleteJobPayload>("execution/cacheCompleteJob");
export const saveStepsDataAndCompleteJob = createAction<ICompleteJobPayload>("execution/saveStepsDataAndCompleteJob");
export const setJobStatus = createAction<SWPJobStatus>("execution/setJobStatus");
export const logJobAction = createAction<ILogJobActionPayload>("execution/logJobAction");
export const setLog = createAction<ISWPJobLogItem[]>("execution/setLog");
export const setPaperExecutions = createAction<IPaperExecution[]>("execution/setPaperExecutions");
export const setJobPaperExecutions = createAction<IJobPaperExecution>("execution/setJobPaperExecutions");
export const setShowAllSteps = createAction<{ jobSWId: number }>("execution/setShowAllSteps");
export const saveShowAllECLs = createAction<{ jobId: number, showAllECLs: boolean, timestamp: Date, userName: string, userEmail: string }>("execution/saveShowAllECLs");
export const setShowAllECLs = createAction<{ showAllECLs: boolean }>("execution/setShowAllECLs");
export const setIsSavingShowAllECLs = createAction<boolean>("execution/setIsSavingAllECLs");
export const saveJobDoc = createAction<{ jobDoc: IJobDocs, jobId: number }>("manageJob/saveJobDoc");
export const addJobDoc = createAction<{ jobDoc: IJobDocs }>("manageJob/addJobDocs");
export const deleteJobDoc = createAction<{ filename: string, jobId: number }>("manageJob/deleteJobDoc")
export const removeJobDoc = createAction<{ filename: string }>("manageJob/removeJobDocs")

export const setStepCommentModalData = createAction<IStepCommentModalData>("execution/setStepCommentModalData");
export const addComment = createAction<IStepComment>("execution/addComment");
export const setComments = createAction<IStepComment[]>("execution/setComments");
export const setCommentsAreOnServer = createAction<{
  commentIds: string[],
  isOnServer: boolean
}>("execution/setCommentsAreOnServer");
export const markSWAsNA = createAction<{
  jobSWId: number,
  userEmail: string
}>("execution/markSWAsNA");

export const openPaperExecuteJob = createAction<{ jobId: number }>("execution/openPaperExecuteJob");
export const closePaperExecuteJob = createAction("execution/closePaperExecuteJob");
export const paperExecuteJob = createAction<{
  userEmail: string,
  jobId: number,
  paperExecutedFileName: string,
  showAllEcls: boolean,
}>("execution/paperExecuteJob");

export const openPaperExecuteSW = createAction<{ jobSWId: number }>("execution/openPaperExecuteSW");
export const closePaperExecuteSW = createAction("execution/closePaperExecuteSW");
export const paperExecuteSW = createAction<{
  userEmail: string,
  jobSWId: number,
  imageFilename: string,
}>("execution/paperExecuteSW");
export const setPaperExecutionsAreOnServer = createAction<{
  jobSWIds: number[],
  isOnServer: boolean
}>("execution/setPaperExecutionsAreOnServer");

export const setTableCellImage = createAction<{ 
  rowKey: number, 
  colKey: number, 
  swId: string, 
  swVersion: string, 
  imageData: string | undefined, 
  stepInfo: IStepIdentifier, 
  componentId: string
}>("execution/setTableCellImage");

export const setRefDocTableCellImage = createAction<{ 
  rowKey: number, 
  colKey: number, 
  swId: string, 
  swVersion: string, 
  imageData: string | undefined, 
  refDocId: string
}>("execution/setRefDocTableCellImage");

export const setJobPaperExecutionIsOnServer = createAction<{ isOnServer: boolean }>("execution/setJobPaperExecutionIsOnServer");
export const startSwitchExecutor = createAction("execution/startSwitchExecutor");
export const setExecutor = createAction<ISWPJobTeamMember | null>("execution/setExecutor");
export const handleRealtimeStepUpdate = createAction<IRealtimeStepResponseInfo[]>("execution/handleRealtimeStepUpdate");
export const handleRealtimeResetSW = createAction<{ jobSWID: number }>("execution/handleRealtimeResetSW");
export const finisheRealtimeResetSW = createAction<{ jobSWID: number }>("execution/finishRealtimeResetSW");
export const beginRealtimeStepLoads = createAction<IStepIdentifier[]>("execution/beginRealtimeStepLoads");
export const finishRealtimeStepLoads = createAction<IStepResponse[]>("execution/finishRealtimeStepLoads");
export const handleRealtimeCommentsUpdate = createAction<IRealtimeStepCommentInfo>("execution/handleRealtimeCommentsUpdate");
export const finishRealtimeCommentLoads = createAction<IStepComment[]>("execution/finishRealtimeCommentLoads");
export const handleRealtimeJobHistoryLoad = createAction<{ jobId: number }>("execution/handleRealtimeJobHistoryLoad");
export const finishRealtimeJobHistoryLoad = createAction<ISWPJobLogItem[]>("execution/finishRealtimeJobHistoryLoad");
export const handleRealtimeJobCompletion = createAction<{ jobId: number }>("execution/handleRealtimeJobCompletion");
export const handleRealtimePaperExecution = createAction<{
  jobId: number,
  paperExec: IRealtimePaperExecutionInfo,
}>("execution/handleRealtimePaperExecution");
export const beginRealtimePaperExecLoad = createAction<{ jobSWId: number }>("execution/beginRealtimePaperExecLoad");
export const finishRealtimePaperExecLoad = createAction<IPaperExecution>("execution/finishRealtimePaperExecLoad");
export const finishRealtimeJobPaperExecLoad = createAction<IJobPaperExecution>("execution/finishRealtimeJobPaperExecLoad");
export const saveOnlineSWUserFeedback = createAction<{ feedbacks: ISWUserFeedback[], jobId: number, createdBy: string }>("execution/saveOnlineSWUserFeedback");
export const getOnlineSWUserFeedbacks = createAction<{ jobId: number, createdBy: string }>("execution/getOnlineSWUserFeedbacks");
export const setOnlineSWUserFeedback = createAction<{ feedbacks: ISWUserFeedback[] }>("execution/setOnlineSWUserFeedback");
export const addOfflineSWUserFeedback = createAction<{ feedbacks: ISWUserFeedback[] }>("execution/addOfflineSWUserFeedback");
export const saveOfflineSWUserFeedback = createAction<{ feedback: ISWUserFeedback }>("execution/saveOfflineSWUserFeedback");
export const addSWUserFeedback = createAction<{ feedback: ISWUserFeedback }>("execution/addSWUserFeedback");
export const launchUserSentimentSurvey = createAction<{ currentUser: string }>("execution/LaunchUserSentimentSurvey");
export const clearSWStepResponses = createAction<{ jobSWId: number }>("execution/clearSWStepResponses");
export const resetSW = createAction<{ jobSWId: number }>("execution/resetSW");
export const clearResetSW = createAction("execution/clearResetSW");
export const clearSWStepComments = createAction<{ jobSWId: number }>("execution/clearSWStepComments");
export const clearSWPaperExecution = createAction<{ jobSWId: number }>("execution/clearSWPaperExecution");
export const clearJobPaperExecution = createAction("execution/clearJobPaperExecution");
export const resetSWResponses = createAction<{ jobId: number, jobSWId: number }>("execution/resetSWResponses");
export const resetJobPaperExecution = createAction<{ jobId: number }>("execution/resetPaperExecution");
export const duplicateSW = createAction<{ jobSWId: number, sortOrder: number, showCriticalSteps: boolean, oldjobSWid: number }>("execution/duplicateSW");
export const updateJobSwidForDuplicate = createAction<{ newSWs: IDuplicateJobSW[] }>("execution/updateJobSwidForDuplicate");
export const updateStepResponsesJobSWidForDuplicate = createAction<{ newSWs: IDuplicateJobSW[] }>("execution/updateStepResponsesJobSWidForDuplicate");
export const updatePaperExecsJobSWidForDuplicate = createAction<{ newSWs: IDuplicateJobSW[] }>("execution/updatePaperExecsJobSWidForDuplicate");
export const updateCommentsJobSWidForDuplicate = createAction<{ newSWs: IDuplicateJobSW[] }>("execution/updateCommentsJobSWidForDuplicate");
export const updateResetSWJobSWidForDuplicate = createAction<{ newSWs: IDuplicateJobSW[] }>("execution/updateResetSWJobSWidForDuplicate");
export const updateIsDirtyForDuplicateSW = createAction<{ jobSWIds: number[] | undefined, isDirty: boolean }>("execution/updateIsDirtyForDuplicateSW");
export const setjobExecutor = createAction<string | null>("execution/setjobExecutor");