import { createReducer } from "@reduxjs/toolkit";
import { IMasterDataState } from "./masterDataTypes";
import { setSection } from "./masterDataActions";

const initialState: IMasterDataState = {
  activityWorkflows: {
    items: [],
  },
  competencyElements: {
    items: [],
  },
  countries: {
    items: [],
  },
  customers: {
    items: [],
  },
  equipment: {
    items: [],
  },
  epicEquipment: {
    items: [],
  },
  businessRoles: {
    items: [],
  },
  productCenters: {
    items: [],
  },
  equivalentSWs: {
    items: [],
  },
  geoUnits: {
    items: [],
  },
  languages: {
    items: [],
  },
  operatingEnvironments: {
    items: [],
  },
  organizations: {
    items: [],
  },
  subBusinessLines: {
    items: [],
  },
  safetyCategories: {
    items: [],
  },
  serviceTypes: {
    items: [],
  },
  tasks: {
    items: [],
  },
  owningPlants: {
    items: [],
  },
  performingPlants: {
    items: [],
  },
  workCenters: {
    items: [],
  },
  materials: {
    items: [],
  },
  types: {
    items: [
      {
        guid: "CL",
        value: "CL",
        code: undefined,
        parentGuid: undefined
      },
      {
        guid: "ECL",
        value: "ECL",
        code: undefined,
        parentGuid: undefined
      },
      {
        guid: "SWI",
        value: "SWI",
        code: undefined,
        parentGuid: undefined
      },
      {
        guid: "TLMSWI",
        value: "TLMSWI",
        code: undefined,
        parentGuid: undefined,
      },
      {
        guid: "LCL",
        value: "LCL",
        code: undefined,
        parentGuid: undefined,
      },
      {
        guid: "MFGSWI",
        value: "MFGSWI",
        code: undefined,
        parentGuid: undefined,
      },
      {
        guid: "MFGCL",
        value: "MFGCL",
        code: undefined,
        parentGuid: undefined,
      },
    ],
  },
  globalOrLocal: {
    items: [
      {
        guid: "Global",
        value: "Global",
        code: undefined,
        parentGuid: undefined
      },
      {
        guid: "Local",
        value: "Local",
        code: undefined,
        parentGuid: undefined
      },
    ],
  },
  taskAlignments: {
    items: [
      {
        guid: "After",
        value: "After",
        code: undefined,
        parentGuid: undefined
      },
      {
        guid: "At",
        value: "At",
        code: undefined,
        parentGuid: undefined
      },
      {
        guid: "Before",
        value: "Before",
        code: undefined,
        parentGuid: undefined
      },
    ],
  },
}

const manageSWReducer = createReducer(initialState, builder => {
  // Set Section
  builder.addCase(setSection, (state, action) => {
    switch (action.payload.name) {
      case "activityWorkflows": {
        state.activityWorkflows = {
          ...action.payload.section,
        };
        break;
      }
      case "equivalentSWs": {
        state.equivalentSWs = {
          ...action.payload.section,
        };
        break;
      }
      case "competencyElements": {
        state.competencyElements = {
          ...action.payload.section,
        };
        break;
      }
      case "countries": {
        state.countries = {
          ...action.payload.section,
        };
        break;
      }
      case "customers": {
        state.customers = {
          ...action.payload.section,
        };
        break;
      }
      case "geoUnits": {
        state.geoUnits = {
          ...action.payload.section,
        };
        break;
      }
      case "equipment": {
        state.equipment = {
          ...action.payload.section,
        };
        break;
      }
      case "epicEquipment": {
        state.epicEquipment = {
          ...action.payload.section,
        };
        break;
      }
      case "productCenters": {
        state.productCenters = {
          ...action.payload.section,
        };
        break;
      }
      case "subBusinessLines": {
        state.subBusinessLines = {
          ...action.payload.section,
        };
        break;
      }
      case "businessRoles": {
        state.businessRoles = {
          ...action.payload.section,
        };
        break;
      }
      case "languages": {
        state.languages = {
          ...action.payload.section,
        };
        break;
      }
      case "operatingEnvironments": {
        state.operatingEnvironments = {
          ...action.payload.section,
        };
        break;
      }
      case "organizations": {
        state.organizations = {
          ...action.payload.section,
        };
        break;
      }
      case "safetyCategories": {
        state.safetyCategories = {
          ...action.payload.section,
        };
        break;
      }
      case "serviceTypes": {
        state.serviceTypes = {
          ...action.payload.section,
        };
        break;
      }
      case "tasks": {
        state.tasks = {
          ...action.payload.section,
        };
        break;
      }
      case "owningPlants": {
        state.owningPlants = {
          ...action.payload.section,
        };
        break;
      }
      case "performingPlants": {
        state.performingPlants = {
          ...action.payload.section,
        };
        break;
      }
      case "workCenters": {
        state.workCenters = {
          ...action.payload.section,
        };
        break;
      }
      case "materials": {
        state.materials = {
          ...action.payload.section,
        }
        break;
      }
    }
  });
});

export default manageSWReducer;