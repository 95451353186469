import React, { useState } from "react";
import "./JobIsCompletedBanner.scoped.scss";
import Banner, { BannerType } from "components/common/Banner";
import { IExecutionJob } from "store/execution/executionTypes";
import useSelector from "store/useSelector";
import AddTeamMemberModal from "components/jobs/management/team/AddTeamMemberModal";
import { Connectivity } from "interfaces/execution/executionInterfaces";
import { useTranslation } from "react-i18next";

interface IJobIsCompletedBannerProps {
  job: IExecutionJob,
}

const JobIsCompletedBanner: React.FC<IJobIsCompletedBannerProps> = ({ job }) => {
  const [isAddTeamOpen, setIsAddTeamOpen] = useState(false);

  const {
    jobsAwaitingCompletion,
    isOnline,
   } = useSelector(store => store.offline);
  const isCompletionAwaitingSync = jobsAwaitingCompletion.indexOf(job.id) !== -1;

  const { t } = useTranslation('execution');

  return (
    <>
      <div className="job-completed-banner">
        <Banner
          type={BannerType.Info}
        >
          <div className="message">
            <span>
              {t('This job is completed. No further changes can be made.')}
            </span>
            <button
              className="secondary-button"
              disabled={isOnline === Connectivity.Offline
                || isCompletionAwaitingSync}
              title={isCompletionAwaitingSync
                ? t("Your offline data must first be synched to the server.")
                : (isOnline === Connectivity.Offline
                  ? t("You cannot use this feature while offline.")
                  : undefined)
              }
              onClick={isOnline === Connectivity.Online
                && !isCompletionAwaitingSync
                ? () => setIsAddTeamOpen(true)
                : undefined
              }
            >
              {t('Share Job')}
            </button>
          </div>
        </Banner>
      </div>
      {isAddTeamOpen &&
        <AddTeamMemberModal
          isOpen={isAddTeamOpen}
          headerText={t("Share Completed Job")}
          onDoneClicked={() => setIsAddTeamOpen(false)}
          jobId={job.id}
        />
      }
    </>
  );
}

export default JobIsCompletedBanner;