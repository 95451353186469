import { all } from 'redux-saga/effects';
import watchMyJobsSagas from './myJobs/myJobsSagas';
import watchManageJobSagas from './manageJob/manageJobSagas';
import watchExecutionSagas from './execution/executionSagas';
import watchOfflineSagas from './offline/offlineSagas';
import watchToastSagas from './toast/toastSagas';
import watchMasterDataSagas from './masterData/masterDataSagas';
import watchJobTemplatesSagas from './jobTemplates/jobTemplatesSagas';
import watchUserManagementSagas from './userManagement/userManagementSagas';
import watchAuthSagas from './auth/authSagas';

export default function* rootSaga() {
  yield all([
    watchMyJobsSagas(),
    watchManageJobSagas(),
    watchExecutionSagas(),
    watchToastSagas(),
    watchOfflineSagas(),
    watchMasterDataSagas(),
    watchJobTemplatesSagas(),
    watchUserManagementSagas(),
    watchAuthSagas(),
  ]);
}