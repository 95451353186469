import React, { useEffect, useState } from "react";
import IdbApi from "apis/idb/IdbApi";
import { useDispatch } from "react-redux";
import { setCachedJobIds, refreshAwaitingSync } from "store/offline/offlineActions";
import { setCurrentUser, setMyCountry, setMyCustomer, setMyGeoUnit, setMyOrg, setMySubBusinessLine } from "store/auth/authActions";
import Banner, { BannerType } from "components/common/Banner";
import { CannotCreateDbError } from "utilities/errors/CannotCreateDbError";
import useSelector from "store/useSelector";
import UpdatedServiceWorkerModal from "./UpdatedServiceWorkerModal";
import { DbBlockingError } from "utilities/errors/DbBlockingError";
import { DbBlockedError } from "utilities/errors/DbBlockedError";
import UserApi from "apis/user/UserApi";
import { hydrateFilters } from "store/myJobs/myJobsActions";
import { MyJobsTabs } from "interfaces/jobs/JobInterfaces";
import { hydrateJobTemplateFilters } from "store/jobTemplates/jobTemplatesActions";
import Logo from "./Logo";
import BlockSpinner from "components/common/BlockSpinner";
import "./AppStartup.scoped.scss";
import { IUserJobCreationPreference } from "interfaces/user/UserInterfaces";
import { useMsal } from "@azure/msal-react";

interface IAppStartupProps {
  children: any,
}

const AppStartup: React.FC<IAppStartupProps> = (props) => {
  const [dbError, setDbError] = useState<Error | undefined>();
  const [isStarting, setIsStarting] = useState(true);
  const { updatedServiceWorker } = useSelector(store => store.offline);

  const dispatch = useDispatch();
  const { accounts, instance } = useMsal();
  const user = accounts[0];
  const userEmail = user.username;
  const userName = user.name !== undefined ? user.name : user.username;

  useEffect(() => {
    let aborted = false;

    const doStartup = async () => {
      // Add current user info to store.
      if (userEmail) {
        // Put the default information into the store.
        dispatch(setCurrentUser({
          email: userEmail,
          name: userName,
          roles: [],
          tccInfo: undefined,
          myCountry: undefined,
          myOrganization: undefined,
          myGeoUnit: undefined,
          myCustomer: undefined,
          mySubBusinessLine: undefined,
        }));

        // Now query the backend (if possible) to get
        // the user's full details.
        try {
          const userDetails = await UserApi.getUser(userEmail);

          if (!aborted) {
            dispatch(setCurrentUser(userDetails));
          }
        } catch {
        }

        // Query User preferences.
        try {
          const userPreferences = await UserApi.getUserJobCreationPreference(userEmail);

          if (!aborted) {
            dispatch(setMyCountry(userPreferences.country));
            dispatch(setMyOrg(userPreferences.organization));
            dispatch(setMyGeoUnit(userPreferences.geoUnit));
            dispatch(setMyCustomer(userPreferences.customer));
            dispatch(setMySubBusinessLine(userPreferences.subBusinessLine));

            localStorage.setItem(`${userEmail.toLowerCase()}_JobCreation_Preferences`,
              JSON.stringify(userPreferences));
          }
        } catch (err: any) {
          // Reading Preferences from Local storage.
          let userPreferencesStr = localStorage.getItem(userEmail + "_JobCreation_Preferences");
          if(userPreferencesStr) {
            let userPreferences: IUserJobCreationPreference = JSON.parse(userPreferencesStr);

            if (userPreferences.country) {
              dispatch(setMyCountry(userPreferences.country));
            }

            if (userPreferences.organization) {
              dispatch(setMyOrg(userPreferences.organization));
            }
            
            if (userPreferences.geoUnit) {
              dispatch(setMyGeoUnit(userPreferences.geoUnit));
            }

            if (userPreferences.customer) {
              dispatch(setMyCustomer(userPreferences.customer));
            }

            if (userPreferences.subBusinessLine) {
              dispatch(setMySubBusinessLine(userPreferences.subBusinessLine));
            }
          }
        }

      } else {
        // If there is no current user, something is amiss with the auth token.
        // Force the user out of the site.
        instance.logoutRedirect();
        return;
      }

      try {
        // Migrate the database.
        if (!aborted) {
          //debugger;
          await IdbApi.migrateDb(err => {
            setDbError(err);
            setIsStarting(false);
          });
        }
      } catch (err: any) {
        //debugger;
        if (!aborted) {
          setDbError(err);
          setIsStarting(false);
        }
        return;
      }

      // Clean up unused image data from previous sessions.
      if (!aborted) {
        await IdbApi.cleanupUnusedImageData();
      }

      // Read cached job ids from the idb into the offline jobs store.
      if (!aborted) {
        const cachedJobIds = await IdbApi.getCachedJobIds();
        dispatch(setCachedJobIds({ jobIds: cachedJobIds }));
      }

      // Update redux to know whether there are any offline responses
      // in the IDB. Instead of dispatching an action and letting
      // a saga handle it async, it needs to be done before opening any
      // other connection. This is so that no other transaction is using
      // the IDB while this is done.
      if (!aborted) {
        dispatch(refreshAwaitingSync());
      }

      if (!aborted) {
        dispatch(hydrateFilters(MyJobsTabs.Active));
        dispatch(hydrateFilters(MyJobsTabs.Completed));
        dispatch(hydrateJobTemplateFilters());
      }

      if (!aborted) {
        setIsStarting(false);
      }
    }

    doStartup();

    return () => {
      aborted = true;
    };
  }, [setIsStarting, setDbError, dispatch, userEmail, userName, instance]);

  if (isStarting) {
    return (
      <div className="centered">
        <Logo />
        <div className="spinner-holder">
          <BlockSpinner />
        </div>
      </div>
    );
  } else if (dbError) {
    if (dbError instanceof CannotCreateDbError) {
      return (
        <div>
          <Banner type={BannerType.Error}>
            An error was encountered while setting up the cache database.
            The cache database is required to store offline jobs and execution responses.
            This error is encountered if you are using Private Browsing mode, Incognito Windows, or similar
            or if your browser does not support Indexed DB.
          </Banner>
          <Banner type={BannerType.Info}>
            If you are using Private Browsing mode, an Incognito Window, or similar,
            please close this window and try visiting again in a normal, non-private
            browser window. Otherwise, use a browser that supports Indexed DB.
          </Banner>
        </div>
      );
    } else if (dbError instanceof DbBlockedError
      || dbError instanceof DbBlockingError) {
      return (
        <div>
          <Banner type={BannerType.Error}>
            {dbError.message}
          </Banner>
          <button
            className="primary-button"
            onClick={() => window.location.reload()}>
            Refresh Page
          </button>
        </div>
      );
    } else {
      return (
        <Banner type={BannerType.Error}>
          {dbError.message}
        </Banner>
      );
    }
  } else {
    return (
      <>
        {props.children}
        {updatedServiceWorker &&
          <UpdatedServiceWorkerModal />
        }
      </>
    );
  }
}

export default AppStartup;