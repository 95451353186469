export default function formatDate(date: Date | undefined,
  showTime: boolean = true,
  showDate: boolean = true,
  monthFormat: 'long' | 'short' = 'long'): string {
  let day = date?.getDate();
  let month = date?.toLocaleString('default', { month: monthFormat });
  let year = date?.getFullYear();
  let hour = date?.getHours();
  let minute = date?.getMinutes();
  let tt = date ? date?.getHours() >= 12 ? "PM" : "AM" : "";

  let dateStr = '';
  if (showDate) {
    dateStr += date ? `${month?.toString().padStart(2, '0')}-${day?.toString().padStart(2, '0')}, ${year}` : '';
  }
  if (showTime) {
    dateStr += date ? ` ${hour?.toString().padStart(2, '0')}:${minute?.toString().padStart(2, '0')} ${tt}` : '';
  }

  return dateStr;
}

export function formatNumericalDate(date: Date | undefined,
  showTime: boolean = true): string {
  if (!date) {
    return "";
  }

  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  let hour = date.getHours();
  let minute = date.getMinutes();
  let tt = date.getHours() >= 12 ? "PM" : "AM";

  let dateStr = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;

  if (showTime) {
    dateStr += ` ${hour?.toString().padStart(2, '0')}:${minute?.toString().padStart(2, '0')} ${tt}`;
  }

  return dateStr;
}

export function getDateDiff(endDate: Date | undefined,
  startDate: Date | undefined): string {
  if (endDate && startDate) {
    var msec = endDate.getTime() - startDate.getTime();
    var mins = Math.floor(msec / 60000);
    var hrs = Math.floor(mins / 60);
    mins = mins % 60;

    if (hrs > 0) {
      return `${hrs} hour${hrs === 1 ? "" : "s"} ${mins} minute${mins === 1 ? "" : "s"}`;
    } else {
      return `${mins} minute${mins === 1 ? "" : "s"}`;
    }
  } else if (endDate) {
    return "0 minutes";
  }

  return "";
}