import React, { useState } from "react";
import Modal from "components/common/Modal";
import Banner, { BannerType } from "components/common/Banner";
import useSelector from "store/useSelector";
import { setCompleteJobError, setIsCompleteJobModalVisible, completeJob, cacheCompleteJob, logJobAction, markSWAsNA, saveStepsDataAndCompleteJob } from "store/execution/executionActions";
import { useDispatch } from "react-redux";
import "./CompleteJobModal.scoped.scss";
import { ExecutionMode } from "store/execution/executionTypes";
import { SWTypes } from "interfaces/sw/SWInterfaces";
import { getSWInstanceNumber } from "utilities/swUtilities";

const CompleteJobModal: React.FC = () => {
  const {
    execution: {
      isDeviationReasonRequired,
      completeJobError,
      job,
      isJobCompleting,
      mode,
      executor,
      stepComments,
      stepResponses,
      paperExecutions,
    },
    auth: {
      currentUser,
    }
  } = useSelector(store => store);
  const [deviationReason, setDeviationReason] = useState<string>("");
  const dispatch = useDispatch();

  const markECLNAForShowAllECLFlagFalse = (jobSWId: number) => {
    if (!job) {
      return null;
    }
    const sw = job.sws.find(sw => sw.jobSWId === jobSWId);

    if (!sw) {
      return null;
    }
    dispatch(markSWAsNA({
      jobSWId,
      userEmail: executor?.email || currentUser.email,
    }));

    const swInstanceNum = getSWInstanceNumber(jobSWId,
      job?.sws);

    dispatch(logJobAction({
      action: `Marked ${sw?.title}${!!swInstanceNum
        ? ` (Instance ${swInstanceNum})`
        : ""
        } N/A as ShowAllECLs false`,
      timestamp: new Date(),
      userName: executor?.name || currentUser.name,
      userEmail: executor?.email || currentUser.email,
    }));
  }

  const onOKClick = () => {
    if (isDeviationReasonRequired
      && !deviationReason.trim()) {
      dispatch(setCompleteJobError("A deviation reason is required."));
      return;
    }

    if (!job) {
      alert("There is no currently loaded job.");
      return;
    }

    let actionPayload = {
      jobId: job.id,
      deviationMessage: deviationReason,
      timestamp: new Date(),
      userEmail: executor?.email || currentUser.email,
    };

    var syncECLToServer = false;
    // If show All ECL is false and ECL is added to Job then, Mark ECL as NA for all the JobSWIds
    var eclAddedToJob = job.sws.filter(r => r.type === SWTypes.ECL).length > 0;
    var showAllECLs = (job.showAllECLs === undefined || job.showAllECLs === null || job.showAllECLs === true);
    if (eclAddedToJob && !showAllECLs) {
      job?.sws.map(jobSW => jobSW.type === SWTypes.ECL && markECLNAForShowAllECLFlagFalse(jobSW.jobSWId));
      syncECLToServer = true;
    }

    const anyDirty = stepComments.some(x => x.isDirty)
      || stepResponses.some(x => x.isDirty)
      || paperExecutions.some(x => x.isDirty)
      || job?.sws.some(x => x.jobSWId < 0 && x.isDirty)
      || syncECLToServer;

    if (anyDirty) {
      dispatch(saveStepsDataAndCompleteJob(actionPayload));
    }
    else {
      if (mode === ExecutionMode.Offline) {
        dispatch(cacheCompleteJob(actionPayload));
      } else {
        dispatch(completeJob(actionPayload));
      }
    }
  }

  const onCancelClick = () => {
    dispatch(setCompleteJobError(""));
    dispatch(setIsCompleteJobModalVisible(false));
  }

  let body: JSX.Element | undefined;

  if (!isJobCompleting) {
    body = <>
      {
        <>
          <p>One or more required steps are incomplete. Please enter a deviation reason.</p>
          <textarea
            placeholder="Deviation reason..."
            value={deviationReason}
            onChange={(e) => setDeviationReason(e.target.value)}
            rows={4}
          />
        </>
      }
      {!!completeJobError &&
        <Banner type={BannerType.Error}>
          {completeJobError}
        </Banner>
      }
    </>;
  }

  return (
    <Modal
      header="Complete Job"
      isOpen={true}
      controls={
        !isJobCompleting &&
        <>
          <button
            className="secondary-button"
            onClick={onCancelClick}
          >
            Cancel
          </button>
          <button
            className="primary-button"
            onClick={onOKClick}
          >
            OK
          </button>
        </>
      }
    >
      {body}
    </Modal>
  );
}

export default CompleteJobModal;