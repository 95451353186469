import React from "react";
import useSelector from "store/useSelector";
import { useDispatch } from "react-redux";
import { setIsDeviationReasonRequired, setIsCompleteJobModalVisible, cacheCompleteJob, completeJob, markSWAsNA, logJobAction, saveStepsDataAndCompleteJob } from "store/execution/executionActions";
import { getSWInstanceNumber, getSWStatus } from "utilities/swUtilities";
import { SWStatus } from "interfaces/execution/executionInterfaces";
import { useTranslation } from "react-i18next";
import { ExecutionMode } from "store/execution/executionTypes";
import { SWTypes } from "interfaces/sw/SWInterfaces";
import { Roles } from "interfaces/user/UserInterfaces";

const CompleteJobButton: React.FC = () => {
  const {
    execution: {
      job,
      stepResponses,
      executor,
      mode,
      stepComments,
      paperExecutions,
    },
    auth: {
      currentUser: {
        email,
        name,
        roles
      },
    },
  } = useSelector(store => store);
  const dispatch = useDispatch();
  const { t } = useTranslation('execution');

  const isTeamMember = !!job?.team.find(x =>
    x.email.toLowerCase() === email.toLowerCase());

  const isExecutiveAdmin = roles.indexOf(Roles.ExecutionAdmin) > -1;

  const markECLNAForShowAllECLFlagFalse = (jobSWId: number) => {
    if (!job) {
      return null;
    }
    const sw = job.sws.find(sw => sw.jobSWId === jobSWId);

    if (!sw) {
      return null;
    }
    dispatch(markSWAsNA({
      jobSWId,
      userEmail: executor?.email || email,
    }));

    const swInstanceNum = getSWInstanceNumber(jobSWId,
      job?.sws);

    dispatch(logJobAction({
      action: `Marked ${sw?.title}${!!swInstanceNum
        ? ` (Instance ${swInstanceNum})`
        : ""
        } N/A as ShowAllECLs false`,
      timestamp: new Date(),
      userName: executor?.name || name,
      userEmail: executor?.email || email,
    }));
  }

  const onClick = () => {
    if (!job) {
      return;
    }

    var eclAddedToJob = job.sws.filter(r => r.type === SWTypes.ECL).length > 0;
    var showAllECLs = (job.showAllECLs === undefined || job.showAllECLs === null || job.showAllECLs === true);

    // Find the first step that is missing a response.
    let hasMissingResponse = false;
    const sws = job.sws;

    for (let i = 0; i < sws.length; i++) {
      let sw = sws[i];
      if (eclAddedToJob && !showAllECLs && sw.type === SWTypes.ECL) {
        continue;
      }

      if (getSWStatus(sw, stepResponses) === SWStatus.Incomplete) {
        hasMissingResponse = true;
        break;
      }
    }

    if (hasMissingResponse) {
      dispatch(setIsCompleteJobModalVisible(true));
    }
    else {
      let actionPayload = {
        jobId: job.id,
        deviationMessage: "",
        timestamp: new Date(),
        userEmail: executor?.email || email,
      };

      var syncECLToServer = false;
      // If show All ECL is false and ECL is added to Job then, Mark ECL as NA for all the JobSWIds. 
      if (eclAddedToJob && !showAllECLs) {
        job?.sws.map(jobSW => jobSW.type === SWTypes.ECL && markECLNAForShowAllECLFlagFalse(jobSW.jobSWId));
        syncECLToServer = true;
      }

      const anyDirty = stepComments.some(x => x.isDirty)
        || stepResponses.some(x => x.isDirty)
        || paperExecutions.some(x => x.isDirty)
        || job?.sws.some(x => x.jobSWId < 0 && x.isDirty)
        || syncECLToServer;

      if (anyDirty) {
        dispatch(saveStepsDataAndCompleteJob(actionPayload));
      }
      else {
        if (mode === ExecutionMode.Offline) {
          dispatch(cacheCompleteJob(actionPayload));
        } else {
          dispatch(completeJob(actionPayload));
        }
      }

      dispatch(setIsCompleteJobModalVisible(false));
    }

    dispatch(setIsDeviationReasonRequired(hasMissingResponse));

  }

  if (!isTeamMember && !isExecutiveAdmin) {
    return null;
  }

  return (
    <button
      className="primary-button"
      onClick={onClick}
    >
      {t('Complete Job')}
    </button>
  );
}

export default CompleteJobButton;