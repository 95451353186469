import { call, put, takeLatest, select, all } from 'redux-saga/effects';
import { IInProgressJob } from './manageJobTypes';
import { getResponseErrorMessage } from 'utilities/validationErrorHelpers';
import JobsApi from 'apis/jobs/JobsApi';
import { ISWPJobCreationResponse, ISWPJob, ISWPUpdateJobResponse, SWPJobType, SWPJobStatus } from 'interfaces/jobs/JobInterfaces';
import { RootState } from 'store/rootStore';
import { saveJob, getJob, setIsGettingJob, setJob, setGetJobError, setSaveSuccess, setIsSaving, setSaveError, searchSWs, setSearchText, setSWSearchResults, applyFilter, setNewIndex, getJobDocPdf, setJobDocPdfOperation } from './manageJobActions';
import { Action } from '@reduxjs/toolkit';
import { ISearchSWsOptions, ISWSearchResult, ISWSearchResultItem } from 'interfaces/sw/SWInterfaces';
import SWApi from 'apis/sw/SWApi';
import { v4 as uuidv4 } from "uuid";
import i18n from 'i18n';
import { Connectivity } from 'interfaces/execution/executionInterfaces';
import IdbApi from 'apis/idb/IdbApi';
import { getFilterCount } from 'utilities/filteringUtilities';
import AzureBlobsApi from 'apis/azureBlobs/AzureBlobsApi';
import { showErrorToast } from 'store/toast/toastActions';

const t = i18n.getFixedT(null, 'sagas');

export default function* watchManageJobSagas() {
  yield all([
    watchSaveJob(),
    watchGetJob(),
    watchGetSWData(),
    watchGetJobDocsPdf(),
  ]);
}

function* watchGetSWData() {
  yield takeLatest([setNewIndex, searchSWs, setSearchText, applyFilter], refreshSWListAsync);
}

function* watchSaveJob() {
  yield takeLatest(saveJob.type, saveManagedJobAsync);
}

function* watchGetJob() {
  yield takeLatest(getJob.type, getJobAsync);
}

function* watchGetJobDocsPdf()
{
  yield takeLatest(getJobDocPdf, getJobDocPdfAsync);
}

function* getJobDocPdfAsync(action: Action) {
  if (!getJobDocPdf.match(action)) {
    return;
  }
  
  try
  {
    yield put(setJobDocPdfOperation(
      { 
        isWorking: true
      }));

    let fileData: string = yield call(AzureBlobsApi.getDataUri, action.payload.fileUrl);
    const mfurl = fileData.replace("data:application/pdf;base64,", "");
    var byteCharacters = atob(mfurl);
    var byteNumbers = new Array(byteCharacters.length);
    for (var i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    var byteArray = new Uint8Array(byteNumbers);
    var file = new Blob([byteArray], {
      type: 'application/pdf;base64'
    });
    var fileURL = URL.createObjectURL(file);
    window.open(fileURL);

    yield put(setJobDocPdfOperation(
    { 
      isWorking: false,
      wasSuccessful: true
    }));
  }
  catch (err) {
     yield put(setJobDocPdfOperation(
      { 
        isWorking: false,
        wasSuccessful: false
      }));
      
      yield put(showErrorToast(getResponseErrorMessage(err)));
  }  
}

function* getJobAsync(action: Action) {
  if (!getJob.match(action)) {
    return;
  }

  yield put(setIsGettingJob(true));
  try {
    const isOnline: Connectivity = yield select((store: RootState) => store.offline.isOnline);
    const fetchedJob: ISWPJob = isOnline === Connectivity.Online
      ? yield call(JobsApi.getJob, action.payload.jobId)
      : yield call([IdbApi, IdbApi.getCachedJob], action.payload.jobId);

    if (fetchedJob.status === SWPJobStatus.Cancelled) {
      throw Error(`Unable to load the job as its status is ${fetchedJob.status}.`);
    }

    const inProgJob: IInProgressJob = {
      ...fetchedJob,
      sws: fetchedJob.sws.map(sw => ({
        ...sw,
        listGuid: uuidv4(),
      })),
    };

    // Align the start of the schedule (if there is one)
    // to today.
    const now = new Date();
    const nowMidnight = new Date(now.getFullYear(),
      now.getMonth(),
      now.getDate());

    if (inProgJob.schedule
      && inProgJob.schedule.startDate < nowMidnight) {
      inProgJob.schedule.startDate = nowMidnight;

      if (inProgJob.schedule.endDate
        && inProgJob.schedule.startDate > inProgJob.schedule.endDate) {
        inProgJob.schedule.endDate = nowMidnight;
      }
    }

    yield put(setJob(inProgJob));
  } catch (err: any) {
    yield put(setGetJobError(err));
  } finally {
    yield put(setIsGettingJob(false));
  }
}

function* saveManagedJobAsync() {
  yield put(setIsSaving(true));
  try {
    const job: IInProgressJob = yield select((state: RootState) => state.manageJob.job);
    let wasSuccessful = false;
    let errorMessage: string | null | undefined = null;

    // check job country and organization.
    if ((!job.country
      || !job.org
      || !job.geoUnit
      || !job.subBusinessLine)
      && job.type === SWPJobType.Standard) {

      errorMessage = `${t("Job GeoUnit, Country, Organization and SubBusinessLine are mandatory.")}`;
      yield put(setSaveSuccess(false));
      yield put(setSaveError(errorMessage));
      yield put(setIsSaving(false));
    } else if ((!job.org
      || !job.subBusinessLine)
      && job.type === SWPJobType.Template) {

      errorMessage = `${t("Job Organization and SubBusinessLine are mandatory.")}`;
      yield put(setSaveSuccess(false));
      yield put(setSaveError(errorMessage));
      yield put(setIsSaving(false));
    } else {
      if (!job.id) {
        const saveResult: ISWPJobCreationResponse = yield call(JobsApi.createJob, job);
        wasSuccessful = saveResult.success;
        errorMessage = saveResult.message;
      } else {
        const updateResult: ISWPUpdateJobResponse = yield call(JobsApi.updateJob, job);
        wasSuccessful = updateResult.success;
        errorMessage = updateResult.message;
      }
      yield put(setSaveSuccess(wasSuccessful));
    }

    if (!wasSuccessful) {
      yield put(setSaveError(errorMessage || t("An error was encountered saving the job.")));
    }
  } catch (err: any) {
    yield put(setSaveSuccess(false));
    yield put(setSaveError(getResponseErrorMessage(err)));
  } finally {
    yield put(setIsSaving(false));
  }
}

function* refreshSWListAsync(action: Action) {
  const swListData: ISWSearchResult = yield select(
    (store: RootState) => store.manageJob.searchSWData.swData
  );

  const filterFields: ISearchSWsOptions = yield select(
    (store: RootState) => store.manageJob.searchSWData.filterFields
  );
  const index: number = yield select(
    (store: RootState) => store.manageJob.searchSWData.index
  );

  let newItems: ISWSearchResultItem[] = [];

  if (getFilterCount(filterFields) === 0) {
    yield put(
      setSWSearchResults({
        ...swListData,
        items: [],
        loadOperation: undefined,
      })
    );
    return;
  }

  yield put(
    setSWSearchResults({
      ...swListData,
      loadOperation: {
        isWorking: true,
        errorMessage: "",
        wasSuccessful: false,
      },
    })
  );

  let results: ISWSearchResult;
  let oldResults = swListData.items;

  try {
    results = yield call(SWApi.searchSWs, filterFields, index);
  } catch (err: any) {
    yield put(
      setSWSearchResults({
        ...swListData,
        loadOperation: {
          isWorking: false,
          errorMessage: getResponseErrorMessage(err),
          wasSuccessful: false,
        },
      })
    );
    return;
  }
  if (index !== 0) {
    newItems = [...oldResults, ...results.items];
  }
  else {
    newItems = results.items;
  }

  yield put(
    setSWSearchResults({
      ...swListData,
      items: newItems,
      totalCount: results.totalCount,
      loadOperation: undefined,
    })
  );
}