import { IStepComment, IStepResponse, QuestionResponse } from "interfaces/execution/executionInterfaces";
import { INumberInputData, IStep, StepComponentTypes } from "interfaces/sw/SWInterfaces";
import React, { useState } from "react";
import StepCompletionIcon, { StepCompletionIcons } from "../sw/steps/StepCompletionIcon";
import "./PrintStep.scoped.scss";
import formatDate from "utilities/formatDate";
import PrintImage, { PrintImageSource } from "./PrintImage";
import ImageModal from "components/common/ImageModal";
import IdbApi from "apis/idb/IdbApi";
import { useDispatch } from "react-redux";
import { showErrorToast } from "store/toast/toastActions";
import PrintComment from "./PrintComment";
import { isFilenameImage } from "utilities/fileUtilities";
import { getStepComments, getStepResponse } from "utilities/swUtilities";
import { IStepIdentifier } from "store/execution/executionTypes";
import useSelector from "store/useSelector";
import { useTranslation } from "react-i18next";
import PrintVideo from "./PrintVideo";
import PrintAudio from "./PrintAudio";

interface IPrintStepProps {
  jobSWId: number,
  step: IStep,
  allResponses: IStepResponse[],
  allComments: IStepComment[],
  isConditionallyDisabled: boolean,
}

enum StepStatus {
  MarkedNA = "Marked N/A",
  Completed = "Completed",
  Incomplete = "Incomplete",
  Uploaded = "Uploaded",
}

const PrintStep: React.FC<IPrintStepProps> = ({
  jobSWId,
  step,
  allResponses,
  allComments,
  isConditionallyDisabled,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('printView');
  const [focusedAttachment, setFocusedAttachment] = useState<{
    title: string,
    imgSrc: string,
  } | undefined>();

  const stepInfo: IStepIdentifier = {
    jobSWId,
    stepId: step.id,
  };

  const stepResponse = getStepResponse(stepInfo, allResponses);
  const stepComments = getStepComments(stepInfo, allComments)
    .sort((a, b) => a.timestamp < b.timestamp ? -1 : 1);

  let stepIcon = StepCompletionIcons.Empty;

  if (stepResponse?.isComplete) {
    if (stepResponse.questionResponse === QuestionResponse.Pass) {
      stepIcon = StepCompletionIcons.GreenCheck;
    } else if (stepResponse.questionResponse === QuestionResponse.Fail) {
      stepIcon = StepCompletionIcons.RedX;
    } else if (stepResponse.isNA) {
      stepIcon = StepCompletionIcons.NotApplicable;
    } else if (stepResponse.isDeviation) {
      stepIcon = StepCompletionIcons.RedCheck;
    } else {
      stepIcon = StepCompletionIcons.GreenCheck;
    }
  }
  else if (stepResponse?.isDeviation) {
    stepIcon = StepCompletionIcons.RedIncomplete;
  }
  else {
    stepIcon = StepCompletionIcons.Empty;
  }

  const {
    paperExecutions,
  } = useSelector(store => store.execution);

  const paperExec = paperExecutions.find(x => x.jobSWId === jobSWId);
  const stepStatus = stepResponse?.isNA
    ? StepStatus.MarkedNA
    : (
      stepResponse?.isComplete
        ? paperExec
          ? StepStatus.Uploaded
          : StepStatus.Completed
        : StepStatus.Incomplete
    );

  let subSteps = null;

  if (step.children.length) {
    let subStepListClassName = "";

    if (step.isConditional) {
      // Check the conditional response to see if substeps should be disabled.
      if (!stepResponse?.conditionalResponse) {
        if (stepStatus !== StepStatus.Uploaded
          && stepStatus !== StepStatus.Completed) {
          subStepListClassName = "conditional-disabled";
        }
      }
    }

    const areChildrenDisabled = step.isConditional
      && (stepResponse?.conditionalResponse !== true
        && (stepStatus !== StepStatus.Uploaded
          && stepStatus !== StepStatus.Completed));

    subSteps = (
      <div
        className={`substeps ${subStepListClassName}`}
      >
        {step.children.map(subStep =>
          <PrintStep
            key={subStep.id}
            jobSWId={jobSWId}
            step={subStep}
            allResponses={allResponses}
            allComments={allComments}
            isConditionallyDisabled={areChildrenDisabled}
          />
        )}
      </div>
    );
  }



  const completionText = stepStatus.toString()
    + (stepStatus === StepStatus.Incomplete
      ? ""
      : ` ${formatDate(stepResponse?.timestamp, true, true, "short")} by ${stepResponse?.userEmail}`);

  const inputs = step.components.filter(x => x.type === StepComponentTypes.DateInput
    || x.type === StepComponentTypes.DateTimeInput
    || x.type === StepComponentTypes.MultiSelectInput
    || x.type === StepComponentTypes.NumberInput
    || x.type === StepComponentTypes.PhotoInput
    || x.type === StepComponentTypes.SelectInput
    || x.type === StepComponentTypes.SignatureInput
    || x.type === StepComponentTypes.TextInput
    || x.type === StepComponentTypes.YesNoInput
    || x.type === StepComponentTypes.PassFailInput
    || x.type === StepComponentTypes.VideoInput
    || x.type === StepComponentTypes.AudioInput);

  const onClickCommentAttachment = async (filename: string) => {
    if (!isFilenameImage(filename)) {
      downloadAttachment(filename);
      return;
    }

    try {
      const commentAtt = await IdbApi.getStepCommentAttData(filename);

      if (!commentAtt) {
        throw new Error(t("Attachment not found."));
      }

      setFocusedAttachment({
        title: filename,
        imgSrc: commentAtt.data,
      });
    } catch (err: any) {
      dispatch(showErrorToast(err.message || err));
    }
  }

  const downloadAttachment = async (filename: string) => {
    try {
      const attData = await IdbApi.getStepCommentAttData(filename);

      if (attData) {
        const link = document.createElement("a");
        link.download = filename;
        link.href = attData.data;
        link.click();
      } else {
        dispatch(showErrorToast(t("Attachment not found in browser cache.")));
      }
    } catch (err: any) {
      dispatch(showErrorToast(err?.message || err.toString()));
    }
  }

  return (
    <div
      className={`print-step ${isConditionallyDisabled ? "conditionally-disabled" : ""}`}
    >
      <StepCompletionIcon icon={stepIcon} />
      <div
        className="step-data"
      >
        <span
          className="title"
        >
          {step.number}. {step.isMemoryStep ? "*" : undefined} {step.title}
        </span>
        <span
          className="completion-data"
        >
          {completionText}
        </span>

        {step.isConditional &&
          <div
            className="input-row conditional-response"
          >
            <span
              className="label"
            >
              {step.conditionalText}
            </span>
            <span
              className="answer"
            >
              {stepResponse?.conditionalResponse &&
                t("Yes")
              }
              {stepResponse?.conditionalResponse === false &&
                t("No")
              }
            </span>
          </div>
        }

        {inputs.length > 0 &&
          <div
            className="inputs"
          >
            <h4
              className="title"
            >
              {t('Inputs')}
            </h4>

            {inputs.map(input => {
              const response = stepResponse?.componentResponses?.find(x => x.id === input.id);
              const responseValues = response?.values;

              let responseDisplay: JSX.Element | string | undefined;

              if (responseValues?.length) {
                if (input.type === StepComponentTypes.DateInput) {
                  responseDisplay = formatDate(new Date(responseValues[0]), false, true, "short");
                } else if (input.type === StepComponentTypes.DateTimeInput) {
                  responseDisplay = formatDate(new Date(responseValues[0]), true, true, "short");
                } else if (input.type === StepComponentTypes.SignatureInput) {
                  responseDisplay = (
                    <PrintImage
                      filename={responseValues[0]}
                      isSignature
                      source={PrintImageSource.UserImage}
                    />
                  );
                } else if (input.type === StepComponentTypes.PhotoInput) {
                  responseDisplay = (
                    <>
                      {responseValues.map(fn => (
                        <PrintImage
                          filename={fn}
                          key={fn}
                          source={PrintImageSource.UserImage}
                        />
                      ))}
                    </>
                  );
                } else if (input.type === StepComponentTypes.VideoInput) {
                  responseDisplay = (
                    <>
                      {responseValues.map(fn => (
                        <PrintVideo
                          filename={fn}
                          key={fn}
                        />
                      ))}
                    </>
                  );
                } else if (input.type === StepComponentTypes.AudioInput) {
                  responseDisplay = (
                    <>
                      {responseValues.map(fn => (
                        <PrintAudio
                          filename={fn}
                          key={fn}
                        />
                      ))}
                    </>
                  );
                }
                else {
                  responseDisplay = responseValues.join(", ");
                }
              }

              return (
                <div
                  className="input-row"
                  key={input.id}
                >
                  <span
                    className="label"
                  >
                    {input.label}
                    {input.type === StepComponentTypes.NumberInput
                      && (input.data as INumberInputData | null)?.uom &&
                      <span>
                        &nbsp;({(input.data as INumberInputData).uom})
                      </span>
                    }
                  </span>
                  <span
                    className="answer"
                  >
                    {responseDisplay}
                  </span>
                </div>
              )
            })}
          </div>
        }

        {stepComments.length > 0 &&
          stepComments.map(comment => (
            <PrintComment
              key={comment.guid}
              stepComment={comment}
              onClickAttachment={(filename: string) => onClickCommentAttachment(filename)}
            />
          ))
        }

        {focusedAttachment &&
          <ImageModal
            imgSrc={focusedAttachment.imgSrc}
            title={focusedAttachment.title}
            fileName={focusedAttachment.title}
            onClose={() => setFocusedAttachment(undefined)}
          />
        }

        {subSteps}
      </div>
    </div>
  );
};

export default PrintStep;