import React from "react";
import "./StepCompletionIcon.scoped.scss";
import greenCheckIcon from "media/icons/custom/green-check.svg";
import yellowArrowIcon from "media/icons/custom/yellow-arrow.svg";
import redCheckIcon from "media/icons/custom/red-check.svg";
import warningIcon from "media/icons/custom/warning.svg";

export enum StepCompletionIcons {
  RedX,
  GreenCheck,
  NotApplicable,
  Empty,
  NonConform,
  RedCheck,
  RedIncomplete,
}

interface IStepCompletionIconProps {
  icon: StepCompletionIcons,
}

const StepCompletionIcon: React.FC<IStepCompletionIconProps> = ({ icon }) => {
  let component: JSX.Element | undefined;

  if (icon === StepCompletionIcons.RedX) {
    component = (
      <span
        className="failed"
      >
        X
      </span>
    );
  } else if (icon === StepCompletionIcons.NotApplicable) {
    component = (
      <img
        src={yellowArrowIcon}
        alt=""
      />
    );
  } else if (icon === StepCompletionIcons.NonConform) {
    component = (
      <img
        src={warningIcon}
        alt=""
      />
    );
  } else if (icon === StepCompletionIcons.GreenCheck) {
    component = (
      <img
        src={greenCheckIcon}
        alt=""
      />
    );
  } 
  else if (icon === StepCompletionIcons.RedCheck) {
    component = (
      <img
        src={redCheckIcon}
        alt=""
      />
    );
  }
  else if (icon === StepCompletionIcons.RedIncomplete) {
    component = (
      <span
        className="red-incomplete"
      >
        ―
      </span>
    );
  }
  else {
    component = (
      <span
        className="incomplete"
      >
        ―
      </span>
    );
  }

  return (
    <span
      className="completion-icon"
    >
      {component}
    </span>
  );
}

export default StepCompletionIcon;