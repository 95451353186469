import React, { useState } from "react";
import thumbUp from "media/icons/dls/thumbs-up.svg";
import thumbDown from "media/icons/dls/thumbs-down.svg";
import { ISWUserFeedback } from "interfaces/jobs/JobInterfaces";
import "./SWUserFeedback.scoped.scss";
import Modal from "components/common/Modal";
import { useTranslation } from "react-i18next";
import useSelector from "store/useSelector";
import { Connectivity } from "interfaces/execution/executionInterfaces";
import { useDispatch } from "react-redux";
import { saveOfflineSWUserFeedback, saveOnlineSWUserFeedback } from "store/execution/executionActions";
import { showErrorToast } from "store/toast/toastActions";
import { ExecutionMode } from "store/execution/executionTypes";

interface ISWUserFeedbackProps {
  feedback: ISWUserFeedback | undefined,
  swId: string,
  version: string,
  jobId: number,
  openFeedback: boolean,
}

const SWUserFeedback: React.FC<ISWUserFeedbackProps> = ({
  feedback,
  swId,
  version,
  jobId,
  openFeedback,
}) => {

  const {
    offline: {
      isOnline,
    },
    auth: {
      currentUser: {
        email,
      },
    },
    execution: {
      mode,
      executor,
      stepComments,
      stepResponses,
      paperExecutions,
      resetJobSWIds,
      job
    },
  } = useSelector(x => x);

  let hasFeedback = feedback === undefined ?
    false :
    true;

  const hasUnsavedChanges = stepComments.some(x => x.isDirty)
    || stepResponses.some(x => x.isDirty)
    || paperExecutions.some(x => x.isDirty)
    || resetJobSWIds.length > 0
    || job?.sws.some(x => x.jobSWId < 0 && x.isDirty);

  const dispatch = useDispatch();
  const { t } = useTranslation("swUserFeedback");
  const [vote, setVote] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(openFeedback);
  const [unsavedModalIsOpen, setUnsavedModalIsOpen] = useState(false);
  const [commentPlaceolder, setCommentPlaceolder] = useState("");
  const [comment, setComment] = useState(hasFeedback ?
    feedback?.feedback === undefined ||
      feedback?.feedback === null ?
      "" :
      feedback?.feedback :
    "");

  const onPositiveClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (hasUnsavedChanges) {
      e.stopPropagation();
      setUnsavedModalIsOpen(true);
      return;
    }

    setModal(e, true, t("Do you have any feedback to share?"));
  }
  const onNegativeClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (hasUnsavedChanges) {
      e.stopPropagation();
      setUnsavedModalIsOpen(true);
      return;
    }

    setModal(e, false, t("How would you improve this Standard Work?"));
  }

  const setModal = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, vote: boolean, placeholder: string) => {
    setIsModalOpen(true);
    setVote(vote);
    setCommentPlaceolder(placeholder);
    e.stopPropagation();
  }

  const onClose = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    setComment(hasFeedback ?
      feedback?.feedback === undefined ||
        feedback?.feedback === null ?
        "" :
        feedback?.feedback :
      "");
    setIsModalOpen(false);
  }

  const onSave = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    if (!vote && comment.trim().length === 0) {
      dispatch(showErrorToast(t("Please type your feedback")));
      return;
    }
    SaveFeedback(vote, comment);
    setIsModalOpen(false);
  }

  const SaveFeedback = (vote: boolean, comment: string) => {
    const newFeedback: ISWUserFeedback = {
      feedbackId: hasFeedback ? feedback!.feedbackId > 0 ? feedback!.feedbackId : 0 : 0,
      swId: swId,
      version: version,
      jobId: jobId,
      vote: vote,
      feedback: comment,
      createdBy: (executor?.email.length ? executor?.email : email),
    };

    if (mode === ExecutionMode.Offline) {
      dispatch(saveOfflineSWUserFeedback(
        {
          feedback: newFeedback,
        }));
    } else if (isOnline === Connectivity.Online) {
      let feedbacks: ISWUserFeedback[] = job?.swUserFeedback || [];
      let filteredFeedbacks = feedbacks.filter(x => x.feedbackId !== newFeedback.feedbackId);
      filteredFeedbacks.push(newFeedback);

      dispatch(saveOnlineSWUserFeedback(
        {
          feedbacks: filteredFeedbacks,
          jobId: newFeedback.jobId,
          createdBy: newFeedback.createdBy,
        }));
    }
  }

  const feedbackClassName = `feedback${hasUnsavedChanges ? ' disabled' : ''}`;

  const buttonTitle =
    hasUnsavedChanges ?
      t("Please Save Data before providing feedback.") :
      undefined;

  return (
    <>
      {
        <div className={feedbackClassName}>
          <button
            className={`feedback-button-${hasFeedback
              ? feedback?.vote ?
                "full" :
                "empty"
              : "empty"}`}
            onClick={(e) => onPositiveClick(e)}
            title={buttonTitle}
          >
            <img
              className="icon-small"
              src={thumbUp}
              alt={t("Like")}
            />
          </button>
          <button
            className={`feedback-button-${hasFeedback
              ? !feedback?.vote ?
                "full" :
                "empty"
              : "empty"}`}
            onClick={(e) => onNegativeClick(e)}
            title={buttonTitle}
          >
            <img
              className="icon-small"
              src={thumbDown}
              alt={t("Do not Like")}
            />
          </button>
        </div>
      }
      {isModalOpen &&
        <Modal
          header={t("Feedback")}
          isOpen={isModalOpen}
          controls={(
            <>
              <button
                className="secondary-button"
                onClick={(e) => onClose(e)}
              >
                {t('Close')}
              </button>
              <button
                className="primary-button"
                onClick={(e) => onSave(e)}
              >
                {t('Save')}
              </button>
            </>
          )}
        >
          <textarea
            className="comment"
            value={comment}
            placeholder={commentPlaceolder}
            onChange={(e) => setComment(e.target.value)}
            onClick={(e) => e.stopPropagation()}
          />
        </Modal>
      }

      {
        unsavedModalIsOpen &&
        <Modal
          header={t("Save Data before Feedback")}
          isOpen={unsavedModalIsOpen}
          controls={
            <button
              className="primary-button"
              onClick={() => setUnsavedModalIsOpen(false)}>
              {t("Accept")}
            </button>}
        >
          {t("Please Save Data before providing feedback.")}
        </Modal>
      }
    </>
  );
}
export default SWUserFeedback;	