import React from "react";
import "./Tab.scoped.scss";

interface ITabProps {
  icon: string,
  label: string,
  isActive: boolean,
  onClick(): void,
}

const Tab: React.FC<ITabProps> = ({
  icon,
  label,
  isActive,
  onClick,
}) =>
  <span
    onClick={onClick}
    className={`tab ${isActive ? "active" : ""}`}
    title={label}
  >
    <img
      src={icon}
      alt=""
    />
    <label className={`right-label ${isActive ? "active" : ""}`}>{label}</label>
    <label className={`bottom-label ${isActive ? "active" : ""}`}>{label}</label>
  </span>;

export default Tab;