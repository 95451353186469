import React, { useState } from "react";
import "./PhotoInput.scoped.scss";
import ManageImagesModal from "./photoInput/ManageImagesModal";
import useSelector from "store/useSelector";
import { IStepComponent, StepComponentTypes } from "interfaces/sw/SWInterfaces";
import { IStepIdentifier } from "store/execution/executionTypes";
import cameraIcon from "media/icons/custom/camera.svg";
import { getComponentResponse } from "utilities/swUtilities";
import { useTranslation } from "react-i18next";

interface IPhotoInputProps {
  stepInfo: IStepIdentifier,
  component: IStepComponent,
  isDisabled: boolean,
  isJobCompleted: boolean,
}

const PhotoInput: React.FC<IPhotoInputProps> = ({ stepInfo, component, isDisabled, isJobCompleted }) => {
  const [isChoosePicModalVisible, setChoosePicModalVisible] = useState(false);
  const stepResponses = useSelector(store => store
    .execution
    .stepResponses);

  const { t } = useTranslation('components');

  if (component.type !== StepComponentTypes.PhotoInput) {
    return null;
  }

  const compResponseValues = getComponentResponse(stepInfo,
    component.id,
    stepResponses)
    ?.values;

  const showChoose = () => setChoosePicModalVisible(true);
  const hideChoose = () => setChoosePicModalVisible(false);

  let previewBarContent: JSX.Element | undefined;

  if (compResponseValues
    && compResponseValues.length > 0) {
    previewBarContent = (
      <span>
        {t('image', {count: compResponseValues.length})}
      </span>
    );
  } else {
    previewBarContent = (
      <span>
        {t('No images selected')}
      </span>
    );
  }

  return (
    <>
      {component.label &&
        <div className="input-header">
          <label>{component.label}</label>
        </div>
      }
      <div className="row">
        <div
          className="icon-holder"
        >
          <img
            src={cameraIcon}
            alt=""
          />
        </div>
        <div
          onClick={showChoose}
          className="preview-bar">
          {previewBarContent}
        </div>
        {!isJobCompleted &&
          <button
            onClick={!isDisabled ? showChoose : undefined}
            disabled={isDisabled}
            className="primary-button choose-button">
            {t('Choose')}
          </button>
        }
      </div>
      {isChoosePicModalVisible &&
        <ManageImagesModal
          stepInfo={stepInfo}
          componentId={component.id}
          onClose={hideChoose}
          disabled={isDisabled}
        />
      }
    </>
  );
}

export default PhotoInput;