import React, { useEffect, useState } from "react";
import { ISWPJobHeader, MyJobsTabs, SWPJobStatus } from "interfaces/jobs/JobInterfaces";
import "./JobHeader.scoped.scss";
import { Link, RouteComponentProps } from "react-router-dom";
import { Routes } from "components/routing/Routing";
import refreshIcon from "media/icons/dls/refresh.svg";
import downloadIcon from "media/icons/dls/download.svg";
import editIcon from "media/icons/dls/edit-1.svg";
import reopenIcon from "media/icons/dls/reset.svg";
import printIcon from "media/icons/dls/print.svg";
import deleteIcon from "media/icons/dls/delete.svg";
import moreIcon from "media/icons/dls/more.svg";
import removeIcon from "media/icons/dls/remove.svg"
import duplicateIcon from "media/icons/dls/duplicate.svg"
import useSelector from "store/useSelector";
import { useDispatch } from "react-redux";
import { setJobModal, setReopenJobModal, removeSelfFromJob, printJob, duplicateJob } from "store/myJobs/myJobsActions";
import { showErrorToast } from "store/toast/toastActions";
import IdbApi from "apis/idb/IdbApi";
import { formatNumericalDate } from "utilities/formatDate";
import JobActionsModal from "../JobActionsModal";
import { useTranslation } from "react-i18next";
import { cacheJobOffline, deleteCachedJobs } from "store/offline/offlineActions";
import { Connectivity } from "interfaces/execution/executionInterfaces";
import CircularProgress from "components/common/CircularProgress";

interface IJobHeaderProps {
  jobHeader: ISWPJobHeader,
  showCacheButton: boolean,
  isOfflineMode: boolean,
  showExtraButtons: boolean,
  tab?: MyJobsTabs,
}

const JobHeader: React.FC<RouteComponentProps & IJobHeaderProps> = ({
  jobHeader,
  showCacheButton,
  isOfflineMode,
  showExtraButtons,
  history,
  tab,
}) => {
  const {
    offline: {
      jobsAwaitingCompletion,
      jobsAwaitingCancellation,
      cachedJobIds,
      isOnline,
    }
  } = useSelector(store => store);

  const { t } = useTranslation('jobHeader');

  const [isActionModalVisible, setIsActionModalVisible] = useState(false);
  const dispatch = useDispatch();

  const [isPendingSync, setIsPendingSync] = useState(jobHeader.id < 0);

  const jobId = jobHeader.id;

  useEffect(() => {
    let aborted = false;

    const checkIfJobIsPending = async () => {
      try {
        // Ask IdbApi if job is pending sync.
        const isPending = await IdbApi.isJobAwaitingSync(jobId);

        if (!aborted) {
          setIsPendingSync(isPending);
        }
      } catch (err: any) {
        if (!aborted) {
          dispatch(showErrorToast(err.message));
        }
      }
    }

    if (jobId > 0) {
      checkIfJobIsPending();
    }

    return () => {
      aborted = true;
    };
  }, [jobId, dispatch, setIsPendingSync]);

  const isCached = cachedJobIds.indexOf(jobHeader.id) > -1;
  const isCompletedInCache = jobsAwaitingCompletion.indexOf(jobHeader.id) > -1;
  const isCancelledInCache = jobsAwaitingCancellation.indexOf(jobHeader.id) > -1;

  const jobDisplay = (jobHeader.jobNumber
    ? `${jobHeader.jobNumber}: `
    : "") + jobHeader.title;

  const printUrl = isOfflineMode
    || isCached
    ? Routes.PrintViewOfflineJob.replace(":id", jobHeader.id.toString())
    : Routes.PrintViewJob.replace(":id", jobHeader.id.toString());

  const executionUrl = isOfflineMode
    || isCached
    ? Routes.ExecuteOfflineJob.replace(":id", jobHeader.id.toString())
    : Routes.ExecuteJob.replace(":id", jobHeader.id.toString());

  const cacheJobClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setIsActionModalVisible(false);
    dispatch(setJobModal({
      modal: "cacheModal",
      data: {
        isOpen: true,
        jobId: jobHeader.id,
        jobDisplay,
      },
    }));
  }

  const uncacheJobClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setIsActionModalVisible(false);
    dispatch(setJobModal({
      modal: "unCacheModal",
      data: {
        isOpen: true,
        jobId: jobHeader.id,
        jobDisplay,
      },
    }));
  }

  const refreshCachedJobClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setIsActionModalVisible(false);
    if (isOnline === Connectivity.Online) {
      dispatch(deleteCachedJobs({
        jobIds: [
          jobHeader.id,
        ],
        isRefreshJob: true,
      }));
      dispatch(cacheJobOffline({
        jobId: jobHeader.id,
        isRefreshJob: true,
      }));
    }
    else {
      dispatch(showErrorToast(t('refresh to device not available when offline')));
    }
  }

  const editClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setIsActionModalVisible(false);

    history.push(Routes.EditJob.replace(":id", jobHeader.id.toString()));
  }

  const printJobClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setIsActionModalVisible(false);
    dispatch(printJob(jobHeader.id));
  }

  const reopenClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setIsActionModalVisible(false);
    dispatch(setReopenJobModal({
      modal: "reopenJobModal",
      data: {
        isOpen: true,
        jobId: jobHeader.id,
        jobDisplay,
        jobStatus: jobHeader.status,
      }
    }));
  }

  const removeSelfFromJobClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setIsActionModalVisible(false);
    dispatch(removeSelfFromJob(jobHeader.id));
  }

  const cancelClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setIsActionModalVisible(false);
    dispatch(setJobModal({
      modal: "cancelJobModal",
      data: {
        isOpen: true,
        jobId: jobHeader.id,
        jobDisplay,
      },
    }));
  }

  const printClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setIsActionModalVisible(false);

    history.push(printUrl);
  }

  const duplicateJobClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setIsActionModalVisible(false);
    dispatch(duplicateJob({ jobId: jobHeader.id }));
  }

  const progressBar = (
    <div className="pending-sync2">
      <CircularProgress
        completedPercentage={jobHeader.completionPercentage}
      />
    </div>
  );

  const cacheButton = showCacheButton
    && !isCached
    && jobHeader.status !== SWPJobStatus.Completed
    ? (
      <button
        className="secondary-button action-button"
        onClick={cacheJobClick}
      >
        <img
          src={downloadIcon}
          alt=""
        />
        <span>
          {t('save to device')}
        </span>
      </button>
    ) : undefined;

  const unCacheButton = isCached
    && jobId > 0
    ? (
      <button
        className="secondary-button action-button"
        onClick={uncacheJobClick}
      >
        <img
          src={deleteIcon}
          alt=""
        />
        <span>
          {t('remove from device')}
        </span>
      </button>
    ) : undefined;

  const refreshJobButton = isCached
    && jobId > 0
    && !isPendingSync
    && !isOfflineMode
    ? (
      <button
        className="secondary-button action-button"
        onClick={refreshCachedJobClick}
      >
        <img
          src={downloadIcon}
          alt=""
        />
        <span>
          {t('refresh to device')}
        </span>
      </button>
    ) : undefined;

  const editButton = (jobHeader.status === SWPJobStatus.Completed
    || jobHeader.id < 0)
    ? undefined
    : (
      <button
        className="secondary-button action-button"
        onClick={editClick}
      >
        <img
          src={editIcon}
          alt=""
        />
        <span>
          {t('edit job')}
        </span>
      </button>
    );

  const printJobButton = jobHeader.status === SWPJobStatus.Completed
    || jobHeader.id <= 0
    ? undefined
    : (
      <button
        className="secondary-button action-button"
        onClick={printJobClick}
        disabled={isOfflineMode}
      >
        <img
          src={printIcon}
          alt=""
        />
        <span>
          {t('print job')}
        </span>
      </button>
    );

  const cancelButton = jobHeader.status === SWPJobStatus.Completed
    || isCancelledInCache
    ? undefined
    : (
      <button
        className="secondary-button action-button"
        onClick={cancelClick}
        disabled={isOfflineMode}
      >
        <img
          src={removeIcon}
          alt=""
        />
        <span>
          {t('cancel job')}
        </span>
      </button>
    );

  const reopenButton = jobHeader.status === SWPJobStatus.Completed
    || isCompletedInCache
    ? (
      <button
        className="secondary-button action-button"
        onClick={reopenClick}
      >
        <img
          src={reopenIcon}
          alt=""
        />
        <span>{t('Reopen Job')}</span>
      </button>
    ) : undefined;

  const pendingSyncText = isPendingSync
    ? (
      <span
        className="pending-sync"
      >
        <img
          src={refreshIcon}
          alt=""
        />
        <span>
          {t('Pending sync')}
        </span>
      </span>
    )
    : undefined;

  const removeMeButton = (jobHeader.status === SWPJobStatus.Completed
    || jobHeader.id < 0
    || !jobHeader.isOnTeam)
    ? undefined
    : (
      <button
        className="secondary-button action-button"
        onClick={removeSelfFromJobClick}
        disabled={isOfflineMode}
      >
        <img
          src={editIcon}
          alt=""
        />
        <span>
          {t('remove me from job')}
        </span>
      </button>
    );

  const printButton = tab === MyJobsTabs.Completed
    || isCompletedInCache
    || jobHeader.status === SWPJobStatus.Completed
    ? (
      <button
        className="secondary-button action-button"
        onClick={printClick}
      >
        <img
          src={printIcon}
          alt=""
        />
        <span>
          {t('view report')}
        </span>
      </button>
    )
    : undefined;

  const duplicateJobButton = (
    <button
      className="secondary-button action-button"
      onClick={duplicateJobClick}
    >
      <img
        src={duplicateIcon}
        alt=""
      />
      <span>
        {t('Duplicate Job')}
      </span>
    </button>
  );

  const onMoreButtonClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setIsActionModalVisible(true);
  }

  const renderActionButtons = (): JSX.Element => {
    const moreButton = editButton
      && (printButton
        || cancelButton)
      && !isCancelledInCache
      ? (
        <button
          className="secondary-button action-button"
          onClick={onMoreButtonClick}
        >
          <img
            src={moreIcon}
            alt="actions"
          />
        </button>
      ) : undefined;

    return (
      <span
        className="actions"
      >
        {cacheButton}
        {refreshJobButton}
        {unCacheButton}
        {showExtraButtons &&
          <>
            {!moreButton
              && !isCancelledInCache &&
              <>
                {editButton}
                {cancelButton}
                {printButton}
                {reopenButton}
                {removeMeButton}
                {printJobButton}
                {duplicateJobButton}
              </>
            }
            {moreButton}
          </>
        }
      </span>
    );
  }

  return (
    <>
      <Link
        className={"job-row " + (isCompletedInCache
          ? SWPJobStatus.Completed
          : jobHeader.status)
        }
        to={tab === MyJobsTabs.Completed
          || isCompletedInCache
          || jobHeader.status === SWPJobStatus.Completed
          ? printUrl
          : executionUrl}
      >
        <span
          className="info"
        >
          <span
            className="status"
          >
            {t(jobHeader.status)}
            {jobHeader.completedBy &&
              ` ${t('by')} ${jobHeader.completedBy}`
            }
            <label
              className="extra-details"
            >
              {` - ${t('Last Updated')}: ${formatNumericalDate(jobHeader.lastActionDate, true)}`}
            </label>
          </span>
          <span
            className="job-title"
          >
            <label
              className="extra-details"
            >
              {jobId > 0
                ? `${jobHeader.id} - `
                : t(`(Offline Only) - `)
              }
            </label>
            {jobDisplay}
          </span>
          <span
            className="job-deviations"
          >
            {t('TotalDeviations ') + jobHeader.deviationCount}
          </span>
        </span>
        {pendingSyncText}
        {progressBar}
        {renderActionButtons()}
      </Link>
      {isActionModalVisible &&
        <JobActionsModal
          onClose={() => setIsActionModalVisible(false)}
          jobDisplay={jobDisplay}
          header={t("Job Actions")}
        >
          {cacheButton}
          {unCacheButton}
          {editButton}
          {cancelButton}
          {printButton}
          {reopenButton}
          {removeMeButton}
          {printJobButton}
          {duplicateJobButton}
        </JobActionsModal>
      }
    </>
  );
}

export default JobHeader;