import React from "react";
import { MyJobsTabs } from "interfaces/jobs/JobInterfaces";
import "./MyJobsToolbar.scoped.scss";
import listIcon from "media/icons/dls/list.svg";
import checklistIcon from "media/icons/dls/checklist.svg";
import jobHeaderIcon from "media/icons/dls/job-header.svg";
import calendarIcon from "media/icons/dls/calendar.svg";
import Tab from "components/common/Tab";
import { useTranslation } from "react-i18next";

interface IMyJobsToolbarProps {
  currentTab: MyJobsTabs,
  onTabChange(tab: MyJobsTabs): void,
}

const MyJobsToolbar: React.FC<IMyJobsToolbarProps> = ({ currentTab, onTabChange }) =>
{
  let { t } = useTranslation('myJobs');

  return(
    <div className="my-jobs-tabs">
      <Tab
        label={t('Active')}
        icon={listIcon}
        isActive={currentTab === MyJobsTabs.Active}
        onClick={() => onTabChange(MyJobsTabs.Active)}
      />
      <Tab
        label={t('Completed')}
        icon={checklistIcon}
        isActive={currentTab === MyJobsTabs.Completed}
        onClick={() => onTabChange(MyJobsTabs.Completed)}
      />
      <Tab
        label={t("Recurring")}
        icon={calendarIcon}
        isActive={currentTab === MyJobsTabs.Recurring}
        onClick={() => onTabChange(MyJobsTabs.Recurring)}
      />
      <Tab
        label={t('Templates')}
        icon={jobHeaderIcon}
        isActive={currentTab === MyJobsTabs.Templates}
        onClick={() => onTabChange(MyJobsTabs.Templates)}
      />
    </div>);
}

export default MyJobsToolbar;