import React, { useEffect, useRef, useState } from "react";
import "./AudioInput.scoped.scss";
import useSelector from "store/useSelector";
import { IStepComponent } from "interfaces/sw/SWInterfaces";
import { IStepIdentifier } from "store/execution/executionTypes";
import { getComponentResponse } from "utilities/swUtilities";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { showErrorToast } from "store/toast/toastActions";
import { validateAndReadAudioFile } from "utilities/fileUtilities";
import IdbApi from "apis/idb/IdbApi";
import { setComponentResponse } from "store/execution/executionActions";
import { ComponentResponseType } from "interfaces/execution/executionInterfaces";

interface IAudioInputProps {
  stepInfo: IStepIdentifier,
  component: IStepComponent,
  isDisabled: boolean,
  isJobCompleted: boolean,
}

const AudioInput: React.FC<IAudioInputProps> = ({ stepInfo, component, isDisabled, isJobCompleted }) => {
  const {
    stepResponses,
  } = useSelector(store => store.execution);

  const { t } = useTranslation('components');
  const dispatch = useDispatch();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const { 
    executor,
    job,
  } = useSelector(state => state.execution);

  const { 
    currentUser,
  } = useSelector(state => state.auth);

  const [audioData, setAudioData] = useState<string | undefined>("");
  const [audioFilename, setAudioFilename] = useState("");

  useEffect(() => {
    const audioFileNames = getComponentResponse(stepInfo,
      component.id,
      stepResponses)
      ?.values;
    
    if (!audioFileNames || audioFileNames.length === 0) {
      return;
    }

    setAudioFilename(audioFileNames[0]);

    let aborted = false;

    const imageFetch = async () => {
      try {
        const img = await IdbApi.getUserImageData(audioFileNames[0]);
        if (!aborted) {
          setAudioData(img?.data);
        }
      } catch (err: any) {
        if (!aborted) {
          dispatch(showErrorToast(err?.message || err.toString()));
        }
      }
    }

    imageFetch();

    return () => {
      aborted = true;
    };
  }, [setAudioData, component.id, dispatch, stepInfo, stepResponses, audioData]);

  const jobId = job?.id;

  const onRemoveAudio = () => {
    dispatch(setComponentResponse({
      response: {
        id: component.id,
        type: ComponentResponseType.Image,
        values: [],
        isDeviation: false,
      },
      stepInfo: stepInfo,
      userEmail: executor?.email || currentUser.email,
    }));

    IdbApi.deleteCachedUserImages([audioFilename]);
    setAudioData("");
    setAudioFilename("");
  }

  const onAudioAddedToCache = (filename: string) => {
    dispatch(setComponentResponse({
      response: {
        id: component.id,
        type: ComponentResponseType.Image,
        values: [filename],
        isDeviation: false,
      },
      stepInfo: stepInfo,
      userEmail: executor?.email || currentUser.email,
    }));
  }

  const onFileChosen = async (event: React.FormEvent<HTMLInputElement>) => {
    if (!jobId) {
      throw new Error("No job is loaded.");
    }

    let tgt = event.target as HTMLInputElement;

    if (!tgt || !tgt.files || !tgt.files.length) {
      return;
    }

    let file = tgt.files[0];

    try {
      const fileData = await validateAndReadAudioFile(file);
      let cacheResponse = await IdbApi.cacheUserImageData(fileData.filename,
        jobId,
        false,
        fileData.dataUri);

      if (cacheResponse) {
        onAudioAddedToCache(fileData.filename);
        setAudioData(fileData.dataUri);
        setAudioFilename(fileData.filename);
      } else {
        dispatch(showErrorToast("Failed to upload audio to cache."));
      }
    } catch (err: any) {
      dispatch(showErrorToast(err.message || err));
    }
  }

  let vid: JSX.Element | undefined;

  if (audioData) {
      vid = (
          <audio controls className="embed">
              <source src={audioData} />
          </audio>
      );
  }

  return (
    <>
      <div>
          <div>{component.label}</div>          
          {vid}
          <input
              type="file"
              accept="audio/mp4,audio/mp3,audio/wav"
              ref={fileInputRef}
              onChange={onFileChosen}
              disabled={isDisabled}
          />
          <div
              className="controls"
          >
              {!audioData &&
                <button
                    className="primary-button choose-audio"
                    onClick={() => fileInputRef.current?.click()}
                    disabled={isDisabled}
                >
                    {t("Choose Audio")}
                </button>
              }
              
              {audioData && 
                <button
                    className="primary-button choose-audio"
                    onClick={() => onRemoveAudio()}
                    disabled={isDisabled}
                >
                    {t("Remove Audio")}
                </button>
              }
          </div>
      </div>
    </>
  );
}

export default AudioInput;