import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { IStepComponent, StepComponentTypes, INumberInputData } from "interfaces/sw/SWInterfaces";
import { logJobAction, setComponentResponse } from "store/execution/executionActions";
import { ComponentResponseType } from "interfaces/execution/executionInterfaces";
import useSelector from "store/useSelector";
import TextOrNumberBox, { TextInputTypes } from "components/execution/sw/controls/TextOrNumberBox";
import { IStepIdentifier } from "store/execution/executionTypes";
import { getComponentResponse } from "utilities/swUtilities";
import Banner, { BannerType } from "components/common/Banner";
import "./NumberInput.scoped.scss";

interface INumberInputProps {
  component: IStepComponent,
  isDisabled: boolean,
  stepInfo: IStepIdentifier,
}

const NumberInput: React.FC<INumberInputProps> = ({ component, isDisabled, stepInfo }) => {
  const dispatch = useDispatch();
  const {
    auth: {
      currentUser: {
        email,
        name,
      },
    },
    execution: {
      stepResponses,
      executor,
    },
  } = useSelector(store => store);

  const [isOutOfRange, setIsOutOfRange] = useState(false);

  if (component.type !== StepComponentTypes.NumberInput) {
    return null;
  }

  const onValueChange = (value: string) => {
    setIsOutOfRange(false);

    if (isDisabled) {
      return;
    }

    let isDeviation = false;
    let valueAsFloat = parseFloat(value);

    if (expectedValue !== undefined) {
      if (valueAsFloat !== expectedValue) {
        setIsOutOfRange(true);
        isDeviation = true;
      }
    }
    
    if (lowerLimit !== undefined) {
      if (valueAsFloat < lowerLimit) {
        setIsOutOfRange(true);
        isDeviation = true;
      }
    }

    if (upperLimit !== undefined) {
      if (valueAsFloat > upperLimit) {
        setIsOutOfRange(true);
        isDeviation = true;
      }
    }

    dispatch(setComponentResponse({
      response: {
        id: component.id,
        type: ComponentResponseType.Number,
        values: [value],
        isDeviation: isDeviation,
      },
      stepInfo,
      userEmail: executor?.email || email,
    }));

    if (isDeviation) {
      dispatch(logJobAction({
        action: `Component response for ${component.label} was completed with deviation.`,
        timestamp: new Date(),
        userName: executor?.name || name,
        userEmail: executor?.email || email,
      }));
    }
  }

  const compResponseValues = getComponentResponse(stepInfo,
    component.id,
    stepResponses)
    ?.values;

  let uom = "";
  let lowerLimit: number | undefined = undefined;
  let expectedValue: number | undefined = undefined;
  let upperLimit: number | undefined = undefined;
  let nonConform = "";

  if (isNumberData(component.data)) {
    uom = component.data.uom;
    lowerLimit = component.data.lowerLimit;
    expectedValue = component.data.expectedValue;
    upperLimit = component.data.upperLimit;
    nonConform = component.data.nonConform;
  }

  let shouldShowConformInfo = lowerLimit !== undefined
    || expectedValue !== undefined
    || upperLimit !== undefined;

  let existingResponseNonConform = false;
  if (compResponseValues 
    && (compResponseValues?.[0] !== "" 
    && compResponseValues?.[0] !== undefined)) {
    let valueAsFloat = parseFloat(compResponseValues[0]);

    if ((expectedValue !== undefined 
        && valueAsFloat !== expectedValue)
      || (lowerLimit !== undefined 
        && valueAsFloat < lowerLimit)
      || (upperLimit !== undefined 
        && valueAsFloat > upperLimit))
    {
      existingResponseNonConform = true;
    }
  }

  return (
    <div>
      {shouldShowConformInfo &&
        <Banner
          type = {BannerType.Info}
        >
          <div className="conformInfo">
            <label>Lower Limit:&nbsp;</label>
            <span>{lowerLimit}</span>
            <label>Expected Value:&nbsp;</label>
            <span>{expectedValue}</span>
            <label>Upper Limit:&nbsp;</label>
            <span>{upperLimit}</span>
          </div>
        </Banner>
      }
      {shouldShowConformInfo 
        && (isOutOfRange 
          || existingResponseNonConform) &&
        <Banner
          type = {BannerType.Warn}              
        >
          {nonConform}
        </Banner>
      }      
      <label>{component.label}{uom ? ` (${uom})` : undefined}</label>
      <TextOrNumberBox
        disabled={isDisabled}
        value={compResponseValues?.[0] || ""}
        onResponseChange={val => onValueChange(val)}
        inputType={TextInputTypes.Number}
      />
    </div>
  );
}

export default NumberInput;

function isNumberData(data: any): data is INumberInputData {
  return data !== null
    && data !== undefined
    && 'uom' in data;
}