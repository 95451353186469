import React from 'react';
import "./FlowLayout.scoped.scss";

interface IFlowLayoutProps {
  header?: JSX.Element,
  footer?: JSX.Element,
  children: any,
}

const FlowLayout: React.FC<IFlowLayoutProps> = ({ children, header, footer }) =>
  <div className="flow-layout">
    {header &&
      <div className="flow-header">
        {header}
      </div>
    }
    <div className="flow-body">
      {children}
    </div>
    {footer &&
      <div className="flow-footer">
        {footer}
      </div>
    }
  </div>;

export default FlowLayout;
