import React from "react";
import { SWTypes } from "interfaces/sw/SWInterfaces";
import "./SWTypeIcon.scoped.scss";

interface ISWTypeIconProps {
  type: SWTypes,
}

const SWTypeIcon: React.FC<ISWTypeIconProps> = ({ type }) => {
  let labelText = ""

  if (type === SWTypes.TLMSWI) {
    labelText = "TLM";
  } else if (type === SWTypes.MFGSWI || type === SWTypes.MFGCL) {
    labelText = "MFG";
  }
  else {
    labelText = type;
  }

  return (<div className={`hexagon ${type}`}>
    <label>{labelText}</label>
  </div>);
}

export default SWTypeIcon;