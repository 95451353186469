import React from "react";
import { ISWSearchResultItem } from "interfaces/sw/SWInterfaces";
import "./SWHeader.scoped.scss";
import removeIcon from "media/icons/dls/delete.svg";
import addIcon from "media/icons/dls/add-to-report.svg";
import dragIcon from "media/icons/dls/drag.svg";
import useSelector from "store/useSelector";
import { useTranslation } from "react-i18next";

export enum SWHeaderButtonMode {
  Hidden = "Hidden",
  Add = "Add",
  Remove = "Remove",
}

interface ILeftControlProps {
  buttonMode: SWHeaderButtonMode,
  onButtonClick?(): void,
  showDraggable?: boolean,
}
const SWHeader: React.FC<ISWSearchResultItem & ILeftControlProps> = (props) => {
  const {
    job,
  } = useSelector(state => state.manageJob);
  
  const { t } = useTranslation('createJob');
  const inJobCount = job.sws.filter(x => x.swId === props.swId).length;

  return (
    <div
      className={`sw-row input`}
    >
      {props.showDraggable &&
        <img
          className="drag"
          src={dragIcon}
          alt=""
        />
      }
      <div
        className="sw-info"
      >
        <div
          className="title"
        >
          {props.title}
        </div>
        {props.description &&
          <div
            className="desc"
          >
            {props.description}
          </div>
        }
      </div>
      {props.buttonMode !== SWHeaderButtonMode.Hidden &&
        <span
          className={`toggle-sw-button input ${props.buttonMode}`}
          title={t(props.buttonMode)}
          onClick={props.onButtonClick}
        >
          <img
            className="icon-small"
            src={props.buttonMode === SWHeaderButtonMode.Add
              ? addIcon
              : removeIcon
            }
            alt={t(props.buttonMode)}
          />
          {inJobCount > 0
            && props.buttonMode === SWHeaderButtonMode.Add && (
              <label className="sw-count-label">
                {inJobCount}
              </label>
            )}
        </span>
      }
    </div>
  );
}

export default SWHeader;