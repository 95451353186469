import { IJobSchedule, ISWPJobTeamMember, SWPJobType } from "interfaces/jobs/JobInterfaces";
import { createAction } from "@reduxjs/toolkit";
import { IMasterDataItem } from "interfaces/masterData/masterDataInterfaces";
import { ISWSearchResult } from "interfaces/sw/SWInterfaces";
import { MetaDataNames } from "store/masterData/masterDataTypes";
import { IInProgressJob, IJobDocs, IManageJobSW, ManageJobViews } from "./manageJobTypes";
import { IOperation } from "interfaces/operations/OperationInterfaces";

export const getJob = createAction<{ jobId: number }>("manageJob/getJob");
export const setIsGettingJob = createAction<boolean>("manageJob/setIsGettingJob");
export const startNewJob = createAction("manageJob/startNewJob");
export const setJob = createAction<IInProgressJob>("manageJob/setJob");
export const setNumber = createAction<string>("manageJob/setNumber");
export const setTitle = createAction<string>("manageJob/setTitle");
export const setIsDemo = createAction<boolean>("manageJob/setIsDemo");
export const setJobType = createAction<SWPJobType>("manageJob/setJobType");
export const setCountry = createAction<IMasterDataItem | undefined>("manageJob/setCountry");
export const setOrg = createAction<IMasterDataItem | undefined>("manageJob/setOrg");
export const setSubBusinessLine = createAction<IMasterDataItem | undefined>("manageJob/setSubBusinessLine");
export const setGeoUnit = createAction<IMasterDataItem | undefined>("manageJob/setGeoUnit");
export const setCustomer = createAction<IMasterDataItem | undefined>("manageJob/setCustomer");
export const addJobDocs = createAction<IJobDocs>("manageJob/addJobDocs");
export const removeJobDocs = createAction<{ filename: string }>("manageJob/removeJobDocs")
export const getJobDocPdf = createAction<{fileUrl: string}>("manageJob/getJobDocPdf");
export const setJobDocPdfOperation = createAction<IOperation>("manageJob/setJobDocPdfOperation");
export const addSW = createAction<IManageJobSW>("manageJob/addSW");
export const removeSW = createAction<{
  listGuid: string,
}>("manageJob/removeSW");
export const moveSW = createAction<{ oldIndex: number, newIndex: number }>("manageJob/moveSW");
export const addTeamMember = createAction<ISWPJobTeamMember>("manageJob/addTeamMember");
export const removeTeamMember = createAction<{ email: string }>("manageJob/removeTeamMember");
export const saveJob = createAction("manageJob/saveJob");
export const setIsSaving = createAction<boolean>("manageJob/setIsSaving");
export const setSaveError = createAction<string>("manageJob/setSaveError");
export const setGetJobError = createAction<string>("manageJob/setGetJobError");
export const setSaveSuccess = createAction<boolean | null>("manageJob/setSaveSuccess");
export const setSearchText = createAction<string>("manageJob/setSearchText");
export const searchSWs = createAction("manageJob/searchSWs");
export const setNewIndex = createAction<number>("manageJob/setNewIndex");
export const setSWSearchResults = createAction<ISWSearchResult>("manageJob/setSWSearchResults");
export const applyFilter = createAction("manageJob/applyFilter");
export const clearFilter = createAction("manageJob/clearFilter");
export const addFilterItem = createAction<{
  metaDataName: MetaDataNames,
  item: IMasterDataItem,
}>("manageJob/addFilterItem");
export const addFilterItems = createAction<{
  metaDataName: MetaDataNames,
  items: IMasterDataItem[],
}>("manageJob/addFilterItems");
export const removeFilterItem = createAction<{
  metaDataName: MetaDataNames,
  item: IMasterDataItem,
}>("manageJob/removeFilterItem");
export const setStringFilter = createAction<{
  attributeName: "maintenanceTaskIds",
  value: string
}>("manageJob/setStringFilter");
export const setSchedule = createAction<IJobSchedule | undefined>("manageJob/setJobSchedule");
export const setCurrentJobView = createAction<ManageJobViews>("manageJob/setCurrentJobView");