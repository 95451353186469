import React, { useState } from "react";
import filterIcon from "media/icons/dls/filter.svg";
import searchIcon from "media/icons/dls/search.svg";
import "./JobFilters.scoped.scss";
import { useDispatch } from "react-redux";
import useSelector from "store/useSelector";
import {
  addCountryFilter as addJobTemplateCountryFilter,
  addOrgFilter as addJobTemplateOrgFilter,
  removeCountryFilter as removeJobTemplateCountryFilter,
  removeOrgFilter as removeJobTemplateOrgFilter,
  searchJobTemplates,
  setSearchTerm as setJobTemplateSearchTerm,
  clearFilters as clearJobTemplateFilters,
} from "store/jobTemplates/jobTemplatesActions";
import FilterModal from "components/common/FilterModal";
import { loadCountries, loadOrganizations } from "store/masterData/masterDataActions";
import ItemPicker from "components/common/itemPicker/ItemPicker";
import { MyJobsTabs } from "interfaces/jobs/JobInterfaces";
import { IJobFilters } from "store/jobTemplates/jobTemplatesTypes";
import { addCountryFilter, addOrgFilter, clearFilters, getMyJobs, removeCountryFilter, removeOrgFilter, setAdminShowAll, setSearchTerm } from "store/myJobs/myJobsActions";
import { IMasterDataItem } from "interfaces/masterData/masterDataInterfaces";
import { AuthCheck, AuthFilter } from "auth/AuthFilter";
import { Roles } from "interfaces/user/UserInterfaces";
import { useTranslation } from "react-i18next";

interface IJobFiltersProps {
  filters: IJobFilters,
  currentTab: MyJobsTabs,
}

const JobFilters: React.FC<IJobFiltersProps> = ({
  filters,
  currentTab,
}) => {
  const {
    masterData: {
      countries,
      organizations,
    },
    myJobs: {
      adminShowAll,
      isFetching,
    },
    jobTemplates: {
      loadOperation,
    },
  } = useSelector(store => store);
  const dispatch = useDispatch();
  const { t } = useTranslation('jobFilters');

  const selectedCountries = filters.countries;
  const selectedOrgs = filters.orgs;
  const searchTerm = filters.searchTerm;

  const [isModalOpen, setIsModalOpen] = useState(false);


  const clearSearchFilter = () => {
    if (currentTab === MyJobsTabs.Templates) {
      dispatch(clearJobTemplateFilters());

      if (searchTerm === "") {
        dispatch(searchJobTemplates());
      } else {
        setJobTemplateSearchTerm("");
      }
    } else {
      dispatch(clearFilters(currentTab));

      if (searchTerm === "") {
        dispatch(searchJobTemplates());
      } else {
        setSearchTerm({
          filterSet: currentTab,
          searchTerm: "",
        });
      }
    }
  }

  const applyFilters = () => {
    if (currentTab === MyJobsTabs.Templates) {
      dispatch(searchJobTemplates());
    } else {
      dispatch(getMyJobs());
    }
  }

  const onCountryAdded = (item: IMasterDataItem) => {
    if (currentTab === MyJobsTabs.Templates) {
      dispatch(addJobTemplateCountryFilter(item));
    } else {
      dispatch(addCountryFilter({
        filterSet: currentTab,
        item,
      }));
    }
  }

  const onCountryRemoved = (item: IMasterDataItem) => {
    if (currentTab === MyJobsTabs.Templates) {
      dispatch(removeJobTemplateCountryFilter(item));
    } else {
      dispatch(removeCountryFilter({
        filterSet: currentTab,
        item,
      }));
    }
  }

  const onOrgAdded = (item: IMasterDataItem) => {
    if (currentTab === MyJobsTabs.Templates) {
      dispatch(addJobTemplateOrgFilter(item));
    } else {
      dispatch(addOrgFilter({
        filterSet: currentTab,
        item,
      }));
    }
  }

  const onOrgRemoved = (item: IMasterDataItem) => {
    if (currentTab === MyJobsTabs.Templates) {
      dispatch(removeJobTemplateOrgFilter(item));
    } else {
      dispatch(removeOrgFilter({
        filterSet: currentTab,
        item,
      }));
    }
  }
  const onSearchClick = () => {
    if (currentTab === MyJobsTabs.Templates) {
      dispatch(searchJobTemplates())
    } else {
      dispatch(getMyJobs())
    }
  }
  const onSearchTermChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (currentTab === MyJobsTabs.Templates) {
      dispatch(setJobTemplateSearchTerm(e.target.value));
    } else {
      dispatch(
        setSearchTerm({
          filterSet: currentTab,
          searchTerm: e.target.value,
        })
      );

    }
  };

  return (
    <>
      <div
        className="search-bar"
      >
        <input
          placeholder={t('Search by job number or name...')}
          type="text"
          value={searchTerm}
          onChange={onSearchTermChanged}
          readOnly={(isFetching
            || loadOperation?.isWorking)}
          onKeyPress={e => e.key === "Enter" ? onSearchClick() : null}
        />
        <button
          onClick={onSearchClick}
          className="secondary-button"
          type="submit">
          <img
            src={searchIcon}
            alt="Search"
            className="icon-small" />
        </button>
        <span
          className="filter-button hover-gray-bg"
          onClick={() => setIsModalOpen(true)}
        >
          <img
            src={filterIcon}
            alt={t('Filters')}
            title={t('Filters')}
            className="icon-medium"
          />
          {selectedOrgs.length
            + selectedCountries.length > 0 &&
            <span className="count"></span>
          }
        </span>
        {currentTab !== MyJobsTabs.Templates && (
          <AuthCheck
            authFilter={AuthFilter.isLoggedIn()
              .hasAnyRoleIn([Roles.ExecutionAdmin, Roles.SysAdmin])
            }
          >
            <button
              className="secondary-button all-button"
              onClick={() => {
                dispatch(setAdminShowAll(!adminShowAll));
              }}
            >
              {t('Show')} {adminShowAll ? t('Mine') : t('All')}
            </button>
          </AuthCheck>
        )}
      </div>
      {isModalOpen &&
        <FilterModal
          applyFilter={applyFilters}
          clearFilter={clearSearchFilter}
          setIsModalOpen={setIsModalOpen}
          filterControls={
            <>
              <div>
                <ItemPicker
                  label={t("Country")}
                  allowMultiple={true}
                  loadOnStart={true}
                  selectedItems={selectedCountries}
                  masterDataSection={countries}
                  loadItems={() => dispatch(loadCountries({ includeAll: true }))}
                  onAddItem={onCountryAdded}
                  onRemoveItem={onCountryRemoved}
                  itemFormatter={item => `${item.code} - ${item.value}`}
                />
              </div>
              <div>
                <ItemPicker
                  label={t("Organization")}
                  allowMultiple={true}
                  loadOnStart={true}
                  selectedItems={selectedOrgs}
                  masterDataSection={organizations}
                  loadItems={() => dispatch(loadOrganizations({ includeAll: true }))}
                  onAddItem={onOrgAdded}
                  onRemoveItem={onOrgRemoved}
                  itemFormatter={item => `${item.code} - ${item.value}`}
                />
              </div>
            </>
          }
        />
      }
    </>
  );
};

export default JobFilters;