import React from "react";
import { useDispatch } from "react-redux";
import { setHasCantCompleteWithDirtyResponsesError } from "store/execution/executionActions";
import Modal, { ModalTheme } from "components/common/Modal";
import { useTranslation } from "react-i18next";

const CantCompleteJobModal: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('execution');

  return (
    <Modal
      isOpen={true}
      header="Pending Responses"
      theme={ModalTheme.Error}
      controls={(
        <button
          className="primary-button"
          onClick={() => dispatch(setHasCantCompleteWithDirtyResponsesError(false))}
        >
          {t('OK')}
        </button>
      )}
    >
      <p>{t('The job cannot be completed while you have pending responses.')}</p>
      <p>{t('Please save your responses using the save responses button at the top right of the screen.')}</p>
    </Modal>
  );
}

export default CantCompleteJobModal;