import React, { useEffect } from "react";
import { RouteComponentProps, Link } from "react-router-dom";
import "./EditUser.scoped.scss";
import { Roles } from "interfaces/user/UserInterfaces";
import { useDispatch } from "react-redux";
import { getUserForEdit, toggleRole, updateUser, resetEditUserData } from "store/userManagement/userManagementActions";
import useSelector from "store/useSelector";
import BlockSpinner from "components/common/BlockSpinner";
import { Routes } from "components/routing/Routing";
import FlowLayout from "components/layout/FlowLayout";
import checkIcon from "media/icons/dls/check.svg";
import UserJobs from "./UserJobs";
import { useTranslation } from "react-i18next";

const EditUser: React.FC<RouteComponentProps<{ email: string }>> = (props) => {
  const email = window.decodeURIComponent(props.match.params.email);
  const {
    userManagement: {
      editUserData: {
        loadOperation,
        saveOperation,
        user,
        isDirty,
      },
    },
  } = useSelector(store => store);
  const dispatch = useDispatch();
  const { t } = useTranslation('userSearch');

  const pageTitle = (!loadOperation?.isWorking
    && !loadOperation?.errorMessage
    && user?.email === email)
    ? `${user.name} (${user.email})`
    : email;

  useEffect(() => {
    dispatch(getUserForEdit(email));

    return () => {
      dispatch(resetEditUserData());
    };
  }, [email, dispatch]);

  const save = () => {
    if (user) {
      dispatch(updateUser(user));
    };
  }

  let component: JSX.Element | undefined;
  let footerComponent: JSX.Element | undefined;

  if (loadOperation?.isWorking
    || saveOperation?.isWorking) {
    component = <BlockSpinner />;
  } else if (loadOperation?.errorMessage
    || saveOperation?.errorMessage) {
    component = (
      <label
        className="error"
      >
        {loadOperation?.errorMessage
          || saveOperation?.errorMessage}
      </label>
    );
  } else if (user) {
    component = (
      <>
        <fieldset className="roles">
          <legend>{t('Roles')}</legend>
          {Object.keys(Roles).map(r =>
            <button
              key={r}
              className={`primary-button ${user.roles
                .indexOf(r as Roles) > -1
                ? "selected"
                : ""}`}
              onClick={() => dispatch(toggleRole(r as Roles))}
            >
              {renderBox(user.roles
                .indexOf(r as Roles) > -1)} {r}
            </button>
          )}
        </fieldset>
        <UserJobs 
          {...props}        
        />
      </>
    );

    footerComponent = (
      <div className="buttons">
        <button
          className="tertiary-button cancel-button"
        >
          <Link
            to={Routes.UserManagement}
          >
            {isDirty ? t("Cancel") : t("Back")}
          </Link>
        </button>
        <button
          className="primary-button"
          disabled={!isDirty
            || loadOperation?.isWorking
            || saveOperation?.isWorking}
          onClick={save}
        >
          {t('Save')}
        </button>
      </div>
    );
  }

  return (
    <FlowLayout
      header={(
        <h2 className="page-title">
          {pageTitle}
        </h2>
      )}
      footer={footerComponent}
    >
      {component}
    </FlowLayout>
  );
}

function renderBox(isChecked: boolean): JSX.Element {
  return (
    <span
      className="checkbox"
    >
      {isChecked &&
        <img
          src={checkIcon}
          className="black-to-white-filter"
          alt="X"
        />
      }
    </span>
  );
}

export default EditUser;