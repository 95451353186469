import { authGetJson } from "utilities/msalFetches";
import config from "config";
import { throwIfResponseError } from "apis/apiUtilities";
import { formatOrganizations, formatCustomers, formatCountries, formatLanguages, formatSafetyCategories, formatActivityWorkflows, formatEquivalentStandardWorks, formatCompetencyElements, formatEquipment, formatOperatingEnvironments, formatServiceTypes, formatTasks, formatGeoUnits, formatEpicEquipment, formatBusinessRoles, formatProductCenters, formatSubBusinessLines, formatOwningPlants, formatPerformingPlants, formatWorkCenters, formatMaterials } from "./masterDataFormatters";
import { IMasterDataItem } from "interfaces/masterData/masterDataInterfaces";

class MasterDataApi {
  public async getCountries(includeAll: boolean): Promise<IMasterDataItem[]> {
    return formatCountries(
      await this.getMasterData(config.endpoints.masterData.getCountries
        + "?includeAll="
        + includeAll));
  }

  public async getGeoUnits(includeAll: boolean): Promise<IMasterDataItem[]> {
    return formatGeoUnits(
      await this.getMasterData(config.endpoints.masterData.getGeoUnits
        + "?includeAll="
        + includeAll));
  }

  public async getLanguages(): Promise<IMasterDataItem[]> {
    return formatLanguages(
      await this.getMasterData(config.endpoints.masterData.getLanguages));
  }

  public async getOrganizations(includeAll: boolean): Promise<IMasterDataItem[]> {
    return formatOrganizations(
      await this.getMasterData(config.endpoints.masterData.getOrganizations
        + "?includeAll="
        + includeAll));
  }

  public async getSubBusinessLines(includeAll: boolean): Promise<IMasterDataItem[]> {
    return formatSubBusinessLines(
      await this.getMasterData(config.endpoints.masterData.getSubBusinessLines
        + "?includeAll="
        + includeAll));
  }
  public async getSafetyCategories(): Promise<IMasterDataItem[]> {
    return formatSafetyCategories(
      await this.getMasterData(config.endpoints.masterData.getSafetyCategories));
  }

  public async searchActivityWorkflows(searchTerm: string): Promise<IMasterDataItem[]> {
    return formatActivityWorkflows(
      await this.getMasterData(config.endpoints.masterData.searchActivityWorkflows
        + "?searchTerm="
        + searchTerm));
  }

  public async searchEquivalentSWs(searchTerm: string): Promise<IMasterDataItem[]> {
    return formatEquivalentStandardWorks(
      await this.getMasterData(config.endpoints.masterData.searchEquivalentSWs
        + "?searchTerm="
        + searchTerm));
  }

  public async searchCompetencyElements(searchTerm: string): Promise<IMasterDataItem[]> {
    return formatCompetencyElements(
      await this.getMasterData(config.endpoints.masterData.searchCompetencyElements
        + "?searchTerm="
        + searchTerm));
  }

  public async searchEquipment(searchTerm: string): Promise<IMasterDataItem[]> {
    return formatEquipment(
      await this.getMasterData(config.endpoints.masterData.searchEquipment
        + "?searchTerm="
        + searchTerm));
  }

  public async searchEpicEquipment(searchTerm: string): Promise<IMasterDataItem[]> {
    return formatEpicEquipment(
      await this.getMasterData(config.endpoints.masterData.searchEpicEquipment
        + "?searchTerm="
        + searchTerm));
  }

  public async searchCustomers(searchTerm: string, includeAll: boolean): Promise<IMasterDataItem[]> {
    return formatCustomers(
      await this.getMasterData(config.endpoints.masterData.searchCustomers
        + `?searchTerm=${searchTerm}`
        + `&includeAll=${includeAll}`));
  }

  public async searchBusinessRoles(searchTerm: string): Promise<IMasterDataItem[]> {
    return formatBusinessRoles(
      await this.getMasterData(config.endpoints.masterData.searchBusinessRoles
        + "?searchTerm="
        + searchTerm));
  }

  public async searchProductCenters(searchTerm: string): Promise<IMasterDataItem[]> {
    return formatProductCenters(
      await this.getMasterData(config.endpoints.masterData.searchProductCenters
        + "?searchTerm="
        + searchTerm));
  }

  public async searchOperatingEnvironments(searchTerm: string): Promise<IMasterDataItem[]> {
    return formatOperatingEnvironments(
      await this.getMasterData(config.endpoints.masterData.searchOperatingEnvironments
        + "?searchTerm="
        + searchTerm));
  }

  public async searchServiceTypes(searchTerm: string): Promise<IMasterDataItem[]> {
    return formatServiceTypes(
      await this.getMasterData(config.endpoints.masterData.searchServiceTypes
        + "?searchTerm="
        + searchTerm));
  }

  public async searchTasks(searchTerm: string): Promise<IMasterDataItem[]> {
    return formatTasks(
      await this.getMasterData(config.endpoints.masterData.searchTasks
        + "?searchTerm="
        + searchTerm));
  }

  public async getOwningPlants(): Promise<IMasterDataItem[]> {
    return formatOwningPlants(
      await this.getMasterData(config.endpoints.masterData.getOwningPlants));
  }

  public async getPerformingPlants(): Promise<IMasterDataItem[]> {
    return formatPerformingPlants(
      await this.getMasterData(config.endpoints.masterData.getPerformingPlants));
  }

  public async searchWorkCenters(searchTerm: string): Promise<IMasterDataItem[]> {
    return formatWorkCenters(
        await this.searchMasterData(config.endpoints.masterData.searchWorkCenters, searchTerm)
      );
  }

  public async searchMaterials(searchTerm: string): Promise<IMasterDataItem[]> {
    return formatMaterials(
        await this.searchMasterData(config.endpoints.masterData.searchMaterials, searchTerm));
  }

  private async getMasterData(url: string): Promise<any> {
    let response = await authGetJson(url);
    await throwIfResponseError(response);
    return await response.json();
  }

  private async searchMasterData(masterDataEndpoint: string, searchTerm: string): Promise<any>{
    let request= `${masterDataEndpoint}?searchTerm=${searchTerm}`;
    return await this.getMasterData(request);
  }
}

export default new MasterDataApi();