import Modal from "components/common/Modal";
import { ISWPJobTeamMember } from "interfaces/jobs/JobInterfaces";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { setExecutor } from "store/execution/executionActions";
import useSelector from "store/useSelector";
import bcryptjs from "bcryptjs";
import "./SwitchExecutorModal.scoped.scss";
import PINValidationModal from "./PINValidationModal";
import { useTranslation } from "react-i18next";

const SwitchExecutorModal: React.FC = () => {
  const {
    auth: {
      currentUser: {
        email,
      },
    },
    execution: {
      job,
      executor,
      isSwitchExecutorVisible,
    },
  } = useSelector(store => store);
  const dispatch = useDispatch();
  const [isPINEntryVisible, setIsPINEntryVisible] = useState(false);
  const [pinEntry, setPINEntry] = useState("");
  const [pinEntryError, setPINEntryError] = useState("");
  const [pinUser, setPINUser] = useState<ISWPJobTeamMember | undefined>();
  const { t } = useTranslation('executor');

  if (!job
    || !isSwitchExecutorVisible) {
    return null;
  }

  const currEmail = executor?.email.toLowerCase()
    || email.toLowerCase();

  const onUserClick = (user: ISWPJobTeamMember) => {
    if (user.email.toLowerCase() === email.toLowerCase()) {
      dispatch(setExecutor(null));
    } else {
      if (!user.offlinePIN) {
        dispatch(setExecutor({
          ...user,
        }));
      } else {
        setIsPINEntryVisible(true);
        setPINUser(user);
      }
    }
  }

  const onPINEntryChange = (value: string) => {
    const isOnlyNums = /^[0-9]*$/.test(value);

    if (!isOnlyNums) {
      setPINEntryError(t("PINs can contain only numbers."));
      return;
    }

    setPINEntry(value);

    if (value.length !== 6) {
      setPINEntryError(t("PINs must be exactly 6 numbers long."));
    }

    setPINEntryError("");
  }

  const onSubmitPIN = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!pinUser) {
      onCancelPIN();
      return;
    }

    if (!pinUser.offlinePIN) {
      dispatch(setExecutor({
        ...pinUser,
      }));
      return;
    }

    if (!bcryptjs.compareSync(pinEntry, pinUser?.offlinePIN)) {
      setPINEntryError(t("Invalid PIN."));
      return;
    }

    onCancelPIN();
    dispatch(setExecutor({
      ...pinUser,
    }));
  }

  const onCancelPIN = () => {
    setPINEntry("");
    setIsPINEntryVisible(false);
  }

  return (
    <Modal
      isOpen={true}
      header={t("Switch Executor")}
      controls={(
        <button
          className="secondary-button"
          onClick={() => dispatch(setExecutor(executor))}
        >
          {t('Cancel')}
        </button>
      )}
    >
      <p>
        {t("Click a team member to switch. The current executor is bold. The * denotes the device's currently logged-in user.")}
      </p>
      {job.team.map(user => (
        <div
          className={`user ${currEmail === user.email.toLowerCase() ? "selected" : ""}`}
          key={user.email}
          onClick={() => onUserClick(user)}
        >
          {user.name} ({user.email})
          {email.toLowerCase() === user.email.toLowerCase()
            ? "*"
            : ""
          }
        </div>
      ))}
      {isPINEntryVisible &&
        <PINValidationModal
          pinEntry={pinEntry}
          onPINEntryChange={onPINEntryChange}
          pinEntryError={pinEntryError}
          onCancelPIN={onCancelPIN}
          onSubmitPIN={onSubmitPIN}
        />
      }
    </Modal>
  );
};

export default SwitchExecutorModal;