import { BannerType } from "components/common/Banner";
import { IJobSchedule, JobScheduleType } from "interfaces/jobs/JobInterfaces";
import formatDate from "utilities/formatDate";
import { getSuffixedNumber } from "utilities/numberUtilities";

export const dayNames = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export function getScheduleDayIndexes(schedule: IJobSchedule) {
  return [
    schedule.weeklySun ? 0 : 99,
    schedule.weeklyMon ? 1 : 99,
    schedule.weeklyTue ? 2 : 99,
    schedule.weeklyWed ? 3 : 99,
    schedule.weeklyThu ? 4 : 99,
    schedule.weeklyFri ? 5 : 99,
    schedule.weeklySat ? 6 : 99,
  ].filter(x => x !== 99);
}

export interface IScheduleValidationResults {
  isValid: boolean,
  errorMessage?: string,
  bannerType?: BannerType,
}

export function validateWeeklySchedule(startDate: Date,
  endDate: Date,
  daysOfWeek: number[]): IScheduleValidationResults {
  if (endDate < startDate) {
    return {
      isValid: false,
      errorMessage: "End date must occur on or after start date.",
      bannerType: BannerType.Error,
    };
  }

  if (!daysOfWeek.length) {
    return {
      isValid: false,
      errorMessage: "A weekly schedule must specify the day(s) of the week.",
      bannerType: BannerType.Error,
    };
  }

  let date = new Date(startDate);
  let end = new Date(endDate);
  date.setHours(0, 0, 0, 0);
  end.setHours(0, 0, 0, 0);

  let chosenWeeklyDays = daysOfWeek.slice();
  let numOfChosenDays = chosenWeeklyDays.length;

  let totalDaysChecked = 0;
  while (date <= end) {
    chosenWeeklyDays = chosenWeeklyDays.filter(x => x !== date.getDay());
    totalDaysChecked++;

    if (totalDaysChecked >= 7
      || chosenWeeklyDays.length === 0) {
      break;
    }

    date.setDate(date.getDate() + 1);
  }

  if (chosenWeeklyDays.length === numOfChosenDays) {
    return {
      isValid: false,
      errorMessage: "Specified date range contains none of the chosen days of the week.",
      bannerType: BannerType.Error,
    };
  } else if (chosenWeeklyDays.length > 0) {
    return {
      isValid: false,
      errorMessage: "The chosen date range doesn't contain one or more of the chosen days of the week.",
      bannerType: BannerType.Warn,
    };
  }

  return {
    isValid: true,
  };
}

export function validateMonthlySchedule(startDate: Date,
  endDate: Date,
  dayOfTheMonth?: number) {
  if (endDate < startDate) {
    return {
      isValid: false,
      errorMessage: "End date must occur on or after start date.",
      bannerType: BannerType.Error,
    };
  }

  if (dayOfTheMonth === undefined
    || dayOfTheMonth < 1
    || dayOfTheMonth > 31) {
    return {
      isValid: false,
      errorMessage: "The day of the month must be a valid day.",
      bannerType: BannerType.Error,
    }
  }

  let date = new Date(startDate);
  let end = new Date(endDate);
  date.setHours(0, 0, 0, 0);
  end.setHours(0, 0, 0, 0);

  let totalDaysChecked = 0;
  let dateFound = false;

  // If the day of the month doesn't occur within the date range, error.
  while (date <= end) {
    if (date.getDate() === dayOfTheMonth) {
      dateFound = true;
      break;
    }

    totalDaysChecked++;

    if (totalDaysChecked >= 31) {
      break;
    }

    date.setDate(date.getDate() + 1);
  }

  if (!dateFound) {
    return {
      isValid: false,
      errorMessage: "The chosen date range doesn't contain the selected day of the month.",
      bannerType: BannerType.Error,
    }
  }

  return {
    isValid: true,
  };
}

export function scheduleToString(schedule: IJobSchedule): string {
  const rangeStr = (schedule.endDate
    ? "from "
    : "starting on ")
    + formatDate(schedule.startDate, false, true)
    + (schedule.endDate
      ? ` through ${formatDate(schedule.endDate, false, true)}.`
      : ` for ${schedule.totalOccurrences} occurrences.`
    );

  let scheduleStr = "";

  if (schedule.type === JobScheduleType.Daily) {
    scheduleStr = "Every day";
  } else if (schedule.type === JobScheduleType.Weekly) {
    const selectedDayNames = getScheduleDayIndexes(schedule)
      .map(dayIx => dayNames[dayIx]);

    scheduleStr = "Every "
      + (schedule.everyX
        && schedule.everyX > 1
        ? `${schedule.everyX} weeks`
        : "week")
      + ` on ${selectedDayNames.join(", ")}`;
  } else if (schedule.type === JobScheduleType.Monthly) {
    scheduleStr = "Every "
      + (schedule.everyX
        && schedule.everyX > 1
        ? `${schedule.everyX} months`
        : "month")
      + ` on the ${getSuffixedNumber(schedule.dayOfMonth || 0)}`;
  }

  return `${scheduleStr} ${rangeStr}`;
}