import React from "react";
import useSelector from "store/useSelector";
import JobHistoryLogItem from "./JobHistoryLogItem";

const JobHistoryList: React.FC = () => {
  const log = useSelector(store => store.execution.log);

  return (
    <>
      {log.map((l, ix) =>
        <JobHistoryLogItem
          {...l}
          key={ix}
        />
      )}
    </>
  );
}

export default JobHistoryList;