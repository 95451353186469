import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

  i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      load: 'languageOnly',
      debug: false,
      defaultNS: 'translation',
      fallbackLng: 'en',
      nonExplicitSupportedLngs: true,
      interpolation: {
        escapeValue: false,
      }, 
      supportedLngs: [
        'en',
        'es',
      ],
      ns: [
        'mainMenu',
        'myJobs',
        'userProfile',
        'createJob',
        'userSearch',
        'jobHeader',
        'jobFilters',
        'jobStatus',
        'pickerModal',
        'execution',
        'executionSW',
        'step',
        'components',
        'tccModal',
        'executor',
        'printView',
        'comments',
        'reopenJob',
        'retireTemplate',
        'uncacheJob',
        'cacheTemplate',
        'dataReport',
        'sagas',
        'executionSaga',
        'offlineSaga',
        'swUserFeedback',
      ]
    });

export default i18n;