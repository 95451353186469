import config from "config";
import { authPostFormData } from "utilities/msalFetches";

class SweetImporterApi {
  public async postFile(file: File): Promise<boolean> {
    let formData = new FormData();
    formData.append("File", file);

    let response = await authPostFormData(config.endpoints.sweetImporter, formData);

    return response.status === 204;
  }
}

export default new SweetImporterApi();