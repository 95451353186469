import React from "react";
import useSelector from "store/useSelector";
import "./ExecutionSWList.scoped.scss";
import ExecutionSW from "./ExecutionSW";
import { useTranslation } from "react-i18next";
import { SWTypes } from "interfaces/sw/SWInterfaces";

const ExecutionSWList: React.FC = () => {
  const { job } = useSelector(store => store.execution);
  const { t } = useTranslation('executionSW');

  if (!job) {
    return <span>{t('No job is loaded.')}</span>;
  }

  var eclAddedToJob = job.sws.filter(r => r.type === SWTypes.ECL).length > 0;
  var showAllECLs = (job.showAllECLs === undefined || job.showAllECLs === null || job.showAllECLs === true);
  return (
    <>
      {
        eclAddedToJob &&
        <div className="showAllECLsDiv">
          {
            showAllECLs
              ? <label>{t('Showing All ECLs')}</label>
              : undefined
          }
        </div>
      }
      <div
        className="step-list"
      >
        {job.sws.map(jobSW => (jobSW.type !== SWTypes.ECL || showAllECLs)
          && <div
            className="sw"
            key={jobSW.jobSWId}
          >
            <ExecutionSW
              jobSWId={jobSW.jobSWId} />
          </div>
        )}

      </div>
    </>);
}

export default ExecutionSWList;