import { IAzureBlobRef } from "interfaces/azureBlobs/azureBlobsInterfaces";
import { ISWUserFeedback } from "interfaces/jobs/JobInterfaces";

export interface IStepResponse {
  stepId: string,
  jobSWId: number,
  isComplete: boolean,
  isNA: boolean,
  questionResponse?: QuestionResponse,
  componentResponses?: IComponentResponse[],
  conditionalResponse?: boolean,
  isDirty: boolean,
  timestamp: Date,
  userEmail: string,
  isDeviation: boolean,
}

export interface IDuplicateJobSW {
  JobSWId: number,
  OldJobSWId: number,
  JobId: number,
  SWId: string,
  Version: string,
  SortOrder: number,
  Deleted: boolean,
}

export interface IDuplicateSWRequest {
  JobSWId: number,
  JobId: number,
  SWId: string,
  Version: string,
  SortOrder: number,
}

export type OfflineStepResponse = {
  jobId: number,
  isOnServer: boolean,
} & IStepResponse;

export type DuplicateFetchedData = {
  offlineResponses: OfflineStepResponse[],
  paperExecutions: IIdbPaperExecution[],
  comments: IStepComment[],
  resetedJobSWs: number[] | undefined,
}

export enum QuestionResponse {
  Pass = "pass",
  Fail = "fail",
  Exceeds = "exceeds",
  Outstanding = "outstanding"
}

export interface IComponentResponse {
  id: string,
  type: ComponentResponseType,
  values: string[],
  isDeviation: boolean,
}

export enum ComponentResponseType {
  Text = "text",
  Image = "image",
  Number = "number",
  Select = "select",
  Signature = "signature",
  Date = "date",
  DateTime = "datetime",
  YesNo = "yesno",
  MultiSelect = "multiselect",
  PassFail = "passfail",
}

export interface IUserImageData {
  filename: string,
  jobId: number,
  isOnServer: boolean,
  data: string,
  expirationDate: Date,
}

export interface IGetJobResponsesResult {
  stepResponses: IStepResponse[],
  images: IResponseImageRef[],
}

export interface IResponseImageRef {
  absoluteUri: string,
  filename: string,
}

export interface ISWImageData {
  swId: string,
  version: string,
  filename: string,
  data: string,
  expirationDate: Date,
}

export interface ISWRefDocData {
  swId: string,
  version: string,
  filename: string,
  data: string,
  expirationDate: Date,
}

export interface IJobDocData {
  filename: string,
  jobId: number,
  data: string,
  expirationDate: Date,
}

export enum SWStatus {
  Incomplete = "Incomplete",
  Completed = "Completed",
  NA = "N/A",
}

export interface IJobCompletion {
  jobId: number,
  deviationMessage: string,
  timestamp: Date,
  isOnServer: boolean,
  userEmail: string,
}

export interface IJobCancellation {
  jobId: number,
  timestamp: Date,
  isOnServer: boolean,
  userEmail: string,
}

export interface IStepComment {
  isDirty: boolean,
  isOnServer: boolean,
  guid: string,
  jobSWId: number,
  stepId: string,
  comment: string,
  userEmail: string,
  timestamp: Date,
  attachments: IStepCommentAttachment[],
  pendingAction: StepCommentActions,
}

export enum StepCommentActions {
  Add = "Add",
  Delete = "Delete",
  NoAction = "NoAction",
}

export interface IStepCommentAttachment {
  filename: string,
  pendingAction: StepCommentActions,
}

export interface IIdbStepComments {
  jobId: number,
  comments: IStepComment[],
}

export interface IIdbStepCommentAttachment {
  filename: string,
  data: string,
  expirationDate: Date,
}

export interface IStepCommentResponse {
  comments: IStepComment[],
  azureAttachments: IAzureBlobRef[],
}

export enum Connectivity {
  Online = "Online",
  Offline = "Offline",
}

export interface IRealtimeStepResponseInfo {
  jobId: number,
  jobSWId: number,
  stepId: string,
  timestamp: Date,
  userEmail: string,
}

export interface IRealtimeStepCommentInfo {
  jobId: number,
  commentGuids: string[],
}

export interface IGetPaperExecutionResponse {
  userEmail: string,
  jobIdOrJobSWId: number,
  timestamp: Date,
  proofFilename: string,
  absoluteUri: string,
  isJobPaperExecution: boolean,
}

export interface IJobPaperExecution {
  userEmail: string,
  jobId: number,
  timestamp: Date,
  imageFilename: string,
  isOnServer: boolean,
  isDirty: boolean,
}

export interface IPaperExecution {
  userEmail: string,
  jobSWId: number,
  timestamp: Date,
  imageFilename: string,
  isOnServer: boolean,
  isDirty: boolean,
}

export interface IIdbJobPaperExecution {
  jobId: number,
  userEmail: string,
  timestamp: Date,
  imageFilename: string,
  isOnServer: boolean,
}

export interface IIdbPaperExecution {
  jobId: number,
  userEmail: string,
  jobSWId: number,
  timestamp: Date,
  imageFilename: string,
  isOnServer: boolean,
}

export interface IRealtimePaperExecutionInfo {
  userEmail: string,
  jobIdORJobSWId: number,
  timestamp: Date,
  proofFilename: string,
  absoluteUri: string,
  isJobPaperExecution: boolean,
}

export interface IOfflineSWUserFeedback {
  jobId: number,
  feedbacks: ISWUserFeedback[],
  isOnServer: boolean,
}

export interface IIdbResetSW {
  jobId: number,
  jobSWIds: number[],
}