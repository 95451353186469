import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setConnectivity } from "store/offline/offlineActions";
import { Detector } from "react-detect-offline";
import { Connectivity } from "interfaces/execution/executionInterfaces";
import config from "config";

const ConnectivityDetector: React.FC = () =>
  <Detector
    polling={{
      url: config.endpoints.getConnectivityStatus,
    }}
    render={(props: any) => (
      <StatusUpdater
        isOnline={props.online}
      />
    )}
  />;

export default ConnectivityDetector;

interface IStatusUpdaterProps {
  isOnline: boolean,
}

const StatusUpdater: React.FC<IStatusUpdaterProps> = ({ isOnline }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setConnectivity(isOnline
      ? Connectivity.Online
      : Connectivity.Offline));
  }, [dispatch, isOnline]);

  return null;
};