import React from "react";
import {
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import NotFound from "components/NotFound";
import MyJobs from "components/jobs/mine/MyJobs";
import ManageJob from "components/jobs/management/ManageJob";
import FileUpload from "../sweetimporter/SweetImporter";
import UserProfile from "components/user/UserProfile";
import ExecutionLoader from "components/execution/ExecutionLoader";
import PrintView from "components/execution/printView/PrintView";
import Execution from "components/execution/Execution";
import AuthRoute from "./AuthRoute";
import UserManagement from "components/user/management/UserManagement";
import { AuthFilter } from "auth/AuthFilter";
import { Roles } from "interfaces/user/UserInterfaces";
import EditUser from "components/user/management/EditUser";

export enum Routes {
  ExecuteJob = "/jobs/execute/:id",
  ExecuteOfflineJob = "/jobs/execute/:id/offline",
  PrintViewJob = "/jobs/execute/:id/print",
  PrintViewOfflineJob = "/jobs/execute/:id/offline/print",
  MyJobs = "/jobs/mine",
  CreateJob = "/jobs/create",
  EditJob = "/jobs/edit/:id",
  Search = "/search",
  MyProfile = "/user/profile",
  MySettings = "/user/settings",
  FileUpload = "/fileupload",
  UserManagement = "/users/manage",
  EditUser = "/users/manage/:email",
  Root = "/"
}

const Routing: React.FC = () =>
  <Switch>
    <Route path={Routes.MyJobs} component={MyJobs} />
    <Route exact path={Routes.Root}>
      <Redirect to={Routes.MyJobs} />
    </Route>
    <Route exact path={Routes.EditJob} component={ManageJob} />
    <Route exact path={Routes.CreateJob} component={ManageJob} />
    <Route exact path={Routes.FileUpload} component={FileUpload} />
    <Route
      path={Routes.ExecuteJob}
      render={(props) =>
        <ExecutionLoader {...props}>
          <Switch>
            <Route exact path={Routes.PrintViewJob} component={PrintView} />
            <Route exact path={Routes.PrintViewOfflineJob} component={PrintView} />
            <Route component={Execution} />
          </Switch>
        </ExecutionLoader>
      }
    />
    <AuthRoute
      path={Routes.UserManagement}
      component={UserManagement}
      exact
      authFilter={AuthFilter.isLoggedIn()
        .hasAnyRoleIn([Roles.ExecutionAdmin, Roles.SysAdmin])
      }
    />
    <Route path={Routes.EditUser} component={EditUser} exact />
    <Route path={Routes.MyProfile} component={UserProfile} />
    <Route component={NotFound} />
  </Switch>;

export default Routing;