import { createAction } from "@reduxjs/toolkit";
import { Connectivity } from "interfaces/execution/executionInterfaces";
import { ISWPJobTeamMember } from "interfaces/jobs/JobInterfaces";
import { IMasterDataItem } from "interfaces/masterData/masterDataInterfaces";
import { IOperation } from "interfaces/operations/OperationInterfaces";

export const cacheJobOffline = createAction<{ jobId: number, isRefreshJob?: boolean }>("offline/cacheJobOffline");
export const cacheTemplateOffline = createAction<{
  jobId: number,
  team: ISWPJobTeamMember[],
  country: IMasterDataItem | undefined,
  geoUnit: IMasterDataItem | undefined,
  subBusinessLine: IMasterDataItem | undefined,
}>("offline/cacheTemplateOffline");
export const setCacheTemplateOperation = createAction<IOperation | undefined>("offline/setCacheTemplateOperation");
export const setCacheOperation = createAction<IOperation | undefined>("offline/setCacheOperation");
export const setCachedJobIds = createAction<{ jobIds: number[] }>("offline/setCachedJobIds");
export const addCachedJobIds = createAction<{ jobIds: number[] }>("offline/addCachedJobIds");

export const removeCachedJobIds = createAction<{ jobIds: number[] }>("offline/removeCachedJobIds");
export const deleteCachedJobs = createAction<{ jobIds: number[], isRefreshJob?: boolean }>("offline/deleteCachedJobs");
export const setDeleteOperation = createAction<IOperation | undefined>("offline/setDeleteOperation");
export const clearDeleteCacheOperation = createAction("offline/clearDeleteCacheOperation");

export const refreshAwaitingSync = createAction("offline/refreshAwaitingSync");
export const setIsAnythingAwaitingSync = createAction<boolean>("offline/setIsAnythingAwaitingSync");
export const setJobsAwaitingCompletions = createAction<{ jobIds: number[] }>("offline/setJobsAwaitingCompletions");
export const setJobsAwaitingCancellations = createAction<{ jobIds: number[] }>("offline/setJobsAwaitingCancellations");

export const uploadCachedData = createAction("offline/uploadCachedData");
export const setUploadCacheOperation = createAction<IOperation | undefined>("offline/setUploadCacheOperation");
export const setUpdatedServiceWorker = createAction<ServiceWorkerRegistration>("offline/setUpdatedServiceWorker");

export const setConnectivity = createAction<Connectivity>("offline/setConnectivity");
export const saveJobOffline = createAction<{ jobId: number }>("offline/saveJobOffline");