import { IStepComment } from "interfaces/execution/executionInterfaces";
import React, { useEffect, useRef, useState } from "react";
import { formatNumericalDate } from "utilities/formatDate";
import AttachmentThumbnail from "./AttachmentThumbnail";
import "./StepComment.scoped.scss";
import attachmentIcon from "media/icons/dls/attachment.svg";
import AddAttachmentModal from "./AddAttachmentModal";
import { IFileData } from "interfaces/files/fileInterfaces";
import i18n from 'i18n';

const t = i18n.getFixedT(null, 'comments');

interface IStepCommentProps {
  onNewCommentChanged?(comment: string): void,
  newComment?: string,
  onAttachmentAdded?(fileData: IFileData): void,
  newAttachmentFilenames?: string[],
  stepComment: IStepComment | undefined,
  editorType: StepCommentEditorType,
  onAttachmentClick(filename: string): void,
}

export enum StepCommentEditorType {
  New,
  Edit,
  View,
}

const StepComment: React.FC<IStepCommentProps> = ({
  stepComment,
  editorType,
  onNewCommentChanged,
  newComment,
  onAttachmentAdded,
  newAttachmentFilenames,
  onAttachmentClick,
}) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [isAddVisible, setIsAddVisible] = useState(false);

  useEffect(() => {
    textAreaRef.current?.focus();
  }, []);

  const onAddAttachmentClick = () => {
    setIsAddVisible(true);
  }

  return (
    <div
      className="step-comment"
    >
      {stepComment
        && editorType !== StepCommentEditorType.New &&
        <>
          {getHeader(stepComment)}
          <div
            className="comment"
          >
            {stepComment
              .comment
              .split('\n')
              .map((line, ix) => (
                <div key={ix}>
                  {line}
                </div>
              ))
            }
          </div>
        </>
      }
      {editorType === StepCommentEditorType.New
        && onNewCommentChanged &&
        <textarea
          className="comment"
          placeholder={t("Add a comment.")}
          value={newComment}
          onChange={(e) => onNewCommentChanged(e.target.value)}
          ref={textAreaRef}
        />
      }
      {getAttBar(stepComment,
        newAttachmentFilenames,
        editorType,
        onAddAttachmentClick,
        onAttachmentClick)
      }
      {isAddVisible &&
        <AddAttachmentModal
          onClose={() => setIsAddVisible(false)}
          onAttachmentChosen={(fileData: IFileData) => {
            if (onAttachmentAdded) {
              onAttachmentAdded(fileData);
              setIsAddVisible(false);
            }
          }}
        />
      }
    </div>
  );
}

function getHeader(stepComment: IStepComment) {
  return (
    <span
      className="header"
    >
      <span
        className="name"
      >
        {stepComment.userEmail}
      </span>
      {t('commented on {date}', {date: formatNumericalDate(stepComment.timestamp, true)})}
    </span>
  );
}

function getAttBar(stepComment: IStepComment | undefined,
  newAttachmentFilenames: string[] | undefined,
  editorType: StepCommentEditorType,
  onAddClick: () => void,
  onAttachmentClick: (filename: string) => void
): JSX.Element | undefined {
  const isReadonly = editorType === StepCommentEditorType.View;

  if (editorType === StepCommentEditorType.View
    && !stepComment?.attachments.length) {
    return;
  }

  const numImages = (newAttachmentFilenames?.length || 0)
    + (stepComment?.attachments.length || 0);

  return (
    <div
      className="att-bar"
    >
      {stepComment?.attachments.map(x =>
        <AttachmentThumbnail
          filename={x.filename}
          onClick={() => onAttachmentClick(x.filename)}
          key={x.filename}
        />
      )}

      {newAttachmentFilenames?.map(x =>
        <AttachmentThumbnail
          filename={x}
          onClick={() => onAttachmentClick(x)}
          key={x}
        />
      )}

      {!isReadonly &&
        <>
          {numImages < 5 &&
            <button
              className="add-att-button"
              title={t("Attach image")}
              onClick={onAddClick}
              style={{
                backgroundImage: `url(${attachmentIcon})`,
              }}
            >
              +
            </button>
          }
        </>
      }
    </div>
  );
}

export default StepComment;