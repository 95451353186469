import { ISWPJobTeamMember, SWPJobStatus, ISWPJobLogItem, ISWUserFeedback } from "interfaces/jobs/JobInterfaces";
import { ISW } from "interfaces/sw/SWInterfaces";
import { IJobPaperExecution, IPaperExecution, IStepComment, IStepResponse } from "interfaces/execution/executionInterfaces";

export interface IExecutionState {
  loadOperation: ILoadOperation | null,
  job: IExecutionJob | null,
  executor: ISWPJobTeamMember | null,
  isSwitchExecutorVisible: boolean,
  mode: ExecutionMode | null,
  currentLocation: IExecutionLocation | null,
  stepResponses: IStepResponse[],
  log: ISWPJobLogItem[],
  isShowingRecap: boolean,
  isUploadingResponses: boolean,
  isSavingShowAllECLs: boolean,
  uploadResponsesError?: string,
  isJobCompleting: boolean,
  isCompleteJobModalVisible: boolean,
  isJobCompletedModalVisible: boolean,
  completeJobError?: string,
  isDeviationReasonRequired: boolean,
  hasCantCompleteWithDirtyResponsesError: boolean,
  stepCommentModalData: IStepCommentModalData,
  stepComments: IStepComment[],
  realtimeStepsLoading: IStepIdentifier[],
  isRealtimeJobCompletionModalVisible: boolean,
  paperExecuteModalData: IPaperExecuteModalData,
  jobPaperExecuteModalData: IJobPaperExecuteModalData,
  paperExecutions: IPaperExecution[],
  jobPaperExecution: IJobPaperExecution,
  realtimeSWPaperExecsLoading: number[],
  swUserFeedback: ISWUserFeedback[],
  resetJobSWIds: number[],
  jobExecutor: string | null,
}

export interface ILoadOperation {
  isLoading: boolean,
  loadErrors: string[],
  jobId: number,
  mode: ExecutionMode,
}

export enum ExecutionMode {
  Online = "Online",
  Offline = "Offline",
}

export interface ILoadErrors {
  messages: string[],
  jobId: number,
}

export interface IExecutionJob {
  id: number,
  title: string,
  jobNumber: string,
  isDemo: boolean,
  showAllECLs: boolean | undefined,
  status: SWPJobStatus,
  sws: IExecutionJobSW[],
  team: ISWPJobTeamMember[],
  swUserFeedback: ISWUserFeedback[],
  startTime: Date | undefined,
  endTime: Date | undefined,
  jobDocs: IJobDocs[],
}


export interface IJobDocs {
  fileName: string,
  fileContent: string,
  fileSize: number,
}

export type IExecutionJobSW = ISW & {
  jobSWId: number,
  sortOrder: number,
  showCriticalSteps: boolean,
  isDirty?: boolean,
};

export interface IExecutionLocation {
  jobSWId: number,
  stepId: string,
}

// Action Payloads

export interface ILogJobActionPayload {
  action: string,
  timestamp: Date,
  userName: string,
  userEmail: string,
  isDirty?: boolean,
}

export interface ILoadExecutionPayload {
  jobId: number,
  executionMode: ExecutionMode
}

export interface ICompleteJobPayload {
  jobId: number,
  deviationMessage: string,
  timestamp: Date,
  userEmail: string,
}

export interface IFinishLoadingPayload {
  job: IExecutionJob,
  mode: ExecutionMode,
  stepResponses: IStepResponse[],
  log: ISWPJobLogItem[],
  comments: IStepComment[],
  paperExecutions: IPaperExecution[],
  jobPaperExecution: IJobPaperExecution,
}

export interface IStepIdentifier {
  stepId: string,
  jobSWId: number,
}

export interface IStepCommentModalData {
  isVisible: boolean,
  stepIdentifier: IStepIdentifier,
}

export interface IPaperExecuteModalData {
  isVisible: boolean,
  jobSWId: number,
}

export interface IJobPaperExecuteModalData {
  isVisible: boolean,
  jobId: number,
}
