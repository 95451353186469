import Modal from "components/common/Modal";
import { TCCComplianceStatuses } from "interfaces/user/UserInterfaces";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { confirmTCC } from "store/auth/authActions";
import useSelector from "store/useSelector";
import ModalSpinner from "./ModalSpinner";
import "./TCCModal.scoped.scss";

const TCCModal: React.FC = () => {
  const [isRejectModalVisible, setIsRejectModalVisible] = useState(false);
  const tccInfo = useSelector(store => store.auth.currentUser.tccInfo);
  const dispatch = useDispatch();
  const { t } = useTranslation('tccModal');

  if (!tccInfo
    || tccInfo.status === TCCComplianceStatuses.Compliant) {
    return null;
  }

  const {
    tccId,
    countries,
    loadOperation,
  } = tccInfo;

  const certifyAnswer = (status: TCCComplianceStatuses) => {
    dispatch(
      confirmTCC({
        tccId,
        status,
      }));
  }

  return (
    <Modal
      header="Trade &amp; Customs Compliance"
      isOpen={true}
      controls={
        <>
          <button
            className="secondary-button"
            onClick={() => {
              certifyAnswer(TCCComplianceStatuses.NotCompliant);
              setIsRejectModalVisible(true);
            }}
          >
            {t('I do not Certify')}
          </button>
          <button
            className="primary-button"
            onClick={() => {
              certifyAnswer(TCCComplianceStatuses.Compliant);
            }}
          >
            {t('I Certify')}
          </button>
        </>
      }
    >
      <div className="content">
        <h3>
          {t('USER AGREEMENT - CERTIFICATION')}
        </h3>
        <div>
          {t('PLEASE CERTIFY THAT YOU WILL NOT BE ACCESSING ANY')}
          {t('INFORMATION OR DOWNLOADING ANY MATERIAL FROM WORKRIGHT ')}
          {t('FOR SUPPORT OF OPERATIONS IN')}
        </div>
        <ul>
          {countries.map((country, ix) =>
            <li
              key={ix}
            >
              {country.name}
              {country.desc ? ' - ' : ''}
              {country.desc}
            </li>)
          }
        </ul>
        {isRejectModalVisible &&
          <Modal
            header={t("Not Authorized")}
            isOpen={true}
            controls={
              <button
                className="primary-button"
                onClick={() => {
                  setIsRejectModalVisible(false);
                }}
              >
                {t('Recertify My TCC Status')}
              </button>
            }
          >
            {t('You are not authorized to use WorkRight.')}
          </Modal>
        }
      </div>
      {loadOperation?.isWorking &&
        <ModalSpinner />
      }
    </Modal>
  );
};

export default TCCModal;