import React, { useEffect, useState } from "react";
import IdbApi from "apis/idb/IdbApi";
import { IStepComponent, ITimeImageComponentData } from "interfaces/sw/SWInterfaces";
import "./TIMEImage.scoped.scss";

interface ITimeImageProps {
  swId: string,
  swVersion: string,
  image: IStepComponent,
  style?: React.CSSProperties,
}

const TIMEImage: React.FC<ITimeImageProps> = ({ swId, swVersion, image, style }) => {
  const [imageData, setImageData] = useState<string | undefined>();
  const [errMsg, setErrMsg] = useState<string | undefined>();

  const {
    data,
  } = image;

  let filename = "";

  if (isImageData(data)) {
    filename = data.filename;
  }

  useEffect(() => {
    let aborted = false;

    const fetchImage = async () => {
      try {
        const img = await IdbApi.getSWImageData(swId, swVersion, filename);
        if (!aborted) {
          setImageData(img?.data);
        }
      } catch (err: any) {
        if (!aborted) {
          setErrMsg(err?.message || err.toString())
        }
      }
    }

    fetchImage();

    return () => {
      aborted = true;
    };
  }, [swId, swVersion, filename, setImageData, setErrMsg]);

  if (!isImageData(image.data)) {
    return null;
  }

  if (imageData) {
    return (
      <div
        className="image-holder"
      >
        <img
          src={imageData}
          alt=""
          style={style}
        />
      </div>
    );
  } else if (errMsg) {
    return (
      <div
        className="image-holder"
      >
        <span>
          {errMsg}
        </span>
      </div>
    );
  } else {
    return null;
  }
}

export default TIMEImage;

function isImageData(data: any): data is ITimeImageComponentData {
  return data !== null
    && data !== undefined
    && 'filename' in data;
}