import React from "react";
import Modal from "components/common/Modal";
import { useDispatch } from "react-redux";
import { resetExecution } from "store/execution/executionActions";
import { Link } from "react-router-dom";
import { Routes } from "components/routing/Routing";
import useSelector from "store/useSelector";
import { useTranslation } from "react-i18next";

interface IRealtimeCompletionModalProps {
  jobExecutor: string | null,
}

const RealtimeCompletionModal: React.FC<IRealtimeCompletionModalProps> = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('execution');

  const job = useSelector(store => store.execution.job);

  return (
    <Modal
      isOpen={true}
      header={t("Job Completed")}
      controls={(
        <Link
          to={Routes.PrintViewJob.replace(":id", job?.id?.toString() || "0")}
        >
          <button
            className="primary-button"
            onClick={() => dispatch(resetExecution())}
          >
            {t('Review Job')}
          </button>
        </Link>
      )}
    >
      <p>{`${t('The job has been completed by')} ${props.jobExecutor}.`}</p>
    </Modal>
  );
}

export default RealtimeCompletionModal;