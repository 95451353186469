import { IOperation } from "interfaces/operations/OperationInterfaces";
import { IMasterDataItem } from "interfaces/masterData/masterDataInterfaces";

export enum Roles {
  ExecutionAdmin = "ExecutionAdmin",
  SysAdmin = "SysAdmin",
}

export interface IUserSearchResult {
  users: IAADUser[],
}

export interface IManageUserUser {
  name: string,
  email: string,
  roles: Roles[],
  tccInfo?: ITCCInfo,
  hasOfflinePIN?: boolean,
  myOrganization: IMasterDataItem | undefined,
  myCountry: IMasterDataItem | undefined,
  myGeoUnit: IMasterDataItem | undefined,
  myCustomer: IMasterDataItem | undefined,
  mySubBusinessLine: IMasterDataItem | undefined,
}

export interface IAADUser {
  name: string,
  email: string,
}

export interface ITCCInfo {
  status: TCCComplianceStatuses,
  tccId: number,
  countries: ITCCCountry[],
  loadOperation?: IOperation,
}

export interface ITCCCountry {
  name: string,
  desc: string,
}

export interface IUserJobCreationPreference {
  organization: IMasterDataItem | undefined,
  country: IMasterDataItem | undefined,
  geoUnit: IMasterDataItem | undefined,
  customer: IMasterDataItem | undefined,
  subBusinessLine: IMasterDataItem | undefined,
}

export enum TCCComplianceStatuses {
  NotCompliant = "NotCompliant",
  Compliant = "Compliant",
}

export enum UserJobsTabs {
  Active = "Active",
  Completed = "Completed",
}

export interface IEmailPINPair {
  email: string,
  pin: string,
}