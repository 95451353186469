import { ISWPJobHeader } from "interfaces/jobs/JobInterfaces";
import { IAADUser, IManageUserUser, ITCCCountry, ITCCInfo, Roles, TCCComplianceStatuses, IUserJobCreationPreference, IEmailPINPair } from "interfaces/user/UserInterfaces";

export function formatGetManageUsersList(response: any): IManageUserUser[] {
  let users = response?.Users;

  if (!Array.isArray(users)) {
    return [];
  }

  return users.map((u: any): IManageUserUser => ({
    name: u.Name,
    email: u.Email,
    roles: u.Roles.map((r: any): Roles => r as Roles),
    tccInfo: undefined,
    myCountry: undefined,
    myOrganization: undefined,
    myGeoUnit: undefined,
    myCustomer: undefined,
    mySubBusinessLine: undefined,
  }));
}

export function formatUsersJobsResponse(response: any): ISWPJobHeader[] {
  if (response.JobHeaders && response.JobHeaders.length > 0) {
    return response.JobHeaders.map((job: any): ISWPJobHeader => ({
      id: job.Id,
      jobNumber: job.JobNumber,
      title: job.Title,
      isDemo: job.IsDemo,
      status: job.Status,
      lastActionDate: new Date(job.LastActionDate),
      completedBy: job.CompletedBy,
      isOnTeam: job.isOnTeam,
      deviationCount: job.DeviationCount,
      totalSteps: job.TotalSteps,
      totalCompletedSteps: job.TotalCompletedSteps,
      completionPercentage: job.CompletionPercentage,
    }));
  } else {
    return [];
  }
}

export function formatGetUserResponse(response: any): IManageUserUser {
  return {
    name: response.Name,
    email: response.Email,
    roles: response.Roles.map((r: any): Roles => r as Roles),
    tccInfo: formatTCCInfo(response.TCCInfo),
    hasOfflinePIN: response.HasOfflinePIN === true
      ? true
      : (response.HasOfflinePIN === false
        ? false
        : undefined),
    myCountry: undefined,
    myOrganization: undefined,
    myGeoUnit: undefined,
    myCustomer: undefined,
    mySubBusinessLine: undefined,
  };
}

export function formatTCCInfo(tcc: any): ITCCInfo {
  return {
    status: formatTCCComplianceStatus(tcc.Status),
    tccId: parseInt(tcc.TCCId, 10),
    countries: tcc.EmbargoedCountries?.length
      ? tcc.EmbargoedCountries.map((x: any) => formatTCCCountry(x))
      : [],
  }
}

function formatTCCComplianceStatus(status: string): TCCComplianceStatuses {
  if (status === TCCComplianceStatuses.NotCompliant) {
    return TCCComplianceStatuses.NotCompliant;
  } else if (status === TCCComplianceStatuses.Compliant) {
    return TCCComplianceStatuses.Compliant;
  }

  throw new Error("Invalid TCC Compliance Status: " + status);
}

function formatTCCCountry(item: any): ITCCCountry {
  return {
    name: item.Country,
    desc: item.SanctionDesc,
  };
}

export function formatConfirmTCCRequest(tccId: number, status: TCCComplianceStatuses): any {
  return {
    TCCId: tccId,
    Response: status,
  };
}

export function formatUpdateUserRequest(user: IManageUserUser): any {
  return {
    User: {
      Name: user.name,
      Email: user.email,
      Roles: user.roles,
    }
  };
}

export function formatSetUserPINRequest(email: string, pin: string) {
  return {
    Email: email,
    PIN: pin,
  };
}

export function formatCreateUserRequest(user: IAADUser): any {
  return {
    Email: user.email,
    Name: user.name,
  };
}

export function formatUpdateUserJobCreationPreferences(country: string | undefined,
  org: string | undefined,
  geoUnit: string | undefined,
  customer: string | undefined,
  subBusinessLine: string | undefined) {
  return {
    Country: country,
    Organization: org,
    GeoUnit: geoUnit,
    Customer: customer,
    SubBusinessLine: subBusinessLine,
  }
}

export function formatUserJobCreationPreferences(response: any): IUserJobCreationPreference {
  let country = response?.Country;
  let org = response?.Organization;
  let geoUnit = response?.GeoUnit;
  let customer = response?.Customer;
  let subBusinessLine = response?.SubBusinessLine;

  return {
    country: country ? {
      guid: country.Guid,
      code: country.Code,
      value: country.Value,
    } : undefined,
    organization: org ? {
      guid: org.Guid,
      value: org.Value,
      code: org.Code,
    } : undefined,
    geoUnit: geoUnit ? {
      guid: geoUnit.Code,
      code: geoUnit.Code,
      value: geoUnit.Name,
    } : undefined,
    customer: customer ? {
      guid: customer.Id,
      code: customer.Id,
      value: customer.Value,
    } : undefined,
    subBusinessLine: subBusinessLine ? {
      guid: subBusinessLine.Code,
      code: subBusinessLine.Code,
      value: subBusinessLine.Value,
    } : undefined,
  }
}

export function formatSearchAADUsersResponse(response: any): IAADUser[] {
  let users = response?.Users;

  if (!Array.isArray(users)) {
    return [];
  }

  return users.map((u: any): IAADUser => ({
    name: u.Name,
    email: u.Email,
  }));
}

export function formatGetUserPINsResponse(response: any): IEmailPINPair[] {
  const pins = response?.PINs;

  if (!Array.isArray(pins)) {
    return [];
  }

  return pins.map((e: any): IEmailPINPair => ({
    email: e.Email,
    pin: e.EncryptedPIN,
  }));
}