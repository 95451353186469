import React from 'react';
import { Router } from 'react-router-dom';
import Layout from 'components/layout/Layout';
import ToastContainer from 'components/toasts/ToastContainer';
import useSelector from 'store/useSelector';
import ModalSpinner from 'components/common/ModalSpinner';
import TCCModal from 'components/common/TCCModal';
import ConnectivityDetector from 'components/common/ConnectivityDetector';
import { createBrowserHistory } from "history";

export const history = createBrowserHistory();

const App: React.FC = () => {
  const {
    uploadOperation,
  } = useSelector(store => store.offline);

  const {
    isUploadingResponses,
    isSavingShowAllECLs,
  } = useSelector(store => store.execution);

  return (
    <Router history={history}>
      <Layout />
      <TCCModal />
      <ConnectivityDetector />
      <ToastContainer />
      { (uploadOperation?.isWorking
         || isUploadingResponses
         || isSavingShowAllECLs) &&
        <ModalSpinner />
      }
    </Router>
  );
};

export default App;