import {
  ISW,
  IGetSWResponse,
  ISWSearchResult,
  ISWSearchResultItem,
  ISWAttachmentRef,
  ISearchSWsOptions
} from "interfaces/sw/SWInterfaces";
import { formatSWPDocument } from "./SWSWPFormatters";

export function formatSearchResults(swSearchResults: any): ISWSearchResult {
  return {
    items: swSearchResults.SearchResults
      ? swSearchResults.SearchResults.map((sr: any) => formatSearchResultItem(sr))
      : [],
    totalCount: swSearchResults.TotalCount,
  }
}

function formatSearchResultItem(item: any): ISWSearchResultItem {
  return {
    swId: item.SWId,
    title: item.Title,
    description: item.Description,
    version: item.Version,
  }
}

export function formatGetSWResponse(swResponse: any): IGetSWResponse {
  return {
    swId: swResponse.SWId,
    version: swResponse.Version,
    documentAbsoluteUri: swResponse.DocumentAbsoluteUri,
    images: swResponse.Images.length > 0
      ? swResponse.Images.map((att: any) => formatSWImageRef(att))
      : [],
    refDocs: swResponse.RefDocs.length > 0
      ? swResponse.RefDocs.map((att: any) => formatSWImageRef(att))
      : [],
  };
}

export function formatSWImageRef(att: any): ISWAttachmentRef {
  return {
    absoluteUri: att.AbsoluteUri,
    filename: att.Filename,
  };
}

export function formatSW(sw: any): ISW {
  if (sw.DocType === "SWP") {
    const formatedSW: ISW = formatSWPDocument(sw);
    return formatedSW;
  } else {
    throw new Error("Unrecognized standard work type.");
  }
}

export function formatSearchSWRequest(filterFields: ISearchSWsOptions, index: number): any {
  let mtTaskIds = (filterFields.maintenanceTaskIdsFilter
    && filterFields.maintenanceTaskIdsFilter?.trim() !== "") ?
    filterFields.maintenanceTaskIdsFilter?.split(",") : [];
  if (mtTaskIds.length !== 0) {
    mtTaskIds = mtTaskIds.map(r => r.trim());
  }
  return {
    filterOptions: {
      searchTerm: filterFields.searchTerm,
      types: filterFields.typesFilter.map(x => x.guid),
      globalOrLocalTypes: filterFields.globalOrLocalFilter.map(x => x.guid),
      taskAlignments: filterFields.alignmentsFilter.map(x => x.guid),
      languageIds: filterFields.languagesFilter.map(x => x.guid),
      equivalentSWIds: filterFields.equivalentSWsFilter.map(x => x.guid),
      owningOrgIds: filterFields.owningOrgsFilter.map(x => x.guid),
      taskIds: filterFields.tasksFilter.map(x => x.guid),
      activityWorkFlowIds: filterFields.activityWorkflowsFilter.map(x => x.guid),
      performingOrgIds: filterFields.performingOrgsFilter.map(x => x.guid),
      subBusinessLines: filterFields.subBusinessLinesFilter.map(x => x.guid),
      serviceTypeIds: filterFields.serviceTypesFilter.map(x => x.guid),
      operatingEnvironmentIds: filterFields.operatingEnvironmentsFilter.map(x => x.guid),
      equipmentIds: filterFields.equipmentFilter.map(x => x.guid),
      countryIds: filterFields.countriesFilter.map(x => x.guid),
      competencyElementIds: filterFields.competencyElementsFilter.map(x => parseInt(x.guid, 10)),
      safetyCategoryIds: filterFields.safetyCategoriesFilter.map(x => x.guid),
      geoUnitIds: filterFields.geoUnitsFilter.map(x => x.guid),
      businessRoleIds: filterFields.businessRolesFilter.map(x => x.guid),
      productCenterIds: filterFields.productCentersFilter.map(x => x.guid),
      epicEquipmentIds: filterFields.epicEquipmentFilter.map(x => x.guid),
      customers: filterFields.customers.map(x => x.guid),
      maintenanceTaskIds: mtTaskIds,
      owningPlantCodes: filterFields.owningPlants.map(x => x.code),
      performingPlantCodes: filterFields.performingPlants.map(x => x.code),
      workCenterIds: filterFields.workCenters.map(x => x.guid),
      materialIds: filterFields.materials.map(x => x.guid),
    },
    index: index,
  };
}