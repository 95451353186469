import config from "config";
import { ISWPJob, ISWPJobCreationResponse, ISWPJobHeader, ISWPUpdateJobResponse, ISWPJobLogItem, MyJobsTabs, ISWPJobTeamMember, IJobTemplateHeader, SWPJobType } from "interfaces/jobs/JobInterfaces";
import { authPostJson, authGetJson, authPutJson } from "utilities/msalFetches";
import { formatJobForCreation, formatCreateJobResponse, formatMyJobsResponse, formatGetJobResponse, formatGetJobHistoryLogResponse, formatJobForUpdation, formatUpdateJobResponse, formatGetJobTemplatesResponse, formatDuplicateJobRequest } from "./JobFormatters";
import { throwIfResponseError } from "apis/apiUtilities";
import { IInProgressJob } from "store/manageJob/manageJobTypes";
import { OfflineFetchError } from "utilities/errors/OfflineFetchError";
import { IJobFilters } from "store/jobTemplates/jobTemplatesTypes";
import { ICancelJobPayload } from "store/myJobs/myJobsTypes";

class JobsApi {
  public async createJob(job: IInProgressJob, bypassSWVersionValidation?: boolean): Promise<ISWPJobCreationResponse> {
    let response: Response;

    try {
      response = await authPostJson(config.endpoints.createJob,
        JSON.stringify(formatJobForCreation(job, bypassSWVersionValidation === true)));
    } catch (err: any) {
      throw new OfflineFetchError(err.message);
    }

    await throwIfResponseError(response);

    let responseObj = await response.json();
    return formatCreateJobResponse(responseObj);
  }

  public async updateJob(job: IInProgressJob): Promise<ISWPUpdateJobResponse> {
    let response = await authPutJson(config.endpoints.updateJob.replace("{id}", job.id!.toString()),
      JSON.stringify(formatJobForUpdation(job)));

    await throwIfResponseError(response);

    let responseObj = await response.json();
    return formatUpdateJobResponse(responseObj);
  }

  public async getMyJobs(statusFilter: MyJobsTabs,
    filters: IJobFilters,
    showAll: boolean,
    typeFilter?: SWPJobType): Promise<ISWPJobHeader[]> {
    let response: Response;

    try {
      response = await authPostJson(config.endpoints.getMyJobs,
        JSON.stringify({
          SearchTerm: filters.searchTerm,
          OrgGuids: filters.orgs.map(x => x.guid),
          CountryGuids: filters.countries.map(x => x.guid),
          JobStatus: statusFilter,
          ShowAll: showAll,
          JobType: typeFilter,
        }));
    } catch (err: any) {
      throw new OfflineFetchError(err.message);
    }

    await throwIfResponseError(response);

    let responseObj = await response.json();

    return formatMyJobsResponse(responseObj);
  }

  public async getJob(jobId: number): Promise<ISWPJob> {
    let response: Response;

    try {
      response = await authGetJson(config.endpoints.getJob.replace("{id}", jobId.toString()));
    } catch (err: any) {
      throw new OfflineFetchError(err.message);
    }

    await throwIfResponseError(response);

    let responseObj = await response.json();

    return formatGetJobResponse(responseObj);
  }

  public async reopenJob(jobId: number): Promise<boolean> {
    let response: Response;

    try {
      response = await authPostJson(config.endpoints.reopenJob.replace("{jobId}", jobId.toString()), jobId)
    } catch (err: any) {
      throw new OfflineFetchError(err.message);
    }

    await throwIfResponseError(response);

    return true;
  }

  public async getJobLog(jobId: number): Promise<ISWPJobLogItem[]> {
    let response: Response;

    try {
      response = await authGetJson(config.endpoints.jobLog.replace("{jobId}", jobId.toString()));
    } catch (err: any) {
      throw new OfflineFetchError(err.message);
    }

    await throwIfResponseError(response);

    let responseObj = await response.json();

    return formatGetJobHistoryLogResponse(responseObj);
  }

  public async shareCompletedJob(jobId: number, shareWith: ISWPJobTeamMember): Promise<boolean> {
    const response = await authPostJson(config.endpoints.shareCompletedJob,
      JSON.stringify({
        JobId: jobId,
        ShareWith: {
          Name: shareWith.name,
          Email: shareWith.email,
        },
      }));

    await throwIfResponseError(response);

    return true;
  }

  public async printJob(jobId: number): Promise<string> {
    try {
      const response = await authPostJson(config.endpoints.printJob.replace("{jobId}", jobId.toString()), jobId)
      await throwIfResponseError(response);
      return (await response.json()).JobPDFUrl;
    } catch (err: any) {
      throw new OfflineFetchError(err.message);
    }
  }

  public async getJobTemplates(filters: IJobFilters): Promise<IJobTemplateHeader[]> {
    let response: Response;

    try {
      response = await authPostJson(config.endpoints.getJobTemplates,
        JSON.stringify({
          SearchTerm: filters.searchTerm,
          OrgGuids: filters.orgs.map(x => x.guid),
          CountryGuids: filters.countries.map(x => x.guid),
        }));
    } catch (err: any) {
      throw new OfflineFetchError(err.message);
    }

    await throwIfResponseError(response);

    let responseObj = await response.json();

    return formatGetJobTemplatesResponse(responseObj);
  }

  public async cloneJobTemplate(templateId: number,
    countryGuid: string,
    geoUnitCode: string,
    subBusinessLineCode: string): Promise<number> {
    let response: Response;

    try {
      response = await authPostJson(config
        .endpoints
        .cloneJobTemplate
        .replace("{templateId}",
          templateId.toString()),
        JSON.stringify({
          CountryGuid: countryGuid,
          GeoUnitCode: geoUnitCode,
          subBusinessLineCode: subBusinessLineCode,
        })
      );
    } catch (err: any) {
      throw new OfflineFetchError(err.message);
    }

    await throwIfResponseError(response);

    let responseObj = await response.json();

    return responseObj.JobId as number;
  }

  public async cancelJob(request: ICancelJobPayload): Promise<boolean> {
    let response: Response;

    try {
      response = await authPostJson(config
        .endpoints
        .cancelJob
        .replace("{jobId}",
          request.jobId.toString()),
        JSON.stringify({
          UserEmail: request.userEmail,
          Timestamp: request.timestamp,
        })
      );
    } catch (err: any) {
      throw new OfflineFetchError(err.message);
    }

    await throwIfResponseError(response);

    return true;
  }

  public async removeSelfFromJob(jobId: number): Promise<boolean> {
    let response: Response;

    try {
      response = await authPostJson(config
        .endpoints
        .removeSelfFromJob
        .replace("{jobId}",
          jobId.toString()), null);
    } catch (err: any) {
      throw new OfflineFetchError(err.message);
    }

    await throwIfResponseError(response);

    return true;
  }

  public async duplicateJob(job: ISWPJob): Promise<ISWPJobCreationResponse> {
    let response: Response;

    try {
      response = await authPostJson(config.endpoints.createJob,
        JSON.stringify(formatDuplicateJobRequest(job)));
    } catch (err: any) {
      throw new OfflineFetchError(err.message);
    }

    await throwIfResponseError(response);

    let responseObj = await response.json();
    return formatCreateJobResponse(responseObj);
  }
}

export default new JobsApi();