import { IManageUserUser, ITCCInfo, TCCComplianceStatuses } from "interfaces/user/UserInterfaces";
import { createAction } from "@reduxjs/toolkit";
import { IOperation } from "interfaces/operations/OperationInterfaces";
import { IMasterDataItem } from "interfaces/masterData/masterDataInterfaces";

export const setLoadOperation = createAction<IOperation | undefined>("auth/setLoadOperation");
export const loadCurrentUserInfo = createAction<{ email: string }>("auth/loadCurrentUserInfo");
export const setCurrentUser = createAction<IManageUserUser>("auth/setCurrentUser");

export const confirmTCC = createAction<{
  tccId: number,
  status: TCCComplianceStatuses,
}>("auth/confirmTCC");
export const setTCCInfo = createAction<ITCCInfo>("auth/setTCCInfo");
export const setTCCInfoOperation = createAction<IOperation | undefined>("auth/setTCCInfoOperation");

export const setMyPIN = createAction<string>("auth/setMyPIN");
export const setSetMyPINOperation = createAction<IOperation | undefined>("auth/setSetMyPINOperation");

export const setMyCountry = createAction<IMasterDataItem | undefined>("auth/setMyCountry");
export const setMyPreferenceOperation = createAction<IOperation | undefined>("auth/setMyPreferenceOperation");
export const saveMyPreferences = createAction("auth/saveMyPreferences");
export const setMyOrg = createAction<IMasterDataItem | undefined>("auth/setMyOrg");
export const setMyGeoUnit = createAction<IMasterDataItem | undefined>("auth/setMyGeoUnit");
export const setMyCustomer = createAction<IMasterDataItem | undefined>("auth/setMyCustomer");
export const setMySubBusinessLine = createAction<IMasterDataItem | undefined>("auth/setMySubBusinessLine");