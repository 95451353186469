import React, { useRef } from "react";
import SWHeader, { SWHeaderButtonMode } from "./SWHeader";
import "./DragDropSWHeader.scoped.scss";
import { useDrag, useDrop, DropTargetMonitor, XYCoord } from "react-dnd";
import { IManageJobSW } from "store/manageJob/manageJobTypes";

interface IDragDropSWHeaderProps {
  index: number,
  sw: IManageJobSW,
  onMoveSW: (dragIndex: number, hoverIndex: number) => void,
  onRemoveClick: () => void,
}

// interface IDragItem {
//   index: number,
//   id: string,
//   type: string,
// }

const DragDropSWHeader: React.FC<IDragDropSWHeaderProps> = ({ sw, index, onMoveSW, onRemoveClick }) => {
  const ref = useRef<HTMLDivElement>(null);

  const [, drop] = useDrop({
    accept: "SW",
    //hover(item: IDragItem, monitor: DropTargetMonitor) {
    hover(item: any, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine the screen rectangle.
      const hoverBoundingRect = ref.current!.getBoundingClientRect();

      // Get the vertical middle coordinate.
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine the mouse position.
      const clientOffset = monitor.getClientOffset();

      // Get the number of pixels to the top.
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half the item's height.
      // When dragging downward, only move when the cursor is below 50%.
      // When dragging upward, only move when the cursor is above 50%.

      // Dragging down.
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging up.
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Perform the re-order operation.
      onMoveSW(dragIndex, hoverIndex);

      // Update the monitor's index.
      item.index = hoverIndex;
    }
  });

  const [{ isDragging }, drag] = useDrag({
    type: "SW",
    item: { id: sw.swId, index },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const opacity = isDragging ? 0.15 : 1;
  drag(drop(ref));

  return (
    <div
      ref={ref}
      className="draggable-sw no-text-select"
      style={{ opacity }}
    >
      <SWHeader
        {...sw}
        buttonMode={SWHeaderButtonMode.Remove}
        onButtonClick={onRemoveClick}
        showDraggable
      />
    </div>
  );
}

export default DragDropSWHeader;