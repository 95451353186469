import { Action } from '@reduxjs/toolkit';
import UserApi from 'apis/user/UserApi';
import { IManageUserUser, ITCCInfo, IUserJobCreationPreference } from 'interfaces/user/UserInterfaces';
import { takeLatest, all, call, put, select } from 'redux-saga/effects';
import { RootState } from 'store/rootStore';
import { showErrorToast, showSuccessToast } from 'store/toast/toastActions';
import { getResponseErrorMessage } from 'utilities/validationErrorHelpers';
import { confirmTCC, setCurrentUser, setMyPIN, setSetMyPINOperation, setTCCInfo, setTCCInfoOperation, setMyPreferenceOperation, saveMyPreferences } from './authActions';
import i18n from 'i18n';

const t = i18n.getFixedT(null, 'sagas');

export default function* watchAuthSagas() {
  yield all([
    watchConfirmTCC(),
    watchSetMyPIN(),
    watchSaveMyPreference(),
  ]);
}

function* watchConfirmTCC() {
  yield takeLatest(confirmTCC, confirmTCCAsync);
}

function* confirmTCCAsync(action: Action) {
  if (!confirmTCC.match(action)) {
    return;
  }

  yield put(setTCCInfoOperation({
    isWorking: true,
    wasSuccessful: false,
  }));

  try {
    let tccInfo: ITCCInfo = yield call(UserApi.submitTCCResponse, action.payload.tccId, action.payload.status);
    if (tccInfo) {
      yield put(setTCCInfo({
        status: tccInfo.status,
        tccId: tccInfo.tccId,
        countries: tccInfo.countries,
        loadOperation: {
          isWorking: false,
          wasSuccessful: true,
        }
      }));
    }
  } catch (err: any) {
    yield put(setTCCInfoOperation({
      isWorking: false,
      wasSuccessful: false,
      errorMessage: getResponseErrorMessage(err),
    }));
    return;
  }
}

function* watchSetMyPIN() {
  yield takeLatest(setMyPIN, setMyPINAsync);
}

function* watchSaveMyPreference() {
  yield takeLatest(saveMyPreferences, saveMyPreferenceAsync);
}

function* saveMyPreferenceAsync(action: Action) {
  const currUser: IManageUserUser = yield select((store: RootState) => store.auth.currentUser);

  if (currUser
    && (!currUser.myCountry?.guid
      || !currUser.myOrganization?.guid
      || !currUser.myGeoUnit?.guid
      || !currUser.mySubBusinessLine?.guid)) {
    yield put(showErrorToast(t("All preferences are mandatory.")));
    return;
  }

  yield put(setMyPreferenceOperation({
    isWorking: true,
  }));

  try {
    yield call(UserApi.updateUserJobCreationPreference,
      currUser.myCountry?.guid,
      currUser.myOrganization?.guid,
      currUser.myGeoUnit?.guid,
      currUser.myCustomer?.guid,
      currUser.mySubBusinessLine?.guid);

    let userPreferences: IUserJobCreationPreference = {
      organization: currUser.myOrganization,
      geoUnit: currUser.myGeoUnit,
      subBusinessLine: currUser.mySubBusinessLine,
      country: currUser.myCountry,
      customer: currUser.myCustomer,
    }

    localStorage.setItem(currUser.email.toLowerCase() + "_JobCreation_Preferences",
      JSON.stringify(userPreferences));

    yield put(showSuccessToast(t("Preferences Saved.")));
    yield put(setMyPreferenceOperation(undefined));

  } catch (err: any) {
    yield put(showErrorToast(getResponseErrorMessage(err)));
    yield put(setMyPreferenceOperation(undefined));
  }
}
function* setMyPINAsync(action: Action) {
  if (!setMyPIN.match(action)) {
    return;
  }

  yield put(setSetMyPINOperation({
    isWorking: true,
  }));

  const currUser: IManageUserUser = yield select((store: RootState) => store.auth.currentUser);

  try {
    yield call(UserApi.setUserPIN, currUser.email, action.payload);

    yield put(showSuccessToast(t("PIN updated successfully.")));

    yield put(setCurrentUser({
      ...currUser,
      hasOfflinePIN: true,
    }));
    yield put(setSetMyPINOperation(undefined));
  } catch (err: any) {
    yield put(setSetMyPINOperation(undefined));
    yield put(showErrorToast(getResponseErrorMessage(err)))
    return;
  }
}