import { IStepResponse, IUserImageData, IComponentResponse, IGetJobResponsesResult, ComponentResponseType, IResponseImageRef, IStepComment, StepCommentActions, IStepCommentResponse, IStepCommentAttachment, IPaperExecution, IGetPaperExecutionResponse, IJobPaperExecution, IDuplicateSWRequest } from "interfaces/execution/executionInterfaces";
import { ISWUserFeedback } from "interfaces/jobs/JobInterfaces";
import { getStepResponse } from "utilities/swUtilities";

export function formatUploadResponsesRequest(jobId: number,
  stepResponses: IStepResponse[],
  imgData: IUserImageData[]): any {
  let compResponses: any[] = [];

  stepResponses.forEach(st => {
    if (st.componentResponses?.length) {
      formatUploadComponentResponses(st)
        .forEach(sa => {
          compResponses.push(sa);
        });
    }
  });

  return {
    StepResponses: stepResponses.map(r => formatUploadStepResponses(jobId, r)),
    ComponentResponses: compResponses,
    ImageData: imgData.length
      ? imgData.map(i => formatUploadImageData(jobId, i))
      : [],
  };
}

export function formatUploadStepResponses(jobId: number, resp: IStepResponse): any {
  return {
    JobId: jobId,
    StepId: resp.stepId,
    JobSWId: resp.jobSWId,
    IsComplete: resp.isComplete,
    IsNotApplicable: resp.isNA,
    QuestionResponse: resp.questionResponse,
    ConditionalResponse: resp.conditionalResponse,
    Timestamp: resp.timestamp.toJSON(),
    UserEmail: resp.userEmail,
    IsDeviation: resp.isDeviation,
  }
}

function formatUploadComponentResponses(stepResponse: IStepResponse): any[] {
  let formattedCompResponses: any[] = [];

  if (!stepResponse.componentResponses?.length) {
    return formattedCompResponses;
  }

  for (let i = 0; i < stepResponse.componentResponses.length; i++) {
    let compResponse = stepResponse.componentResponses[i];

    compResponse
      .values
      .map(v => formatUploadComponentResponse(stepResponse, compResponse, v))
      .forEach(f => formattedCompResponses.push(f));
  }

  return formattedCompResponses;
}

export function formatUploadComponentResponse(stepResponse: IStepResponse,
  compResponse: IComponentResponse,
  value: string): any {
  return {
    JobSWId: stepResponse.jobSWId,
    ComponentId: compResponse.id,
    StepId: stepResponse.stepId,
    Type: compResponse.type,
    Value: value,
  }
}

export function formatDuplicateSWs(job: IDuplicateSWRequest,
  jobid: number): any {
  return {
    OldJobSWId: job.JobSWId,
    JobId: jobid,
    SWId: job.SWId,
    Version: job.Version,
    SortOrder: job.SortOrder,
  }
}

export function formatUploadImageData(jobId: number, imgData: IUserImageData): any {
  return {
    JobId: jobId,
    Filename: imgData.filename,
    DataUri: imgData.data,
  }
}

export function formatGetJobResponsesResponse(resp: any): IGetJobResponsesResult {
  const stepResponses = resp.Responses?.length
    ? resp.Responses.map((r: any) => formatGetJobResponsesStepResponse(r))
    : [];

  if (resp.ComponentResponses?.length) {
    formatGetJobResponsesCompResponsesForSteps(resp.ComponentResponses,
      stepResponses);
  }

  return {
    stepResponses,
    images: resp.Images?.length
      ? resp.Images.map((i: any) => formatResponseImageRef(i))
      : [],
  };
}

function formatGetJobResponsesStepResponse(resp: any): IStepResponse {
  return {
    stepId: resp.StepId,
    jobSWId: resp.JobSWId,
    isComplete: resp.IsComplete,
    isNA: resp.IsNotApplicable,
    questionResponse: resp.QuestionResponse,
    componentResponses: [],
    conditionalResponse: resp.ConditionalResponse,
    isDirty: false,
    timestamp: new Date(resp.Timestamp),
    userEmail: resp.UserEmail,
    isDeviation: resp.IsDeviation,
  }
}

function formatGetJobResponsesCompResponsesForSteps(componentResponses: any, stepResponses: IStepResponse[]) {
  componentResponses.forEach((compResponse: any) => {
    // Find the step for this SA response.
    const step = getStepResponse({
      stepId: compResponse.StepId as string,
      jobSWId: compResponse.JobSWId as number,
    },
      stepResponses);

    if (!step) {
      return;
    }

    if (!step.componentResponses) {
      step.componentResponses = [];
    }

    const existingCompResp = step.componentResponses
      .find(compResp => compResp.id === compResponse.ComponentId);

    if (existingCompResp) {
      existingCompResp.values.push(compResponse.Value);
    } else {
      step.componentResponses.push({
        id: compResponse.ComponentId,
        type: formatComponentResponseType(compResponse.Type),
        values: [compResponse.Value],
        isDeviation: compResponse.IsDeviation,
      });
    }
  });
}

function formatComponentResponseType(type: any): ComponentResponseType {
  switch (type as string) {
    case "Text": return ComponentResponseType.Text;
    case "Image": return ComponentResponseType.Image;
    case "Date": return ComponentResponseType.Date;
    case "DateTime": return ComponentResponseType.DateTime;
    case "MultiSelect": return ComponentResponseType.MultiSelect;
    case "Number": return ComponentResponseType.Number;
    case "Select": return ComponentResponseType.Select;
    case "Signature": return ComponentResponseType.Signature;
    case "YesNo": return ComponentResponseType.YesNo;
    case "PassFail": return ComponentResponseType.PassFail;
    default: {
      throw new Error(`Unrecognized component response type from server: ${type}`);
    }
  }
}

function formatResponseImageRef(image: any): IResponseImageRef {
  return {
    filename: image.Filename,
    absoluteUri: image.AbsoluteUri,
  }
}

export async function formatUploadStepCommentsRequest(comments: IStepComment[]): Promise<any> {
  const commentsToSend = comments.filter(x => !x.isOnServer);

  const requestArr: any[] = [];

  for (let i = 0; i < commentsToSend.length; i++) {
    const commentToSend = commentsToSend[i];

    const comm: any = {
      Guid: commentToSend.guid,
      JobSWId: commentToSend.jobSWId,
      StepId: commentToSend.stepId,
      UserEmail: commentToSend.userEmail,
      Comment: commentToSend.comment,
      Timestamp: commentToSend.timestamp,
      Attachments: [],
    };

    requestArr.push(comm);

    const changedAtts = commentToSend
      .attachments
      .filter(x => x.pendingAction !== StepCommentActions.Delete);

    for (let j = 0; j < changedAtts.length; j++) {
      const att = changedAtts[j];

      const attReq: any = {
        Action: att.pendingAction,
        Filename: att.filename,
      };

      if (att.pendingAction === StepCommentActions.Add) {
        attReq.DataUri = "";
      }

      comm.Attachments.push(attReq);
    }
  }

  return requestArr;
}

export function formatGetStepCommentsResponse(response: any): IStepCommentResponse {
  if (!response
    || !response.Comments) {
    return {
      comments: [],
      azureAttachments: [],
    };
  }

  const parsedResponse: IStepCommentResponse = {
    comments: response.Comments.map((x: any): IStepComment => ({
      isDirty: false,
      isOnServer: true,
      guid: x.Guid,
      jobSWId: x.JobSWId,
      stepId: x.StepId,
      comment: x.Comment,
      userEmail: x.UserEmail,
      timestamp: new Date(x.Timestamp),
      attachments: x.Attachments?.map((i: any): IStepCommentAttachment => ({
        filename: i.Filename,
        pendingAction: StepCommentActions.NoAction,
      })) || [],
      pendingAction: StepCommentActions.NoAction,
    })),
    azureAttachments: [],
  };

  response.Comments.forEach((x: any) => {
    x.Attachments?.forEach((i: any) => {
      parsedResponse.azureAttachments.push({
        filename: i.Filename,
        signedUrl: i.AbsoluteUri,
      });
    });
  });

  return parsedResponse;
}

export function formatUploadPaperExecutionRequest(paperExec: IPaperExecution,
  imageDataUri: string) {
  return {
    UserEmail: paperExec.userEmail,
    JobIdORJobSWId: paperExec.jobSWId,
    Timestamp: paperExec.timestamp,
    ProofFilename: paperExec.imageFilename,
    DataUri: imageDataUri,
  };
}

export function formatUploadJobPaperExecutionRequest(paperExec: IJobPaperExecution,
  imageDataUri: string) {
  return {
    UserEmail: paperExec.userEmail,
    JobIdORJobSWId: paperExec.jobId,
    Timestamp: paperExec.timestamp,
    ProofFilename: paperExec.imageFilename,
    DataUri: imageDataUri,
    IsJobPaperExecution: true,
  };
}

export function formatGetPaperExecutionsResponse(response: any): IGetPaperExecutionResponse[] {

  if (!response?.PaperExecutions
    || !Array.isArray(response.PaperExecutions)) {
    return [];
  }

  var swPaperExecresponse = response.PaperExecutions.map((x: any): IGetPaperExecutionResponse => ({
    jobIdOrJobSWId: x.JobSWId,
    proofFilename: x.ProofFilename,
    userEmail: x.UserEmail,
    timestamp: new Date(x.Timestamp),
    absoluteUri: x.AbsoluteUri,
    isJobPaperExecution: false,
  }));

  if (response.JobPaperExecution) {
    var jobPaperExecResponse: IGetPaperExecutionResponse = ({
      jobIdOrJobSWId: response.JobPaperExecution.JobId,
      proofFilename: response.JobPaperExecution.ProofFilename,
      userEmail: response.JobPaperExecution.UserEmail,
      timestamp: new Date(response.JobPaperExecution.Timestamp),
      absoluteUri: response.JobPaperExecution.AbsoluteUri,
      isJobPaperExecution: true,
    });

    swPaperExecresponse.push(jobPaperExecResponse)
  }
  return swPaperExecresponse;
}

export function formatSaveSWUserFeedbackRequest(feedbacks: ISWUserFeedback[]) {
  return {
    UsersFeedback: feedbacks.map((feedback) => ({
      Id: feedback.feedbackId,
      SWId: feedback.swId,
      Version: feedback.version,
      JobId: feedback.jobId,
      Feedback: feedback.feedback,
      Vote: feedback.vote,
      CreatedBy: feedback.createdBy,
    }))
  }
}

export function formatSaveAllECLsequest(jobId: number, showAllECLsValue: boolean, userEmail?: string, timestamp?: Date) {
  return {
    jobId: jobId,
    showAllECLs: showAllECLsValue,
    userEmail: userEmail,
    timestamp: timestamp
  }
}

export function formatGetSWUserFeedbacksResponse(response: any): ISWUserFeedback[] {
  return response.UsersFeedback.map((feedback: any) => formatSWUserFeedback(feedback));
}

export function formatSWUserFeedback(feedback: any): ISWUserFeedback {
  return {
    feedbackId: feedback.Id,
    swId: feedback.SWId,
    version: feedback.Version,
    jobId: feedback.JobId,
    feedback: feedback.Feedback,
    vote: feedback.Vote,
    createdBy: feedback.CreatedBy,
  }
}