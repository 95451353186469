import React from "react";
import Modal from "components/common/Modal";
import { Link } from "react-router-dom";
import { Routes } from "components/routing/Routing";
import useSelector from "store/useSelector";
import { ExecutionMode } from "store/execution/executionTypes";
import { useTranslation } from "react-i18next";

interface IJobCompletedModalProps {
  jobId: number,
}

const JobCompletedModal: React.FC<IJobCompletedModalProps> = ({
  jobId,
}) => {
  const {
    execution: {
      mode,
    }
  } = useSelector(store => store);

  const { t } = useTranslation('execution');

  const printUrl = mode === ExecutionMode.Offline
    ? Routes.PrintViewOfflineJob.replace(":id", jobId.toString())
    : Routes.PrintViewJob.replace(":id", jobId.toString());

  return (
    <Modal
      isOpen={true}
      header={t("Job Completed")}
      controls={(
        <>
          <Link
            to={Routes.MyJobs}
          >
            <button
              className="secondary-button"
            >
              {t('My Jobs')}
            </button>
          </Link>
          <Link
            to={printUrl}
          >
            <button
              className="primary-button"
            >
              {t('Review Job')}
            </button>
          </Link>
        </>
      )}
    >
      <p>{t('The job has been completed.')}</p>
    </Modal>
  );
}

export default JobCompletedModal;