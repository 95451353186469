import { IDBPDatabase, IDBPTransaction } from 'idb';
import { ISWPOfflineDb, DbStoreNames } from "./IdbApi";
import { MissingDbMigrationsError } from 'utilities/errors/MissingDbMigrationsError';

export function applyIdbMigrations(db: IDBPDatabase<ISWPOfflineDb>,
  transaction: any,
  oldVersion: number,
  newVersion: number) {
  let updatedToVersion = oldVersion;

  if (updatedToVersion === 0
    && newVersion >= 4) {
    // Initial DB version is 4 due to development builds.
    migrateToVersion4(db);
    updatedToVersion = 4;
  }

  if (newVersion >= 5
    && oldVersion < 5) {
    // The storage format of offline SWs has changed and requires update.
    migrateToVersion5(db);
    updatedToVersion = 5;
  }

  if (newVersion >= 6
    && oldVersion < 6) {
    migrateToVersion6(db);
    updatedToVersion = 6;
  }

  if (newVersion >= 7
    && oldVersion < 7) {
    migrateToVersion7(db);
    updatedToVersion = 7;
  }

  if (newVersion >= 8
    && oldVersion < 8) {
    migrateToVersion8(db);
    updatedToVersion = 8;
  }

  if (newVersion >= 9
    && oldVersion < 9) {
    migrateToVersion9(db);
    updatedToVersion = 9;
  }

  if (newVersion >= 10
    && oldVersion < 10) {
    migrateToVersion10(db, transaction);
    updatedToVersion = 10;
  }

  if (newVersion >= 11
    && oldVersion < 11) {
    migrateToVersion11(db, transaction);
    updatedToVersion = 11;
  }

  if (newVersion >= 12
    && oldVersion < 12) {
    migrateToVersion12(db);
    updatedToVersion = 12;
  }

  if (newVersion >= 13
    && oldVersion < 13) {
    migrateToVersion13(db);
    updatedToVersion = 13;
  }

  if (newVersion >= 14
    && oldVersion < 14) {
    migrateToVersion14(db);
    updatedToVersion = 14;
  }

  if (newVersion >= 15
    && oldVersion < 15) {
    migrateToVersion15(db);
    updatedToVersion = 15;
  }

  if (newVersion >= 16
    && oldVersion < 16) {
    migrateToVersion16(db);
    updatedToVersion = 16;
  }

  if (newVersion >= 17
    && oldVersion < 17) {
    migrateToVersion17(db);
    updatedToVersion = 17
  }
    
  // Add new migration calls here.

  // If the db hasn't been updated to the requested version, that means
  // someone forgot to add a migrateToVersionX function in here.
  if (updatedToVersion !== newVersion) {
    throw new MissingDbMigrationsError(updatedToVersion, newVersion);
  }
}

function migrateToVersion4(db: IDBPDatabase<ISWPOfflineDb>) {
  if (!db.objectStoreNames.contains(DbStoreNames.Jobs)) {
    // Create Jobs store.
    db.createObjectStore(DbStoreNames.Jobs, {
      keyPath: "id",
    });
  }

  if (!db.objectStoreNames.contains(DbStoreNames.SWs)) {
    // Create SWs store.
    const swStore = db.createObjectStore(DbStoreNames.SWs, {
      keyPath: ["jobId", "swId", "swVersion"],
    });

    swStore.createIndex("by-job", "jobId");
  }

  if (!db.objectStoreNames.contains(DbStoreNames.SWImageData)) {
    const swImageStore = db.createObjectStore(DbStoreNames.SWImageData, {
      keyPath: ["swId", "version", "filename"],
    });

    swImageStore.createIndex("by-sw", ["swId", "version"]);
  }

  if (!db.objectStoreNames.contains(DbStoreNames.UserImageData)) {
    const userImageStore = db.createObjectStore(DbStoreNames.UserImageData, {
      keyPath: "filename",
    });

    userImageStore.createIndex("by-job", "jobId");
  }

  if (!db.objectStoreNames.contains(DbStoreNames.JobResponses)) {
    // Hard coding old store name due to changes in version 10.
    const jobResponsesStore = db.createObjectStore("job-responses", {
      keyPath: ["jobId", "stepId"],
    });

    jobResponsesStore.createIndex("by-job", "jobId");
  }

  if (!db.objectStoreNames.contains(DbStoreNames.JobCompletions)) {
    db.createObjectStore(DbStoreNames.JobCompletions, {
      keyPath: "jobId",
    });
  }

  if (!db.objectStoreNames.contains(DbStoreNames.JobHistoryLog)) {
    db.createObjectStore(DbStoreNames.JobHistoryLog, {
      keyPath: "jobId",
    });
  }
}

function migrateToVersion5(db: IDBPDatabase<ISWPOfflineDb>) {
  // Offline SWs are no longer stored as ISWs and instead
  // stored as json strings to be parsed afterward.
  // There is no way to update the ISWs back to JSON as
  // the format has changed.
  // The only option is to drop everything from the db
  // and restart.

  // Delete all the object stores and recreate as version 4.
  db.deleteObjectStore(DbStoreNames.UserImageData);
  db.deleteObjectStore(DbStoreNames.SWImageData);
  db.deleteObjectStore(DbStoreNames.SWs);
  // Hard coding old store name due to changes in v10.
  db.deleteObjectStore("job-responses");
  db.deleteObjectStore(DbStoreNames.Jobs);
  db.deleteObjectStore(DbStoreNames.JobCompletions);
  db.deleteObjectStore(DbStoreNames.JobHistoryLog);

  migrateToVersion4(db);
}

function migrateToVersion6(db: IDBPDatabase<ISWPOfflineDb>) {
  // A new store for RefDocs was added to the idb.

  if (!db.objectStoreNames.contains(DbStoreNames.SWRefDocData)) {
    const swImageStore = db.createObjectStore(DbStoreNames.SWRefDocData, {
      keyPath: ["swId", "version", "filename"],
    });

    swImageStore.createIndex("by-sw", ["swId", "version"]);
  }
}

function migrateToVersion7(db: IDBPDatabase<ISWPOfflineDb>) {
  // A new store for StepComments and StepCommentAttData was added to the idb.

  if (!db.objectStoreNames.contains(DbStoreNames.StepCommentAttData)) {
    db.createObjectStore(DbStoreNames.StepCommentAttData, {
      keyPath: "filename",
    });
  }

  if (!db.objectStoreNames.contains(DbStoreNames.StepComments)) {
    db.createObjectStore(DbStoreNames.StepComments, {
      keyPath: "jobId",
    });
  }
}

function migrateToVersion8(db: IDBPDatabase<ISWPOfflineDb>) {
  // A new store for job cancellations was added to the idb.
  if (!db.objectStoreNames.contains(DbStoreNames.JobCancellations)) {
    db.createObjectStore(DbStoreNames.JobCancellations, {
      keyPath: "jobId",
    });
  }
}

function migrateToVersion9(db: IDBPDatabase<ISWPOfflineDb>) {
  // Paper Executions store was added.
  if (!db.objectStoreNames.contains(DbStoreNames.PaperExecutions)) {
    const paperExecStore = db.createObjectStore(DbStoreNames.PaperExecutions, {
      keyPath: "jobSWId",
    });

    paperExecStore.createIndex("by-job", "jobId");
  }
}

async function migrateToVersion10(db: IDBPDatabase<ISWPOfflineDb>,
  transaction: IDBPTransaction<ISWPOfflineDb>) {
  // Step Responses need to support jobSWId.
  // The existing "job-responses" table's keypath does
  // not support this so a new store must be made.

  // Create new store ("step-responses") with new keyPath.
  const jobResponsesStore = db.createObjectStore(DbStoreNames.JobResponses, {
    keyPath: ["jobSWId", "stepId"],
  });

  jobResponsesStore.createIndex("by-job", "jobId");

  // Copy all the data from the old "job-responses".
  const tx = transaction;

  const oldStore = tx.objectStore("job-responses");
  let oldCursor = await oldStore.openCursor();

  while (oldCursor) {
    const response = oldCursor.value;
    if (!response.jobSWId) {
      (response as any).jobSWId = 0;
    }
    const jobResponseStore = tx.objectStore(DbStoreNames.JobResponses);
    await (jobResponseStore as any).put(response);
    oldCursor = await oldCursor.continue();
  }

  // Drop the old object store now that the values have been copied.
  db.deleteObjectStore("job-responses");
}

function migrateToVersion11(db: IDBPDatabase<ISWPOfflineDb>,
  transaction: IDBPTransaction<ISWPOfflineDb>) {
  // Added job type index to jobs.
  if (db.objectStoreNames.contains(DbStoreNames.Jobs)) {
    const jobStore = transaction.objectStore(DbStoreNames.Jobs);

    if (!jobStore.indexNames.contains("by-type")) {
      (jobStore as any).createIndex("by-type", "type");
    }
  }
}

function migrateToVersion12(db: IDBPDatabase<ISWPOfflineDb>) {

  if (!db.objectStoreNames.contains(DbStoreNames.SWUserFeedback)) {
    db.createObjectStore(DbStoreNames.SWUserFeedback, {
      keyPath: "jobId",
    });
  }
}

function migrateToVersion13(db: IDBPDatabase<ISWPOfflineDb>) {

  if (!db.objectStoreNames.contains(DbStoreNames.ResetedSW)) {
    db.createObjectStore(DbStoreNames.ResetedSW, {
      keyPath: "jobId",
    });
  }
}

function migrateToVersion14(db: IDBPDatabase<ISWPOfflineDb>) {

  if (!db.objectStoreNames.contains(DbStoreNames.ShowAllEclsJobUpdates)) {
    db.createObjectStore(DbStoreNames.ShowAllEclsJobUpdates, {
      keyPath: "jobId",
    });
  }
}

function migrateToVersion15(db: IDBPDatabase<ISWPOfflineDb>) {
  if (!db.objectStoreNames.contains(DbStoreNames.JobPaperExecution)) {
    db.createObjectStore(DbStoreNames.JobPaperExecution, {
      keyPath: "jobId",
    });
  }
}

function migrateToVersion16(db: IDBPDatabase<ISWPOfflineDb>) {
  if (!db.objectStoreNames.contains(DbStoreNames.DuplicateSWs)) {
    const duplicateStores = db.createObjectStore(DbStoreNames.DuplicateSWs, {
      keyPath: ["jobId", "jobSWId"],
    });

    duplicateStores.createIndex("by-duplicatesSW", "jobId");
  }
}

function migrateToVersion17(db: IDBPDatabase<ISWPOfflineDb>) {
  if (!db.objectStoreNames.contains(DbStoreNames.JobDocs)) {
    const jobDocStore = db.createObjectStore(DbStoreNames.JobDocs, {
      keyPath: "filename",
    });

    jobDocStore.createIndex("by-job", "jobId");
  }
}