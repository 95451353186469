import React from "react";
import ExecutionToolbarButton from "./ExecutionToolbarButton";
import { ExecutionViews } from "../Execution";
import checklistIcon from "media/icons/dls/checklist.svg";
import timeIcon from "media/icons/dls/time.svg";
import documentsIcon from "media/icons/dls/documents.svg";
import { useTranslation } from "react-i18next";
import pdf from "media/icons/dls/pdf.svg";

interface IExecutionToolbarProps {
  currentView: ExecutionViews,
  onViewButtonClick(view: ExecutionViews): void,
}

const ExecutionToolbar: React.FC<IExecutionToolbarProps> = ({ currentView, onViewButtonClick }) => {
  const { t } = useTranslation('execution');
  return (
    <>
      <ExecutionToolbarButton
        onClick={() => onViewButtonClick(ExecutionViews.Steps)}
        iconUrl={checklistIcon}
        text={t("Steps")}
        isActive={currentView === ExecutionViews.Steps}
      />
      <ExecutionToolbarButton
        onClick={() => onViewButtonClick(ExecutionViews.RefDocs)}
        iconUrl={documentsIcon}
        text={t("Ref Docs")}
        isActive={currentView === ExecutionViews.RefDocs}
      />
      <ExecutionToolbarButton
        onClick={() => onViewButtonClick(ExecutionViews.JobDocs)}
        iconUrl={pdf}
        text={t("Job Docs")}
        isActive={currentView === ExecutionViews.JobDocs}
      />
      <ExecutionToolbarButton
        onClick={() => onViewButtonClick(ExecutionViews.History)}
        iconUrl={timeIcon}
        text={t("History")}
        isActive={currentView === ExecutionViews.History}
      />
    </>);
}

export default ExecutionToolbar;