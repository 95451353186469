import { IMasterDataItem } from "interfaces/masterData/masterDataInterfaces";

export function formatArrayResponse(response: any, mappingFn: any, dataProperty: string= "data"): IMasterDataItem[] {
    if (!isDataArrayPropertyValid(response, dataProperty)){
        return [];
    }
  
    return response[dataProperty].map(mappingFn);
}

export function isDataArrayPropertyValid(responseData: any, dataProperty: string): boolean {
    return responseData[dataProperty]?.length && Array.isArray(responseData[dataProperty]);
}
  
export const codeNameFormatter= (item: any): Partial<IMasterDataItem> => ({
    code: item.Code,
    guid: item.Guid || item.Code,
    value: item.Name
});

export const codeNameIdFormatter= (item: any): Partial<IMasterDataItem> => ({
    code: item.Code,
    guid: item.Id || item.Code,
    value: item.Name
});

export const FORMATTING_PROPS= {
    plants : "Plants",
    workCenters : "WorkCenters",
    materials : "Materials"
};