import Modal from "components/common/Modal";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { addComment, setStepCommentModalData } from "store/execution/executionActions";
import { showErrorToast } from "store/toast/toastActions";
import useSelector from "store/useSelector";
import StepComment, { StepCommentEditorType } from "./StepComment";
import { v4 as uuidv4 } from 'uuid';
import IdbApi from "apis/idb/IdbApi";
import { StepCommentActions } from "interfaces/execution/executionInterfaces";
import AttachmentThumbnail from "./AttachmentThumbnail";
import "./StepCommentModal.scoped.scss";
import { isFilenameImage } from "utilities/fileUtilities";
import { IFileData } from "interfaces/files/fileInterfaces";
import { IStepIdentifier } from "store/execution/executionTypes";
import { getStepComments } from "utilities/swUtilities";
import { useTranslation } from "react-i18next";

interface attachmentActionsModalOptions {
  isVisible: boolean,
  filename: string,
}

interface IStepCommentModalProps {
  stepInfo: IStepIdentifier,
}

const StepCommentModal: React.FC<IStepCommentModalProps> = ({ stepInfo }) => {
  const {
    execution: {
      job,
      stepCommentModalData,
      stepComments,
      executor,
    },
    auth,
  } = useSelector(store => store);
  const dispatch = useDispatch();
  const { t } = useTranslation('comments');

  const [newComment, setNewComment] = useState("");
  const [newAttachmentFilenames, setNewAttachmentFilenames] = useState<string[]>([]);
  const [isDirty, setIsDirty] = useState(false);
  const [attachmentActionsModalOptions, setattachmentActionsModalOptions] = useState<attachmentActionsModalOptions>({
    isVisible: false,
    filename: "",
  });

  if (!job) {
    return null;
  }

  const onNewCommentChanged = (comment: string) => {
    setNewComment(comment);
    setIsDirty(true);
  }

  const onAttachmentAdded = async (fileData: IFileData) => {
    try {
      await IdbApi.cacheStepCommentAttData(
        fileData.filename,
        fileData.dataUri
      );

      setNewAttachmentFilenames([
        ...newAttachmentFilenames,
        fileData.filename,
      ]);

      setIsDirty(true);
    } catch (err: any) {
      dispatch(showErrorToast(err.message || err.toString()));
    }
  }

  const onClose = (confirmClose: boolean) => {
    if (confirmClose
      && !window.confirm(t("You have an unsaved comment. Click OK to abandon your changes."))) {
      return;
    }

    dispatch(setStepCommentModalData({
      ...stepCommentModalData,
      isVisible: false,
    }))
  }

  const onSave = () => {
    if (!newComment.trim()
      && !newAttachmentFilenames.length) {
      dispatch(showErrorToast(t("A comment or attachment is required.")));
      return;
    }

    const now = new Date();

    // Dispatch a new comment.
    dispatch(addComment({
      guid: uuidv4(),
      isDirty: true,
      isOnServer: false,
      jobSWId: stepInfo.jobSWId,
      stepId: stepInfo.stepId,
      comment: newComment,
      userEmail: executor?.email || auth.currentUser.email,
      timestamp: now,
      attachments: newAttachmentFilenames.map(x => ({
        filename: x,
        pendingAction: StepCommentActions.Add,
      })),
      pendingAction: StepCommentActions.Add,
    }));

    setIsDirty(false);

    onClose(false);
  }

  const onAttachmentClicked = (filename: string,
    isNewAttachment: boolean) => {
    if (isNewAttachment) {
      // Show attachment actions modal.
      setattachmentActionsModalOptions({
        isVisible: true,
        filename,
      });
    } else {
      downloadAttachment(filename);
    }
  }

  const downloadAttachment = async (filename: string) => {
    try {
      const attData = await IdbApi.getStepCommentAttData(filename);

      if (attData) {
        const link = document.createElement("a");
        link.download = filename;
        link.href = attData.data;
        link.click();
      } else {
        dispatch(showErrorToast(t("Attachment not found in browser cache.")));
      }
    } catch (err: any) {
      dispatch(showErrorToast(err?.message || err.toString()));
    }

    closeAttachmentOptions();
  }

  const deleteAttachment = async (filename: string) => {
    // Remove it from the IDB.
    await IdbApi.deleteStepCommentAtt(filename);

    // Remove it from the state list.
    setNewAttachmentFilenames(
      newAttachmentFilenames
        .filter(x => x !== filename));
    closeAttachmentOptions();
  }

  const closeAttachmentOptions = () => {
    setattachmentActionsModalOptions({
      ...attachmentActionsModalOptions,
      isVisible: false,
    })
  }

  return (
    <Modal
      header={t("Step Comments")}
      isOpen={true}
      controls={(
        <>
          <button
            className="secondary-button"
            onClick={() => onClose(isDirty)}
          >
            {t('Close')}
          </button>
          <button
            className="primary-button"
            onClick={onSave}
            disabled={!newComment.trim()
              && !newAttachmentFilenames.length
            }
          >
            {t('Save')}
          </button>
        </>
      )}
    >
      <StepComment
        editorType={StepCommentEditorType.New}
        stepComment={undefined}
        onNewCommentChanged={onNewCommentChanged}
        newComment={newComment}
        onAttachmentAdded={onAttachmentAdded}
        newAttachmentFilenames={newAttachmentFilenames}
        onAttachmentClick={(filename: string) => onAttachmentClicked(filename, true)}
      />
      {getStepComments(stepInfo, stepComments)
        .sort((a, b) => a.timestamp < b.timestamp ? 1 : -1)
        .map(comment => (
          <StepComment
            key={comment.guid}
            editorType={StepCommentEditorType.View}
            stepComment={comment}
            onAttachmentClick={(filename: string) => onAttachmentClicked(filename, false)}
          />
        ))
      }
      {attachmentActionsModalOptions.isVisible &&
        <Modal
          isOpen={true}
          header={t("Attachment Actions")}
          controls={
            <>
              <button
                className="tertiary-button"
                onClick={() => deleteAttachment(attachmentActionsModalOptions.filename)}
              >
                {t('Delete')}
              </button>
              <button
                className="secondary-button"
                onClick={() => closeAttachmentOptions()}
              >
                {t('Cancel')}
              </button>
              <button
                className="primary-button"
                onClick={() => downloadAttachment(attachmentActionsModalOptions.filename)}
              >
                {t('Download')}
              </button>
            </>
          }
        >
          <div
            className="att-options-text"
          >
            <p>
              {t('What would you like to do with {filename}?', {filename: attachmentActionsModalOptions.filename})}
            </p>
            {isFilenameImage(attachmentActionsModalOptions.filename) &&
              <div
                className="att-row"
              >
                <AttachmentThumbnail
                  filename={attachmentActionsModalOptions.filename}
                  onClick={() => { }}
                />
              </div>
            }
          </div>
        </Modal>
      }
    </Modal>
  );
}

export default StepCommentModal;