import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Banner, { BannerType } from "./common/Banner";

const NotFound: React.FC = () =>
{
  const { t } = useTranslation('mainMenu');
  return(
    <div>
      <h2 className="page-title">
        {t('Page Not Found')}
      </h2>
      <Banner type={BannerType.Error}>
        {t('The page you requested was not found.')}
      </Banner>
      <p>
        <Link to="/">
          {t('Go to My Jobs')}
        </Link>
      </p>
    </div>);
}

export default NotFound;