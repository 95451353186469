import React from "react";
import {  IStepComponent } from "interfaces/sw/SWInterfaces";
import "./NoticeRichText.scoped.scss";
import sanitizeHtml from 'sanitize-html-react';

interface IRichTextComponentProps {
  component: IStepComponent,
}

const NoticeRichText : React.FC<IRichTextComponentProps> = ({ component }) => {
  if (!isRichTextData(component.label)) {
    return null;
  }

  return (
    <div
      className="html-content"
      dangerouslySetInnerHTML={createMarkup(component.label)}
    />
  )
}

export default NoticeRichText;

function isRichTextData(data: any): data is string {
  return data !== null
    && data !== undefined
}

function createMarkup(data: string) {
  const cleanHtml = sanitizeHtml(data, {
    allowedTags: ['p', 'ul', 'li', 'ol', 'b', 'i', 'a', 'strong', 'em', 'ins'],
    allowedAttributes: {
      'a': ['href', 'name', 'target']
    },
  });

  return { __html: cleanHtml };
}