import React from "react";
import "./TitleBar.scoped.scss";
import { Link } from "react-router-dom";
import { Routes } from "components/routing/Routing";
import profileIcon from "media/icons/dls/profile.svg";
import helpIcon from "media/icons/dls/help.svg";
import workRightLogo from "media/work-right.png";
import useSelector from "store/useSelector";
import { useTranslation } from "react-i18next";
import config from "config";

const TitleBar: React.FC = () => {
  const currUser = useSelector(store => store.auth.currentUser);
  const { t } = useTranslation('mainMenu');

  return (
    <div
      className="title-bar no-text-select"
    >
      <img
        className="logo"
        src={workRightLogo}
        alt=""
      />
      <span
        className="spacer"
      ></span>
      <TitleNavLink 
        to={{ pathname: config.endpoints.ITHelp.portal }}
        text={t('IT Help')} 
        title={t('Submit Ticket')} 
        iconImg={helpIcon}
        target="_blank" 
      />

      <TitleNavLink 
        to={Routes.MyProfile}
        title={t('My Profile')} 
        iconImg={profileIcon} 
        text={currUser.email}
      />
    </div>
  );
}

interface ITitleNavLinkProps {
  /** The target destination for the link */
  to: any,
  /** The title description for the link */
  title: string,
  /** The link target. E.g: _blank */
  target?: string,
  /** The display text for the link */
  text?: string,
  /** The link icon's src location */
  iconImg?: string,
  /** The link icon's title description */
  iconTitle?: string
}

/**
 * Renders a nav link item which can contain an image.
 * @param props The component properties. 
 * @returns The title nav link. 
 */
 const TitleNavLink: React.FC<ITitleNavLinkProps> = (props) => {
  return (
    <Link
      to={props.to} 
      target={props.target}
      title={props.title}
    >
    <span className="link-content">{props.text || props.title}</span>
    {props.iconImg!.length > 0 &&
      <img
        className="icon-medium black-to-white-filter"
        src={props.iconImg}
        alt={props.iconTitle || props.title}
      />
    }
  </Link>
  );
}

export default TitleBar;