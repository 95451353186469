import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { IStepComponent, StepComponentTypes, ISelectComponentData } from "interfaces/sw/SWInterfaces";
import { logJobAction, setComponentResponse } from "store/execution/executionActions";
import { ComponentResponseType } from "interfaces/execution/executionInterfaces";
import useSelector from "store/useSelector";
import ToggleButtonInput from "components/execution/sw/controls/ToggleButtonInput";
import { IStepIdentifier } from "store/execution/executionTypes";
import { getComponentResponse } from "utilities/swUtilities";
import Banner, { BannerType } from "components/common/Banner";

interface ISelectInputProps {
  component: IStepComponent,
  isDisabled: boolean,
  stepInfo: IStepIdentifier,
}

const SelectInput: React.FC<ISelectInputProps> = ({ component, isDisabled, stepInfo }) => {
  const dispatch = useDispatch();
  const {
    auth: {
      currentUser: {
        email,
        name,
      },
    },
    execution: {
      stepResponses,
      executor,
    },
  } = useSelector(store => store);

  const [isExpectedValue, setIsExpectedValue] = useState(true);

  if (component.type !== StepComponentTypes.SelectInput) {
    return null;
  }

  const onValueChange = (value: string) => {
    setIsExpectedValue(true);
    if (isDisabled) {
      return;
    }

    let isDeviation = false;

    if (expectedValue !== undefined) {
      if (value !== expectedValue) {
        setIsExpectedValue(false);
        isDeviation = true;
      }
    }

    dispatch(setComponentResponse({
      response: {
        id: component.id,
        type: ComponentResponseType.Select,
        values: [value],
        isDeviation: isDeviation,
      },
      stepInfo,
      userEmail: executor?.email || email,
    }));

    if (isDeviation) {
      dispatch(logJobAction({
        action: `Component response for ${component.label} was completed with deviation.`,
        timestamp: new Date(),
        userName: executor?.name || name,
        userEmail: executor?.email || email,
      }));
    }
  }

  const compResponseValues = getComponentResponse(stepInfo,
    component.id,
    stepResponses)
    ?.values;

  let options: string[] = [];
  let nonConform: string = "";
  let expectedValue: string | undefined = undefined;

  if (isSelectData(component.data)) {
    options = component.data.options || [];
    nonConform = component.data.nonConform || "";
    expectedValue = component.data.expectedValue;
  }

  let shouldShowConformInfo = expectedValue !== undefined;

  let existingResponseNonConform = false;
  if (compResponseValues
    && (compResponseValues?.[0] !== ""
      && compResponseValues?.[0] !== undefined)) {
    let selectedValue = compResponseValues[0];

    if ((expectedValue !== undefined
      && selectedValue !== expectedValue)) {
      existingResponseNonConform = true;
    }
  }

  return (
    <div>
      {shouldShowConformInfo &&
        <Banner
          type={BannerType.Info}
        >
          <div className="conformInfo">
            <label>Expected Value:&nbsp;</label>
            <span>{expectedValue}</span>
          </div>
        </Banner>
      }
      {shouldShowConformInfo
        && (!isExpectedValue
          || existingResponseNonConform) &&
        <Banner
          type={BannerType.Warn}
        >
          {nonConform}
        </Banner>
      }
      <label>{component.label}</label>
      <ToggleButtonInput
        options={options}
        disabled={isDisabled}
        onAnswerChosen={val => onValueChange(val)}
        currentValue={compResponseValues?.[0]}
      />
    </div>
  );
}

export default SelectInput;

function isSelectData(data: any): data is ISelectComponentData {
  return data !== null
    && data !== undefined
    && 'options' in data;
}