import Banner, { BannerType } from "components/common/Banner";
import ModalSpinner from "components/common/ModalSpinner";
import JobTemplateHeader from "components/jobs/mine/jobHeaders/JobTemplateHeader";
import { MyJobsTabs } from "interfaces/jobs/JobInterfaces";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { setCloneOperation } from "store/jobTemplates/jobTemplatesActions";
import { setMyJobsTab } from "store/myJobs/myJobsActions";
import useSelector from "store/useSelector";
import OfflineTemplatesList from "./OfflineTemplatesList";

const JobTemplates: React.FC<RouteComponentProps> = (props) => {
  const {
    templates,
    hasOfflineError,
    loadOperation,
    cloneOperation,
  } = useSelector(state => state.jobTemplates);
  const dispatch = useDispatch();
  const { t } = useTranslation('myJobs');

  useEffect(() => {
    if (cloneOperation?.wasSuccessful) {
      dispatch(setCloneOperation(undefined));
      dispatch(setMyJobsTab(MyJobsTabs.Active));
    }
  }, [cloneOperation, dispatch]);

  let displayComponent: JSX.Element | undefined;

  if (loadOperation?.isWorking
    || cloneOperation?.isWorking) {
    displayComponent = <ModalSpinner />;
  } else if (hasOfflineError) {
    displayComponent = (
      <OfflineTemplatesList
        {...props}
      />
    );
  } else if (loadOperation?.errorMessage) {
    displayComponent = (
      <Banner
        type={BannerType.Error}
      >
        {loadOperation.errorMessage}
      </Banner>
    );
  } else if (templates!?.length === 0) {
    displayComponent = (
      <Banner type={BannerType.Info}>
        {t('No job templates found.')}
      </Banner>
    );
  } else if (templates!?.length > 0) {
    displayComponent = (
      <>
        {templates!?.map(job =>
          <JobTemplateHeader
            key={job.id}
            isOffline={false}
            {...props}
            jobHeader={job}
          />
        )}
      </>
    );
  }

  return (
    <>
      {displayComponent}
    </>
  );
}

export default JobTemplates;