import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import {
  addCachedJobIds,
  removeCachedJobIds,
  setDeleteOperation,
  setIsAnythingAwaitingSync,
  setUploadCacheOperation,
  cacheJobOffline,
  deleteCachedJobs,
  refreshAwaitingSync,
  uploadCachedData,
  setJobsAwaitingCompletions,
  setCacheOperation,
  setJobsAwaitingCancellations,
  cacheTemplateOffline,
  setCacheTemplateOperation,
  setCachedJobIds,
  saveJobOffline,
} from './offlineActions';
import { ISWPJob, ISWPJobLogItem, IOfflineSWPJobLog, ISWPJobSW, ISWPJobCreationResponse, ISWUserFeedback, IOfflineJobShowAllECLs, IOfflineJobDuplicateJobSW, ISWPUpdateJobResponse } from 'interfaces/jobs/JobInterfaces';
import JobsApi from 'apis/jobs/JobsApi';
import { getResponseErrorMessage } from 'utilities/validationErrorHelpers';
import { IGetJobResponsesResult, IResponseImageRef, ISWImageData, IUserImageData, OfflineStepResponse, IJobCompletion, IStepResponse, ComponentResponseType, ISWRefDocData, IIdbStepComments, IStepComment, IStepCommentResponse, IJobCancellation, IGetPaperExecutionResponse, IPaperExecution, IIdbPaperExecution, IIdbStepCommentAttachment, StepCommentActions, IOfflineSWUserFeedback, IIdbResetSW, IIdbJobPaperExecution, IJobPaperExecution, IDuplicateJobSW, IDuplicateSWRequest, DuplicateFetchedData, IJobDocData } from 'interfaces/execution/executionInterfaces';
import ExecutionApi from 'apis/execution/ExecutionApi';
import { IGetSWResponse, ISWAttachmentRef, IOfflineSW } from 'interfaces/sw/SWInterfaces';
import SWApi from 'apis/sw/SWApi';
import IdbApi from 'apis/idb/IdbApi';
import AzureBlobsApi from 'apis/azureBlobs/AzureBlobsApi';
import { Action } from '@reduxjs/toolkit';
import { showErrorToast, showInfoToast, showSuccessToast } from 'store/toast/toastActions';
import { formatSW } from 'apis/sw/SWFormatters';
import { batchCalls } from 'store/saga-helpers';
import { cacheStepCommentAtt, downloadAndCacheUserImage } from 'store/execution/executionSagas';
import { setCommentsAreOnServer, setPaperExecutionsAreOnServer, launchUserSentimentSurvey, setJobPaperExecutionIsOnServer } from 'store/execution/executionActions';
import { ExecutionMode, IExecutionState } from 'store/execution/executionTypes';
import UserApi from 'apis/user/UserApi';
import { IEmailPINPair, IManageUserUser } from 'interfaces/user/UserInterfaces';
import { cloneDeep } from 'lodash';
import { removeJobsFromJobList } from 'store/myJobs/myJobsActions';
import { IInProgressJob, IManageJobSW } from 'store/manageJob/manageJobTypes';
import { IOldNewJobSWIdMapping } from './offlineTypes';
import { RootState } from 'store/rootStore';
import { history } from 'App';
import { Routes } from 'components/routing/Routing';
import i18n from 'i18n';

const t = i18n.getFixedT(null, 'offlineSaga');

export default function* watchOfflineSagas() {
  yield all([
    watchCacheJobOffline(),
    watchCacheTemplateOffline(),
    watchDeleteCachedJobs(),
    watchRefreshAwaitingSync(),
    watchUploadCachedData(),
    watchSaveJoboffline(),
  ]);
}

function* watchCacheJobOffline() {
  yield takeLatest(cacheJobOffline, cacheJobAsync);
}

function* watchCacheTemplateOffline() {
  yield takeLatest(cacheTemplateOffline, cacheTemplateAsync);
}

function* watchDeleteCachedJobs() {
  yield takeLatest(deleteCachedJobs, deleteCachedJobsAsync);
}

function* watchRefreshAwaitingSync() {
  yield takeLatest(refreshAwaitingSync, refreshAwaitingSyncAsync);
}

function* watchUploadCachedData() {
  yield takeLatest(uploadCachedData, uploadCachedDataAsync)
}

function* watchSaveJoboffline() {
  yield takeLatest(saveJobOffline, saveJobOfflineAsync)
}

function* cacheTemplateAsync(action: Action) {
  if (!cacheTemplateOffline.match(action)) {
    return;
  }

  const {
    jobId,
    country,
    geoUnit,
    subBusinessLine,
    team: origTeam,
  } = action.payload;

  if (!origTeam.length) {
    yield put(showErrorToast(t("Team is required when caching a template.")));
    return;
  }

  yield put(setCacheTemplateOperation({
    isWorking: true,
  }));

  // Start by looking up the users on the backend in order
  // to get their offline PINs.
  let team = cloneDeep(action.payload.team);

  // Check to see if this job is already in the Idb.
  const oldJobInIdb: ISWPJob | undefined = yield call([IdbApi, IdbApi.getCachedJob], jobId);

  // Load the job itself.
  let job: ISWPJob;

  try {
    // Call jobs api to get the job.
    let result: ISWPJob = yield call(JobsApi.getJob, jobId);

    if (!result) {
      throw new Error(t("Failed to load template."));
    }

    job = result;
  } catch (err: any) {
    yield put(showErrorToast(`${t('Failed to load template.')}: ${getResponseErrorMessage(err)}`));
    yield put(setCacheTemplateOperation(undefined));
    return;
  }

  let failure = false;

  if (!job.country
    && !country) {
    yield put(showErrorToast(t("Country is required when caching a template.")));
    failure = true;
  }

  if (!job.geoUnit
    && !geoUnit) {
    yield put(showErrorToast(t("GeoUnit is required when caching a template.")));
    failure = true;
  }

  if (!job.subBusinessLine
    && !subBusinessLine) {
    yield put(showErrorToast(t("SubBusinessLine is required when caching a template.")));
    failure = true;
  }

  if (failure) {
    yield put(setCacheTemplateOperation(undefined));
    return;
  }

  if (oldJobInIdb) {
    if (oldJobInIdb.lastActionDate < job.lastActionDate) {
      // Delete the job and continue caching updated data.
      yield call(deleteCachedJobsAsync, deleteCachedJobs({
        jobIds: [jobId],
      }));
      console.log(t("Deleted old template from idb before continuing."));
    } else {
      // Server version isn't newer. Skip update.
      yield put(setCacheTemplateOperation(undefined));
      console.log(t("Skipped updating template since it is not updated on server."));
      return;
    }
  }

  try {
    let result: IEmailPINPair[] = yield call(UserApi.getUserPINs, team.map(x => x.email));

    // Update the team list to contain those offline PINs.
    result.forEach(emailPin => {
      const teamMembers = team.filter(x => x.email.toLowerCase() === emailPin.email.toLowerCase());

      teamMembers.forEach(tm => {
        tm.offlinePIN = emailPin.pin;
      });
    });
  } catch (err: any) {
    yield put(showErrorToast(`${t('Failed to load user offline PINs')}: ${getResponseErrorMessage(err)}`));
    yield put(setCacheTemplateOperation(undefined));
    return;
  }
  // Overwrite the loaded job with the selected attributes, only if different.
  if (job.geoUnit?.code != geoUnit?.code){
    job.geoUnit = geoUnit;
  }
  if (job.country?.guid != country?.guid){
    job.country = country;
  }
  if (job.subBusinessLine?.code != subBusinessLine?.code){
    job.subBusinessLine = subBusinessLine;
  }
  job.team = team;

  // Since the job was loaded successfully, load all the SWs.

  // Start loading each one individually at the same time
  // and wait for them all to finish.
  try {
    let initial: ISWPJobSW[] = [];
    let uniqueSWs = job.sws.reduce((acc, curr) =>
      !acc.find(x => x.swId === curr.swId
        && x.version === curr.version)
        ? acc.concat(curr)
        : acc
      , initial);

    let swDocImagesAndDocs: [string, ISWImageData[], ISWRefDocData[]][] = yield all(uniqueSWs.map(swHeader =>
      call(downloadSW, swHeader.swId, swHeader.version)));

    // Cache them all.
    let swImages: ISWImageData[] = [];
    let swRefDocs: ISWRefDocData[] = [];
    swDocImagesAndDocs.forEach(swAndI => swImages.push(...swAndI[1]));
    swDocImagesAndDocs.forEach(swAndI => swRefDocs.push(...swAndI[2]));

    if (swImages.length) {
      // Cache all SW Images at once.
      yield call([IdbApi, IdbApi.cacheAllSWImageData], swImages);
    }

    if (swRefDocs.length) {
      // Cache all SW Ref Docs at once.
      yield call([IdbApi, IdbApi.cacheAllSWRefDocData], swRefDocs);
    }

    // Cache all SWs at once.
    yield call([IdbApi, IdbApi.cacheSWs],
      swDocImagesAndDocs.map((s): IOfflineSW => {
        let sw = formatSW(s[0]);
        return {
          jobId: job.id,
          swId: sw.id,
          swVersion: sw.version,
          swJson: s[0],
        };
      }));
  } catch (err: any) {
    yield put(showErrorToast(`${t('Failed to load standard work for template')}: ${getResponseErrorMessage(err)}`));
    yield put(setCacheTemplateOperation(undefined));
    return;
  }

  // Cache the job template into the idb.
  try {
    yield call([IdbApi, IdbApi.cacheJob], job);
  } catch (err: any) {
    yield put(showErrorToast(`${t('Failed to cache job')}: ${getResponseErrorMessage(err)}`));
    yield put(setCacheTemplateOperation(undefined));
    return;
  }

  yield put(addCachedJobIds({ jobIds: [job.id] }));

  yield put(showSuccessToast(t("Job template cached successfully.")));
  yield put(setCacheTemplateOperation(undefined));
}

function* cacheJobAsync(action: Action) {
  if (!cacheJobOffline.match(action)) {
    return;
  }

  yield put(setCacheOperation({
    isWorking: true,
  }));

  let job: ISWPJob;

  // First, load the job itself.
  try {
    // Call jobs api to get the job.
    let result: ISWPJob = yield call(JobsApi.getJob, action.payload.jobId);

    if (!result) {
      throw new Error(t("Failed to load job."));
    }

    job = result;
  } catch (err: any) {
    yield put(showErrorToast(`${t('Failed to load job.')}: ${getResponseErrorMessage(err)}`));
    yield put(setCacheOperation(undefined));
    return;
  }
  // Load all Job Docs if Any.
  try {

    let jobDocuments: IJobDocData[] = [];
    for (let index = 0; index < job.jobDocs.length; index++) {
      const element = job.jobDocs[index];
      let fileContent: ISWAttachmentRef = {
        filename: element.fileName,
        absoluteUri: element.fileContent,
      }
      let jobDoc: IJobDocData = yield call(loadJobDocsIfNotInDb, fileContent, action.payload.jobId)
      if (jobDoc) {
        jobDocuments.push(jobDoc);
      }
    }
    if (jobDocuments) {
      yield call([IdbApi, IdbApi.cacheAllJobDocs], jobDocuments);
    }
  } catch (err: any) {
    yield put(showErrorToast(`${t('Failed to load job due to issue in Job docs.')}: ${getResponseErrorMessage(err)}`));
    yield put(setCacheOperation(undefined));
    return;
  }
  // Since the job was loaded successfully, load all the SWs.

  // Start loading each one individually at the same time
  // and wait for them all to finish.
  try {
    let initial: ISWPJobSW[] = [];
    let uniqueSWs = job.sws.reduce((acc, curr) =>
      !acc.find(x => x.swId === curr.swId
        && x.version === curr.version)
        ? acc.concat(curr)
        : acc
      , initial);

    let swDocImagesAndDocs: [string, ISWImageData[], ISWRefDocData[]][] = yield all(uniqueSWs.map(swHeader =>
      call(downloadSW, swHeader.swId, swHeader.version)));



    // Cache them all.
    let swImages: ISWImageData[] = [];
    let swRefDocs: ISWRefDocData[] = [];
    swDocImagesAndDocs.forEach(swAndI => swImages.push(...swAndI[1]));
    swDocImagesAndDocs.forEach(swAndI => swRefDocs.push(...swAndI[2]));

    if (swImages.length) {
      // Cache all SW Images at once.
      yield call([IdbApi, IdbApi.cacheAllSWImageData], swImages);
    }

    if (swRefDocs.length) {
      // Cache all SW Ref Docs at once.
      yield call([IdbApi, IdbApi.cacheAllSWRefDocData], swRefDocs);
    }

    // Cache all SWs at once.
    yield call([IdbApi, IdbApi.cacheSWs],
      swDocImagesAndDocs.map((s): IOfflineSW => {
        let sw = formatSW(s[0]);
        return {
          jobId: job.id,
          swId: sw.id,
          swVersion: sw.version,
          swJson: s[0],
        };
      }));
  } catch (err: any) {
    yield put(showErrorToast(`${t('Failed to load standard work for job')}: ${getResponseErrorMessage(err)}`));
    yield put(setCacheOperation(undefined));
    return;
  }

  // Load any previous users' responses.
  let getJobResponsesResult: IGetJobResponsesResult;
  try {
    getJobResponsesResult = yield call(ExecutionApi.getResponses, action.payload.jobId);
  } catch (err: any) {
    yield put(showErrorToast(`${t('Failed loading previous responses')}: ${getResponseErrorMessage(err)}`));
    yield put(setCacheOperation(undefined));
    return;
  }

  // Load job history log from server.
  let jobHistoryLog: ISWPJobLogItem[] | undefined;

  try {
    jobHistoryLog = yield call(JobsApi.getJobLog, action.payload.jobId);
  } catch (err: any) {
    yield put(showErrorToast(`${t('Failed loading job history log')}: ${getResponseErrorMessage(err)}`));
    yield put(setCacheOperation(undefined));
    return;
  }

  // Load user response images simultaneously.
  if (getJobResponsesResult.images.length) {
    try {
      let loadedImages: (IUserImageData | undefined)[] = yield all(getJobResponsesResult
        .images
        .map(i => call(loadResponseImageIfNotInDb, i, action.payload.jobId)));

      let userImgData: IUserImageData[] = loadedImages
        .filter(x => x !== undefined)
        .map(x => x as IUserImageData);

      if (userImgData.length) {
        // Cache all the job response images at once.
        yield call([IdbApi, IdbApi.cacheAllUserImageData], userImgData);
      }
    } catch (err: any) {
      yield put(showErrorToast(`${t('Failed to cache response images')}: ${getResponseErrorMessage(err)}`));
      yield put(setCacheOperation(undefined));
      return;
    }
  }

  // Load step comments.
  let comments: IStepComment[] | undefined;

  // Call execution api to get step comments.
  try {
    const stepCommentsResponse: IStepCommentResponse =
      yield call(ExecutionApi.getStepComments,
        action.payload.jobId);

    if (stepCommentsResponse.azureAttachments.length) {
      // Load and cache all the step comment atts.
      yield call(batchCalls,
        5,
        stepCommentsResponse.azureAttachments,
        cacheStepCommentAtt);
    }

    comments = stepCommentsResponse.comments;
  } catch (err: any) {
    yield put(showErrorToast(`${t('Failed loading job comments')}: ${getResponseErrorMessage(err)}`));
    yield put(setCacheOperation(undefined));
    return;
  }

  if (comments) {
    try {
      // Cache all the step comments to the idb.
      const idbStepComments: IIdbStepComments = {
        jobId: action.payload.jobId,
        comments,
      };

      yield call([IdbApi, IdbApi.cacheStepComments],
        idbStepComments);
    } catch (err: any) {
      yield put(showErrorToast(`${t('Failed to cache job comments')}: ${getResponseErrorMessage(err)}`));
      yield put(setCacheOperation(undefined));
      return;
    }
  }

  // Load paper executions from the server.
  try {
    const paperExecs: IGetPaperExecutionResponse[] = yield call(
      ExecutionApi.getPaperExecutions,
      action.payload.jobId);

    if (paperExecs.length) {
      // Load all their images.
      const loadArgs = paperExecs.map((x): {
        imageRef: IResponseImageRef,
        jobId: number,
      } => ({
        imageRef: {
          filename: x.proofFilename,
          absoluteUri: x.absoluteUri,
        },
        jobId: action.payload.jobId,
      }));

      yield batchCalls(3, loadArgs, downloadAndCacheUserImage);

      const paperExecutions = paperExecs.map((x): IPaperExecution => ({
        userEmail: x.userEmail,
        timestamp: x.timestamp,
        imageFilename: x.proofFilename,
        jobSWId: x.jobIdOrJobSWId,
        isOnServer: true,
        isDirty: false,
      }));

      // Cache the paper executions into the idb.
      yield call([IdbApi, IdbApi.cachePaperExecutions],
        paperExecutions.map((x): IIdbPaperExecution => ({
          imageFilename: x.imageFilename,
          jobId: action.payload.jobId,
          userEmail: x.userEmail,
          jobSWId: x.jobSWId,
          timestamp: x.timestamp,
          isOnServer: x.isOnServer,
        })));
    }
  } catch (err: any) {
    yield put(showErrorToast(`${t('Failed to cache paper executions')}: ${getResponseErrorMessage(err)}`));
    yield put(setCacheOperation(undefined));
    return;
  }

  if (comments) {
    try {
      // Cache all the step comments to the idb.
      const idbStepComments: IIdbStepComments = {
        jobId: action.payload.jobId,
        comments,
      };

      yield call([IdbApi, IdbApi.cacheStepComments],
        idbStepComments);
    } catch (err: any) {
      yield put(showErrorToast(`${t('Failed to cache job comments')}: ${getResponseErrorMessage(err)}`));
      yield put(setCacheOperation(undefined));
      return;
    }
  }

  // Get the job log from the server.
  try {
    jobHistoryLog = yield call(JobsApi.getJobLog, action.payload.jobId);
  } catch (err: any) {
    yield put(showErrorToast(`${t('Failed loading job history log')}: ${getResponseErrorMessage(err)}`));
    yield put(setCacheOperation(undefined));
    return;
  }

  // Start caching the data.
  if (getJobResponsesResult.stepResponses.length) {
    try {
      // Cache the Job Responses into cache all at once.
      let offlineStepResponses: OfflineStepResponse[] =
        getJobResponsesResult.stepResponses
          .map(r => ({
            jobId: job.id,
            ...r,
            isOnServer: true,
            isDirty: false,
          }));

      yield call([IdbApi, IdbApi.cacheJobResponses], offlineStepResponses);
    } catch (err: any) {
      yield put(showErrorToast(`${t('Failed to cache job responses')}: ${getResponseErrorMessage(err)}`));
      yield put(setCacheOperation(undefined));
      return;
    }
  }

  // Cache the job history log.
  try {
    let offlineJobLog: IOfflineSWPJobLog = {
      jobId: action.payload.jobId,
      log: jobHistoryLog || [],
    };

    yield call([IdbApi, IdbApi.cacheJobLog], offlineJobLog);
  } catch (err: any) {
    yield put(showErrorToast(`${t('Failed to cache job history log')}: ${getResponseErrorMessage(err)}`));
    yield put(setCacheOperation(undefined));
    return;
  }

  // Cache the job definition.
  try {
    yield call([IdbApi, IdbApi.cacheJob], job);
  } catch (err: any) {
    yield put(showErrorToast(`${t('Failed to cache job')}: ${getResponseErrorMessage(err)}`));
    yield put(setCacheOperation(undefined));
    return;
  }

  yield put(addCachedJobIds({ jobIds: [job.id] }));
  yield put(setCacheOperation(undefined));

  const jobDisplay = (job.jobNumber
    ? `${job.jobNumber}: `
    : "") + job.title;

  if (!action.payload.isRefreshJob) {
    yield put(showSuccessToast(`${jobDisplay} ${t('has been saved to your device')}.`));
  }
  else {
    yield put(showSuccessToast(`${jobDisplay} ${t('has been refreshed to your device')}.`));
  }
}

function* downloadSW(swId: string, version: string) {
  // Get SW and images urls from server.
  const getSWResponse: IGetSWResponse = yield call(SWApi.getSW, swId, version);

  // Retrieve SW document from Azure cloud.
  const sw: string = yield call(SWApi.getSWDocumentJsonString, getSWResponse.documentAbsoluteUri);
  let swImageDatas: ISWImageData[] = [];
  let swRefDocDatas: ISWRefDocData[] = [];

  // If there were any images in this SW, get them all too.
  if (getSWResponse.images.length) {
    try {
      let imageData: (ISWImageData | undefined)[] = yield all(getSWResponse
        .images
        .map(i => call(loadSWImageIfNotInDb, i, getSWResponse.swId, getSWResponse.version)));

      swImageDatas = imageData
        .filter((i: any) => i !== undefined)
        .map(x => x as ISWImageData);
    } catch (err: any) {
      yield put(showErrorToast(`${t('Failed to load standard work images')}: ${getResponseErrorMessage(err)}`));
      yield put(setCacheOperation(undefined));
      throw err;
    }
  }

  // Also cache all the refDocs if there are any.
  if (getSWResponse.refDocs.length) {
    try {
      let refDocData: (ISWRefDocData | undefined)[] = yield all(getSWResponse
        .refDocs
        .map(i => call(loadSWRefDocIfNotInDb, i, getSWResponse.swId, getSWResponse.version)));
      swRefDocDatas = refDocData
        .filter((i: any) => i !== undefined)
        .map(x => x as ISWRefDocData);
    } catch (err: any) {
      yield put(showErrorToast(`${t('Failed to load standard work refDocs')}: ${getResponseErrorMessage(err)}`));
      yield put(setCacheOperation(undefined));
      throw err;
    }
  }

  return [sw, swImageDatas, swRefDocDatas];
}

function* loadSWImageIfNotInDb(attRef: ISWAttachmentRef, swId: string, swVersion: string) {
  const isInCache: boolean = yield call([IdbApi, IdbApi.isSWImageInCache],
    swId,
    swVersion,
    attRef.filename);

  if (!isInCache) {
    // If the image isn't already in the cache, retrieve it and put it in the cache.
    let dataUri: string = yield call(AzureBlobsApi.getDataUri, attRef.absoluteUri);

    let imgRef: ISWImageData = {
      swId,
      version: swVersion,
      filename: attRef.filename,
      data: dataUri,
      expirationDate: new Date(),
    };

    return imgRef;
  } else {
    return undefined;
  }
}

function* loadJobDocsIfNotInDb(attRef: ISWAttachmentRef, jobID: number) {
  const isInCache: boolean = yield call([IdbApi, IdbApi.isJobDocInCache], attRef.filename);

  if (!isInCache) {
    let dataUri: string = yield call(AzureBlobsApi.getDataUri, attRef.absoluteUri);

    let jobDoc: IJobDocData = {
      jobId: jobID,
      filename: attRef.filename,
      expirationDate: new Date(),
      data: dataUri,
    };

    return jobDoc;
  } else {
    return undefined;
  }
}

function* loadSWRefDocIfNotInDb(attRef: ISWAttachmentRef, swId: string, swVersion: string) {
  const isInCache: boolean = yield call([IdbApi, IdbApi.isSWRefDocInCache],
    swId,
    swVersion,
    attRef.filename);

  if (!isInCache) {
    // If the image isn't already in the cache, retrieve it and put it in the cache.
    let dataUri: string = yield call(AzureBlobsApi.getDataUri, attRef.absoluteUri);

    let refDoc: ISWRefDocData = {
      swId,
      version: swVersion,
      filename: attRef.filename,
      data: dataUri,
      expirationDate: new Date(),
    };

    return refDoc;
  } else {
    return undefined;
  }
}

export function* loadResponseImageIfNotInDb(imageRef: IResponseImageRef, jobId: number) {
  const isInCache: boolean = yield call([IdbApi, IdbApi.isUserImageInCache], imageRef.absoluteUri);

  if (!isInCache) {
    // If the image isn't already in the cache, retrieve it and put it in the cache.
    let dataUri: string = yield call(AzureBlobsApi.getDataUri, imageRef.absoluteUri);

    let imgData: IUserImageData = {
      filename: imageRef.filename,
      jobId,
      isOnServer: true,
      data: dataUri,
      expirationDate: new Date(),
    };

    return imgData;
  } else {
    return undefined;
  }
}

function* deleteCachedJobsAsync(action: Action) {
  if (!deleteCachedJobs.match(action)) {
    return;
  }

  yield put(setDeleteOperation({
    isWorking: true,
  }));

  try {
    // Delete all the job-related stuff from the Idb.
    // Remove this jobId from the cachedJobs list.
    for (let i = 0; i < action.payload.jobIds.length; i++) {
      yield call([IdbApi, IdbApi.deleteCachedJob], action.payload.jobIds[i]);
    }

    yield put(removeCachedJobIds(action.payload));

    const negativeJobIds = action.payload.jobIds.filter(x => x < 0);

    if (negativeJobIds.length) {
      yield put(removeJobsFromJobList({
        jobIds: negativeJobIds,
      }));
    }

    if (!action.payload.isRefreshJob) {
      yield put(refreshAwaitingSync());
      yield put(showSuccessToast(t('Job removed from device.', { count: action.payload.jobIds.length })));
    }
  } catch (err: any) {
    yield put(showErrorToast(getResponseErrorMessage(err)));
  }

  yield put(setDeleteOperation(undefined));
}

function* refreshAwaitingSyncAsync() {
  try {
    const anyAwaiting: boolean = yield call([IdbApi, IdbApi.isAnythingAwaitingSync]);
    yield put(setIsAnythingAwaitingSync(anyAwaiting));
    // Completions
    const cachedJobCompletions: IJobCompletion[] = yield call([IdbApi, IdbApi.getAllCachedJobCompletions]);

    let completedJobIds = cachedJobCompletions
      .filter(x => !x.isOnServer)
      .map(x => x.jobId);

    yield put(setJobsAwaitingCompletions({
      jobIds: completedJobIds,
    }));

    // Cancellations
    const cachedJobCancellations: IJobCancellation[] = yield call([IdbApi, IdbApi.getAllCachedJobCancellations]);

    let cancelledJobIds = cachedJobCancellations
      .filter(x => !x.isOnServer)
      .map(x => x.jobId);

    yield put(setJobsAwaitingCancellations({
      jobIds: cancelledJobIds,
    }));
  } catch (err: any) {
    yield put(showErrorToast(`${t('Failed to refresh data awaiting sync')}: ` + err.message));
  }
}

function* uploadCachedDataAsync() {
  // Show modal spinner.
  yield put(setUploadCacheOperation({
    isWorking: true,
  }));

  let cacheOnlyOK = false;
  try {
    // Perform the "offline-only jobs" sync.
    cacheOnlyOK = yield call(uploadCacheOnlyJobsAsync);
  } catch (err: any) {
    yield put(showErrorToast(getResponseErrorMessage(err)));
  }

  if (!cacheOnlyOK) {
    // Show modal spinner.
    yield put(setUploadCacheOperation({
      isWorking: false,
    }));
    return false;
  }

  // Load all duplicate SWs from idb.
  let allDuplicateSWs: IOfflineJobDuplicateJobSW[] = yield call([IdbApi, IdbApi.getAllDuplicateSWs]);

  // Load step responses from idb.
  let allStepResponses: OfflineStepResponse[] = yield call([IdbApi, IdbApi.getAllCachedJobResponses]);

  // Load job history from idb.
  let allJobLogs: IOfflineSWPJobLog[] = yield call([IdbApi, IdbApi.getAllCachedJobLogs]);

  // Load show all ecls updates for jobs from idb.
  let allShowAllEclsUpdatesJobs: IOfflineJobShowAllECLs[] = yield call([IdbApi, IdbApi.getAllShowAllECLsUpdates]);

  // Load job completions from idb.
  let allJobCompletions: IJobCompletion[] = yield call([IdbApi, IdbApi.getAllCachedJobCompletions]);

  // Load job cancellations from idb.
  let allJobCancellations: IJobCancellation[] = yield call([IdbApi, IdbApi.getAllCachedJobCancellations]);

  // Load all comments from idb.
  let jobCommentsContainingDirty: IIdbStepComments[] = yield call([IdbApi, IdbApi.getCachedUnSynchedStepComments]);

  // Load job Paper Execution from idb database.
  let allJobPaperExecutions: IIdbPaperExecution[] = yield call([IdbApi, IdbApi.getAllCachedJobPaperExecutions]);

  // Load all the paper executions from the idb.
  let allPaperExecutions: IIdbPaperExecution[] = yield call([IdbApi, IdbApi.getAllCachedPaperExecutions]);

  // Load all the sw user feedbacks from the idb.
  let allSWUserFeedbacks: IOfflineSWUserFeedback[] = yield call([IdbApi, IdbApi.getAllCachedSWUserFeedbacks]);

  // Load all the reseted SW from the idb.
  let allResetedSWs: IIdbResetSW[] = yield call([IdbApi, IdbApi.getAllCachedResetedSW]);

  // Load all the reseted SW from the idb.
  let allJobs: ISWPJob[] = yield call([IdbApi, IdbApi.getAllCachedJobs]);

  if (allJobs.length) {
    allJobs = allJobs.filter(x => x.isDirty);
  }

  // If there is nothing to upload, quit early.
  if (!allStepResponses.length
    && !allJobLogs.length
    && !allJobCompletions.length
    && !allJobCancellations.length
    && !jobCommentsContainingDirty.length
    && !allPaperExecutions.length
    && !allSWUserFeedbacks.length
    && !allResetedSWs.length
    && !allShowAllEclsUpdatesJobs.length
    && !allDuplicateSWs.length
    && !allJobs.length) {
    // Show modal spinner.
    yield put(setUploadCacheOperation({
      isWorking: false,
    }));
    return;
  }

  // Get the distinct list of jobIds.
  let jobIds = [
    ...allStepResponses.map(r => r.jobId),
    ...allJobLogs.map(l => l.jobId),
    ...allJobCompletions.map(c => c.jobId),
    ...allJobCancellations.map(c => c.jobId),
    ...jobCommentsContainingDirty.map(c => c.jobId),
    ...allPaperExecutions.map(c => c.jobId),
    ...allJobPaperExecutions.map(c => c.jobId),
    ...allSWUserFeedbacks.map(c => c.jobId),
    ...allResetedSWs.map(c => c.jobId),
    ...allShowAllEclsUpdatesJobs.map(c => c.jobId),
    ...allDuplicateSWs.map(c => c.jobId),
    ...allJobs.map(c => c.id),
  ];

  jobIds = jobIds.filter((jobId, ix) => jobIds.indexOf(jobId) === ix);

  // Show modal spinner.
  yield put(setUploadCacheOperation({
    isWorking: true,
  }));

  let hadAnySyncErrors = false;

  // Handle the set of data for each jobId.
  for (let i = 0; i < jobIds.length; i++) {
    let jobId = jobIds[i];

    // Get this job from the idb (if available).
    const job: ISWPJob | undefined = yield call([IdbApi, IdbApi.getCachedJob], jobId);

    // Handle uploading data for this job.
    try {
      yield call(handleUploadCachedDataForJob,
        allStepResponses
          .filter(r => r.jobId === jobId
            && !r.isOnServer),
        allJobLogs.find(l => l.jobId === jobId),
        allDuplicateSWs.filter(l => l.jobId === jobId),
        allShowAllEclsUpdatesJobs.find(l => l.jobId === jobId),
        allJobCompletions.find(c => c.jobId === jobId),
        allJobCancellations.find(c => c.jobId === jobId),
        jobCommentsContainingDirty.find(c => c.jobId === jobId)
          ?.comments
          .filter(x => !x.isOnServer) || [],
        allJobPaperExecutions.filter(x => x.jobId === jobId),
        allPaperExecutions.filter(x => x.jobId === jobId),
        allSWUserFeedbacks.find(x => x.jobId === jobId)?.feedbacks,
        allResetedSWs.find(x => x.jobId === jobId)?.jobSWIds,
        jobId,
        job !== undefined,
        job);
    } catch (err: any) {
      yield put(showErrorToast(`${t('Failed data sync for job id')} ${jobId}: ${getResponseErrorMessage(err)}`));
      hadAnySyncErrors = true;
    }
  }

  // Refresh the Is Anything Awaiting Sync indicator.
  yield call(refreshAwaitingSyncAsync);

  // Update any cached templates  
  yield call(refreshOfflineTemplatesAsync);

  // Hide modal spinner.
  yield put(setUploadCacheOperation(undefined));
  yield put(hadAnySyncErrors
    ? showInfoToast(t("Data sync completed with failures."))
    : showSuccessToast(t("Your data has been synchronized.")));
  if (allJobCompletions.length) {
    const currentUser: IManageUserUser = yield select((store: RootState) => store.auth.currentUser);
    yield put(launchUserSentimentSurvey({ currentUser: currentUser.email }));
  }
}

export function* handleUploadCachedDataForJob(offlineResponses: OfflineStepResponse[],
  jobLog: IOfflineSWPJobLog | undefined,
  duplicateSWs: IOfflineJobDuplicateJobSW[] | undefined,
  showAllECLsJob: IOfflineJobShowAllECLs | undefined,
  completion: IJobCompletion | undefined,
  cancellation: IJobCancellation | undefined,
  comments: IStepComment[],
  jobPaperExecutions: IIdbPaperExecution[],
  paperExecutions: IIdbPaperExecution[],
  wsUserFeedbacks: ISWUserFeedback[] | undefined,
  resetedJobSWs: number[] | undefined,
  jobId: number,
  isJobInCache: boolean,
  job: ISWPJob | undefined) {

  // Handle attachment uploads.
  yield call(findAndUploadUserAttachments,
    offlineResponses,
    comments,
    jobId,
    isJobInCache);

  let hadDataSyncError = false;

  //duplicateSWs
  let fetchData: DuplicateFetchedData = {
    offlineResponses: [],
    paperExecutions: [],
    comments: [],
    resetedJobSWs: [],
  };

  if (job) {
    if (job.isDirty) {
      try {
        let inprogressJob: IInProgressJob = {
          id: job.id,
          title: job.title,
          jobNumber: job.jobNumber,
          isDemo: job.isDemo,
          jobDocs: job.jobDocs,
          sws: job.sws.map((x: ISWPJobSW): IManageJobSW => ({
            jobSWId: x.jobSWId,
            swId: x.swId,
            title: x.title,
            description: x.description,
            version: x.version,
            listGuid: "",
            sortOrder: x.sortOrder,
          })),
          team: job.team,
          type: job.type,
          country: job.country,
          org: job.org,
          geoUnit: job.geoUnit,
          subBusinessLine: job.subBusinessLine,
          customer: job.customer,
          schedule: job.schedule,
        };

        const updateResult: ISWPUpdateJobResponse = yield call(JobsApi.updateJob, inprogressJob);

        if (updateResult.success) {
          try {
            job.isDirty = false;
            yield call([IdbApi, IdbApi.deleteCachedJobDocs], jobId);
            yield call([IdbApi, IdbApi.updateCachedJob], job);
          } catch (err: any) {
            hadDataSyncError = true;
            yield put(showErrorToast(`${t('Failed to remove sync job data for job id')} ${jobId}: ${getResponseErrorMessage(err)}`));
          }
        }
        else {
          yield put(showErrorToast(`${t('Failed to sync data for job id')} ${job.id}: ${updateResult.message}`));
          return;
        }
      } catch (err: any) {
        hadDataSyncError = true;
        yield put(showErrorToast(`${t('Failed to sync job data for job id')} ${jobId}: ${getResponseErrorMessage(err)}`));
      }
    }
  }

  try {
    fetchData = yield call(handleUploadCachedDuplicateSWs, duplicateSWs, jobId, offlineResponses, paperExecutions, comments, resetedJobSWs);
  } catch (err: any) {
    hadDataSyncError = true;
    yield put(showErrorToast(`${t('Failed to sync duplicate SWs for job id')} ${jobId}: ${getResponseErrorMessage(err)}`));
  }
  if (fetchData.resetedJobSWs && fetchData.resetedJobSWs?.length > 0) {
    resetedJobSWs = fetchData.resetedJobSWs;
  }
  if (fetchData.offlineResponses.length > 0) {
    offlineResponses = fetchData.offlineResponses;
  }
  if (fetchData.paperExecutions.length > 0) {
    paperExecutions = fetchData.paperExecutions;
  }
  if (fetchData.comments.length > 0) {
    comments = fetchData.comments;
  }

  try {
    yield call(handleUploadCachedResetedSWs, resetedJobSWs, jobId);
  } catch (err: any) {
    hadDataSyncError = true;
    yield put(showErrorToast(`${t('Failed to sync Reseted SW for job id')} ${jobId}: ${getResponseErrorMessage(err)}`));
  }

  try {
    yield call(handleUploadCachedResponses, offlineResponses, jobId);
  } catch (err: any) {
    hadDataSyncError = true;
    yield put(showErrorToast(`${t('Failed to sync responses for job id')} ${jobId}: ${getResponseErrorMessage(err)}`));
  }

  // handle job paper execution
  try {
    yield call(handleUploadCachedJobPaperExecutions, jobPaperExecutions, isJobInCache);
  } catch (err: any) {
    hadDataSyncError = true;
    yield put(showErrorToast(`${t('Failed to sync job Paper execution for job id')} ${jobId}: ${getResponseErrorMessage(err)}`));
  }

  try {
    yield call(handleUploadCachedPaperExecutions, paperExecutions, isJobInCache);
  } catch (err: any) {
    hadDataSyncError = true;
    yield put(showErrorToast(`${t('Failed to sync responses for job id')} ${jobId}: ${getResponseErrorMessage(err)}`));
  }

  try {
    yield call(handleUploadCachedComments, comments, jobId, isJobInCache);
  } catch (err: any) {
    hadDataSyncError = true;
    yield put(showErrorToast(`${t('Failed to sync comments for job id')} ${jobId}: ${getResponseErrorMessage(err)}`));
  }

  try {
    yield call(handleUploadCachedJobHistoryLog, jobLog, jobId, isJobInCache);
  } catch (err: any) {
    hadDataSyncError = true;
    yield put(showErrorToast(`${t('Failed to sync history log for job id')} ${jobId}: ${getResponseErrorMessage(err)}`));
  }

  try {
    yield call(handleUploadCachedSWUserFeedbacks, wsUserFeedbacks, jobId);
  } catch (err: any) {
    hadDataSyncError = true;
    yield put(showErrorToast(`${t('Failed to sync SW User Feedbacks for job id')} ${jobId}: ${getResponseErrorMessage(err)}`));
  }

  try {
    yield call(handleUploadCachedShowAllECLsUpdates, showAllECLsJob, jobId);
  } catch (err: any) {
    hadDataSyncError = true;
    yield put(showErrorToast(`${t('Failed to sync Show All ECLs for job id')} ${jobId}: ${getResponseErrorMessage(err)}`));
  }

  if (!hadDataSyncError) {
    let wasCompletionUploaded: boolean = yield call(handleUploadJobCompletion, completion, jobId, isJobInCache);
    let wasCancelUploaded: boolean = yield call(handleUploadJobCancellation, cancellation, jobId, isJobInCache);

    if (wasCompletionUploaded
      || wasCancelUploaded) {
      try {
        yield call(deleteCachedJobsAsync, deleteCachedJobs({
          jobIds: [jobId],
        }));
      } catch (err: any) {
        throw new Error(`${t('Failed to remove job from cache')}(${jobId}): ${getResponseErrorMessage(err)}`);
      }
    }
  }
}

export function* findAndUploadUserAttachments(responses: IStepResponse[],
  comments: IStepComment[],
  jobId: number,
  isJobInCache: boolean) {
  // Find the step response images that aren't yet on the server.
  let responseImages: IUserImageData[] = [];

  for (let rIx = 0; rIx < responses.length; rIx++) {
    let response = responses[rIx];

    if (!response.componentResponses?.length) {
      continue;
    }

    for (let saIx = 0; saIx < response.componentResponses.length; saIx++) {
      let compResp = response.componentResponses[saIx];

      if (compResp.type !== ComponentResponseType.Image
        && compResp.type !== ComponentResponseType.Signature) {
        continue;
      }

      for (let vIx = 0; vIx < compResp.values.length; vIx++) {
        let userImage: IUserImageData | undefined = yield call([IdbApi, IdbApi.getUserImageData], compResp.values[vIx]);

        if (userImage
          && !userImage.isOnServer) {
          responseImages.push(userImage);
        }
      }
    }
  }

  // Find the comment attachments that aren't yet on the server.
  let commentAttachments: IIdbStepCommentAttachment[] = [];

  for (let i = 0; i < comments.length; i++) {
    const changedAtts = comments[i]
      .attachments
      .filter(x => x.pendingAction === StepCommentActions.Add);

    for (let j = 0; j < changedAtts.length; j++) {
      const att = changedAtts[j];

      let attachment: IIdbStepCommentAttachment | undefined = yield call([IdbApi, IdbApi.getStepCommentAttData], att.filename);

      if (!attachment
        || !attachment.data) {
        throw new Error(t('Failed to load step comment attachment {filename} from cache.', { filename: att.filename }));
      }

      commentAttachments.push(attachment);
    }
  }

  if (!responseImages.length
    && !commentAttachments.length) {
    return;
  }

  if (responseImages.length) {
    yield batchCalls(1,
      responseImages.map(x => ({
        image: x,
        isJobInCache,
        mode: ExecutionMode.Offline,
      })),
      uploadUserImage);
  }

  if (commentAttachments.length) {
    yield batchCalls(1,
      commentAttachments.map(x => ({
        attachment: x,
        jobId,
      })),
      uploadCommentAttachment);
  }
}

export function* uploadUserImage(item: {
  image: IUserImageData,
  isJobInCache: boolean,
  mode: ExecutionMode,
}) {
  yield call(ExecutionApi.uploadUserImage,
    item.image.filename,
    item.image.data,
    item.image.jobId);

  // Update user image in IDB as on server.
  yield call([IdbApi, IdbApi.updateUserImagesAreOnServer],
    [item.image.filename],
    true);
}

export function* uploadCommentAttachment(commentAtt: {
  attachment: IIdbStepCommentAttachment,
  jobId: number,
}) {
  yield call(ExecutionApi.uploadCommentAttachment,
    commentAtt.attachment.filename,
    commentAtt.attachment.data,
    commentAtt.jobId);
}

export function* handleUploadCachedResponses(offlineResponses: OfflineStepResponse[],
  jobId: number) {
  // Upload step responses.
  if (offlineResponses?.length) {
    let responses = offlineResponses
      .map((r): IStepResponse => ({
        ...r,
      }));

    let responsesUploadedSuccessfully = false;

    try {
      yield call(ExecutionApi.uploadResponses, jobId, responses, []);
      responsesUploadedSuccessfully = true;
    } catch (err: any) {
      throw new Error(`${t('Failed to upload cached responses')} (${jobId}): ${getResponseErrorMessage(err)}`);
    }

    if (responsesUploadedSuccessfully) {
      // Update the responses to indicate they're on the server.
      try {
        yield call([IdbApi, IdbApi.updateJobResponsesAreOnServer],
          jobId,
          responses.map(r => r.stepId),
          true);
      } catch (err: any) {
        throw new Error(`${t('Failed to update old responses in cache')}(${jobId}): ${getResponseErrorMessage(err)}`);
      }
    }
  }
}

export function* handleUploadCachedDuplicateSWs(duplicateSWs: IOfflineJobDuplicateJobSW[] | undefined
  , jobId: number
  , offlineResponses: OfflineStepResponse[]
  , paperExecutions: IIdbPaperExecution[]
  , comments: IStepComment[]
  , resetedJobSWs: number[] | undefined) {

  let swResponses: IDuplicateSWRequest[] = [];
  if (duplicateSWs === undefined || duplicateSWs === null) {
    return;
  }
  else {
    duplicateSWs.forEach(s => {
      swResponses.push({
        JobSWId: s.jobSWId,
        JobId: jobId,
        SWId: s.swId,
        Version: s.version,
        SortOrder: s.sortOrder,
      });
    })
  }

  let wasUploadSuccessful = false;
  let newSWs: IDuplicateJobSW[] = [];
  try {
    newSWs = yield call(ExecutionApi.duplicateSWsAddInJob, swResponses, jobId);
    wasUploadSuccessful = true;
  }
  catch (err: any) {
    throw new Error(`${t('Failed to upload show all ecls updates for job')} (${jobId}): ${getResponseErrorMessage(err)}`);
  }

  if (wasUploadSuccessful) {

    //Update new JobSWId for Job
    let job: ISWPJob | undefined = yield call([IdbApi, IdbApi.getCachedJob], jobId);
    if (job) {
      job?.sws.map(x => {
        let jobswid = newSWs.find(y => y.OldJobSWId === x.jobSWId)?.JobSWId;
        if (jobswid) {
          x.jobSWId = jobswid;
        }
      });

      try {
        yield call([IdbApi, IdbApi.updateJob], jobId, job);
      } catch (err: any) {
        yield put(showErrorToast(`${t('Failed to update job with duplicate sws')}: ${getResponseErrorMessage(err)}`));
        yield put(setCacheOperation(undefined));
        return;
      }
    }

    //Update jobswid for Step Responses
    offlineResponses.forEach(x => {
      let jobswid = newSWs.find(y => y.OldJobSWId === x.jobSWId)?.JobSWId;
      if (jobswid) {
        x.jobSWId = jobswid;
      }
    });
    try {
      yield call([IdbApi, IdbApi.updateStepResponses], jobId, offlineResponses);
    } catch (err: any) {
      yield put(showErrorToast(`${t('Failed to update job with duplicate sws')}: ${getResponseErrorMessage(err)}`));
      yield put(setCacheOperation(undefined));
      return;
    }

    //Update jobswid for Paper Execs
    paperExecutions.forEach(x => {
      let jobswid = newSWs.find(y => y.OldJobSWId === x.jobSWId)?.JobSWId;
      if (jobswid) {
        x.jobSWId = jobswid;
      }
    });
    try {
      yield call([IdbApi, IdbApi.updatePaperExecution], jobId, paperExecutions);
    } catch (err: any) {
      yield put(showErrorToast(`${t('Failed to update job with duplicate sws')}: ${getResponseErrorMessage(err)}`));
      yield put(setCacheOperation(undefined));
      return;
    }

    //Update jobswid for Step Comments
    comments.forEach(x => {
      let jobswid = newSWs.find(y => y.OldJobSWId === x.jobSWId)?.JobSWId;
      if (jobswid) {
        x.jobSWId = jobswid;
      }
    });
    try {
      yield call([IdbApi, IdbApi.updateComments], jobId, comments);
    } catch (err: any) {
      yield put(showErrorToast(`${t('Failed to update job with duplicate sws')}: ${getResponseErrorMessage(err)}`));
      yield put(setCacheOperation(undefined));
      return;
    }

    //Update new JobSWId for Reset
    resetedJobSWs = resetedJobSWs?.map(x => {
      let jobswid = newSWs.find(y => y.OldJobSWId === x)?.JobSWId;
      return jobswid ?? x;
    });

    //Delete DuplicateSW indexDB table
    try {
      yield call([IdbApi, IdbApi.deleteCachedDuplicateSWsForJob],
        jobId);
    } catch (err: any) {
      yield put(setCacheOperation(undefined));
      throw new Error(`${t('Failed to remove duplicate updates from cache')}(${jobId}): ${getResponseErrorMessage(err)}`);
    }
  }
  return { offlineResponses, paperExecutions, comments, resetedJobSWs };
}

export function* handleUploadCachedJobHistoryLog(jobLog: IOfflineSWPJobLog | undefined,
  jobId: number,
  isJobInCache: boolean) {
  let logItems = jobLog?.log.filter(l => !l.isOnServer);

  if (!jobLog
    || !logItems?.length) {
    return;
  }

  // Upload job log items to server.
  let wasUploadSuccessful = false;

  try {
    yield call(ExecutionApi.uploadJobHistoryLog,
      jobLog.jobId,
      logItems);
    wasUploadSuccessful = true;
  } catch (err: any) {
    throw new Error(`${t('Failed to upload job history')}(${jobId}): ${getResponseErrorMessage(err)}`);
  }

  if (wasUploadSuccessful) {
    if (!isJobInCache) {
      // The job is not in cache. Delete the log from idb.
      try {
        yield call([IdbApi, IdbApi.deleteCachedJobHistoryLog],
          jobId);
      } catch (err: any) {
        throw new Error(`${t('Failed to remove old job history from cache')}(${jobId}): ${getResponseErrorMessage(err)}`);
      }
    } else {
      // Update the log to indicate it's on the server.
      try {
        yield call([IdbApi, IdbApi.updateJobHistoryLogIsOnServer],
          jobId);
      } catch (err: any) {
        throw new Error(`${t('Failed to update job history in cache')}(${jobId}): ${getResponseErrorMessage(err)}`);
      }
    }
  }
}

export function* handleUploadCachedComments(cachedComments: IStepComment[],
  jobId: number,
  isJobInCache: boolean) {
  let comments = cachedComments.filter(x => !x.isOnServer);

  if (!comments.length) {
    return;
  }

  // Upload comments to the server.
  let wasUploadSuccessful = false;

  try {
    yield call(ExecutionApi.uploadComments,
      jobId,
      comments);
    wasUploadSuccessful = true;
  } catch (err: any) {
    throw new Error(`${t('Failed to upload step comments for job')} ${jobId}: ${getResponseErrorMessage(err)}`);
  }

  if (wasUploadSuccessful) {
    if (!isJobInCache) {
      const attFilenames: string[] = [];

      cachedComments
        .forEach(c => {
          c.attachments.forEach(i => attFilenames.push(i.filename));
        });

      // The job is not in cache. Delete the comments from idb.
      try {
        yield call([IdbApi, IdbApi.deleteCachedStepCommentsAndAtts],
          jobId,
          attFilenames);
      } catch (err: any) {
        throw new Error(`${t('Failed to remove old step comments from cache for job')} ${jobId}: ${getResponseErrorMessage(err)}`);
      }
    } else {
      // Update the step comments to indicate they're on the server.
      try {
        yield call([IdbApi, IdbApi.updateStepCommentsIsOnServer],
          jobId);

        // Update all the comments to show they are now on server in redux.
        yield put(setCommentsAreOnServer({
          commentIds: comments.map(r => r.guid),
          isOnServer: true,
        }));
      } catch (err: any) {
        throw new Error(`${t('Failed to update old step comments in cache for job')} ${jobId}: ${getResponseErrorMessage(err)}`);
      }
    }
  }
}

export function* handleUploadJobCompletion(completion: IJobCompletion | undefined,
  jobId: number,
  isJobInCache: boolean) {
  if (!completion
    || completion.isOnServer) {
    return false;
  }

  // Upload job log items to server.
  let wasUploadSuccessful = false;

  try {
    yield call(ExecutionApi.uploadCompletion,
      jobId,
      completion.deviationMessage,
      completion.timestamp,
      completion.userEmail);
    wasUploadSuccessful = true;
  } catch (err: any) {
    throw new Error(`${t('Failed to upload job completion')}(${jobId}): ${getResponseErrorMessage(err)}`);
  }

  if (wasUploadSuccessful) {
    if (!isJobInCache) {
      // The job is not in cache. Delete the log from idb.
      try {
        yield call([IdbApi, IdbApi.deleteCachedJobCompletion],
          jobId);
      } catch (err: any) {
        throw new Error(`${t('Failed to remove job completion from cache')}(${jobId}): ${getResponseErrorMessage(err)}`);
      }
    } else {
      // Update the log to indicate it's on the server.
      try {
        yield call([IdbApi, IdbApi.updateJobCompletionIsOnServer],
          jobId,
          true);
      } catch (err: any) {
        throw new Error(`${t('Failed to update job completion in cache')}(${jobId}): ${getResponseErrorMessage(err)}`);
      }
    }
    return true;
  }
  return false;
}

export function* handleUploadJobCancellation(cancellation: IJobCancellation | undefined,
  jobId: number,
  isJobInCache: boolean) {
  if (!cancellation
    || cancellation.isOnServer) {
    return false;
  }

  // Upload job log items to server.
  let wasUploadSuccessful = false;

  try {
    yield call(ExecutionApi.uploadCancellation,
      jobId,
      cancellation.timestamp,
      cancellation.userEmail);
    wasUploadSuccessful = true;
  } catch (err: any) {
    throw new Error(`${t('Failed to upload job cancellation')}(${jobId}): ${getResponseErrorMessage(err)}`);
  }

  if (wasUploadSuccessful) {
    if (!isJobInCache) {
      // The job is not in cache. Delete the log from idb.
      try {
        yield call([IdbApi, IdbApi.deleteCachedJobCancellation],
          jobId);
      } catch (err: any) {
        throw new Error(`${t('Failed to remove job cancellation from cache')}(${jobId}): ${getResponseErrorMessage(err)}`);
      }
    } else {
      // Update the log to indicate it's on the server.
      try {
        yield call([IdbApi, IdbApi.updateJobCancellationIsOnServer],
          jobId,
          true);
      } catch (err: any) {
        throw new Error(`${t('Failed to update job cancellation in cache')}(${jobId}): ${getResponseErrorMessage(err)}`);
      }
    }
    return true;
  }
  return false;
}

export function* handleUploadCachedJobPaperExecutions(allJobPaperExecutions: IIdbPaperExecution[],
  isJobInCache: boolean) {
  let jobPaperExecs = allJobPaperExecutions.filter(x => !x.isOnServer);

  if (!jobPaperExecs.length) {
    return;
  }

  const jobId = allJobPaperExecutions[0].jobId;
  let wasUploadSuccessful = false;

  try {
    yield batchCalls(2,
      jobPaperExecs.map(x => ({
        jobPaperExec: x,
        isJobInCache,
      })),
      handleUploadCachedJobPaperExecution);
    wasUploadSuccessful = true;
  } catch (err: any) {
    throw new Error(`${t('Failed to upload Job paper executions for job')} ${jobId}: ${getResponseErrorMessage(err)}`);
  }

  if (wasUploadSuccessful) {
    if (!isJobInCache) {
      const attFilenames: string[] =
        allJobPaperExecutions
          .map(x => x.imageFilename);

      // The job is not in cache. Delete the paper executions and images.
      try {
        yield call([IdbApi, IdbApi.deleteCachedJobPaperExecution],
          jobId);

        yield call([IdbApi, IdbApi.deleteCachedUserImages],
          attFilenames);
      } catch (err: any) {
        throw new Error(`${t('Failed to remove old Job paper execution images from cache for job')} ${jobId}: ${getResponseErrorMessage(err)}`);
      }
    } else {
      // Update the step comments to indicate they're on the server.
      try {
        yield call([IdbApi, IdbApi.updateJobPaperExecutionIsOnServer],
          jobId);

        // Update all the paper executions to show they are now on server in redux.
        yield put(setJobPaperExecutionIsOnServer({
          isOnServer: true,
        }));
      } catch (err: any) {
        throw new Error(`${t('Failed to update old Job paper executions in cache for job')} ${jobId}: ${getResponseErrorMessage(err)}`);
      }
    }
  }
}

export function* handleUploadCachedPaperExecutions(allPaperExecutions: IIdbPaperExecution[],
  isJobInCache: boolean) {
  let paperExecs = allPaperExecutions.filter(x => !x.isOnServer);

  if (!paperExecs.length) {
    return;
  }

  const jobId = allPaperExecutions[0].jobId;

  // Upload comments to the server.
  let wasUploadSuccessful = false;

  try {
    yield batchCalls(2,
      paperExecs.map(x => ({
        paperExec: x,
        isJobInCache,
      })),
      handleUploadCachedPaperExecution);
    wasUploadSuccessful = true;
  } catch (err: any) {
    throw new Error(`${t('Failed to upload paper executions for job')} ${jobId}: ${getResponseErrorMessage(err)}`);
  }

  if (wasUploadSuccessful) {
    if (!isJobInCache) {
      const attFilenames: string[] =
        allPaperExecutions
          .map(x => x.imageFilename);

      // The job is not in cache. Delete the paper executions and images.
      try {

        yield call([IdbApi, IdbApi.deleteCachedPaperExecutions],
          jobId);

        yield call([IdbApi, IdbApi.deleteCachedUserImages],
          attFilenames);
      } catch (err: any) {
        throw new Error(`${t('Failed to remove old paper execution images from cache for job')} ${jobId}: ${getResponseErrorMessage(err)}`);
      }
    } else {
      // Update the step comments to indicate they're on the server.
      try {
        yield call([IdbApi, IdbApi.updatePaperExecutionsIsOnServer],
          jobId);

        // Update all the paper executions to show they are now on server in redux.
        yield put(setPaperExecutionsAreOnServer({
          jobSWIds: paperExecs.map(x => x.jobSWId),
          isOnServer: true,
        }));
      } catch (err: any) {
        throw new Error(`${t('Failed to update old paper executions in cache for job')} ${jobId}: ${getResponseErrorMessage(err)}`);
      }
    }
  }
}

export function* handleUploadCachedJobPaperExecution(args: {
  jobPaperExec: IIdbJobPaperExecution,
  isJobInCache: boolean
}) {
  // Look up the image in the idb.
  const img: IUserImageData | undefined = yield call([IdbApi, IdbApi.getUserImageData], args.jobPaperExec.imageFilename);

  if (!img) {
    throw new Error(`${t('Job Paper execution image not found')}: ${args.jobPaperExec.imageFilename}`);
  }

  // Send everything to the server.
  const jobPaperExecution: IJobPaperExecution = {
    ...args.jobPaperExec,
    isDirty: false,
  };

  yield call(ExecutionApi.uploadJobPaperExecution,
    jobPaperExecution,
    img.data,
    args.jobPaperExec.jobId);
}

export function* handleUploadCachedPaperExecution(args: {
  paperExec: IIdbPaperExecution,
  isJobInCache: boolean
}) {
  // Look up the image in the idb.
  const img: IUserImageData | undefined = yield call([IdbApi, IdbApi.getUserImageData], args.paperExec.imageFilename);

  if (!img) {
    throw new Error(`${t('Paper execution image not found')}: ${args.paperExec.imageFilename}`);
  }

  // Send everything to the server.
  const paperExecution: IPaperExecution = {
    ...args.paperExec,
    isDirty: false,
  };

  yield call(ExecutionApi.uploadPaperExecution,
    paperExecution,
    img.data,
    args.paperExec.jobId);
}

function* uploadCacheOnlyJobsAsync() {
  let offlineOnlyJobs: ISWPJob[] = yield call([IdbApi, IdbApi.getCachedJobs], true);

  if (!offlineOnlyJobs.length) {
    return true;
  }

  let redirectToExecutionJobId: number | undefined = undefined;

  //  Detect if the user has an offline-only job currently loaded in redux and, if so,
  // whether it has any unsaved changes. The sync operation is destructive for the
  // offline data since Ids will change.
  let executionData: IExecutionState = yield select((store: RootState) => store.execution);

  if (executionData.job
    && executionData.job.id < 0
    && (executionData.stepComments.some(x => x.isDirty)
      || executionData.stepResponses.some(x => x.isDirty)
      || executionData.paperExecutions.some(x => x.isDirty)
      || executionData.log.some(x => x.isDirty))) {

    yield put(showErrorToast("You are currently executing an offline-only job with unsaved changes. Save all data before syncing to the server."));
    return false;
  }

  // Send job to CreateJob endpoint, receive new Id, update job and all supporting items with
  // the new Id. Continue.
  for (var i = 0; i < offlineOnlyJobs.length; i++) {
    let offlineJob = offlineOnlyJobs[i];

    const oldJobSWs = offlineJob.sws.map((x, ix): IManageJobSW => ({
      jobSWId: x.jobSWId,
      swId: x.swId,
      title: x.title,
      description: x.description,
      version: x.version,
      listGuid: "",
      sortOrder: ix + 1,
    }));

    if (oldJobSWs.some(x => x.jobSWId === undefined)) {
      throw new Error(t('One or more SWs inside offline job {id} has an undefined jobSWId.', { id: offlineJob.id }));
    }

    const oldJobSWIdsWithSort = oldJobSWs.map(x => ({
      jobSWId: x.jobSWId || 0,
      sortOrder: x.sortOrder,
    }))

    oldJobSWs.forEach(x => x.jobSWId = 0);

    // Create the job and re-align all sort orders.
    let newJob: IInProgressJob = {
      id: null,
      title: offlineJob.title,
      jobNumber: offlineJob.jobNumber,
      isDemo: offlineJob.isDemo,
      jobDocs: [],
      sws: oldJobSWs,
      team: offlineJob.team,
      type: offlineJob.type,
      country: offlineJob.country,
      org: offlineJob.org,
      geoUnit: offlineJob.geoUnit,
      customer: offlineJob.customer,
      subBusinessLine: offlineJob.subBusinessLine,
    };

    try {
      const createResponse: ISWPJobCreationResponse = yield call(JobsApi.createJob, newJob);

      if (!createResponse.success) {
        // Skip this job.
        yield put(showErrorToast(`${t('The server rejected the creation of your offline job')} '${offlineJob.title}' (${offlineJob.id}).`
          + ` ${t('Skipping this job. Reason')}: ${createResponse.message}`));
        continue;
      }

      // Parse out the JobSWIds from the response and update everything in Idb.
      const oldNewJobSWIdPairs = createResponse.jobSWs.map((newJobSW): IOldNewJobSWIdMapping => {
        const oldSW = oldJobSWIdsWithSort.find(x => x.sortOrder === newJobSW.sortOrder);

        if (!oldSW) {
          throw new Error(t('Job ({id}) was created on server ({jobId}) but SWs are misaligned. Unable to update local data!', { id: offlineJob.id, jobId: createResponse.jobId }));
        }

        return {
          oldJobSWId: oldSW.jobSWId,
          newJobSWId: newJobSW.jobSWId,
        };
      });

      // Update all IDB stuff for this job to use the new Id instead.
      try {
        yield call([IdbApi, IdbApi.migrateJobIds],
          offlineJob.id,
          createResponse.jobId,
          oldNewJobSWIdPairs);
      } catch (err: any) {
        throw new Error(`${t('Failed to update Idb Ids for job')} ${offlineJob.id}: ${getResponseErrorMessage(err)}`);
      }

      if (executionData.job
        && executionData.job.id === offlineJob.id) {
        redirectToExecutionJobId = createResponse.jobId;
      }
    } catch (err: any) {
      throw new Error(`${t('Failed to sync job creation to server')}: ${getResponseErrorMessage(err)}`);
    }

    // Update the list of offline jobs in redux to include this new one.
    const cachedJobIds: number[] = yield call([IdbApi, IdbApi.getCachedJobIds]);
    yield put(setCachedJobIds({ jobIds: cachedJobIds }));

    if (redirectToExecutionJobId !== undefined) {
      // If the user had one of these offline jobs in redux, redirect them to the new page.
      history.push(Routes.ExecuteOfflineJob.replace(":id", redirectToExecutionJobId.toString()));
    }
  }

  return true;
}

function* refreshOfflineTemplatesAsync() {
  let offlineTemplates: ISWPJob[] = yield call([IdbApi, IdbApi.getCachedTemplates]);

  if (!offlineTemplates.length) {
    return;
  }

  for (let i = 0; i < offlineTemplates.length; i++) {
    const country = offlineTemplates[i].country;
    const geoUnit = offlineTemplates[i].geoUnit;
    const subBusinessLine = offlineTemplates[i].subBusinessLine;
    const team = offlineTemplates[i].team;

    // Re-cache the job.
    yield call(cacheTemplateAsync, cacheTemplateOffline({
      jobId: offlineTemplates[i].id,
      team,
      country,
      geoUnit,
      subBusinessLine,
    }));
  }
}

export function* handleUploadCachedSWUserFeedbacks(feedbacks: ISWUserFeedback[] | undefined, jobId: number) {
  if (feedbacks === undefined || feedbacks === null) {
    return;
  }

  if (!feedbacks.length) {
    return;
  }

  let wasUploadSuccessful = false;

  try {
    yield call(ExecutionApi.saveOnlineSWUserFeedback, feedbacks);
    wasUploadSuccessful = true;
  } catch (err: any) {
    throw new Error(`${t('Failed to upload sw user feedback for job')} (${jobId}): ${getResponseErrorMessage(err)}`);
  }

  if (wasUploadSuccessful) {
    try {
      yield call([IdbApi, IdbApi.deleteCachedSWUserFeedback],
        jobId);
    } catch (err: any) {
      throw new Error(`${t('Failed to remove sw user feedback from cache')}(${jobId}): ${getResponseErrorMessage(err)}`);
    }
  }
}

export function* handleUploadCachedShowAllECLsUpdates(eclUpdates: IOfflineJobShowAllECLs | undefined, jobId: number) {
  if (eclUpdates === undefined || eclUpdates === null) {
    return;
  }

  let wasUploadSuccessful = false;

  try {
    yield call(ExecutionApi.saveShowAllECLs, eclUpdates.jobId, eclUpdates.showAllECLs);

    wasUploadSuccessful = true;
  } catch (err: any) {
    throw new Error(`${t('Failed to upload show all ecls updates for job')} (${jobId}): ${getResponseErrorMessage(err)}`);
  }

  if (wasUploadSuccessful) {
    try {
      yield call([IdbApi, IdbApi.deleteCachedShowAllECLsForJob],
        jobId);
    } catch (err: any) {
      throw new Error(`${t('Failed to remove show all ecls updates from cache')}(${jobId}): ${getResponseErrorMessage(err)}`);
    }
  }
}

export function* handleUploadCachedResetedSWs(jobSWIds: number[] | undefined, jobId: number) {
  if (jobSWIds === undefined || jobSWIds === null) {
    return;
  }

  if (!jobSWIds.length) {
    return;
  }

  let wasUploadSuccessful = false;

  try {
    yield call(ExecutionApi.resetOnlineSWStepResponses, jobSWIds);
    wasUploadSuccessful = true;
  } catch (err: any) {
    throw new Error(`${t('Failed to upload reseted SWs for job')} (${jobId}): ${getResponseErrorMessage(err)}`);
  }

  if (wasUploadSuccessful) {
    try {
      yield call([IdbApi, IdbApi.deleteCachedResetedSWs],
        jobId);
    } catch (err: any) {
      throw new Error(`${t('Failed to remove reseted SWs from cache')}(${jobId}): ${getResponseErrorMessage(err)}`);
    }
  }
}

function* saveJobOfflineAsync(action: Action) {
  if (!saveJobOffline.match(action)) {
    return;
  }

  yield put(setCacheOperation({
    isWorking: true,
  }));

  try {
    let inDBJob: ISWPJob = yield call([IdbApi, IdbApi.getCachedJob], action.payload.jobId);
    let inMeoryJob: IInProgressJob = yield select((store: RootState) => store.manageJob.job);

    if (inDBJob && inMeoryJob) {
      if (inMeoryJob.sws.length <= 0) {
        yield put(showErrorToast('Job must have at least one SW'));
        yield put(setCacheOperation(undefined));
        return;
      }

      if (inMeoryJob.team.length <= 0) {
        yield put(showErrorToast('Job must have at least team member'));
        yield put(setCacheOperation(undefined));
        return;
      }

      yield call([IdbApi, IdbApi.updateJobOffline], inDBJob, inMeoryJob);
    }

    yield put(setCacheOperation({
      isWorking: false,
    }));
    yield put(showSuccessToast(t("Job updated successfully.")));
    yield put(setIsAnythingAwaitingSync(true));
    history.push(Routes.EditJob.replace(":id", action.payload.jobId.toString()));
  } catch (err: any) {
    yield put(showErrorToast(`${t('Failed to save job offline.')}: ${getResponseErrorMessage(err)}`));
    yield put(setCacheOperation(undefined));
    return;
  }
}