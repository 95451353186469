import React from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import 'react-circular-progressbar/dist/styles.css';
import './CircularProgress.scoped.scss';

interface ICircularProgressProps {
    completedPercentage: number,
}

const CircularProgress: React.FC<ICircularProgressProps> = ({ completedPercentage }) => {

    function defineCompletionColor(percentage: number): string {
        if (percentage <= 30) {
            return "#A9A9A9";
        } else if (percentage >= 100) {
            return "#009688";
        } else {
            return "#ff9800";
        }
    }

    return (
        <div className="circularContainer"
            title="Job Completion Percentage">
            <CircularProgressbar
                value={completedPercentage}
                text={`${completedPercentage}%`}
                styles={{
                    // Customize the root svg element
                    root: {},
                    // Customize the path, i.e. the "completed progress"
                    path: {
                        // Path color
                        stroke: defineCompletionColor(completedPercentage),
                        // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                        strokeLinecap: 'round',
                        // Customize transition animation
                        transition: 'stroke-dashoffset 0.5s ease 0s',
                    },
                    // Customize the circle behind the path, i.e. the "total progress"
                    trail: {
                        // Trail color
                        stroke: '#cdd5dc',
                        // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                        strokeLinecap: 'round',
                    },
                    // Customize the text
                    text: {
                        // Text color
                        fill: defineCompletionColor(completedPercentage),
                        // Text size
                        fontSize: '20px',
                    },
                    // Customize background - only used when the `background` prop is true
                    background: {
                        fill: '#3e98c7',
                    },
                }}
            />
        </div>
    );
}

export default CircularProgress;