import Banner, { BannerType } from "components/common/Banner";
import React from "react";
import { useTranslation } from "react-i18next";
import useSelector from "store/useSelector";

const ExecutorBanner: React.FC = () => {
  const {
    executor,
  } = useSelector(store => store.execution);
  const { t } = useTranslation('executor');

  if (!executor) {
    return null;
  }

  return (
    <Banner
      type={BannerType.Warn}
    >
      {t('You are executing this job as')} <b>{executor.email}</b>.
    </Banner>
  );
};

export default ExecutorBanner;