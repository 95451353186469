import React from "react";
import { Roles, IManageUserUser } from "interfaces/user/UserInterfaces";
import useSelector from "store/useSelector";
import Error403 from "components/common/Error403";

export enum FilterType {
  Any,
  All,
}

export class AuthFilter {
  public emails: string[] = [];
  public roles: Roles[] = [];
  public roleFilterType: FilterType = FilterType.Any;
  public lastMatchResult?: boolean;
  public lastMatchForbiddenReason?: string;

  private constructor() {
  }

  static isLoggedIn() {
    return new AuthFilter();
  }

  hasEmailIn(emails: string[]) {
    this.emails = emails.map(x => x.toLowerCase());
    return this;
  }

  hasEmail(email: string) {
    this.emails = [email.toLowerCase()];
    return true;
  }

  hasAnyRoleIn(roles: Roles[]) {
    this.roles = roles;
    this.roleFilterType = FilterType.Any;
    return this;
  }

  hasAllRolesIn(roles: Roles[]) {
    this.roles = roles;
    this.roleFilterType = FilterType.All;
    return this;
  }

  hasRole(role: Roles) {
    this.roles = [role];
    this.roleFilterType = FilterType.Any;
    return this;
  }

  isMatch(user: IManageUserUser): boolean {
    const {
      email,
      roles,
    } = user;

    if (!email) {
      // User is not logged in.
      this.lastMatchResult = false;
      this.lastMatchForbiddenReason = "User is not authenticated";
      return false;
    }

    if (this.emails.length) {
      if (this
        .emails
        .indexOf(email.toLowerCase()) === -1) {
        this.lastMatchResult = false;
        this.lastMatchForbiddenReason = "Users email does not match any specified emails.";
        return false;
      }
    }

    if (!this.roles.length) {
      // There are no specific filters.
      // That means it's a match.
      this.lastMatchResult = true;
      this.lastMatchForbiddenReason = undefined;
      return true;
    }

    if (this.roles.length) {
      if (this.roleFilterType === FilterType.Any
        && !roles.find(x => this.roles.indexOf(x) > -1)) {
        this.lastMatchResult = false;
        this.lastMatchForbiddenReason = "User does not match any specified roles.";
        return false;
      } else if (this.roleFilterType === FilterType.All
        && this.roles.find(x => roles.indexOf(x) === -1)) {
        this.lastMatchResult = false;
        this.lastMatchForbiddenReason = "User does not match all specified roles.";
        return false;
      }
    }

    this.lastMatchResult = true;
    this.lastMatchForbiddenReason = undefined;
    return true;
  }
}

interface IAuthCheckProps {
  authFilter: AuthFilter,
  or?: AuthFilter[],
  show403OnFail?: boolean,
  children: any,
}

export const AuthCheck: React.FC<IAuthCheckProps> = ({
  authFilter,
  or,
  children,
  show403OnFail = false,
}) => {
  const user = useSelector(store => store.auth.currentUser);

  if (!authFilter.isMatch(user)) {
    return show403OnFail
      ? <Error403 />
      : null;    
  }

  return (
    <>
      {children}
    </>
  );
}