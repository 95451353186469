import IdbApi from "apis/idb/IdbApi";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { showErrorToast } from "store/toast/toastActions";
import { isFilenameImage } from "utilities/fileUtilities";
import "./AttachmentThumbnail.scoped.scss";
import docIcon from "media/icons/dls/document.svg";
import { useTranslation } from "react-i18next";

interface IAttachmentThumbnailProps {
  filename: string,
  onClick(): void,
}

const AttachmentThumbnail: React.FC<IAttachmentThumbnailProps> = ({
  filename,
  onClick,
}) => {
  const [imgData, setImgData] = useState("");
  const dispatch = useDispatch();
  const fileIsImage = isFilenameImage(filename);
  const { t } = useTranslation('comments');

  useEffect(() => {
    let aborted = false;

    const imageFetch = async () => {
      try {
        const img = await IdbApi.getStepCommentAttData(filename);
        if (!aborted) {
          setImgData(img?.data || "");
        }
      } catch (err: any) {
        if (!aborted) {
          dispatch(showErrorToast(t(`Failed to load image thumbnail {message}`, {message: err})));
        }
      }
    }

    if (fileIsImage) {
      imageFetch();
    }

    return () => {
      aborted = true;
    };
  }, [filename, fileIsImage, setImgData, dispatch, t]);

  let backgroundImage = "none";

  if (fileIsImage
    && imgData) {
    backgroundImage = `url(${imgData})`;
  } else if (!fileIsImage) {
    backgroundImage = `url(${docIcon})`;
  }

  return (
    <div
      onClick={onClick}
      className="thumbnail"
      title={filename}
      style={{
        backgroundImage,
      }}
    ></div>
  );
};

export default AttachmentThumbnail;