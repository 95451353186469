import React, { useState, useEffect } from "react";
import "./Execution.scoped.scss";
import ExecutionJobHeader from "./ExecutionJobHeader";
import CompleteJobButton from "./completion/CompleteJobButton";
import useSelector from "store/useSelector";
import CompleteJobModal from "./completion/CompleteJobModal";
import CantCompleteJobModal from "./completion/CantCompleteJobModal";
import JobCompletedModal from "./completion/JobCompletedModal";
import { SWPJobStatus } from "interfaces/jobs/JobInterfaces";
import ExecutionSWList from "./sw/ExecutionSWList";
import JobHistoryList from "./jobHistory/JobHistoryList";
import { Link, Prompt } from "react-router-dom";
import { useDispatch } from "react-redux";
import { resetExecution } from "store/execution/executionActions";
import JobRefDocsList from "./refDocs/JobRefDocsList";
import FlowLayout from "components/layout/FlowLayout";
import Modal from "components/common/Modal";
import { unCacheCancelJob } from "store/myJobs/myJobsActions";
import SaveDataButton from "./SaveDataButton";
import { useTranslation } from "react-i18next";
import ModalSpinner from "components/common/ModalSpinner";
import PaperExecuteModal from "./sw/PaperExecuteModal";
import JobDocs from "./jobDocs/JobDocs";

export enum ExecutionViews {
  Steps = "Steps",
  RefDocs = "RefDocs",
  JobDocs = "JobDocs",
  History = "History",
}

const Execution: React.FC = () => {
  const dispatch = useDispatch();

  const {
    execution: {
      isCompleteJobModalVisible,
      isJobCompletedModalVisible,
      hasCantCompleteWithDirtyResponsesError,
      job,
      stepResponses,
      log,
      stepComments,
      paperExecutions,
      isDeviationReasonRequired,
      isJobCompleting,
      paperExecuteModalData
    },
    offline: {
      jobsAwaitingCancellation,
    }
  } = useSelector(store => store);

  const [currView, setCurrView] = useState<ExecutionViews>(ExecutionViews.Steps);

  const { t } = useTranslation('execution');

  const hasAnyDirty = stepResponses.some(r => r.isDirty)
    || log.some(r => r.isDirty)
    || stepComments.some(r => r.isDirty)
    || paperExecutions.some(x => x.isDirty)
    || job?.sws.some(x => x.jobSWId < 0 && x.isDirty);

  const isCancelledInCache = job
    && jobsAwaitingCancellation.indexOf(job?.id) > -1;

  const unCancelJob = () => {
    if (!job) {
      return;
    }

    dispatch(
      unCacheCancelJob({
        jobId: job?.id,
      }));
  }

  useEffect(() => {
    return () => {
      dispatch(resetExecution());
    };
  }, [dispatch]);

  useEffect(() => {
    const onUnload = (e: Event) => {
      if (hasAnyDirty) {
        e.preventDefault();
        e.returnValue = true;
      }
    };

    window.addEventListener("beforeunload", onUnload);

    return () => {
      window.removeEventListener("beforeunload", onUnload);
    };
  }, [hasAnyDirty]);

  let modal: JSX.Element | undefined;

  if (hasCantCompleteWithDirtyResponsesError) {
    modal = <CantCompleteJobModal />;
  } else if (isCompleteJobModalVisible &&
    isDeviationReasonRequired) {
    modal = <CompleteJobModal />;
  } else if (isJobCompletedModalVisible
    && job) {
    modal = (
      <JobCompletedModal
        jobId={job?.id}
      />
    );
  }

  let mainComponent: JSX.Element | undefined;

  if (currView === ExecutionViews.Steps) {
    mainComponent = <ExecutionSWList />;
  } else if (currView === ExecutionViews.RefDocs) {
    mainComponent = (
      <JobRefDocsList
        SWs={job?.sws || []}
      />
    );
  } else if (currView === ExecutionViews.History) {
    mainComponent = <JobHistoryList />;
  } else if (currView === ExecutionViews.JobDocs) {
    mainComponent = <JobDocs />
  }

  return (
    <>
      <FlowLayout
        header={
          <ExecutionJobHeader
            currentView={currView}
            setCurrView={(view: ExecutionViews) => setCurrView(view)}
          />
        }
        footer={job?.status !== SWPJobStatus.Completed
          ? (
            <div className="execution-footer">
              <SaveDataButton />
              <CompleteJobButton />
            </div>
          )
          : undefined
        }
      >
        {isJobCompleting &&
          <ModalSpinner />
        }
        {mainComponent}
        <Prompt
          when={hasAnyDirty}
          message={t(`You have unsaved execution data. Leaving this page`)
            + t(` will result in the loss of that data. Press OK to abandon`)
            + t(` your unsaved data or press Cancel to stay on this page.`)
          }
        />
      </FlowLayout>
      {modal}
      {isCancelledInCache &&
        <Modal
          header={t("Cancelled Job")}
          isOpen={true}
          controls={
            <>
              <button
                className="secondary-button"
                onClick={() => unCancelJob()}
              >
                {t('Un-Cancel Job')}
              </button>
              <Link
                to="/jobs/mine"
              >
                <button
                  className="primary-button"
                >
                  {t('My Jobs')}
                </button>
              </Link>
            </>
          }
        >
          {t('This job is pending cancellation and cannot be executed.')}
        </Modal>
      }
      {paperExecuteModalData.isVisible &&
        <PaperExecuteModal
          {...paperExecuteModalData}
        />
      }
    </>
  );
}

export default Execution;