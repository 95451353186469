import React, { useState } from "react";
import "./JobAttributesForm.scoped.scss";
import useSelector from "store/useSelector";
import { useDispatch } from "react-redux";
import { setTitle, setNumber, setIsDemo, setJobType, setOrg, setCountry, setGeoUnit, setSchedule, setCustomer, setSubBusinessLine } from "store/manageJob/manageJobActions";
import { IJobSchedule, SWPJobType } from "interfaces/jobs/JobInterfaces";
import ItemPicker from "components/common/itemPicker/ItemPicker";
import { IMasterDataItem } from "interfaces/masterData/masterDataInterfaces";
import { loadCountries, loadGeoUnits, loadOrganizations, loadSubBusinessLines, searchCustomers } from "store/masterData/masterDataActions";
import ScheduleEditorModal from "../scheduling/ScheduleEditorModal";
import { scheduleToString } from "../scheduling/scheduleUtils";
import { useTranslation } from "react-i18next";

const JobAttributesForm: React.FC = () => {
  const {
    id,
    jobNumber,
    title,
    isDemo,
    type,
    org,
    country,
    geoUnit,
    customer,
    subBusinessLine,
    schedule,
  } = useSelector(state => state.manageJob.job);

  const {
    countries,
    organizations,
    geoUnits,
    customers,
    subBusinessLines
  } = useSelector(state => state.masterData);

  const dispatch = useDispatch();  const [isScheduleEditorVisible, setIsScheduleEditorVisible] = useState(false);
  const { t } = useTranslation('createJob');
  const isEdit = id !== null;

  return (
    <>
      <div>
        <label className="mandatory">{t('Job Title')}</label>
        <input
          placeholder={t('Job Title')}
          type="text"
          value={title}
          onChange={e => dispatch(setTitle(e.target.value))}
          autoFocus
        />
      </div>
      <div>
        <label>{t('Job Id')}</label>
        <input
          placeholder={t('Job Id')}
          type="text"
          value={jobNumber}
          onChange={e => dispatch(setNumber(e.target.value))}
        />
      </div>
      <div
        className="master-data"
      >
        <div>
          <ItemPicker
            isMandatory={true}
            label={t("GeoUnit")}
            selectedItems={geoUnit
              ? [geoUnit]
              : []
            }
            onAddItem={(item: IMasterDataItem) => dispatch(setGeoUnit(item))}
            onRemoveItem={() => dispatch(setGeoUnit(undefined))}
            loadItems={() => dispatch(loadGeoUnits({ includeAll: true }))}
            masterDataSection={geoUnits}
            loadOnStart={true}
            itemFormatter={(item: IMasterDataItem) => item.value}
          />
        </div>
        <div>
          <ItemPicker
            isMandatory={true}
            label={t("Country")}
            selectedItems={country
              ? [country]
              : []
            }
            onAddItem={(item: IMasterDataItem) => dispatch(setCountry(item))}
            onRemoveItem={() => dispatch(setCountry(undefined))}
            loadItems={() => dispatch(loadCountries({ includeAll: true }))}
            masterDataSection={countries}
            loadOnStart={true}
            itemFormatter={(item: IMasterDataItem) => item.value}
          />
        </div>
      </div>
      <div
        className="master-data"
      >
        <div>
          <ItemPicker
            isMandatory
            label={t("Organization")}
            selectedItems={org
              ? [org]
              : []
            }
            onAddItem={(item: IMasterDataItem) => dispatch(setOrg(item))}
            onRemoveItem={() => dispatch(setOrg(undefined))}
            loadItems={() => dispatch(loadOrganizations({ includeAll: true }))}
            masterDataSection={organizations}
            loadOnStart={true}
            itemFormatter={(item: IMasterDataItem) => `${item.code} - ${item.value}`}
          />
        </div>
        <div>
          <ItemPicker
            isMandatory={false}
            isAsyncSearch={true}
            label={t("Customer")}
            selectedItems={customer
              ? [customer]
              : []
            }
            allowMultiple={false}
            onAddItem={(item: IMasterDataItem) => dispatch(setCustomer(item))}
            onRemoveItem={() => dispatch(setCustomer(undefined))}
            loadItems={(searchTerm: string) => dispatch(searchCustomers({ searchTerm: searchTerm, includeAll: true }))}
            masterDataSection={customers}
            itemFormatter={(item: IMasterDataItem) => item.value}
          />
        </div>
      </div>
      <div
        className="master-data"
      >
        <div>
          <ItemPicker
            isMandatory
            label={t("SubBusinessLine")}
            selectedItems={subBusinessLine
              ? [subBusinessLine]
              : []
            }
            onAddItem={(item: IMasterDataItem) => dispatch(setSubBusinessLine(item))}
            onRemoveItem={() => dispatch(setSubBusinessLine(undefined))}
            loadItems={() => dispatch(loadSubBusinessLines({ includeAll: true }))}
            masterDataSection={subBusinessLines}
            loadOnStart={true}
            itemFormatter={(item: IMasterDataItem) => `${item.code} - ${item.value}`}
          />
        </div>
        <div>

        </div>
      </div>
      <div>
        <label
          className={`check-label ${isEdit ? "disabled" : ""} mandatory`}
        >
          {t('Job type')}
        </label>
        <select
          disabled={isEdit}
          value={type}
          onChange={(e) => {
            dispatch(setJobType(e.target.value as SWPJobType));
          }}
        >
          <option
            value={SWPJobType.Standard}
          >
            {t('Standard')}
          </option>
          <option
            value={SWPJobType.Template}
          >
            {t('Template')}
          </option>
          <option
            value={SWPJobType.Recurring}
          >
            {t('Recurring')}
          </option>
        </select>
      </div>
      <div>
        <label className="check-label">
          <input
            type="checkbox"
            checked={isDemo}
            onChange={e => dispatch(setIsDemo(e.target.checked))}
          />
          {t('Job is for demo purposes only')}
        </label>
      </div>
      {type === SWPJobType.Recurring &&
        <>
          <fieldset>
            <legend>{t('Recurring Schedule')}</legend>
            <p>
              {schedule === undefined
                ? t("This job does not yet have a recurring schedule.")
                : scheduleToString(schedule)
              }
            </p>
            <button
              className="primary-button"
              onClick={() => setIsScheduleEditorVisible(true)}
            >
              {schedule === undefined
                ? t("Create Recurring Schedule")
                : t("Edit Recurring Schedule")
              }
            </button>
          </fieldset>
          {
            isScheduleEditorVisible &&
            <ScheduleEditorModal
              schedule={schedule}
              onClose={() => setIsScheduleEditorVisible(false)}
              onDelete={() => dispatch(setSchedule(undefined))}
              onSave={(schedule: IJobSchedule) => dispatch(setSchedule(schedule))}
            />
          }
        </>
      }
    </>
  );
}

export default JobAttributesForm;