import React from "react";
import "./MainMenu.scoped.scss";
import { useRouteMatch, Link } from "react-router-dom";
import useSelector from "store/useSelector";
import { Routes } from "components/routing/Routing";
import { useDispatch } from "react-redux";
import { uploadCachedData } from "store/offline/offlineActions";
import exploreJobsIcon from "media/icons/dls/explore-jobs.svg";
import addNewJobIcon from "media/icons/dls/add-new-job.svg";
import uploadIcon from "media/icons/dls/upload.svg";
import teamIcon from "media/icons/dls/team.svg";
import { AuthCheck, AuthFilter } from "auth/AuthFilter";
import { Roles } from "interfaces/user/UserInterfaces";
import { useTranslation } from 'react-i18next';

const MainMenu: React.FC = () => {
  const {
    isAnythingAwaitingSync,
  } = useSelector(store => store.offline);
  
  const dispatch = useDispatch();
  const { t } = useTranslation('mainMenu');

  return (
    <div className="menu">
      <Link
        to={Routes.MyJobs}
        className={"menu-item " + (useRouteMatch(Routes.MyJobs) ? "selected" : "")}
        title={t('My Jobs')}>
        <img
          className="icon-medium black-to-slb-blue-filter"
          src={exploreJobsIcon}
          alt={t('My Jobs')}
        />
        <span>
          {t('My Jobs')}
        </span>
      </Link>
      <Link
        to={Routes.CreateJob}
        className={"menu-item " + (useRouteMatch(Routes.CreateJob)?.isExact === true ? "selected" : "")}
        title={t('Create Jobs')}>
        <img
          className="icon-medium black-to-slb-blue-filter"
          src={addNewJobIcon}
          alt={t('Create Jobs')}
        />
        <span>
        {t('Create Jobs')}
        </span>
      </Link>
      <AuthCheck
        authFilter={AuthFilter.isLoggedIn()
          .hasAnyRoleIn([Roles.ExecutionAdmin, Roles.SysAdmin])
        }
      >
        <Link
          to={Routes.UserManagement}
          className={"menu-item " + (useRouteMatch(Routes.UserManagement)?.isExact ? "selected" : "")}
          title={t('Users')}
        >
          <img
            className="icon-medium black-to-slb-blue-filter"
            src={teamIcon}
            alt={t('Users')}
          />
          <span>
          {t('Users')}
          </span>
        </Link>
      </AuthCheck>
      <span
        onClick={isAnythingAwaitingSync
          ? () => dispatch(uploadCachedData())
          : undefined
        }
        title={t('Sync offline data to server')}
        className={"menu-item " + (!isAnythingAwaitingSync ? "disabled" : "")}
      >
        <img
          className="icon-medium black-to-slb-blue-filter"
          src={uploadIcon}
          alt={t('Sync to server')}
        />
        <span>
        {t('Sync Data')}
        </span>
      </span>
    </div>
  );
}

export default MainMenu;