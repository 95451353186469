import React, { useCallback } from "react";
import { DndProvider } from "react-dnd-multi-backend";
import { HTML5toTouch } from 'rdndmb-html5-to-touch';
import DragDropSWHeader from "./DragDropSWHeader";
import { useDispatch } from "react-redux";
import useSelector from "store/useSelector";
import { moveSW, removeSW } from "store/manageJob/manageJobActions";

const ManageSWForm: React.FC = () => {
  const { sws } = useSelector(state => state.manageJob.job);
  const dispatch = useDispatch();

  const onMoveSW = useCallback((oldIndex: number, newIndex: number) => {
      dispatch(moveSW({ oldIndex, newIndex }));
    }, [dispatch]
  );

  return (
    <>
      <DndProvider options={HTML5toTouch}>
        {sws.map((sw, ix) =>
          <DragDropSWHeader
            key={sw.listGuid}
            sw={sw}
            index={ix}
            onMoveSW={onMoveSW}
            onRemoveClick={() => dispatch(removeSW({
              listGuid: sw.listGuid,
            }))}
          />
        )}
      </DndProvider>
      <div>*</div>
    </>
  );
}

export default ManageSWForm;