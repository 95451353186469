import { Task } from "redux-saga";
import { delay, fork } from "redux-saga/effects";

export function* batchCalls(maxConcurrent: number, reqData: any[], method: (req: any) => any, waitDelay: number = 100) : any {
  let requests = [...reqData];
  let pendingTasks: Task[] = [];
  let allTasks: Task[] = [];

  // While there are more requests to enqueue
  // or there are tasks still running...
  while (requests.length > 0
    || pendingTasks.length > 0) {
    // While there are still requests to enqueue
    // and there is still room to enqueue them...
    while (pendingTasks.length < maxConcurrent
      && requests.length > 0) {
      // Enqueue the next task.
      let req = requests.splice(0, 1)[0];
      const task = yield fork(method, req);
      pendingTasks.push(task);
      allTasks.push(task);
    }

    // Wait a little.
    yield delay(waitDelay);

    // Remove from pending tasks the completed ones.
    pendingTasks = pendingTasks.filter(x => x.isRunning());
  }

  return allTasks.map(x => x.result());
}