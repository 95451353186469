import React from "react";
import "./JobToolbar.scoped.scss";
import settingsIcon from "media/icons/dls/settings.svg";
import newDocIcon from "media/icons/dls/new-doc.svg";
import numberedListIcon from "media/icons/dls/numbered-list.svg";
import pdf from "media/icons/dls/pdf.svg";
import teamIcon from "media/icons/dls/team.svg";
import useSelector from "store/useSelector";
import { ManageJobViews } from "store/manageJob/manageJobTypes";
import { useDispatch } from "react-redux";
import { setCurrentJobView } from "store/manageJob/manageJobActions";
import { useTranslation } from "react-i18next";

interface IJobToolbarProps {
  numSWsInJob: number,
  numJobDocs: number,
  isTemplate: boolean,
}

const JobToolbar: React.FC<IJobToolbarProps> = ({
  numSWsInJob,
  numJobDocs,
  isTemplate,
}) => {

  const {
    currentView,
  } = useSelector(state => state.manageJob);

  const dispatch = useDispatch();
  const { t } = useTranslation('createJob');

  return (
    <div
      className="toolbar"
    >
      <span
        onClick={() => dispatch(setCurrentJobView(ManageJobViews.Attrs))}
        className={"tab " + (currentView === ManageJobViews.Attrs ? "active" : "")}>
        <img
          className="icon-medium"
          src={settingsIcon}
          alt={t('Job Attributes')}
          title={t('Job Attributes')} />
        <span>
          {t('Job Attributes')}
        </span>
      </span>
      <span
        onClick={() => dispatch(setCurrentJobView(ManageJobViews.Search))}
        className={"tab " + (currentView === ManageJobViews.Search ? "active" : "")}>
        <img
          className="icon-medium"
          src={newDocIcon}
          alt={t('Add Standard Work')}
          title={t('Add Standard Work')} />
        <span>
          {t('Add Standard Work')}
        </span>
      </span>
      <span
        onClick={() => dispatch(setCurrentJobView(ManageJobViews.SW))}
        className={"tab " + (currentView === ManageJobViews.SW ? "active" : "")}>
        <img
          className="icon-medium"
          src={numberedListIcon}
          alt={t("Review and Manage Standard Work")}
          title={t("Review and Manage Standard Work")} />
        <label className="sw-count-label">
          {numSWsInJob}
        </label>
        <span>
          {t("Review and Manage Standard Work")}
        </span>
      </span>
      {!isTemplate && <span
        onClick={() => dispatch(setCurrentJobView(ManageJobViews.Docs))}
        className={"tab " + (currentView === ManageJobViews.Docs ? "active" : "")}>
        <img
          className="icon-medium"
          src={pdf}
          alt={t("Add Job Docs")}
          title={t("Add Job Docs")} />
        <label className="sw-count-label">
          {numJobDocs}
        </label>
        <span>
          {t("Add Job Docs")}
        </span>
      </span>}
      <span
        onClick={() => dispatch(setCurrentJobView(ManageJobViews.Team))}
        className={"tab " + (currentView === ManageJobViews.Team ? "active" : "")}>
        <img
          className="icon-medium"
          src={teamIcon}
          alt={isTemplate
            ? t("Template Editors")
            : t("Team")
          }
          title={isTemplate
            ? t("Template Editors")
            : t("Team")
          } />
        <span>
          {t('Manage Team')}
        </span>
      </span>
    </div>);
}

export default JobToolbar;