import React, { useEffect, useState } from "react";
import { ISWPJob } from "interfaces/jobs/JobInterfaces";
import IdbApi from "apis/idb/IdbApi";
import Banner, { BannerType } from "components/common/Banner";
import BlockSpinner from "components/common/BlockSpinner";
import useSelector from "store/useSelector";
import { RouteComponentProps } from "react-router-dom";
import JobTemplateHeader from "../jobHeaders/JobTemplateHeader";
import { useTranslation } from "react-i18next";

interface IOfflineTemplatesListState {
  offlineTemplates: ISWPJob[],
  isLoading: boolean,
  loadError?: string,
}

const OfflineTemplatesList: React.FC<RouteComponentProps> = (props) => {
  // Pull the list of offline job ids so that when they change, the list of offline
  // jobs here also changes. It is passed as a dependency of useEffect.
  const {
    offline: {
      cachedJobIds,
    },
    auth: {
      currentUser,
    },
  } = useSelector(store => store);
  const [state, setState] = useState<IOfflineTemplatesListState>({
    offlineTemplates: [],
    isLoading: false,
  });

  const { t } = useTranslation('cacheTemplate');

  useEffect(() => {
    setState({
      offlineTemplates: [],
      isLoading: true,
      loadError: undefined,
    });

    let aborted = false;

    const loadCachedJobs = async () => {
      try {
        const offlineTemplates = (await IdbApi.getCachedTemplates())
          .sort((a, b) => b.lastActionDate.getTime() - a.lastActionDate.getTime());

        if (!aborted) {
          setState({
            offlineTemplates,
            isLoading: false,
            loadError: undefined,
          });
        }
      } catch (err: any) {
        if (!aborted) {
          setState({
            offlineTemplates: [],
            isLoading: false,
            loadError: err.message || err.toString(),
          });
        }
      }
    };

    loadCachedJobs();

    return () => {
      aborted = true;
    };
  }, [setState, cachedJobIds]);

  let mainComponent: JSX.Element | undefined;

  if (state.isLoading) {
    mainComponent = <BlockSpinner />;
  } else if (state.loadError) {
    mainComponent = (
      <Banner
        type={BannerType.Error}
      >
        {state.loadError}
      </Banner>
    );
  } else if (state.offlineTemplates.length === 0) {
    mainComponent = (
      <Banner
        type={BannerType.Info}
      >
        {t('You have no templates cached for offline use.')}
      </Banner>
    );
  } else {
    // Show list of jobs!
    mainComponent = (
      <>
        {state
          .offlineTemplates
          .map(job =>
            <JobTemplateHeader
              key={job.id}
              {...props}
              isOffline={true}
              jobHeader={{
                id: job.id,
                jobNumber: job.jobNumber,
                canEdit: job.team.some(x => x.email.toLowerCase() === currentUser.email.toLowerCase()),
                title: job.title,
                countryGuid: job.country?.guid || "",
                geoUnitCode: job.geoUnit?.code || "",
                subBusinessLineCode: job.subBusinessLine?.code || "",
              }}
            />
          )}
      </>
    );
  }

  return (
    <>
      <Banner
        type={BannerType.Warn}
      >
        {t('You have no network connectivity.')}
        {t('Below is your list of offline templates.')}
        {t('To retrieve the full list of templates, click the reload button')}
        {t('at the top of the page when you regain network connectivity.')}
      </Banner>
      {mainComponent}
    </>
  );
}

export default OfflineTemplatesList;