import Modal from "components/common/Modal";
import React from "react";
import { useTranslation } from "react-i18next";

export interface IFilterModalProps {
  filterControls?: JSX.Element,
  applyFilter(): void,
  clearFilter(): void,
  setIsModalOpen(value: boolean): void,
}

const FilterModal: React.FC<IFilterModalProps> = (props) => {
  const { t } = useTranslation('jobFilters');
  return (
    <Modal
      header={t("Job Filters")}
      isOpen={true}
      controls={
        <>
          <button
            className="secondary-button"
            onClick={() => {
              props.clearFilter();
              props.setIsModalOpen(false);
            }}
          >
            {t('Clear')}
          </button>
          <button
            className="primary-button"
            onClick={() => {
              props.applyFilter();
              props.setIsModalOpen(false);
            }}
          >
            {t('Apply')}
          </button>
        </>
      }
    >
      {props.filterControls}
    </Modal>
  );
};

export default FilterModal;