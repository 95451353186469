import React, { useEffect } from "react";
import { ITableComponentCellData } from "interfaces/sw/SWInterfaces";
import "./RefDocTableCell.scoped.scss";
import { useDispatch } from "react-redux";
import { showErrorToast } from "store/toast/toastActions";
import IdbApi from "apis/idb/IdbApi";
import { setRefDocTableCellImage } from "store/execution/executionActions";

interface IRefDocTableCellProps {
  rowKey: number,
  colKey: number,
  cells: ITableComponentCellData[],
  swId: string,
  swVersion: string,
  refDocGuid: string,
}

const RefDocTableCell: React.FC<IRefDocTableCellProps> = ({
  rowKey,
  colKey,
  cells,
  swId,
  swVersion,
  refDocGuid,
}) => {
  const dispatch = useDispatch();
  let existingCell = cells.find(x => x.rowIndex === rowKey && x.colIndex === colKey);
  let filename = "";

  if (existingCell?.value) {
    let cellImage = getImage(existingCell.value);

    if (cellImage) {
      filename = cellImage;
    }
  }

  useEffect(() => {
    let aborted = false;
    const fetchImage = async () => {
      try {
        const img = await IdbApi.getSWImageData(swId, swVersion, filename);
        if (!aborted) {
          dispatch(setRefDocTableCellImage({
            rowKey: rowKey,
            colKey: colKey,
            swId: swId,
            swVersion: swVersion,
            imageData: img?.data,
            refDocId: refDocGuid,
          }));
        }
      } catch (err: any) {
        if (!aborted) {
          showErrorToast(err?.message || err.toString())
        }
      }
    }

    if (filename !== "") {
      fetchImage();
    }

    return () => {
      aborted = true;
    };
  }, [existingCell, dispatch, filename, swId, swVersion, colKey, rowKey, refDocGuid]);

  if (!existingCell) {
    return null;
  }

  return (
    <div
      className="execution-editor"
      dangerouslySetInnerHTML={createMarkup(existingCell.value)}
    />
  );  
}

export default RefDocTableCell;

function createMarkup(data: string) {
  return { __html: data };
}

function getImage(html: string): string | undefined {
  const imgRex = /<img.*?alt="(.*?)"[^>]+>/g;
    let img;
    while ((img = imgRex.exec(html))) {
        return img[1];      
    }
  return undefined;
}