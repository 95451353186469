import { takeLatest, put, select, call, all, delay } from 'redux-saga/effects';
import { Action } from '@reduxjs/toolkit';
import { setAddUserModalData, setAddUserSearchText, getManageUsersList, setManageUsersData, setSearchManageUsersText, getUserForEdit, setEditUserData, createUser, updateUser, getUsersJobs, setUsersJobsOperation, setUsersJobsTab, setUsersJobs, setJobSearchTerm } from './userManagementActions';
import { RootState } from "../rootStore";
import { IAddUserModalData, IManageUsersData, IEditUserData, IUserManagementState } from './userManagementTypes';
import { IManageUserUser, IAADUser } from 'interfaces/user/UserInterfaces';
import { getResponseErrorMessage } from 'utilities/validationErrorHelpers';
import { showSuccessToast, showErrorToast } from 'store/toast/toastActions';
import UserApi from 'apis/user/UserApi';
import { ISWPJobHeader } from 'interfaces/jobs/JobInterfaces';
import i18n from 'i18n';

const t = i18n.getFixedT(null, 'sagas');

export default function* watchUserManagementSagas() {
  yield all([
    watchSetAddUserSearchText(),
    watchGetManageUsersList(),
    watchGetUserForEdit(),
    watchUpdateUser(),
    watchCreateUser(),
    watchGetUsersJobs(),
  ]);
}

function* watchSetAddUserSearchText() {
  yield takeLatest(setAddUserSearchText, setAddUserSearchTextAsync);
}

function* watchGetManageUsersList() {
  yield takeLatest([
    getManageUsersList,
    setSearchManageUsersText,
  ], refreshManageUsersListAsync);
}

function* watchGetUserForEdit() {
  yield takeLatest(getUserForEdit, getUserForEditAsync);
}

function* watchCreateUser() {
  yield takeLatest(createUser, createUserAsync);
}

function* watchUpdateUser() {
  yield takeLatest(updateUser, updateUserAsync);
}

function* watchGetUsersJobs() {
  yield takeLatest([getUsersJobs, setUsersJobsTab], getUsersJobsAsync);
}

function* setAddUserSearchTextAsync(action: Action) {
  if (!setAddUserSearchText.match(action)) {
    return;
  }

  let modalData: IAddUserModalData =
    yield select((store: RootState) => store.userManagement.addUserModalData);

  // If modal is closed, quit.
  if (!modalData.isOpen) {
    return;
  }

  // If no search text, clear modal results.
  if (!modalData.searchText.trim()) {
    yield put(setAddUserModalData({
      ...modalData,
      searchResults: [],
      loadOperation: undefined,
    }));
    return;
  }

  yield put(setAddUserModalData({
    ...modalData,
    loadOperation: {
      isWorking: true,
      errorMessage: "",
      wasSuccessful: false,
    },
  }));

  try {
    const searchResults: IAADUser[] = yield call(UserApi.searchAADUsers, action.payload);

    yield put(setAddUserModalData({
      ...modalData,
      searchResults,
      loadOperation: undefined,
    }));
  } catch (err: any) {
    yield put(setAddUserModalData({
      ...modalData,
      loadOperation: {
        isWorking: false,
        wasSuccessful: false,
        errorMessage: getResponseErrorMessage(err),
      },
    }));
  }
}

function* refreshManageUsersListAsync(action: Action) {
  let searchText = "";

  if (setSearchManageUsersText.match(action)) {
    searchText = action.payload;
  }

  const manageUsersData: IManageUsersData = yield select((store: RootState) => store
    .userManagement
    .manageUsersData);

  if (getManageUsersList.match(action)) {
    searchText = manageUsersData.searchText;
  }

  yield put(setManageUsersData({
    ...manageUsersData,
    getUsersOperation: {
      isWorking: true,
      errorMessage: "",
      wasSuccessful: false,
    },
  }));

  let userList: IManageUserUser[] = [];

  try {
    userList = yield call(UserApi.getManageUsersList, searchText);
  } catch (err: any) {
    yield put(setManageUsersData({
      ...manageUsersData,
      getUsersOperation: {
        isWorking: false,
        errorMessage: getResponseErrorMessage(err),
        wasSuccessful: false,
      },
    }));
    return;
  }

  yield put(setManageUsersData({
    ...manageUsersData,
    userList,
    getUsersOperation: undefined,
  }));
}

function* getUserForEditAsync(action: Action) {
  if (!getUserForEdit.match(action)) {
    return;
  }

  const editUserData: IEditUserData = yield select((store: RootState) => store
    .userManagement
    .editUserData);

  yield put(setEditUserData({
    ...editUserData,
    loadOperation: {
      isWorking: true,
      errorMessage: "",
      wasSuccessful: false,
    },
  }));

  let user: IManageUserUser | null;

  try {
    user = yield call(UserApi.getUser, action.payload);
  } catch (err: any) {
    yield put(setEditUserData({
      ...editUserData,
      loadOperation: {
        isWorking: false,
        wasSuccessful: false,
        errorMessage: getResponseErrorMessage(err),
      },
    }));
    return;
  }

  if (user) {
    yield put(setEditUserData({
      ...editUserData,
      user,
      isDirty: false,
      loadOperation: undefined,
    }));
    yield getUsersJobsAsync(action);
  } else {
    yield put(setEditUserData({
      ...editUserData,
      loadOperation: {
        isWorking: false,
        wasSuccessful: false,
        errorMessage: t('The user {user} was not found.', {user: action.payload}),
      },
    }));
  }
}

function* createUserAsync(action: Action) {
  if (!createUser.match(action)) {
    return;
  }

  const {
    addUserModalData,
    manageUsersData: {
      getUsersOperation,
      searchText,
    },
  }: IUserManagementState = yield select((store: RootState) => store.userManagement);

  yield put(setAddUserModalData({
    ...addUserModalData,
    createOperation: {
      isWorking: true,
    },
  }));

  try {
    yield call(UserApi.createUser, action.payload.user);
  } catch (err: any) {
    yield put(setAddUserModalData({
      ...addUserModalData,
      createOperation: undefined,
    }));
    yield put(showErrorToast(`${t('Failed to add')} ${action.payload.user.email}. ${getResponseErrorMessage(err)}`));
    return;
  }

  yield put(setAddUserModalData({
    ...addUserModalData,
    createOperation: undefined,
  }));

  yield put(showSuccessToast(`${action.payload.user.email} ${t('was added successfully')}.`));

  if (action.payload.refreshManageUsersList
    && !getUsersOperation
    && (!searchText
      || action.payload.user.email.toLowerCase().indexOf(searchText.toLowerCase()) > -1
      || action.payload.user.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1)) {
    yield put(getManageUsersList());
  }
}

function* updateUserAsync(action: Action) {
  if (!updateUser.match(action)) {
    return;
  }

  const editUserData: IEditUserData = yield select((store: RootState) => store
    .userManagement
    .editUserData);

  yield put(setEditUserData({
    ...editUserData,
    saveOperation: {
      isWorking: true,
    },
  }));

  try {
    yield call(UserApi.updateUser, action.payload);
  } catch (err: any) {
    yield put(setEditUserData({
      ...editUserData,
      saveOperation: undefined,
    }));
    yield put(showErrorToast(`${t('Failed to update')} ${action.payload.email}. ${getResponseErrorMessage(err)}`));
    return;
  }

  yield put(setEditUserData({
    ...editUserData,
    isDirty: false,
    saveOperation: undefined,
  }));

  yield put(showSuccessToast(`${action.payload.email} ${t('was updated successfully')}.`));
}

function* getUsersJobsAsync(action: Action) {
  if (setJobSearchTerm.match(action)) {
    yield delay(1000);
  }

  const editUserData: IEditUserData = yield select((store: RootState) => store
    .userManagement
    .editUserData); 

  let searchTerm = editUserData.jobData.jobSearchTerm;  
  let teamMemberEmail = editUserData.user?.email;
  let statusFilter = editUserData.jobData.currentTab;

  if (!teamMemberEmail) {
    return;
  }

  yield put(setUsersJobsOperation({
    isWorking: true,
  }));

  try {
    const searchResults: ISWPJobHeader[] = yield call(UserApi.getUsersJobs,
      statusFilter,
      searchTerm,
      teamMemberEmail);
    yield put(setUsersJobs(searchResults));
  } catch (err: any) {
    yield put(showErrorToast(`${t('Failed to get jobs for user')} ${teamMemberEmail}. ${getResponseErrorMessage(err)}`));
  } finally {
    yield put(setUsersJobsOperation({
      isWorking: false,
    }));
  }
}