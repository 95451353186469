import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { IStepComponent, StepComponentTypes, IMultiselectComponentData } from "interfaces/sw/SWInterfaces";
import { logJobAction, setComponentResponse } from "store/execution/executionActions";
import { ComponentResponseType } from "interfaces/execution/executionInterfaces";
import useSelector from "store/useSelector";
import { IStepIdentifier } from "store/execution/executionTypes";
import ItemPicker from "components/common/itemPicker/ItemPicker";
import { IMasterDataItem } from "interfaces/masterData/masterDataInterfaces";
import { getComponentResponse } from "utilities/swUtilities";
import Banner, { BannerType } from "components/common/Banner";

interface IMultiSelectInputProps {
  component: IStepComponent,
  isDisabled: boolean,
  stepInfo: IStepIdentifier,
}

const MultiSelectInput: React.FC<IMultiSelectInputProps> = ({ component, isDisabled, stepInfo }) => {
  const dispatch = useDispatch();
  const {
    auth: {
      currentUser: {
        email,
        name,
      },
    },
    execution: {
      stepResponses,
      executor,
    },
  } = useSelector(store => store);

  const [isExpectedValue, setIsExpectedValue] = useState(true);

  if (component.type !== StepComponentTypes.MultiSelectInput) {
    return null;
  }

  const compResponseValues = getComponentResponse(stepInfo,
    component.id,
    stepResponses)
    ?.values;

  const onItemAdded = (item: IMasterDataItem) => {
    setIsExpectedValue(true);
    let isDeviation = false;

    if (expectedValue !== undefined) {
      let compResponses: string[] | undefined;
      compResponses = compResponseValues
        ? [...compResponseValues, item.value]
        : [item.value]

      // Convert string to array to check the expected value.
      let expectedValueArray: string[] = expectedValue.split(",").sort();
      if (JSON.stringify(expectedValueArray) !== JSON.stringify(compResponses.sort())) {
        setIsExpectedValue(false);
        isDeviation = true;
      }
    }
    dispatch(setComponentResponse({
      response: {
        id: component.id,
        type: ComponentResponseType.MultiSelect,
        values: compResponseValues
          ? [...compResponseValues, item.value]
          : [item.value],
        isDeviation: isDeviation,
      },
      stepInfo,
      userEmail: executor?.email || email,
    }));

    if (isDeviation) {
      dispatch(logJobAction({
        action: `Component response for ${component.label} was completed with deviation.`,
        timestamp: new Date(),
        userName: executor?.name || name,
        userEmail: executor?.email || email,
      }));
    }
  }

  const onItemRemoved = (item: IMasterDataItem) => {
    setIsExpectedValue(true);
    let isDeviation = false;

    // Convert string to array to check the expected value.


    if (expectedValue !== undefined) {
      let expectedValueArray: string[] = expectedValue.split(",").sort();
      if (JSON.stringify(expectedValueArray) !== JSON.stringify(compResponseValues?.filter(x => x !== item.value).sort())) {
        setIsExpectedValue(false);
        isDeviation = true;
      }
    }

    dispatch(setComponentResponse({
      response: {
        id: component.id,
        type: ComponentResponseType.MultiSelect,
        values: compResponseValues
          ? compResponseValues.filter(x => x !== item.value)
          : [],
        isDeviation: isDeviation,
      },
      stepInfo,
      userEmail: executor?.email || email,
    }));

    if (isDeviation) {
      dispatch(logJobAction({
        action: `Component response for ${component.label} was completed with deviation.`,
        timestamp: new Date(),
        userName: executor?.name || name,
        userEmail: executor?.email || email,
      }));
    }
  }

  let options: string[] = [];
  let nonConform: string = "";
  let expectedValue: string | undefined = undefined;

  if (isMultiSelectData(component.data)) {
    options = component.data.options || [];
    nonConform = component.data.nonConform;
    expectedValue = component.data.expectedValue?.join(",");
  }

  let shouldShowConformInfo = expectedValue !== undefined;

  let existingResponseNonConform = false;
  if (compResponseValues
    && (compResponseValues.length !== 0
      && compResponseValues !== undefined)) {
    let selectedValue = compResponseValues;


    if (expectedValue !== undefined) {
      let expectedValueArray = expectedValue.split(",");
      let sortedArray: string[] = [...selectedValue].sort();
      let sortedExpectedArray: string[] = [...expectedValueArray].sort();
      if (JSON.stringify(sortedArray) !== JSON.stringify(sortedExpectedArray)) {
        existingResponseNonConform = true;
      }
    }
  }

  return (
    <div>
      {shouldShowConformInfo &&
        <Banner
          type={BannerType.Info}
        >
          <div className="conformInfo">
            <label>Expected Value:&nbsp;</label>
            <span>{expectedValue}</span>
          </div>
        </Banner>
      }
      {shouldShowConformInfo
        && (!isExpectedValue
          || existingResponseNonConform) &&
        <Banner
          type={BannerType.Warn}
        >
          {nonConform}
        </Banner>
      }
      <ItemPicker
        label={component.label}
        selectedItems={compResponseValues?.map(x => ({
          guid: x,
          value: x,
        })) || []}
        masterDataSection={{
          items: options.map(x => ({
            guid: x,
            value: x,
          }))
        }}
        loadItems={() => { }}
        itemFormatter={(item) => item.value}
        onAddItem={onItemAdded}
        onRemoveItem={onItemRemoved}
        disabled={isDisabled}
        allowMultiple={true}
      />
    </div>
  );
}

export default MultiSelectInput;

function isMultiSelectData(data: any): data is IMultiselectComponentData {
  return data !== null
    && data !== undefined
    && 'options' in data;
}