import Banner, { BannerType } from "components/common/Banner";
import Modal from "components/common/Modal";
import React from "react";
import { useDispatch } from "react-redux";
import useSelector from "store/useSelector";
import ItemPicker from "components/common/itemPicker/ItemPicker";
import { loadCountries, loadGeoUnits, loadSubBusinessLines } from "store/masterData/masterDataActions";
import { IMasterDataItem } from "interfaces/masterData/masterDataInterfaces";
import { setAttributePopup, cloneTemplate } from "store/jobTemplates/jobTemplatesActions";
import "./JobTemplateAttributesModal.scoped.scss";
import { showErrorToast } from "store/toast/toastActions";
import { useTranslation } from "react-i18next";

const JobTemplateAttributesModal: React.FC = () => {
  const {
    masterData: {
      countries,
      geoUnits,
      subBusinessLines
    },
    jobTemplates: {
      attributePopup,
    },
  } = useSelector(state => state)
  const dispatch = useDispatch();
  const { t } = useTranslation('jobHeader');

  const requiredItems = [];

  if (!attributePopup.template?.countryGuid) {
    requiredItems.push(t("country"));
  }

  if (!attributePopup.template?.geoUnitCode) {
    requiredItems.push(t("geounit"));
  }

  if (!attributePopup.template?.subBusinessLineCode) {
    requiredItems.push(t("subBusinessLine"));
  }

  const onClose = () => {
    dispatch(setAttributePopup({
      isOpen: false,
      country: undefined,
      geoUnit: undefined,
      subBusinessLine: undefined,
      template: undefined,
    }));
  }

  const onCreateJobClick = () => {
    if (!attributePopup.template) {
      dispatch(showErrorToast(t("No template selected.")));
      return;
    }

    dispatch(cloneTemplate({
      countryGuid: attributePopup.template?.countryGuid
        || attributePopup.country?.guid,
      geoUnitCode: attributePopup.template?.geoUnitCode
        || attributePopup.geoUnit?.guid,
      subBusinessLineCode: attributePopup.template?.subBusinessLineCode
        || attributePopup.subBusinessLine?.guid,
      template: attributePopup.template,
    }));

    onClose();
  }

  return (
    <Modal
      isOpen={true}
      header={t(`Select {items} for job`, {items: requiredItems.join(t(" and "))})}
      controls={
        <>
          <button
            className="secondary-button"
            onClick={onClose}
          >
            {t('Cancel')}
          </button>
          <button
            className="primary-button"
            disabled={(!attributePopup.country?.guid
              && !attributePopup.template?.countryGuid)
              || (!attributePopup.geoUnit?.guid
                && !attributePopup.template?.geoUnitCode)
              || (!attributePopup.subBusinessLine?.guid
                  && !attributePopup.template?.subBusinessLineCode)
            }
            onClick={onCreateJobClick}
          >
            {t('Create Job')}
          </button>
        </>
      }
    >
      <Banner
        type={BannerType.Info}
      >
        {t('Please select {items} to create the new job.', {items: requiredItems.join(t(" and "))})}
      </Banner>
      {!attributePopup.template?.geoUnitCode &&
        <div
          className="row"
        >
          <ItemPicker
            label={t("GeoUnit")}
            isMandatory
            selectedItems={attributePopup.geoUnit
              ? [attributePopup.geoUnit]
              : []
            }
            onAddItem={(item: IMasterDataItem) => dispatch(setAttributePopup({
              isOpen: true,
              country: attributePopup.country,
              geoUnit: item,
              subBusinessLine: attributePopup.subBusinessLine,
              template: attributePopup.template,
            }))}
            onRemoveItem={() => dispatch(setAttributePopup({
              country: attributePopup.country,
              geoUnit: undefined,
              subBusinessLine: attributePopup.subBusinessLine,
              isOpen: true,
              template: attributePopup.template,
            }))}
            loadItems={() => dispatch(loadGeoUnits({ includeAll: true }))}
            masterDataSection={geoUnits}
            loadOnStart={true}
            itemFormatter={(item: IMasterDataItem) => item.value}
          />
        </div>
      }
      {!attributePopup.template?.countryGuid &&
        <div
          className="row"
        >
          <ItemPicker
            label={t("Country")}
            isMandatory
            selectedItems={attributePopup.country
              ? [attributePopup.country]
              : []
            }
            onAddItem={(item: IMasterDataItem) => dispatch(setAttributePopup({
              isOpen: true,
              country: item,
              geoUnit: attributePopup.geoUnit,
              subBusinessLine: attributePopup.subBusinessLine,
              template: attributePopup.template,
            }))}
            onRemoveItem={() => dispatch(setAttributePopup({
              country: undefined,
              geoUnit: attributePopup.geoUnit,
              subBusinessLine: attributePopup.subBusinessLine,
              isOpen: true,
              template: attributePopup.template,
            }))}
            loadItems={() => dispatch(loadCountries({ includeAll: true }))}
            masterDataSection={countries}
            loadOnStart={true}
            itemFormatter={(item: IMasterDataItem) => item.value}
          />
        </div>
      }
      {!attributePopup.template?.subBusinessLineCode &&
        <div
          className="row"
        >
          <ItemPicker
            label={t("SubBusinessLine")}
            isMandatory
            selectedItems={attributePopup.subBusinessLine
              ? [attributePopup.subBusinessLine]
              : []
            }
            onAddItem={(item: IMasterDataItem) => dispatch(setAttributePopup({
              isOpen: true,
              country: attributePopup.country,
              geoUnit: attributePopup.geoUnit,
              subBusinessLine: item,
              template: attributePopup.template,
            }))}
            onRemoveItem={() => dispatch(setAttributePopup({
              country: attributePopup.country,
              subBusinessLine: undefined,
              geoUnit: attributePopup.geoUnit,
              isOpen: true,
              template: attributePopup.template,
            }))}
            loadItems={() => dispatch(loadSubBusinessLines({ includeAll: true }))}
            masterDataSection={subBusinessLines}
            loadOnStart={true}
            itemFormatter={(item: IMasterDataItem) => item.value}
          />
        </div>
      }
    </Modal>
  );
};

export default JobTemplateAttributesModal;