import Modal from "components/common/Modal";
import React from "react";
import { useTranslation } from "react-i18next";
import "./JobActionsModal.scoped.scss";

interface IJobActionsModalProps {
  header: string,
  jobDisplay: string,
  onClose(): void,
  children: any,
}

const JobActionsModal: React.FC<IJobActionsModalProps> = ({
  children,
  header,
  jobDisplay,
  onClose,
}) =>
{
  const { t } = useTranslation('jobHeader');
  return(
    <Modal
      isOpen={true}
      onCloseButtonClicked={onClose}
      header={header}
      controls={(
        <button
          className="primary-button"
          onClick={onClose}
        >
          {t('Cancel')}
        </button>
      )}
    >
      <b>
        {jobDisplay}
      </b>
      <div
        className="more-actions"
      >
        {children}
      </div>
    </Modal>);
}

export default JobActionsModal;