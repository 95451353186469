import { ISWPJobHeader, IJobTemplateHeader, MyJobsTabs, SWPJobStatus } from "interfaces/jobs/JobInterfaces";
import { createAction } from "@reduxjs/toolkit";
import { ICancelJobPayload, JobModal, IReopenJobModal, IJobDownloadPDFData, } from "./myJobsTypes";
import { IMasterDataItem } from "interfaces/masterData/masterDataInterfaces";
import { IOperation } from "interfaces/operations/OperationInterfaces";

export const cancelJob = createAction<ICancelJobPayload>("myJobs/cancelJob");
export const reopenJob = createAction<{ jobId: number, status: SWPJobStatus }>("myJobs/reopenJob");
export const removeSelfFromJob = createAction<number>("myJobs/removeSelfFromJob");
export const setIsUpdating = createAction<boolean>("myJobs/setIsUpdating");
export const unCacheCancelJob = createAction<{ jobId: number }>("myJobs/unCacheCancelJob");
export const setCancelJobOperation = createAction<IOperation>("myJobs/setCancelJobOperation");
export const setReopenJobOperation = createAction<IOperation>("myJobs/setReopenJobOperation");
export const printJob = createAction<number>("myJobs/printJob");
export const setJobPDFData = createAction<IJobDownloadPDFData>("myJobs/setJobPDFData");
export const getMyJobs = createAction("myJobs/getMyJobs");
export const setIsFetching = createAction<boolean>("myJobs/setIsFetching");
export const setAdminShowAll = createAction<boolean>("myJobs/setAdminShowAll");
export const setMyJobs = createAction<ISWPJobHeader[]>("myJobs/setMyJobs");
export const setJobTemplates = createAction<IJobTemplateHeader[]>("myJobs/setJobTemplates");
export const setError = createAction<string | undefined>("myJobs/setError");
export const setHasOfflineError = createAction<boolean>("myJobs/setHasOfflineError");
export const setMyJobsTab = createAction<MyJobsTabs>("myJobs/setMyJobsTab");
export const setReopenJobModal = createAction<{
  data: IReopenJobModal,
  modal: "reopenJobModal"
}>("myJobs/setReopenJobModal");
export const setJobModal = createAction<{
  data: JobModal,
  modal: "cacheModal" | "unCacheModal" | "cancelJobModal",
}>("myJobs/setJobModal");
export const removeJobsFromJobList = createAction<{
  jobIds: number[],
}>("myJobs/removeJobsFromJobList");

// Search and filter actions.
export const hydrateFilters = createAction<MyJobsTabs>("myJobs/hydrateFilters");
export const addOrgFilter = createAction<{
  filterSet: MyJobsTabs,
  item: IMasterDataItem,
}>("myJobs/addOrgFilter");
export const addCountryFilter = createAction<{
  filterSet: MyJobsTabs,
  item: IMasterDataItem,
}>("myJobs/addCountryFilter");
export const removeOrgFilter = createAction<{
  filterSet: MyJobsTabs,
  item: IMasterDataItem,
}>("myJobs/removeOrgFilter");
export const removeCountryFilter = createAction<{
  filterSet: MyJobsTabs,
  item: IMasterDataItem,
}>("myJobs/removeCountryFilter");
export const setSearchTerm = createAction<{
  filterSet: MyJobsTabs,
  searchTerm: string,
}>("myJobs/setSearchTerm");
export const clearFilters = createAction<MyJobsTabs>("myJobs/clearFilters");
export const duplicateJob = createAction<{ jobId: number }>("myJobs/duplicateJob");