import React from "react";
import BlockSpinner from "components/common/BlockSpinner";
import "./SearchSWForm.scoped.scss";
import Banner, { BannerType } from "components/common/Banner";
import SWHeader, { SWHeaderButtonMode } from "./SWHeader";
import { useDispatch } from "react-redux";
import useSelector from "store/useSelector";
import { addSW, setNewIndex } from "store/manageJob/manageJobActions";
import SearchBar from "./SearchBar";
import FlowLayout from "components/layout/FlowLayout";
import { useTranslation } from "react-i18next";

const SearchSWForm: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('createJob');
  const {
    searchSWData: {
      swData: {
        items,
        loadOperation,
        totalCount
      }
      , index
    }
  } = useSelector(state => state.manageJob);
  let searchResults: JSX.Element | undefined;
  const LENGTH = totalCount;
  const LIMIT = 100;
  const loadMore = () => {
    const newIndex = index + LIMIT;
    const newShowMore = newIndex < (LENGTH - 1);
    if (newShowMore) {
      dispatch(setNewIndex(newIndex));
    }
  }

  if (items && !loadOperation?.isWorking) {
    if (items.length > 0) {
      searchResults = (
        <>
          {items.map(sw => (
            <SWHeader
              key={sw.swId}
              {...sw}
              buttonMode={SWHeaderButtonMode.Add}
              onButtonClick={() => dispatch(addSW({
                jobSWId: undefined,
                swId: sw.swId,
                title: sw.title,
                description: sw.description,
                version: sw.version,
                sortOrder: 0,
                listGuid: "",
              }))}
            />
          )
          )}
        </>
      );
    } else {
      searchResults = <div>{t('No Standard Work was found with the specified search criteria.')}</div>;
    }
  }

  return (
    <>
      <FlowLayout
        header={(
          <SearchBar />
        )}
      >
        {!!loadOperation?.errorMessage &&
          <Banner type={BannerType.Error}>
            {loadOperation.errorMessage}
          </Banner>
        }
        {loadOperation?.isWorking &&
          <div className="spinner-row">
            <BlockSpinner />
          </div>
        }
        {searchResults}
      </FlowLayout>
      {items.length > 0 && (items.length !== totalCount) && <button className="primary-button" onClick={loadMore}> Load More </button>}
    </>
  );
}

export default SearchSWForm;