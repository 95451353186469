import Banner, { BannerType } from "components/common/Banner";
import Modal from "components/common/Modal";
import React from "react";
import { useDispatch } from "react-redux";
import { reopenJob, setReopenJobModal } from "store/myJobs/myJobsActions";
import useSelector from "store/useSelector";
import { SWPJobStatus } from "interfaces/jobs/JobInterfaces";
import { useTranslation } from "react-i18next";

const ReopenJobModal: React.FC = () => {
  const {
    myJobs: {
      reopenJobModal,
    }
  } = useSelector(store => store);
  const dispatch = useDispatch();
  const { t } = useTranslation('reopenJob');

  const onCancelJobClick = () => {
    dispatch(reopenJob({
      jobId: reopenJobModal.jobId,
      status: reopenJobModal.jobStatus
    }));

    onClose();
  }

  const onClose = () => {
    dispatch(setReopenJobModal({
      modal: "reopenJobModal",
      data: {
        isOpen: false,
        jobId: 0,
        jobDisplay: "",
        jobStatus: SWPJobStatus.New,
      },
    }));
  }

  return (
    <Modal
      isOpen={true}
      header={t(`Reopen Job - {jobDisplay}`, {jobDisplay: reopenJobModal.jobDisplay})}
      controls={
        <>
          <button
            className="secondary-button"
            onClick={onClose}
          >
            {t('Cancel')}
          </button>
          <button
            className="primary-button"
            onClick={onCancelJobClick}
          >
            {t('Reopen Job')}
          </button>
        </>
      }
    >
      <Banner
        type={BannerType.Warn}
      >
        {t('Warning This job will be ')}<b>{t('Re-Opened')}</b>{t(' and will be seen in Active Jobs List.')}<br />
        <br />
        {t('Are you sure?  If you do not want to ')}<b>{t('Re-Open')}</b>{t(' this job, click ')}<b>{t('CANCEL')}</b>.
      </Banner>
    </Modal>
  );
};

export default ReopenJobModal;